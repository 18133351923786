import React from "react";
import { Container, Row, Col } from "reactstrap";
import Gift from "../../../assests/images/gift.svg";
import "./Section2.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section2 = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="section2">
      <Container>
        <Row>
          <Col lg="8" md="8" sm="12" xs="12">
            <div className="section2__content">
              <p>{t("section2.title")}</p>
            </div>
          </Col>

          <Col lg="4" md="4" sm="12" xs="12">
            <div>
              <img src={Gift} alt="" className="gift" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section2;
