import React, { useRef } from "react";
import { Container, Row, Col } from "reactstrap";

import Pasii_de_facut from "../../../assests/images/Pasii-de-facut-pentru-tele-Portare.png";
import Crestem_comunitatea from "../../../assests/images/Crestem_comunitatea.svg";
import Facebook from "../../../assests/images/share/facebook.svg";
import Instagram from "../../../assests/images/share/instagram.svg";
import Linkedin from "../../../assests/images/share/linkedin.svg";

import StartTeleportare_btn from "../../../assests/images/homePage/StartTeleportare_btn.png";

import "./Section7.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section7 = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="section7" name="pasi-de-facut" id="pasi-de-facut">
      <Container>
        <div className="section7_Title">
          <p>{t("section7.title")}</p>
        </div>
        <div className="section7_subTitle">
          <p>
            {t("section7.subtitle")} <br /> {t("section7.subtitle1")}
          </p>
        </div>

        <Row className="section7_pasi">
          <Col lg="7" md="7">
            <div>
              <img src={Pasii_de_facut} alt="" className="Pasii_de_facut" />
            </div>
          </Col>

          <Col lg="5" md="5">
            <div className="section7_pasi_tele-Portare">
              <p>{t("section7.description")} </p>
              <p>{t("section7.description1")} </p>
              <p>{t("section7.description2")}</p>
              <p>{t("section7.description3")}</p>
              <p>{t("section7.description4")}</p>
              <p>{t("section7.description5")}</p>
            </div>
            <div className="section7_button">
              <div className="image-button">
                <img
                  src={StartTeleportare_btn}
                  className="image-with-hover-cursor"
                  onClick={(event) => (window.location.href = "/senflua-form")}
                  style={{
                    width: "206px",
                    height: "auto",
                  }}
                />
                <div
                  className="button-text image-with-hover-cursor"
                  onClick={(event) => (window.location.href = "/senflua-form")}
                  style={{
                    color: "white",
                    marginLeft: "10px",
                    marginTop: "7px",
                  }}
                >
                  {t("section7.button")}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="section7_Title2">
          <p>{t("section7.title2")}</p>
        </div>
        <div className="section7_subTitle2">
          <p>
            {t("section7.subtitle2")} <br /> {t("section7.subtitle3")}
          </p>
        </div>

        <Row className="section7_share">
          <Col lg="3" md="3"></Col>
          <Col lg="3" md="3">
            <div className="section7_share_title">
              <p>{t("section7.text1")}</p>
            </div>
            <div className="section7_share_icon">
              <a href="https://www.facebook.com/Senflua" target="_blank">
                <img src={Facebook} alt="" />
              </a>
              <a href="https://www.instagram.com/senflua/" target="_blank">
                <img src={Instagram} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/senflua-asigurari/"
                target="_blank"
              >
                <img src={Linkedin} alt="" />
              </a>
            </div>

            <div className="section7_share_subTitle">
              <p>{t("section7.text2")}</p>
            </div>
          </Col>
          <Col lg="3" md="3">
            <img
              src={Crestem_comunitatea}
              alt="Crestem comunitatea"
              className="Crestem_comunitatea"
            />
          </Col>
          <Col lg="3" md="3"></Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section7;
