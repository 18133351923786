import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import IlustratieBusiness from "../../../assests/images/IlustratieBusiness.svg";
import Contact from "../../../assests/images/homePage/senfluaAsistent.png";

import "./SenfluaBussines.css";

import { useLanguage } from "../../utils/utils.jsx";

const SenfluaBussines = () => {
  const { t, i18n } = useLanguage();

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="sectionMedical">
      <Container>
        <Row>
          <Col lg="8" md="6" sm="12" xs="12">
            <div className="sectionBusiness_title1">
              <p>{t("business.subtitle")}</p>
            </div>
            <div className="sectionMedical__subtitle2">
              <p>{t("business.title")}</p>
            </div>
            {screenSize > 1000 ? (
              <div className="sectionMedical__subtitle3">
                <p>
                  {t("business.description")}
                  <br /> {t("business.description1")}
                  <br /> {t("business.description2")}
                  <br /> {t("business.description3")}
                  tău.
                </p>
              </div>
            ) : (
              <div className="sectionMedical__subtitle3">
                <p>
                  {t("business.description")}
                  {t("business.description1")}
                  {t("business.description2")}
                  {t("business.description3")}
                </p>
              </div>
            )}

            <div className="image-button">
              <img
                src={Contact}
                alt=""
                className="Contact"
                style={{
                  width: "276px",
                  height: "auto",
                }}
                onClick={(event) => (window.location.href = "/senflua-form")}
              />
              <div
                className="button-text image-with-hover-cursor"
                onClick={(event) => (window.location.href = "/senflua-form")}
                style={{
                  marginLeft: "30px",
                  marginTop: "10px",
                }}
              >
                {t("business.button")}
              </div>
            </div>
          </Col>

          <Col lg="4" md="6" sm="12" xs="12">
            <img
              src={IlustratieBusiness}
              alt=""
              className="IlustratieBusiness"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SenfluaBussines;
