import React, { Fragment } from "react";
import HeaderRCA from "../HeaderRCA/HeaderRCA";
import RCAbuy from "../RCA_buyTBI/RCA_buy";
import FooterRCA from "../FooterRCA/FooterRCA";
import TabBar from "../TabBar/TabBar";

const RCA_buyTBI = () => {
  return (
    <Fragment>
      <TabBar />
      <HeaderRCA />
      <RCAbuy />
      <FooterRCA />
    </Fragment>
  );
};

export default RCA_buyTBI;
