import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";

import Vreau_sa_discut_btn from "../../assests/images/RCA/Vreau_sa_discut_btn.svg";
import travel_hello from "../../assests/images/Travel/Life_Hello.svg";
import travel_yay from "../../assests/images/Travel/travel_yay.svg";
import btn from "../../assests/images/Travel/calatorie_btn.png";
import asiguratori from "../../assests/images/RCA/asiguratori.png";

import { useTranslation } from "react-i18next";
import "../../i18n";

import "./LP_Travel.css";

const LP_Travel = () => {
  useEffect(() => {
    document.title = "Senflua - Travel";
  }, []);

  const { t, i18n } = useTranslation();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slides = [
    <div key={1}>
      <Container className="containerTravel__lp">
        <Row className="sectionTravel__form">
          <Col lg="6" md="6" className="sectionTravel__left">
            <p className="sectionTravel__left_title1">
              {t("travel.lpTravel.title11")}
            </p>
            <p className="sectionTravel__left_title2">
              {t("travel.lpTravel.title12")}
            </p>
            <p className="sectionTravel__left_text1">
              {t("travel.lpTravel.subtitle11")}
              <br />
              {t("travel.lpTravel.subtitle12")}
            </p>

            <div
              className="image-button Travel_btn"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={btn}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
                style={{
                  width: "270px",
                  height: "auto",
                }}
              />

              <div
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                {t("travel.lpTravel.button1")}
              </div>
            </div>
          </Col>
          <Col lg="6" md="6" className="sectionTravel__lp">
            <p className="sectionTravel__right_title1"> #SENFLUA.LIFE</p>
            <img src={travel_hello} alt="" className="Travel_right1" />
          </Col>
        </Row>
      </Container>
    </div>,
    <div key={2}>
      <Container className="containerTravel__form">
        <Row className="sectionTravel__form">
          <Col xl="6" lg="6" md="12" sm="12" className="sectionTravel__left">
            <p className="sectionTravel__left_title1">
              {t("travel.lpTravel.title21")}
            </p>
            <p className="sectionTravel__left_title3">
              {t("travel.lpTravel.title22")}
            </p>
            <p className="sectionTravel__left_text1">
              {t("travel.lpTravel.subtitle21")} <br />
              {t("travel.lpTravel.subtitle22")}
            </p>
            <p className="sectionTravel__left_text2">
              {t("travel.lpTravel.subtitle23")} <br />{" "}
              {t("travel.lpTravel.subtitle24")}
            </p>

            <div
              className="image-button Travel_btn"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={btn}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
                style={{
                  width: "270px",
                  height: "auto",
                }}
              />

              <div
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "30px",
                  marginTop: "10px",
                }}
              >
                {t("travel.lpTravel.button1")}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="6" md="12" sm="12" className="sectionTravel__lp">
            <p className="sectionTravel__right_title1"> #SENFLUA.THINGS</p>
            <img src={asiguratori} alt="" className="Travel_right2" />
          </Col>
        </Row>
      </Container>
    </div>,
    <div key={3}>
      <Container className="containerTravel__form">
        <Row className="sectionTravel__form">
          <Col xl="6" lg="6" md="12" sm="12" className="sectionTravel__left">
            <p className="sectionTravel__left_title1">
              {t("travel.lpTravel.title31")}
            </p>
            <p className="sectionTravel__left_title3">
              {t("travel.lpTravel.title32")}
            </p>
            <p className="sectionTravel__left_text4">
              {t("travel.lpTravel.subtitle31")}
              <br />
              {t("travel.lpTravel.subtitle32")}
              <br /> {t("travel.lpTravel.subtitle33")}
              <br /> {t("travel.lpTravel.subtitle34")}
            </p>

            <div
              className="image-button Travel_btn"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={btn}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
                style={{
                  width: "270px",
                  height: "auto",
                }}
              />

              <div
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "30px",
                  marginTop: "10px",
                }}
              >
                {t("travel.lpTravel.button1")}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="6" md="12" sm="12" className="sectionTravel__lp">
            <p className="sectionTravel__right_title1"> #SENFLUA.THINGS</p>
            <img src={travel_yay} alt="" className="Travel_right3" />
          </Col>
        </Row>
      </Container>
    </div>,
  ];

  return (
    <section className="section_formTravel">
      <Slider {...settings}>{slides}</Slider>
    </section>
  );
};

export default LP_Travel;
