import React from "react";

import "./PopUpInfo.css";

const PopUpInfo = ({ top, left, text, title }) => {
  return (
    <div
      className="popup-container"
      style={{
        top: top + 10,
        left: left + 10,
      }}
    >
      {title.length > 0 && <p className="titlePopUpInfo">{title}</p>}
      <p className="textPopUpInfo">{text}</p>
    </div>
  );
};

export default PopUpInfo;
