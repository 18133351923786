import React from "react";

import Calatorie from "../../assests/images/SenfluaForm/Calatorie.svg";
import Viata from "../../assests/images/SenfluaForm/Viata.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipAsigurareLife({ formAig, setFormAig, page, setPage }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        #senflua.LIFE
      </p>
      <p className="nameTitle2">{t("typeForm.tipAsigurareLife.title")}</p>

      <div style={{ marginBottom: "30px" }}>
        <label
          className="senfluaFormTipAsigLife image-with-hover-cursor"
          style={{ width: "172px", height: "101px" }}
          onClick={(event) => {
            setPage(7);
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                viataCalatorie: 1,
              },
            }));
          }}
        >
          <img src={Viata} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipAsigurareLife.v1")}
        </label>

        <label
          className="senfluaFormTipAsigLife image-with-hover-cursor"
          onClick={(event) => {
            setPage(11);
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                viataCalatorie: 2,
              },
            }));
          }}
        >
          <img src={Calatorie} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipAsigurareLife.v2")}
        </label>
      </div>
    </div>
  );
}

export default TipAsigurareLife;
