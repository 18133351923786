import React from "react";

import FooterupTravel from "../Footer_up_Travel/Footer_up_Travel";
import FooterDownTravel from "../Footer_Down_Travel/Footer_Down_Travel";

import "./FooterTravel.css";

const FooterTravel = () => {
  return (
    <footer className="FooterTravel" id="contact">
      <FooterupTravel />
      <FooterDownTravel />
    </footer>
  );
};

export default FooterTravel;
