import React from "react";
import "./PassList.css"; // Asigură-te că ai un fișier CSS pentru a defini stilurile

const PassList = ({ yellowCount }) => {
  const words = [
    "Pasul 1 - despre locuinta",
    "Pasul 2 - bunuri",
    "PASUL 3 - FRANȘIZA",
    "FINAL",
  ];

  return (
    <div className="word-list">
      {words.map((word, index) => (
        <div key={index}>
          <span
            className={`word-container ${
              index < yellowCount ? "wordYello" : "wordBlack"
            }`}
          >
            {word}
          </span>
          <div
            className={`word-container ${
              index < yellowCount ? "underlineyellow" : "underlineblack"
            }`}
          />
        </div>
      ))}
    </div>
  );
};

export default PassList;
