import React, { Fragment } from "react";
import HeaderTravel from "../HeaderTravel/HeaderTravel";
import FooterTravel from "../FooterTravel/FooterTravel";
import Confirmare_HomeProtect from "../Confirmare_HomeProtect/Confirmare_HomeProtect.jsx";

const ConfirmareHomeProtect = () => {
  return (
    <Fragment>
      <HeaderTravel />
      <Confirmare_HomeProtect />
      <FooterTravel />
    </Fragment>
  );
};

export default ConfirmareHomeProtect;
