import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-range-slider-input/dist/style.css";

import Spinner from "react-bootstrap/Spinner";

import "./HomeProtectNew.css";
import { Container, Row, Col } from "reactstrap";

import { useTranslation } from "react-i18next";

import PassList from "./components/passList/PassList";
import DownloadPDFButton from "./components/DownloadPDFButton/DownloadPDFButton";
import PopUpInfo from "./components/PopUpInfo/PopUpInfo";

import sageata from "../../assests/images/RCA/sageata.svg";
import portabile from "../../assests/images/HomeProtect/portabile.svg";
import bunuriPortabile from "../../assests/images/HomeProtect/bunuriPortabile.svg";
import inundatie from "../../assests/images/HomeProtect/inundatie.svg";
import bunuri from "../../assests/images/HomeProtect/bunuri.svg";
import electrice from "../../assests/images/HomeProtect/electrice.svg";

import centralapng from "../../assests/images/HomeProtect/centrala.svg";
import window1 from "../../assests/images/HomeProtect/window.svg";
import dauneVecini from "../../assests/images/HomeProtect/dauneVecini.svg";
import pass from "../../assests/images/HomeProtect/pass.png";
import contactfooter from "../../assests/images/HomeProtect/contact.svg";
import whatsapp from "../../assests/images/HomeProtect/whatsapp.png";

import money from "../../assests/images/HomeProtect/money.svg";

import "../../i18n";

function Bunuri({
  home,
  setHome,
  judete,
  setJudete,
  orase,
  setOrase,
  constructia,
  currency,
  covers,
  setCovers,
  page,
  setPage,
  oferta,
  setOferta,
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [afiseazaLista, setAfiseazaLista] = useState(false);
  const [afiseazaPopUpLocuinta, setAfiseazaPopUpLocuinta] = useState(false);
  const [afiseazaPopUpPortabile, setAfiseazaPopUpPortabile] = useState(false);
  const [afiseazaPopUpElectrice, setAfiseazaPopUpElectrice] = useState(false);
  const [afiseazaPopUpGaz, setAfiseazaPopUpGaz] = useState(false);
  const [afiseazaPopUpCentrale, setAfiseazaPopUpCentrale] = useState(false);
  const [pozitieCursor, setPozitieCursor] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e, parametru) => {
    setPozitieCursor({ top: e.clientY, left: e.clientX });

    if (parametru === 1) {
      setAfiseazaPopUpLocuinta(true);
    } else if (parametru === 2) {
      setAfiseazaPopUpPortabile(true);
    } else if (parametru === 3) {
      setAfiseazaPopUpElectrice(true);
    } else if (parametru === 4) {
      setAfiseazaPopUpGaz(true);
    } else if (parametru === 5) {
      setAfiseazaPopUpCentrale(true);
    } else if (parametru === 6) setAfiseazaLista(true);
  };

  const handleMouseLeave = () => {
    setAfiseazaPopUpLocuinta(false);
    setAfiseazaPopUpPortabile(false);
    setAfiseazaPopUpElectrice(false);
    setAfiseazaPopUpGaz(false);
    setAfiseazaPopUpCentrale(false);
    setAfiseazaLista(false);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [errorSuma, setErrorSuma] = useState(false);
  const [errorSA, setErrorSA] = useState(false);

  const calculateVecini = (locuintaValue) => {
    return locuintaValue * 0.2;
  };

  const calculateGaz = () => {
    const newBunuri = parseInt(covers.bunuri, 10);
    const newLocuinta = parseInt(covers.locuinta, 10);

    const limitValue = (newBunuri + newLocuinta) * 0.1;
    return limitValue;
  };

  let intr = 0;

  useEffect(() => {
    if (intr === 0) {
      functionRefresh();
      intr = 1;
    }
  }, []);

  async function functionRefresh() {
    const isInvalidCoverAmount =
      (home.currency === "RON" &&
        (covers.locuinta < 100000 || covers.locuinta > 15000000)) ||
      (home.currency === "EUR" &&
        (covers.locuinta < 20000 || covers.locuinta > 300000));

    if (isInvalidCoverAmount) {
      setErrorSuma(true);

      window.scrollTo(0, 0);
    } else {
      setErrorSuma(false);
      setShowPopup(true);
      const requestOptions1 = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ quoteRequest: home, covers: covers }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/ofertaHouse1`,
          requestOptions1
        );
        const json = await response.json();
        if (json.error === false) {
          setOferta((prevOferta) => {
            const updatedOferta = {
              ...prevOferta,
              error: false,
              badRequest: {
                ...prevOferta.badRequest,
                code: "",
                message: "",
              },
            };

            // Actualizează valorile existente din json în oferta
            for (const key in json) {
              if (json.hasOwnProperty(key)) {
                if (key === "covers") {
                  // Dacă cheia este "covers", actualizează individual covers
                  updatedOferta[key] = updatedOferta[key].map((cover) => {
                    const jsonCover = json[key].find(
                      (jsonCover) =>
                        jsonCover.productCoverCode === cover.productCoverCode
                    );
                    if (jsonCover) {
                      jsonCover.name = cover.name; // utilizează numele din oferta curentă
                    }
                    return jsonCover || cover;
                  });
                } else {
                  updatedOferta[key] = json[key];
                }
              }
            }

            // Pentru cheile care nu există în json, setează insuredSum pe 0 în covers
            if (updatedOferta.covers) {
              updatedOferta.covers.forEach((cover) => {
                if (
                  !json.covers.find(
                    (jsonCover) =>
                      jsonCover.productCoverCode === cover.productCoverCode
                  )
                ) {
                  cover.insuredSum = 0;
                  cover.premium = 0;
                }
              });
            }

            return updatedOferta;
          });
        } else {
          setOferta((prevOferta) => ({
            ...prevOferta,
            error: true,
            offerId: "0",
            series: "0",
            number: "0",
            badRequest: {
              ...prevOferta.badRequest,
              code: json.badRequest.code,
              message: json.badRequest.message,
            },
            covers: prevOferta.covers.map((cover) => ({
              ...cover,
              premium: 0,
            })),
          }));
          setCovers((prevState) => ({
            ...prevState,
            electrice: 0,
          }));
        }
      } catch (error) {
        navigate("/eroare");
      }
      setShowPopup(false);
    }
  }

  useEffect(() => {
    const updatedVecini = calculateVecini(covers.locuinta);
    setLimite((prevLimite) => ({
      ...prevLimite,
      geamuri: updatedVecini,
      gaz: calculateGaz(),
    }));
  }, [covers.locuinta, covers.unelte]);

  const [limite, setLimite] = useState({
    bunuri:
      home.currency === "EUR"
        ? covers.locuinta * 0.2 < 25000
          ? covers.locuinta * 0.2
          : 25000
        : home.currency === "RON"
        ? covers.locuinta * 0.2 < 125000
          ? covers.locuinta * 0.2
          : 125000
        : 0,
    unelte:
      home.currency === "EUR" ? 10000 : home.currency === "RON" ? 50000 : 0,
    echipamente:
      home.currency === "EUR" ? 1000 : home.currency === "RON" ? 5000 : 0,
    electrice:
      home.currency === "EUR" ? 2000 : home.currency === "RON" ? 10000 : 0,
    gaz: calculateGaz(),
    centrala:
      home.currency === "EUR" ? 10000 : home.currency === "RON" ? 50000 : 0,
    geamuri: calculateVecini(covers.locuinta),
    vecini:
      home.currency === "EUR" ? 10000 : home.currency === "RON" ? 50000 : 0,
  });

  const updateLimite = (event) => {
    setLimite((prevLimite) => ({
      ...prevLimite,
      bunuri:
        home.currency === "EUR"
          ? event.target.value * 0.2 < 25000
            ? event.target.value * 0.2
            : 25000
          : home.currency === "RON"
          ? event.target.value * 0.2 < 125000
            ? event.target.value * 0.2
            : 125000
          : 0,
      geamuri: calculateVecini(event.target.value),
      vecini: calculateVecini(event.target.value),
      gaz: calculateGaz(),
    }));
    setCovers((prevState) => ({
      ...prevState,
      locuinta: event.target.value,
      bunuri: 0,
      unelte: 0,
      echipamenteTelefoane: 0,
      echipamenteTablete: 0,
      echipamenteFoto: 0,
      electrice: 0,
      gaz: 0,
      centrala: 0,
      geamuri: 0,
      restul: 0,
    }));
  };

  useEffect(() => {
    const calculateLimits = () => {
      const newBunuri = covers.bunuri;
      const newUnelte = covers.unelte;
      const newEchipamente =
        covers.echipamenteTelefoane +
        covers.echipamenteTablete +
        covers.echipamenteFoto;

      const limitValue = (newBunuri + newUnelte + newEchipamente) * 0.5;
      const limitValueElectrice = newBunuri * 0.5;

      setCovers((prevState) => {
        const updatedCovers = { ...prevState };

        if (covers.electrice > limitValue && covers.gaz > calculateGaz()) {
          updatedCovers.electrice = covers.bunuri * 0.5;
          updatedCovers.gaz = calculateGaz();
        } else if (covers.electrice > limitValue) {
          updatedCovers.electrice = limitValue;
        } else if (covers.gaz > calculateGaz()) {
          updatedCovers.gaz = calculateGaz();
        }

        return updatedCovers;
      });

      setLimite({
        ...limite,
        electrice: limitValueElectrice,
        gaz: calculateGaz(),
      });

      const limit =
        covers.bunuri +
        covers.unelte +
        covers.echipamenteTelefoane +
        covers.echipamenteTablete +
        covers.echipamenteFoto;
      if (limit > covers.locuinta * 0.5) setErrorSA(true);
      else setErrorSA(false);
    };
    calculateLimits();
  }, [
    covers.bunuri,
    covers.unelte,
    covers.echipamenteTelefoane,
    covers.echipamenteTablete,
    covers.echipamenteFoto,
  ]);

  const handleValueChange = (value) => {
    if (home.currency === "EUR") {
      if (value < 500) {
        return Math.ceil(value / 500) * 500;
      } else {
        return Math.floor(value / 100) * 100;
      }
    } else {
      if (value < 2500) {
        return Math.ceil(value / 2500) * 2500;
      } else {
        return Math.floor(value / 500) * 500;
      }
    }
  };

  const calculateCovers = () => {
    // Calculăm suma valorilor din covers
    const entries = Object.entries(covers).filter(
      ([key]) => key !== "locuinta"
    );

    const totalCovers = entries.reduce((acc, [_, val]) => acc + val, 0);
    return totalCovers;
  };

  const calculateAndCompare = () => {
    const totalCovers = calculateCovers();
    return totalCovers < limite.bunuri;
  };

  const handleChangeBunuri = (event) => {
    const newValue = Number(event.target.value);
    const updatedValue = handleValueChange(newValue);

    setCovers((prevState) => ({
      ...prevState,
      bunuri: updatedValue,
    }));

    if (newValue === 0) {
      setCovers((prevState) => ({
        ...prevState,
        unelte: 0,
        echipamenteTelefoane: 0,
        echipamenteTablete: 0,
        echipamenteFoto: 0,
        electrice: 0,
        gaz: 0,
      }));
    } else {
    }
  };

  const handleChangePortabile = (event) => {
    if (covers.bunuri > 0) {
      const newValue = Number(event.target.value);
      const updatedValue = handleValueChange(newValue);

      setCovers((prevState) => ({
        ...prevState,
        unelte: updatedValue,
      }));
    }
  };

  const handleValuePortabile = (value) => {
    if (home.currency === "EUR") {
      if (value < 200) {
        return Math.ceil(value / 200) * 200;
      } else {
        return Math.floor(value / 100) * 100;
      }
    } else {
      if (value < 1000) {
        return Math.ceil(value / 1000) * 1000;
      } else {
        return Math.floor(value / 500) * 500;
      }
    }
  };

  const handleChangeTelefon = (event) => {
    if (covers.bunuri > 0) {
      const newValue = Number(event.target.value);
      const updatedValue = handleValuePortabile(newValue);

      setCovers((prevState) => ({
        ...prevState,
        echipamenteTelefoane: updatedValue,
      }));
    }
  };
  const handleChangeTableta = (event) => {
    if (covers.bunuri > 0) {
      const newValue = Number(event.target.value);
      const updatedValue = handleValuePortabile(newValue);

      setCovers((prevState) => ({
        ...prevState,
        echipamenteTablete: updatedValue,
      }));
    }
  };
  const handleChangeFoto = (event) => {
    if (covers.bunuri > 0) {
      const newValue = Number(event.target.value);
      var updatedValue = handleValuePortabile(newValue);
      const calculate = calculateCovers() + updatedValue;

      setCovers((prevState) => ({
        ...prevState,
        echipamenteFoto: updatedValue,
      }));
    }
  };

  const updateHome = () => {
    if (errorSA === false) {
      functionRefresh();
      if (oferta.error === false) {
        setPage(page + 1);
      }
    }
    window.scrollTo(0, 0);
  };

  return (
    <section className="sectionLocuinte">
      <PassList yellowCount="2" />
      <Container>
        <div className="sectionTravel_buy__form_back">
          <img
            src={sageata}
            alt=""
            className="image-with-hover-cursor sageata_travel"
            onClick={() => {
              window.scrollTo(0, 0);
              setPage(page - 1);
            }}
          />
          <p
            className="sectionTravel_buy_button_back image-with-hover-cursor"
            onClick={() => {
              window.scrollTo(0, 0);
              setPage(page - 1);
            }}
          >
            {t("travelEurolife.back")}
          </p>
        </div>

        <Row className="sectionHP_BunuriRow">
          <Col xl="1" lg="0" md="0" sm="0"></Col>
          <Col xl="9" lg="10" md="10" sm="12">
            <div className="sectionHP_Bunuri">
              <p className="sectionLocuinte_BunuriInfo">
                {t("homeProtect.pasul2.title1")}{" "}
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "LUNARA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pasul2.title2")}
              </p>
              <p className="sectionLocuinte_BunuriSubInfo">
                {t("homeProtect.pasul2.subtitle1")}
              </p>
              <div>
                <p className="sectionLocuinte_BunuriText">
                  {t("homeProtect.pasul2.subtitleInput")}

                  <input
                    className="section_BunuriTextInput"
                    type="text"
                    name="name"
                    placeholder={t("homeProtect.pasul1.subtitle61")}
                    value={covers.locuinta}
                    onChange={updateLimite}
                  />
                  {home.currency}
                </p>
              </div>
              <label className="section_BunuriTextriscuri">
                {t("homeProtect.pasul2.riscurileAsigurate")}
                <label
                  className="section_BunuriTextriscuriUnder"
                  onMouseEnter={(e) => handleMouseEnter(e, 1)}
                  onMouseLeave={handleMouseLeave}
                >
                  {t("homeProtect.pasul2.riscurileAsigurate1")}
                </label>
              </label>

              {afiseazaLista && (
                <PopUpInfo
                  top={pozitieCursor.top}
                  left={pozitieCursor.left}
                  text={t("homeProtect.pasul2.infoasig1")}
                  title={""}
                />
              )}

              {oferta.error === true && (
                <label className="errorTextLocuinte">
                  {oferta.badRequest.message}
                </label>
              )}
              {errorSuma === true && (
                <label className="errorTextLocuinte">
                  {t("homeProtect.pasul2.subtitleText")}
                </label>
              )}
              <p className="locuinte_infopermanent">
                {t("homeProtect.pasul2.indicatii")}
              </p>
            </div>
            <div className="sectionLocuinte_Bunuri1">
              <Row className="sectionLocuinte_BunuriRow1">
                <p className="sectionLocuinte_BunuriInfo">
                  {t("homeProtect.pasul2.title3")}
                </p>
                <p className="section_BunuriTextriscuri">
                  {t("homeProtect.pasul2.subtitle3")}
                </p>
                <Col
                  xl="4"
                  lg="4"
                  md="6"
                  sm="12"
                  className="sectionHP_BunuriSpace"
                >
                  <img src={bunuri} alt="" />

                  <p className="sectionLocuinte_BunuriAsig">
                    {t("homeProtect.pasul2.asig1")}
                  </p>
                  <p
                    style={{
                      minHeight: "50px",
                    }}
                    className="sectionLocuinte_BunuriSubAsig"
                  >
                    {t("homeProtect.pasul2.subasig1")}
                    <label
                      className="letterMic"
                      onMouseEnter={(e) => handleMouseEnter(e, 6)}
                      onMouseLeave={handleMouseLeave}
                    >
                      i
                    </label>
                  </p>
                  {afiseazaPopUpLocuinta && (
                    <PopUpInfo
                      top={pozitieCursor.top}
                      left={pozitieCursor.left}
                      text={t("homeProtect.pasul2.textListaRiscuri")}
                      title={t("homeProtect.pasul2.titleListaRiscuri")}
                    />
                  )}
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.bunuri}
                      value={covers.bunuri}
                      onChange={handleChangeBunuri}
                    />
                  </div>
                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.bunuri}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {/* {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_B_2000"
                    ).premium || 0}{" "} */}
                    {parseFloat(
                      oferta.covers.find(
                        (covers) => covers.productCoverCode === "0814_B_2000"
                      ).premium || 0
                    ) +
                      parseFloat(
                        oferta.covers.find(
                          (covers) => covers.productCoverCode === "0814_DOCUMEN"
                        ).premium || 0
                      )}{" "}
                    {home.currency}
                  </p>
                </Col>
                <Col xl="4" lg="4" md="6" sm="12">
                  <img
                    src={bunuriPortabile}
                    alt=""
                    style={{
                      height: "98px",
                      marginBottom: "10px",
                    }}
                  />
                  <p className="sectionLocuinte_BunuriAsig">
                    {t("homeProtect.pasul2.asig3")}
                  </p>
                  <p
                    className="sectionLocuinte_BunuriSubAsig"
                    // style={{
                    //   marginBottom: "16px",
                    // }}
                    style={{
                      minHeight: "50px",
                    }}
                  >
                    {t("homeProtect.pasul2.subasig3")}
                    <label
                      className="letterMic"
                      onMouseEnter={(e) => handleMouseEnter(e, 2)}
                      onMouseLeave={handleMouseLeave}
                    >
                      i
                    </label>
                  </p>
                  {afiseazaPopUpPortabile && (
                    <PopUpInfo
                      top={pozitieCursor.top}
                      left={pozitieCursor.left}
                      text={t("homeProtect.pasul2.infoasig3")}
                      title={""}
                    />
                  )}
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.unelte}
                      value={covers.unelte}
                      onChange={handleChangePortabile}
                    />
                  </div>
                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.unelte}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_BUP"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>
                <Col xl="4" lg="4" md="6" sm="12">
                  <img
                    src={electrice}
                    style={{
                      height: "98px",
                      marginBottom: "10px",
                    }}
                    alt=""
                  />
                  <p className="sectionLocuinte_BunuriAsig">
                    {t("homeProtect.pasul2.asig4")}
                  </p>
                  <p
                    className="sectionLocuinte_BunuriSubAsig"
                    style={{
                      minHeight: "50px",
                    }}
                  >
                    {t("homeProtect.pasul2.subasig4")}
                    <label
                      className="letterMic"
                      onMouseEnter={(e) => handleMouseEnter(e, 3)}
                      onMouseLeave={handleMouseLeave}
                    >
                      i
                    </label>
                  </p>
                  {afiseazaPopUpElectrice && (
                    <PopUpInfo
                      top={pozitieCursor.top}
                      left={pozitieCursor.left}
                      text={t("homeProtect.pasul2.infoasig4")}
                      title={""}
                    />
                  )}
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.electrice}
                      value={covers.electrice}
                      onChange={(event) => {
                        if (covers.bunuri > 0) {
                          setCovers((prevState) => ({
                            ...prevState,
                            electrice: event.target.value.toUpperCase(),
                          }));
                        }
                      }}
                      step="100"
                    />
                  </div>
                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.electrice}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_FENOMEN"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>
                <Col xl="4" lg="6" md="6" sm="12">
                  <img src={portabile} alt="" />
                  <p className="sectionLocuinte_BunuriAsig">
                    {t("homeProtect.pasul2.asig2")}
                  </p>
                </Col>
                <Col xl="2" lg="6" md="6" sm="12">
                  <p className="sectionLocuinte_BunuriAsig">Telefoane</p>
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.echipamente}
                      value={covers.echipamenteTelefoane}
                      onChange={handleChangeTelefon}
                    />
                  </div>

                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.echipamenteTelefoane}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_EEP_TEL"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>

                <Col xl="3" lg="6" md="6" sm="12">
                  <p className="sectionLocuinte_BunuriAsig">Tablete</p>
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.echipamente}
                      value={covers.echipamenteTablete}
                      onChange={handleChangeTableta}
                    />
                  </div>
                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.echipamenteTablete}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_EEP_TAB"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>

                <Col xl="3" lg="3" md="6" sm="12">
                  <p className="sectionLocuinte_BunuriAsig">Foto/video</p>
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.echipamente}
                      value={covers.echipamenteFoto}
                      onChange={handleChangeFoto}
                    />
                  </div>
                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.echipamenteFoto}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_EEP_LAP"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>
                {errorSA === true && (
                  <p className="errorTextLocuinte">
                    {t("homeProtect.pasul2.avertisment")}
                  </p>
                )}
                <p className="locuinte_infopermanent">
                  {t("homeProtect.pasul2.indicatii")}
                </p>
              </Row>
            </div>
            <div className="sectionLocuinte_Bunuri1">
              <Row className="sectionLocuinte_BunuriRow1">
                <p className="sectionLocuinte_BunuriInfo">
                  {t("homeProtect.pasul2.title4")}
                </p>
                <Col
                  xl="3"
                  lg="3"
                  md="6"
                  sm="12"
                  className="sectionHP_BunuriSpace"
                >
                  <img src={inundatie} alt="" />
                  <p className="sectionLocuinte_BunuriAsig">
                    {t("homeProtect.pasul2.asig5")}
                  </p>
                  <p
                    style={{
                      minHeight: "120px",
                    }}
                    className="sectionLocuinte_BunuriSubAsig"
                  >
                    {t("homeProtect.pasul2.subasig5")}
                    <label
                      className="letterMic"
                      onMouseEnter={(e) => handleMouseEnter(e, 4)}
                      onMouseLeave={handleMouseLeave}
                    >
                      i
                    </label>
                  </p>
                  {afiseazaPopUpGaz && (
                    <PopUpInfo
                      top={pozitieCursor.top}
                      left={pozitieCursor.left}
                      text={t("homeProtect.pasul2.infoasig5")}
                      title={""}
                    />
                  )}
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.gaz}
                      value={covers.gaz}
                      onChange={(e) =>
                        setCovers((prevState) => ({
                          ...prevState,
                          gaz: e.target.value,
                        }))
                      }
                      step="100"
                    />
                  </div>

                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.gaz}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {/* {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_AA_INST"
                    ).premium || 0}{" "} */}
                    {parseFloat(
                      oferta.covers.find(
                        (covers) => covers.productCoverCode === "0814_AA_INST"
                      ).premium || 0
                    ) +
                      parseFloat(
                        oferta.covers.find(
                          (covers) => covers.productCoverCode === "0814_APA_CO"
                        ).premium || 0
                      )}{" "}
                    {home.currency}
                  </p>
                </Col>
                <Col xl="3" lg="3" md="6" sm="12">
                  <img src={centralapng} alt="" />
                  <p className="sectionLocuinte_BunuriAsig spaceBunuri">
                    {t("homeProtect.pasul2.asig6")}
                  </p>
                  <p
                    className="sectionLocuinte_BunuriSubAsig"
                    style={{
                      minHeight: "120px",
                    }}
                  >
                    {t("homeProtect.pasul2.subasig6")}
                    <label
                      className="letterMic"
                      onMouseEnter={(e) => handleMouseEnter(e, 5)}
                      onMouseLeave={handleMouseLeave}
                    >
                      i
                    </label>
                  </p>
                  {afiseazaPopUpCentrale && (
                    <PopUpInfo
                      top={pozitieCursor.top}
                      left={pozitieCursor.left}
                      text={t("homeProtect.pasul2.infoasig6")}
                      title={""}
                    />
                  )}
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.centrala}
                      value={covers.centrala}
                      onChange={(e) =>
                        setCovers((prevState) => ({
                          ...prevState,
                          centrala: e.target.value,
                        }))
                      }
                      step="500"
                    />
                  </div>

                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.centrala}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_ACC_CT"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>
                <Col xl="3" lg="3" md="6" sm="11">
                  <img src={window1} alt="" />
                  <p
                    className="sectionLocuinte_BunuriAsig"
                    style={{
                      marginBottom: "42px",
                    }}
                  >
                    {t("homeProtect.pasul2.asig7")}
                  </p>
                  <p
                    className="sectionLocuinte_BunuriSubAsig"
                    style={{
                      minHeight: "120px",
                    }}
                  >
                    {t("homeProtect.pasul2.subasig7")}
                  </p>
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.geamuri}
                      value={covers.geamuri}
                      onChange={(e) =>
                        setCovers((prevState) => ({
                          ...prevState,
                          geamuri: e.target.value,
                        }))
                      }
                      step="100"
                    />
                  </div>

                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.geamuri}
                  </label>
                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_SPARG"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>
                <Col xl="3" lg="3" md="6" sm="12">
                  <img
                    style={{
                      marginBottom: "15px",
                    }}
                    src={dauneVecini}
                    alt=""
                  />
                  <p
                    className="sectionLocuinte_BunuriAsig"
                    style={{
                      marginBottom: "40px",
                    }}
                  >
                    {t("homeProtect.pasul2.asig8")}
                  </p>
                  <p
                    className="sectionLocuinte_BunuriSubAsig"
                    style={{
                      minHeight: "120px",
                    }}
                  >
                    {t("homeProtect.pasul2.subasig8")}
                  </p>
                  <div>
                    <input
                      id="typeinp"
                      type="range"
                      min="0"
                      max={limite.vecini}
                      value={covers.restul}
                      onChange={(e) =>
                        setCovers((prevState) => ({
                          ...prevState,
                          restul: e.target.value,
                        }))
                      }
                      step="100"
                    />
                  </div>
                  <label className="sectionLocuinte_BunuriAsigInput">
                    {covers.restul}
                  </label>

                  <p className="sectionLocuinte_BunuriAsigP">
                    {oferta.covers.find(
                      (covers) => covers.productCoverCode === "0814_RC"
                    ).premium || 0}{" "}
                    {home.currency}
                  </p>
                </Col>
                <p className="locuinte_infopermanent">
                  {t("homeProtect.pasul2.indicatii")}
                </p>
              </Row>
            </div>
            <div
              style={{
                marginTop: "-35px",
                textAlign: "center",
              }}
            >
              <DownloadPDFButton />
            </div>
          </Col>
          <Col xl="2" lg="2" md="2" sm="1">
            <img src={money} alt="" />
            <div className="sticker">
              <p className="ofertaTitleLocuinte">
                {t("homeProtect.pret.title")}
              </p>
              <p className="ofertaPretLocuinte">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "LUNARA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.luna")}
              </p>
              <p className="ofertaPretLocuinte2">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "TRIMESTRIALA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.trimestru")}
              </p>
              <p className="ofertaPretLocuinte2">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "SEMESTRIALA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.semestru")}
              </p>
              <p className="ofertaPretLocuinte2">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "ANUALA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.an")}
              </p>

              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <img
                  src={pass}
                  alt=""
                  className="image-with-hover-cursor buttonRefresh"
                  onClick={functionRefresh}
                />

                <div
                  className="locuinte_buttonText image-with-hover-cursor"
                  onClick={functionRefresh}
                >
                  {t("homeProtect.pret.refresh")}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          style={{
            marginTop: "80px",
          }}
        >
          <Col xl="1" lg="0" md="0" sm="0"></Col>
          <Col xl="3" lg="4" md="6" sm="12">
            <div className="sectionTravel_Travel__form_back">
              <img
                src={sageata}
                alt=""
                className="image-with-hover-cursor sageata_travel"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              />
              <p
                className="sectionTravel_buy_button_back image-with-hover-cursor"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              >
                {t("travelEurolife.back")}
              </p>
            </div>
          </Col>
          <Col xl="4" lg="4" md="6" sm="12">
            <div>
              <img
                src={pass}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "276px",
                  height: "auto",
                }}
                onClick={updateHome}
              />

              <div
                className="HP_buttonText image-with-hover-cursor"
                style={{
                  marginLeft: "56px",
                  marginTop: "-35px",
                }}
                onClick={updateHome}
              >
                {t("homeProtect.pasul1.button")}
              </div>
            </div>
          </Col>

          <Col xl="4" lg="4" md="6" sm="12">
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={contactfooter} alt="" className="imgFooterNext" />
              <img
                style={{ width: "260px", height: "auto", marginTop: "-200px" }}
                src={whatsapp}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              />
              <p
                className="whatsappLocuinte image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              >
                {t("homeProtect.pasul1.whatsapp")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {showPopup && (
        <div className="popup-wrapper">
          <div className="popup-overlay" />
          <div className="popup-containerTransparent">
            <Spinner
              animation="border"
              role="status"
              style={{
                height: "90px",
                width: "90px",
              }}
            ></Spinner>
          </div>
        </div>
      )}
    </section>
  );
}
export default Bunuri;
