import React from "react";
import { Container, Row, Col } from "reactstrap";

import Logo_vertical from "../../assests/images/Logo_vertical.svg";

import LogoFooter from "../../assests/images/share_footer/LogoFooter.svg";
import Fa from "../../assests/images/share_footer/fa_footer.svg";
import Insta from "../../assests/images/share_footer/insta_footer.svg";
import In from "../../assests/images/share_footer/in_footer.svg";
import MailIcon from "../../assests/images/share_footer/MailIcon.svg";
import PhoneIcon from "../../assests/images/share_footer/PhoneIcon.svg";
import "./FooterBlack.css";

import { useLanguage } from "../utils/utils.jsx";

const FooterBlack = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="FooterBlack">
      <Container className="FooterBlack_con">
        <Row>
          <Col lg="6" md="6" className="FooterBlack_center_left">
            <img src={LogoFooter} alt="" />
            <div className="FooterBlack_contact">
              <p>{t("footerBlack.text1")}</p>
              <a href="mailto:hello@senflua.ro">
                <img src={MailIcon} alt="" target="_blank" />
                hello@senflua.ro
              </a>
              <br />

              <a href="tel:0753555525">
                <img src={PhoneIcon} alt="" target="_blank" />
                +40 753 555 525
              </a>
            </div>
          </Col>

          <Col lg="6" md="6" className="FooterBlack_center_right">
            <div>
              <p>{t("footerBlack.text2")}</p>
            </div>
            <div>
              <a href="https://www.instagram.com/senflua/" target="_blank">
                <img src={Insta} alt="" />
              </a>
              <a href="https://www.facebook.com/Senflua" target="_blank">
                <img src={Fa} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/senflua-asigurari/"
                target="_blank"
              >
                <img src={In} alt="" />
              </a>
            </div>
          </Col>
        </Row>
        <div className="FooterBlack_center_SubTitle">
          <p>{t("footerBlack.text3")}</p>
        </div>

        <Row className="FooterBlack_center_RowLink">
          <Col className="FooterBlack_center_Link equal-width">
            <div className="FooterBlack_center_LinkDiv">
              <a href="https://www.asfromania.ro/" target="_blank">
                asfromania.ro
              </a>
            </div>
          </Col>

          <Col className="FooterBlack_center_Link equal-width">
            <div className="FooterBlack_center_LinkDiv">
              <a href="https://anpc.ro/" target="_blank">
                anpc.ro
              </a>
            </div>
          </Col>

          <Col className="FooterBlack_center_Link equal-width">
            <div className="FooterBlack_center_LinkDiv">
              <a href="https://www.salfin.ro/" target="_blank">
                salfin.ro
              </a>
            </div>
          </Col>

          <Col className="FooterBlack_center_Link equal-width">
            <div className="FooterBlack_center_LinkDiv">
              <a
                href="https://www.baar.ro/asigurati-cu-risc-ridicat/"
                target="_blank"
              >
                baar.ro
              </a>
            </div>
          </Col>

          <Col className="FooterBlack_center_Link equal-width">
            <div className="FooterBlack_center_LinkDiv">
              <a href="https://www.aida.info.ro/polite-rca" target="_blank">
                aida.info.ro
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FooterBlack;
