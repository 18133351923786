import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import helloSenflua from "../../../assests/images/helloSenflua.svg";
import RCAbtn from "../../../assests/images/homePage/VreauRCA.png";
import Travelbtn from "../../../assests/images/homePage/Travel_btn.png";

import IaLegatura from "../../../assests/images/homePage/iaLegatura_btn.png";
import "./HeroSection.css";

import { useLanguage } from "../../utils/utils.jsx";

const PopUpCuvant = ({ top, left }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: top + 10,
        left: left + 10,
        background: "#8fc4f7",
        padding: "8px",
        borderRadius: "4px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
        zIndex: 9999,
      }}
    >
      Sume pana in 2000 de lei
    </div>
  );
};

const HeroSection = () => {
  const { t, i18n } = useLanguage();

  const [afiseazaPopUp, setAfiseazaPopUp] = useState(false);
  const [pozitieCursor, setPozitieCursor] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e) => {
    setPozitieCursor({ top: e.clientY, left: e.clientX });
    setAfiseazaPopUp(true);
  };

  const handleMouseLeave = () => {
    setAfiseazaPopUp(false);
  };

  return (
    <section className="hero_section">
      <Container>
        <Row>
          <Col lg="8" md="8" sm="12" xs="12" className="textHero">
            <div>
              <p className="title1">{t("hero.subtitle")}</p>
              <p className="title2">{t("hero.title")}</p>
              <p className="subtitleHero">
                {t("hero.description1")} <br /> {t("hero.description2")}
              </p>
            </div>
            <div className="image-Travelbtn_img">
              <div className="image-button">
                <a href="/asigurare-rca">
                  <img
                    src={RCAbtn}
                    alt=""
                    className="image-with-hover-cursor bt_resize"
                  />

                  <div className="button-text">{t("hero.button1")}</div>
                </a>
              </div>
              <div className="image-button">
                <a href="/asigurare-travel">
                  <img
                    src={Travelbtn}
                    alt=""
                    className="image-with-hover-cursor bt_resize"
                  />
                  <div className="button-text">{t("hero.button2")}</div>
                </a>
              </div>
              <div className="image-button">
                <a href="/asigurare-casa-ta">
                  <img
                    src={RCAbtn}
                    alt=""
                    className="image-with-hover-cursor bt_resize"
                  />

                  <div className="button-text">{t("hero.button4")}</div>
                </a>
              </div>
            </div>

            <p
              className="subtitleHero mb-4"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Cumpara RCA in rate fara dobanda*
            </p>
            {afiseazaPopUp && (
              <PopUpCuvant top={pozitieCursor.top} left={pozitieCursor.left} />
            )}
            <p className="subtitleHero mb-4">
              {t("hero.description3")} <br />
            </p>

            <div className="image-button">
              <img
                src={IaLegatura}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "228px",
                  height: "auto",
                }}
                onClick={(event) => (window.location.href = "/senflua-form")}
              />

              <div
                className="button-text image-with-hover-cursor"
                style={{
                  color: "white",
                  font: "normal normal bold 14px/19px Alegreya",
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
                onClick={(event) => (window.location.href = "/senflua-form")}
              >
                {t("hero.button3")}
              </div>
            </div>
          </Col>

          <Col lg="4" md="4" sm="12" xs="12" className="hero_right">
            <img src={helloSenflua} alt="" className="helloHome" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
