import React from "react";

import x4 from "../../assests/images/SenfluaForm/4x4.svg";
import autoturism from "../../assests/images/SenfluaForm/autoturism.svg";
import electric from "../../assests/images/SenfluaForm/electric.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipMasina({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.tipMasina.title")}</p>
      <spam className="namesubTitle">{t("typeForm.tipMasina.subtitle")}</spam>
      <div>
        <label
          className={
            formAig.senfluaThinhs.gep
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                gep: !formAig.senfluaThinhs.gep,
              },
            }));
          }}
          style={{ width: "89px", height: "101px" }}
        >
          <img src={x4} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipMasina.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.autoturism
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                autoturism: !formAig.senfluaThinhs.autoturism,
              },
            }));
          }}
          style={{ width: "132px", height: "101px" }}
        >
          <img src={autoturism} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipMasina.v2")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.hybrid
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                hybrid: !formAig.senfluaThinhs.hybrid,
              },
            }));
          }}
          style={{ width: "168px", height: "101px" }}
        >
          <img src={electric} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipMasina.v3")}
        </label>
      </div>
    </div>
  );
}

export default TipMasina;
