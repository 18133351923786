import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import Newsletter from "../../../assests/images/newsletter.svg";
import Aboneaza_btn from "../../../assests/images/homePage/Aboneaza_btn.png";

import "./Section9.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section9 = () => {
  const { t, i18n } = useLanguage();

  const [email, setEmail] = useState("");
  const [errorMail, setErrorMail] = useState(false);
  const [message, setMessage] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  };

  function handleSendEmail(event) {
    if (validateEmail(email)) {
      fetch(`${process.env.REACT_APP_API_URL}/newsletter?email=${email}`, {
        method: "GET",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      });

      setErrorMail(false);
      setMessage(true);
      setEmail("");
    } else {
      setErrorMail(true);
    }
  }
  return (
    <section className="section9">
      <Container>
        <Row>
          <Col lg="6" md="12" sm="12" xs="12">
            <div>
              <img src={Newsletter} alt="" className="newsletter" />
            </div>
          </Col>
          <Col lg="6" md="12" sm="12" xs="12">
            <div className="section9_title">
              <p>{t("section9.title")}</p>
            </div>
            <div className="section9_subTitle">
              <p>
                {t("section9.text1")}
                <br /> {t("section9.text2")}
                <br /> {t("section9.text3")}
                <br /> {t("section9.text4")}
              </p>
            </div>
            <div className="section9_newsletter">
              <input
                value={email}
                type="email"
                placeholder={t("section9.input")}
                onChange={(event) => setEmail(event.target.value)}
              />

              <div className="image-button">
                <img
                  src={Aboneaza_btn}
                  alt=""
                  className="image-with-hover-cursor"
                  onClick={handleSendEmail}
                  style={{
                    width: "119px",
                    height: "auto",
                  }}
                />
                <div
                  className="button-text image-with-hover-cursor"
                  style={{
                    color: "white",
                    marginLeft: "10px",
                    marginTop: "7px",
                  }}
                >
                  {t("section9.button")}
                </div>
              </div>
            </div>
            <p
              style={{
                fontStyle: "italic",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "22px",
                fontFamily: "Alegreya",
              }}
            >
              {errorMail ? t("section9.message") : null}
            </p>
            <p
              style={{
                fontStyle: "italic",
                fontWeight: "normal",
                fontSize: "14px",
                lineHeight: "22px",
                fontFamily: "Alegreya",
              }}
            >
              {message ? t("section9.message2") : null}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section9;
