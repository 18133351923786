import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { useLanguage } from "../utils/utils.jsx";

import "./HomeProtectNew.css";
import DownloadPDFButton from "./components/DownloadPDFButton/DownloadPDFButton";
import PopUpInfo from "./components/PopUpInfo/PopUpInfo";

import PassList from "./components/passList/PassList";
import ChatBubble from "./components/chatBubble/ChatBubble";
import SenfluaPas1 from "../../assests/images/HomeProtect/SenfluaPas1.svg";
import sageata from "../../assests/images/RCA/sageata.svg";
import casa from "../../assests/images/HomeProtect/casa_locuinta.svg";
import apartament from "../../assests/images/HomeProtect/apartament.svg";
import pass from "../../assests/images/HomeProtect/pass.png";
import contactfooter from "../../assests/images/HomeProtect/contact.svg";
import whatsapp from "../../assests/images/HomeProtect/whatsapp.png";

import Whatsapp_btn from "../../assests/images/Whatsapp_btn.png";
import Logo from "../../assests/images/logo.svg";

function Adresa({
  home,
  setHome,
  judete,
  setJudete,
  orase,
  setOrase,
  constructia,
  currency,
  covers,
  setCovers,
  page,
  setPage,
  codPostal,
  setCodPostal,
  franchises,
  setFranchises,
  etaje,
  confirm,
  setConfirm,
  TipuriStrazi,
  TipuriEtaje,
}) {
  useEffect(() => {
    document.title = "Senflua - HomeProtectNew";
  }, []);

  const [showPopup, setShowPopup] = useState(false);

  function handleButtonClick() {
    setShowPopup(true);
  }

  const { t, i18n } = useLanguage();
  const navigate = useNavigate();

  const [afiseazaPopUp, setAfiseazaPopUp] = useState(false);
  const [afiseazaPopUpSum, setAfiseazaPopUpSum] = useState(false);
  const [pozitieCursor, setPozitieCursor] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e, parametru) => {
    setPozitieCursor({ top: e.clientY, left: e.clientX });

    if (parametru === 1) {
      setAfiseazaPopUp(true);
    } else if (parametru === 2) {
      setAfiseazaPopUpSum(true);
    }
  };

  const handleMouseLeave = () => {
    setAfiseazaPopUp(false);
    setAfiseazaPopUpSum(false);
  };

  const camere = [
    ["", "Selecteaza"],
    ...Array.from({ length: 30 }, (_, index) => [
      String(index + 1),
      String(index + 1),
    ]),
  ];

  const currentYear = new Date().getFullYear();

  const years = [
    ["", "Selecteaza"],
    ...Array.from({ length: currentYear - 1949 }, (_, index) => [
      `${currentYear - index}`,
      `${currentYear - index}`,
    ]),
  ];

  const [error, setError] = useState(false);
  const [errorSupravata, setErrorSuprafata] = useState(false);
  const [errorSuma, setErrorSuma] = useState(false);

  const updateHome = () => {
    const isCasa = home.house.technicalDetails.houseType === "Casa";

    const isInvalidAddress =
      !confirm ||
      home.house.address.county === "100" ||
      home.house.address.city === "9999999999" ||
      home.house.address.postCode === "select" ||
      home.house.technicalDetails.constructionType === "100" ||
      home.house.address.number.length < 1 ||
      home.house.address.streetType === "select" ||
      home.house.address.street.length < 1 ||
      (!isCasa &&
        (home.house.address.floor === "" ||
          home.house.address.appartment.length < 1)) ||
      home.house.technicalDetails.heightRegime === "select" ||
      home.house.technicalDetails.usableArea.length < 1 ||
      covers.locuinta.length < 1;

    const isInvalidUsableArea =
      home.house.technicalDetails.usableArea < 20 ||
      home.house.technicalDetails.usableArea > 500;

    const isInvalidCoverAmount =
      (home.currency === "RON" &&
        (covers.locuinta < 100000 || covers.locuinta > 15000000)) ||
      (home.currency === "EUR" &&
        (covers.locuinta < 20000 || covers.locuinta > 300000));

    setError(isInvalidAddress);
    setErrorSuprafata(isInvalidUsableArea);
    setErrorSuma(isInvalidCoverAmount);

    if (
      !isInvalidAddress &&
      !isInvalidUsableArea &&
      !isInvalidCoverAmount &&
      confirm
    ) {
      setError(false);
      setErrorSuprafata(false);
      setErrorSuma(false);

      window.scrollTo(0, 0);
      setPage(page + 1);
    }
  };

  async function handleJudetChange(event) {
    const judet = event.target.value;
    if (judet !== "100") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getCities?parentCode=${judet}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setHome((prevHome) => ({
          ...prevHome,
          house: {
            ...prevHome.house,
            address: {
              ...prevHome.house.address,
              county: judet,
              city: "9999999999",
              postCode: "select",
            },
          },
        }));
        setOrase([{ code: "9999999999", description: "Selecteaza" }, ...data]);
        setCodPostal([{ code: "select", description: "Selecteaza" }]);
      } catch (error) {
        navigate("/eroare");
      }
      //}
    } else {
      setHome((prevHome) => ({
        ...prevHome,
        house: {
          ...prevHome.house,
          address: {
            ...prevHome.house.address,
            county: judet,
            city: "9999999999",
            postCode: "select",
          },
        },
      }));
      setOrase([{ code: "9999999999", description: "Selecteaza" }]);
    }
  }

  async function handleFranchises(value) {
    //const judet = event.target.value;
    setCovers((prevState) => ({
      ...prevState,
      bunuri: 0,
      unelte: 0,
      echipamenteTelefoane: 0,
      echipamenteTablete: 0,
      echipamenteFoto: 0,
      electrice: 0,
      gaz: 0,
      centrala: 0,
      geamuri: 0,
      restul: 0,
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getFranchises?currencies=${value}`,
        {
          headers: {
            "API-Key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      setFranchises(data);
      setHome((prevHome) => ({
        ...prevHome,
        franchise: data[0].code,
      }));
    } catch (error) {
      navigate("/eroare");
    }
  }

  async function handleOrasChange(event) {
    const oras = event.target.value;

    setHome((prevHome) => ({
      ...prevHome,
      house: {
        ...prevHome.house,
        address: {
          ...prevHome.house.address,
          city: oras,
          postCode: "select",
        },
      },
    }));

    if (oras !== "9999999999") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getPostCodes?postCodes=${oras}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCodPostal([{ code: "select", description: "Selecteaza" }, ...data]);
      } catch (error) {
        navigate("/eroare");
      }
    } else {
      setCodPostal([{ code: "select", description: "Selecteaza" }]);
    }
  }

  return (
    <section className="sectionLocuinte">
      <div className="LocuinteSpace">
        <PassList yellowCount={page} />
      </div>
      <Row className="LocuinteSpace">
        <Col xl="1" lg="1" md="0" sm="0"></Col>
        <Col xl="2" lg="2" md="2" sm="12" className="locuinteColImg">
          <img className="locuinteImg" src={SenfluaPas1} alt="" />
        </Col>
        <Col xl="7" lg="8" md="10" sm="12">
          <ChatBubble
            message={t("homeProtect.pasul1.title")}
            position="left"
            dimention="CB-title"
          />
        </Col>
      </Row>
      <Container className="containerLocuinte">
        <Row>
          <Col xl="1" lg="0" md="0" sm="0"></Col>
          <Col xl="9" lg="10" md="10" sm="10">
            <Row className="detaliiRow">
              <label className="detaliiMiniTitle11">
                {t("homeProtect.pasul1.subtitle2")} <br />
              </label>
              <Col xl="12" lg="12" md="12" sm="12">
                <div
                  className="detaliiMiniTitle11"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <label
                    className={
                      home.house.technicalDetails.houseType === "Casa"
                        ? "locuinte_ButtonRed image-with-hover-cursor"
                        : "locuinte_Button image-with-hover-cursor"
                    }
                    style={{
                      width: "85px",
                      height: "101px",
                    }}
                    onClick={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            houseType: "Casa",
                          },
                          address: {
                            ...prevHome.house.address,
                            building: "",
                            entrance: "",
                            floor: "",
                            appartment: "",
                          },
                        },
                      }))
                    }
                  >
                    <img src={casa} alt="" />
                    {t("homeProtect.pasul1.subtitle21")}
                  </label>

                  <label
                    className={
                      home.house.technicalDetails.houseType === "Bloc"
                        ? "locuinte_ButtonRed image-with-hover-cursor"
                        : "locuinte_Button image-with-hover-cursor"
                    }
                    style={{
                      width: "138px",
                      height: "101px",
                    }}
                    onClick={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            houseType: "Bloc",
                          },
                        },
                      }))
                    }
                  >
                    <img src={apartament} alt="" />
                    {t("homeProtect.pasul1.subtitle22")}
                  </label>
                  {home.house.technicalDetails.houseType === "Casa" && (
                    <div
                      className="locuinte_info"
                      style={{
                        width: "196px",
                        height: "89px",
                        padding: "10px",
                      }}
                    >
                      <label>{t("homeProtect.pasul1.info")}</label>
                    </div>
                  )}
                </div>
              </Col>
              <label className="detaliiMiniTitle">
                {t("homeProtect.pasul1.minititle")} <br />
              </label>

              <Col xl="4" lg="4" md="6" sm="12">
                <label
                  className={
                    home.house.address.county === "100" && error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle1")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                  <br />
                  <select
                    className="centered-select"
                    onChange={handleJudetChange}
                    value={home.house.address.county}
                  >
                    {judete.map((judet, index) => (
                      <option key={judet.code} value={judet.code}>
                        {judet.description}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col xl="5" lg="5" md="6" sm="12" className="centerColum">
                <label
                  className={
                    home.house.address.city === "9999999999" && error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle13")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                  <br />
                  <select
                    className="centered-select"
                    onChange={handleOrasChange}
                    value={home.house.address.city}
                  >
                    {orase.map((judet, index) => (
                      <option key={judet.code} value={judet.code}>
                        {judet.description}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col xl="3" lg="3" md="6" sm="12">
                <label
                  className={
                    home.house.address.postCode === "select" && error === true
                      ? "detaliiMiniTitleCodPostalselectRed"
                      : "detaliiMiniTitleCodPostalselect"
                  }
                >
                  {t("homeProtect.pasul1.subtitle7")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <select
                    className="centered-select"
                    onChange={(event) => {
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          address: {
                            ...prevHome.house.address,
                            postCode: event.target.value,
                          },
                        },
                      }));
                    }}
                    value={home.house.address.postCode}
                  >
                    {codPostal.map((codP, index) => (
                      <option
                        key={codP.code}
                        value={index === 0 ? codP.code : codP.description}
                      >
                        {codP.description}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col xl="4" lg="4" md="6" sm="12">
                <label
                  className={
                    home.house.address.streetType === "select" && error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle8")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <select
                    className="centered-select"
                    value={home.house.address.streetType}
                    onChange={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          address: {
                            ...prevHome.house.address,
                            streetType: event.target.value,
                          },
                        },
                      }))
                    }
                  >
                    {Object.keys(TipuriStrazi).map((key, index) => (
                      <option key={index} value={key}>
                        {TipuriStrazi[key]}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col xl="5" lg="5" md="6" sm="12" className="centerColum">
                <label
                  className={
                    home.house.address.street.length < 1 && error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle9")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                  <br />
                  <input
                    type="text"
                    name="name"
                    placeholder={t("homeProtect.pasul1.subtitle61")}
                    value={home.house.address.street}
                    onChange={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          address: {
                            ...prevHome.house.address,
                            street: event.target.value,
                          },
                        },
                      }))
                    }
                  />
                </label>
              </Col>
              <Col xl="3" lg="3" md="6" sm="12">
                <label
                  className={
                    home.house.address.number.length < 1 && error === true
                      ? "detaliiMiniTitle3red"
                      : "detaliiMiniTitle3"
                  }
                >
                  {t("homeProtect.pasul1.subtitle10")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <input
                    type="text"
                    name="name"
                    placeholder={t("homeProtect.pasul1.subtitle61")}
                    value={home.house.address.number}
                    onChange={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          address: {
                            ...prevHome.house.address,
                            number: event.target.value,
                          },
                        },
                      }))
                    }
                  />
                </label>
              </Col>

              {home.house.technicalDetails.houseType === "Bloc" && (
                <Col xl="2" lg="2" md="4" sm="6">
                  <div>
                    <label className="detaliiMiniTitle3">
                      {t("homeProtect.pasul1.subtitle11")}
                      <br />
                      <input
                        type="text"
                        name="name"
                        placeholder={t("homeProtect.pasul1.subtitle61")}
                        value={home.house.address.building}
                        onChange={(event) =>
                          setHome((prevHome) => ({
                            ...prevHome,
                            house: {
                              ...prevHome.house,
                              address: {
                                ...prevHome.house.address,
                                building: event.target.value,
                              },
                            },
                          }))
                        }
                      />
                    </label>
                  </div>
                </Col>
              )}
              {home.house.technicalDetails.houseType === "Bloc" && (
                <Col xl="2" lg="2" md="4" sm="6">
                  <label className="detaliiMiniTitle3">
                    {t("homeProtect.pasul1.subtitle14")} <br />
                    <input
                      type="text"
                      name="name"
                      placeholder={t("homeProtect.pasul1.subtitle61")}
                      value={home.house.address.entrance}
                      onChange={(event) =>
                        setHome((prevHome) => ({
                          ...prevHome,
                          house: {
                            ...prevHome.house,
                            address: {
                              ...prevHome.house.address,
                              entrance: event.target.value,
                            },
                          },
                        }))
                      }
                    />
                  </label>
                </Col>
              )}

              {home.house.technicalDetails.houseType === "Bloc" && (
                <Col xl="3" lg="3" md="4" sm="6" className="centerColum">
                  <label
                    className={
                      home.house.address.floor === "" && error === true
                        ? "detaliiMiniTitle3red"
                        : "detaliiMiniTitle3"
                    }
                  >
                    {t("homeProtect.pasul1.subtitle15")}
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>{" "}
                    <br />
                    <select
                      className="centered-select"
                      onChange={(event) =>
                        setHome((prevHome) => ({
                          ...prevHome,
                          house: {
                            ...prevHome.house,
                            address: {
                              ...prevHome.house.address,
                              floor: event.target.value,
                            },
                          },
                        }))
                      }
                      value={home.house.address.floor}
                    >
                      {Object.entries(etaje).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </label>
                </Col>
              )}
              {home.house.technicalDetails.houseType === "Bloc" && (
                <Col xl="2" lg="2" md="4" sm="6">
                  <label
                    className={
                      home.house.address.appartment.length < 1 && error === true
                        ? "detaliiMiniTitle3red"
                        : "detaliiMiniTitle3"
                    }
                  >
                    {t("homeProtect.pasul1.subtitle16")}
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>{" "}
                    <br />
                    <input
                      type="text"
                      name="name"
                      placeholder={t("homeProtect.pasul1.subtitle61")}
                      value={home.house.address.appartment}
                      onChange={(event) =>
                        setHome((prevHome) => ({
                          ...prevHome,
                          house: {
                            ...prevHome.house,
                            address: {
                              ...prevHome.house.address,
                              appartment: event.target.value,
                            },
                          },
                        }))
                      }
                    />
                  </label>
                </Col>
              )}
              <Col xl="3" lg="3" md="4" sm="6">
                <label
                  className={
                    home.house.technicalDetails.rooms === "" && error === true
                      ? "detaliiMiniTitle3red"
                      : "detaliiMiniTitle3"
                  }
                >
                  {t("homeProtect.pasul1.subtitle17")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <select
                    className="centered-select"
                    onChange={(event) => {
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            rooms: event.target.value,
                          },
                        },
                      }));
                    }}
                    value={home.house.technicalDetails.rooms}
                  >
                    {camere.map(([value, label], index) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <label className="detaliiMiniTitle">
                {t("homeProtect.pasul1.minititle2")} <br />
              </label>

              <Col xl="4" lg="4" md="6" sm="12">
                <label
                  className={
                    home.house.technicalDetails.constructionYear === "" &&
                    error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle4")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <select
                    value={home.house.technicalDetails.constructionYear}
                    onChange={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            constructionYear: event.target.value,
                          },
                        },
                      }))
                    }
                  >
                    {years.map(([value, label], index) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col xl="4" lg="4" md="6" sm="12">
                <label
                  className={
                    home.house.technicalDetails.constructionType === "100" &&
                    error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle20")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <select
                    className="centered-select"
                    value={home.house.technicalDetails.constructionType}
                    onChange={(event) => {
                      event.preventDefault();
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            constructionType: event.target.value,
                          },
                        },
                      }));
                    }}
                  >
                    {constructia.map((construc, index) => (
                      <option key={construc.code} value={construc.code}>
                        {construc.description}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col xl="4" lg="4" md="6" sm="12">
                <label
                  className={
                    home.house.technicalDetails.heightRegime === "select" &&
                    error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle18")}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <select
                    className="centered-select"
                    value={home.house.technicalDetails.heightRegime}
                    onChange={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            heightRegime: event.target.value,
                          },
                        },
                      }))
                    }
                  >
                    {Object.keys(TipuriEtaje).map((key, index) => (
                      <option key={index} value={key}>
                        {TipuriEtaje[key]}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col xl="4" lg="5" md="12" sm="12">
                <label
                  className={
                    home.house.technicalDetails.usableArea.length < 1 &&
                    error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle3")}
                  <label
                    className="letter"
                    onMouseEnter={(e) => handleMouseEnter(e, 1)}
                    onMouseLeave={handleMouseLeave}
                  >
                    i
                  </label>
                  {afiseazaPopUp && (
                    <PopUpInfo
                      top={pozitieCursor.top}
                      left={pozitieCursor.left}
                      text={t("homeProtect.pasul1.mesajSuprata")}
                      title={""}
                    />
                  )}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <input
                    type="text"
                    name="name"
                    placeholder={t("homeProtect.pasul1.subtitle61")}
                    value={home.house.technicalDetails.usableArea}
                    onChange={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            usableArea: event.target.value,
                          },
                        },
                      }))
                    }
                  />
                </label>
              </Col>
              <Col xl="8" lg="7" md="12" sm="12">
                <label className="detaliiMiniTitle4">
                  {t("homeProtect.pasul1.subtitleInformatii")} <br />
                  <input
                    type="text"
                    name="name"
                    placeholder={t("homeProtect.pasul1.subtitle61")}
                    value={home.house.technicalDetails.buildingIdentification}
                    onChange={(event) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        house: {
                          ...prevHome.house,
                          technicalDetails: {
                            ...prevHome.house.technicalDetails,
                            buildingIdentification: event.target.value,
                          },
                        },
                      }))
                    }
                  />
                </label>
              </Col>
              <label className="detaliiMiniTitle">
                {t("homeProtect.pasul1.minititle3")} <br />
              </label>
              <Col xl="5" lg="6" md="12" sm="12">
                <label
                  className={
                    covers.locuinta.length < 1 && error === true
                      ? "detaliiMiniTitle2red"
                      : "detaliiMiniTitle2"
                  }
                >
                  {t("homeProtect.pasul1.subtitle24")}
                  <label
                    className="letter"
                    onMouseEnter={(e) => handleMouseEnter(e, 2)}
                    onMouseLeave={handleMouseLeave}
                  >
                    i
                  </label>
                  {afiseazaPopUpSum && (
                    <PopUpInfo
                      top={pozitieCursor.top}
                      left={pozitieCursor.left}
                      text={t("homeProtect.pasul1.mesajSuma")}
                      title={""}
                    />
                  )}
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>{" "}
                  <br />
                  <input
                    type="text"
                    placeholder={t("homeProtect.pasul1.subtitle61")}
                    value={covers.locuinta}
                    onChange={(e) =>
                      setCovers((prevState) => ({
                        ...prevState,
                        locuinta: e.target.value,
                      }))
                    }
                  />
                </label>
              </Col>

              <Col xl="5" lg="6" md="12" sm="12">
                <label className="detaliiMiniTitle2">
                  {t("homeProtect.pasul1.subtitle23")} <br />
                </label>
                <div>
                  <label
                    className={
                      home.currency === "RON"
                        ? "detaliiButton2Red image-with-hover-cursor"
                        : "detaliiButton2 image-with-hover-cursor"
                    }
                    onClick={(event) => {
                      setHome((prevHome) => ({
                        ...prevHome,
                        currency: "RON",
                      }));
                      handleFranchises("RON");
                      //updateFranchize();
                    }}
                  >
                    {t("homeProtect.pasul1.subtitle231")}
                  </label>

                  {/* <label
                    className={
                      home.currency === "EUR"
                        ? "detaliiButton2Red image-with-hover-cursor"
                        : "detaliiButton2 image-with-hover-cursor"
                    }
                    onClick={(event) => {
                      setHome((prevHome) => ({
                        ...prevHome,
                        currency: "EUR",
                      }));
                      handleFranchises("EUR");
                      //updateFranchize();
                    }}
                  >
                    {t("homeProtect.pasul1.subtitle232")}
                  </label> */}
                </div>
              </Col>
              <Col xl="12" lg="12" md="12" sm="12">
                <div
                  className={
                    confirm === false && error === true
                      ? "detePersonaleCheckboxRed"
                      : "detePersonaleCheckbox"
                  }
                >
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) => setConfirm(e.target.checked)}
                      checked={confirm}
                    />
                    {t("homeProtect.pasul1.checkBox")}
                  </label>
                </div>
              </Col>

              <Col xl="12" lg="12" md="12" sm="12">
                <div
                  style={{
                    textAlign: "left",
                  }}
                >
                  <label className="sub_title_rca_buy">
                    {t("rca.request124")}
                  </label>
                  <div>
                    <Button
                      key={1}
                      className="sub_title_rca_select dimentionDN"
                      onClick={handleButtonClick}
                    >
                      {t("rca.request122")}
                    </Button>
                    <Button
                      key={2}
                      className="sub_title_rca_select_active dimentionDN"
                    >
                      {t("rca.request123")}
                    </Button>
                  </div>
                  {error === true && (
                    <label className="errorTextLocuinte">
                      {t("homeProtect.pasul1.error")}
                    </label>
                  )}
                  <div>
                    {errorSupravata === true && (
                      <label className="errorTextLocuinte">
                        {t("homeProtect.pasul1.errorSupravata")}
                      </label>
                    )}
                  </div>
                  <div>
                    {errorSuma === true && (
                      <label className="errorTextLocuinte">
                        {t("homeProtect.pasul2.subtitleText")}
                      </label>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <div
              style={{
                marginTop: "-30px",
                textAlign: "center",
              }}
            >
              <DownloadPDFButton />
            </div>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "80px",
          }}
        >
          <Col xl="1" lg="0" md="0" sm="0"></Col>
          <Col xl="3" lg="4" md="6" sm="12">
            <div className="sectionTravel_Travel__form_back">
              <img
                src={sageata}
                alt=""
                className="image-with-hover-cursor sageata_travel"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              />
              <p
                className="sectionTravel_buy_button_back image-with-hover-cursor"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              >
                {t("travelEurolife.back")}
              </p>
            </div>
          </Col>
          <Col xl="4" lg="4" md="6" sm="12">
            <div>
              <img
                src={pass}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "276px",
                  height: "auto",
                }}
                onClick={updateHome}
              />

              <div
                className="buttonTextLocuinte image-with-hover-cursor"
                style={{
                  marginLeft: "56px",
                  marginTop: "-35px",
                }}
                onClick={updateHome}
              >
                {t("homeProtect.pasul1.button")}
              </div>
            </div>
          </Col>

          <Col xl="4" lg="4" md="6" sm="12">
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={contactfooter} alt="" className="imgFooterNext" />
              <img
                style={{ width: "260px", height: "auto", marginTop: "-200px" }}
                src={whatsapp}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              />
              <p
                className="whatsappLocuinte image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              >
                {t("homeProtect.pasul1.whatsapp")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {showPopup && (
        <div className="popup-wrapper">
          <div className="popup-overlay" onClick={() => setShowPopup(false)} />
          <div className="popup-container">
            <p className="popup-text">
              {t("rca.pop-up1.text1")}
              <a href="/page-contact" target="_blank">
                {" "}
                {t("rca.pop-up1.form")}{" "}
              </a>
              {t("rca.pop-up1.text2")}{" "}
              <div className="image-button">
                <img
                  src={Whatsapp_btn}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "170px",
                    height: "auto",
                  }}
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                />

                <div
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "15px",
                    marginTop: "7px",
                  }}
                >
                  {t("header.menu5")}
                  <a href="https://wa.me/40724542277" target="_blank">
                    <svg
                      className="whats"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
                        fill="rgba(0,0,0,1)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {t("rca.pop-up1.text3")}
            </p>

            <button
              onClick={() => setShowPopup(false)}
              className="popup-button"
            >
              {t("rca.pop-up1.button2")}
            </button>
            <div className="popup-logo">
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Adresa;
