import React from "react";
import { Container, Row, Col } from "reactstrap";

import Logo_black from "../../assests/images/logo_black.svg";

import Fa from "../../assests/images/share_footer/fa_footer.svg";
import Insta from "../../assests/images/share_footer/insta_footer.svg";
import In from "../../assests/images/share_footer/in_footer.svg";

import "./Footer_up_Travel.css";

import { useLanguage } from "../utils/utils.jsx";

const Footer_up_Travel = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="Footer_up_Travel">
      <Container className="Footer_up_Travel_con">
        <Row>
          <Col lg="6" md="6" className="Footer_up_Travel_center_left">
            <img src={Logo_black} alt="" />
          </Col>
          <Col lg="6" md="6" className="Footer_up_Travel_center_right">
            <div>
              <p>{t("footerBlack.text2")}</p>
            </div>
            <div>
              <a href="https://www.instagram.com/senflua/" target="_blank">
                <img src={Insta} alt="" />
              </a>
              <a href="https://www.facebook.com/Senflua" target="_blank">
                <img src={Fa} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/senflua-asigurari/"
                target="_blank"
              >
                <img src={In} alt="" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer_up_Travel;
