import React from "react";

import resPrinc from "../../assests/images/SenfluaForm/resPrinc.svg";
import muncaAcasa from "../../assests/images/SenfluaForm/muncaAcasa.svg";
import resSecundara from "../../assests/images/SenfluaForm/resSecundara.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipCasa({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.tipCasa.title")}</p>
      <p className="namesubTitle">{t("typeForm.tipCasa.subtitle1")}</p>
      <p className="namesubTitle">{t("typeForm.tipCasa.subtitle2")}</p>

      <div>
        <label
          className={
            formAig.senfluaThinhs.resedinta
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                resedinta: !formAig.senfluaThinhs.resedinta,
              },
            }));
          }}
          style={{ width: "204px", height: "101px" }}
        >
          <img src={resPrinc} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipCasa.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.munca
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                munca: !formAig.senfluaThinhs.munca,
              },
            }));
          }}
          style={{ width: "168px", height: "101px" }}
        >
          <img src={muncaAcasa} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipCasa.v2")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.resedintaSecundara
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                resedintaSecundara: !formAig.senfluaThinhs.resedintaSecundara,
              },
            }));
          }}
          style={{ width: "204px", height: "101px" }}
        >
          <img src={resSecundara} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipCasa.v3")}
        </label>
      </div>
    </div>
  );
}

export default TipCasa;
