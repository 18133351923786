import React, { Fragment } from "react";
import HeaderTravel from "../HeaderTravel/HeaderTravel";

import LPtravel from "../LP_Travel/LP_Travel";
import FooterTravel from "../FooterTravel/FooterTravel";
import TabBar from "../TabBar/TabBar";

const Travel_LandingPage = () => {
  return (
    <Fragment>
      <TabBar />
      <HeaderTravel />
      <LPtravel />
      <FooterTravel />
    </Fragment>
  );
};

export default Travel_LandingPage;
