import React, { Fragment } from "react";

import Header from "../header/Header";
import HeroCondtii from "../HeroCondtii/HeroCondtii";
import SectionTextTermeniConditii from "../SectionTextTermeniConditii/SectionTextTermeniConditii";

import Footer from "../Footer/Footer";

const TermeniConditii = () => {
  return (
    <Fragment>
      <Header />
      <HeroCondtii title="Termeni și condiții" />
      <SectionTextTermeniConditii />

      <Footer />
    </Fragment>
  );
};

export default TermeniConditii;
