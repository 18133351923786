import React, { Fragment } from "react";
import Header from "../HeaderLocuinte/HeaderLocuinte";
import HomeProtectNew from "../HomeProtectNew/HomeProtectNew";
import TabBar from "../TabBar/TabBar";
import Footer from "../Footer/Footer";

const Locuinte = () => {
  return (
    <Fragment>
      <TabBar />
      <Header />
      <HomeProtectNew />
      <Footer />
    </Fragment>
  );
};

export default Locuinte;
