import React from "react";

import Romania from "../../assests/images/SenfluaForm/romania.svg";
import Europa from "../../assests/images/SenfluaForm/european.svg";
import regiuni from "../../assests/images/SenfluaForm/alteRegiuni.svg";

import { useLanguage } from "../utils/utils.jsx";

function UndeCalatorie({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ marginTop: "20px" }}>
        #senflua.LIFE
      </p>
      <p className="nameTitle2">{t("typeForm.undeCalatorie.title")}</p>
      <p className="namesubTitle">{t("typeForm.undeCalatorie.subtitle1")}</p>
      <p className="namesubTitle">{t("typeForm.undeCalatorie.subtitle2")}</p>
      <div>
        <label
          className={
            formAig.senfluaLife.romania
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                romania: !formAig.senfluaLife.romania,
              },
            }));
          }}
          style={{ width: "114px", height: "101px" }}
        >
          <img src={Romania} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.undeCalatorie.v1")}
        </label>

        <label
          className={
            formAig.senfluaLife.europa
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                europa: !formAig.senfluaLife.europa,
              },
            }));
          }}
          style={{ width: "100px", height: "101px" }}
        >
          <img src={Europa} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.undeCalatorie.v2")}
        </label>

        <label
          className={
            formAig.senfluaLife.alteRegiuni
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                alteRegiuni: !formAig.senfluaLife.alteRegiuni,
              },
            }));
          }}
          style={{ width: "154px", height: "101px" }}
        >
          <img src={regiuni} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.undeCalatorie.v3")}
        </label>
      </div>
    </div>
  );
}

export default UndeCalatorie;
