import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import Contact from "../../../assests/images/homePage/senfluaAsistent.png";
import IlustratieMalpraxis from "../../../assests/images/IlustratieMalpraxis.svg";

import { useLanguage } from "../../utils/utils.jsx";

import "./SenfluaMeical.css";

const SenfluaMeical = () => {
  const { t, i18n } = useLanguage();

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="sectionMedical">
      <Container className="sectionMedical_con">
        <Row>
          <Col lg="8" md="7" sm="12" xs="12">
            <div className="sectionMedical_title1">
              <p>{t("medical.subtitle")}</p>
            </div>
            <div className="sectionMedical__subtitle2">
              <p>{t("medical.title")}</p>
            </div>
            {screenSize > 1000 ? (
              <div className="sectionMedical__subtitle3">
                <p>
                  {t("medical.description")}
                  <br /> {t("medical.description1")}
                  <br /> {t("medical.description2")}
                </p>
              </div>
            ) : (
              <div className="sectionMedical__subtitle3">
                <p>
                  {t("medical.description")}
                  {t("medical.description1")}
                  {t("medical.description2")}
                </p>
              </div>
            )}

            <div className="image-button">
              <img
                src={Contact}
                alt=""
                className="Contact"
                style={{
                  width: "276px",
                  height: "auto",
                }}
                onClick={(event) => (window.location.href = "/senflua-form")}
              />
              <div
                className="button-text image-with-hover-cursor"
                onClick={(event) => (window.location.href = "/senflua-form")}
                style={{
                  marginLeft: "30px",
                  marginTop: "10px",
                }}
              >
                {t("medical.button")}
              </div>
            </div>
          </Col>

          <Col lg="4" md="5">
            <img
              src={IlustratieMalpraxis}
              alt=""
              className="IlustratieMalpraxis"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SenfluaMeical;
