import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useLanguage } from "../utils/utils.jsx";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import { useCookies } from "react-cookie";

import "./HomeProtectNew.css";
import PopUpInfo from "./components/PopUpInfo/PopUpInfo";

import PassList from "./components/passList/PassList";
import whatsapp from "../../assests/images/HomeProtect/whatsapp.png";
import pass from "../../assests/images/HomeProtect/pass.png";
import contactfooter from "../../assests/images/HomeProtect/contact.svg";
import sageata from "../../assests/images/RCA/sageata.svg";

import Add from "../../assests/images/Travel/add.svg";

function DatePersonale({
  home,
  setHome,
  judete,
  setJudete,
  orase,
  constructia,
  currency,
  covers,
  setCovers,
  page,
  setPage,
  codPostal,
  setCodPostal,
  franchises,
  setFranchises,
  etaje,
  oferta,
  setOferta,
  citizenship,
  assignees,
  showNewPeople,
  setShowNewPeople,
  IsProprietar,
  setIsProprietar,
  adresaBoolean,
  setAdresaBoolean,
  cesionari,
  setCesionari,
  contractant,
  setContractant,
  adresa,
  setAdresa,
  paid,
  setPaid,
  dateSuplimentare,
  setDateSuplimentare,
  TipuriStrazi,
  TipuriEtaje,
}) {
  useEffect(() => {
    document.title = "Senflua - HomeProtectNew";
  }, []);

  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [afiseazaPopUpPaid, setAfiseazaPopUpPaid] = useState(false);
  const [pozitieCursor, setPozitieCursor] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e) => {
    setPozitieCursor({ top: e.clientY, left: e.clientX });
    setAfiseazaPopUpPaid(true);
  };

  const handleMouseLeave = () => {
    setAfiseazaPopUpPaid(false);
  };

  const [error, setError] = useState(false);
  const [errorSuma, setErrorSuma] = useState(false);
  const [errorSelect, setErrorSelect] = useState(false);
  //const [totalPlata, setTotalPlata] = useState(0);
  const [modificare, setModificare] = useState(true);

  const [tc, setTc] = useState(false);
  const [pc, setPc] = useState(false);
  const [ofr, setOfr] = useState(false);

  const today = new Date(Date.now() + 86400000);
  const [maxDateInceput, setMaxDateinceput] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 60);
    return tomorrow;
  });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function formatDateview(inputDate) {
    const dataObiect = new Date(inputDate);
    const ziua = dataObiect.getDate();
    const luna = dataObiect.getMonth() + 1;
    const anul = dataObiect.getFullYear();
    return `${ziua.toString().padStart(2, "0")}.${luna
      .toString()
      .padStart(2, "0")}.${anul}`;
  }

  const handleChangeDataInceput = (date) => {
    setDateSuplimentare({
      ...dateSuplimentare,
      date: formatDate(date),
    });

    setModificare(true);
  };

  const validateNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setModificare(true);
    setDateSuplimentare((prevState) => ({
      ...prevState,
      number: value,
    }));
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  };

  async function functionRefresh() {
    home.assignees = cesionari.filter((cesionar) => cesionar.id !== "");

    setShowPopup(true);
    const requestOptions1 = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quoteRequest: home, covers: covers }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertaHouseFinal`,
        requestOptions1
      );
      const json = await response.json();

      if (json.error === false) {
        setOferta((prevOferta) => {
          const updatedOferta = {
            ...prevOferta,
            error: false,
            badRequest: {
              ...prevOferta.badRequest,
              code: "",
              message: "",
            },
          };

          // Actualizează valorile existente din json în oferta
          for (const key in json) {
            if (json.hasOwnProperty(key)) {
              if (key === "covers") {
                // Dacă cheia este "covers", actualizează individual covers
                updatedOferta[key] = updatedOferta[key].map((cover) => {
                  const jsonCover = json[key].find(
                    (jsonCover) =>
                      jsonCover.productCoverCode === cover.productCoverCode
                  );
                  if (jsonCover) {
                    jsonCover.name = cover.name; // utilizează numele din oferta curentă
                  }
                  return jsonCover || cover;
                });
              } else {
                updatedOferta[key] = json[key];
              }
            }
          }

          // Pentru cheile care nu există în json, setează insuredSum pe 0 în covers
          if (updatedOferta.covers) {
            updatedOferta.covers.forEach((cover) => {
              if (
                !json.covers.find(
                  (jsonCover) =>
                    jsonCover.productCoverCode === cover.productCoverCode
                )
              ) {
                cover.insuredSum = 0;
                cover.premium = 0;
              }
            });
          }

          return updatedOferta;
        });
      } else {
        setOferta((prevOferta) => ({
          ...prevOferta,
          error: true,
          offerId: "0",
          series: "0",
          number: "0",
          badRequest: {
            ...prevOferta.badRequest,
            code: json.badRequest.code,
            message: json.badRequest.message,
          },
          covers: prevOferta.covers.map((cover) => ({
            ...cover,
            premium: 0,
          })),
        }));
        setCovers((prevState) => ({
          ...prevState,
          electrice: 0,
        }));
      }
    } catch (error) {
      navigate("/eroare");
    }
  }
  const [intrat, setIntrat] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/paidState?idOffert=${oferta.offerId}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setPaid(data);

        setModificare(false);
      } catch (error) {
        navigate("/eroare");
      }
    };
    if (
      intrat === 1 &&
      (!showNewPeople || !verificareCesionari()) &&
      home.customer.uniqueIdentifier.length > 0 &&
      home.customer.firstName.length > 0 &&
      (!(home.customer.personType === 0) ||
        home.customer.lastName.length > 0) &&
      (IsProprietar ||
        (contractant.nume.length > 0 &&
          contractant.prenume.length > 0 &&
          contractant.cnp.length > 0)) &&
      (!adresaBoolean ||
        (adresa.county !== "100" &&
          adresa.streetType !== "select" &&
          adresa.city !== "9999999999" &&
          adresa.postCode !== "select"))
    ) {
      fetchData();
    }
  }, [oferta]);

  useEffect(() => {
    const fetchData = async () => {
      const requestOptions1 = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ quoteRequest: home, oferta: oferta }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/bnr`,
          requestOptions1
        );
        const json = await response.json();
        //setTotalPlata(json);
        console.log(json);
        setDateSuplimentare({
          ...dateSuplimentare,
          totalPlata: json,
        });
      } catch (error) {
        navigate("/eroare");
      }
      setShowPopup(false);
      //setModificare(false);
    };
    if (
      intrat === 1 &&
      paid.hasActivePad !== undefined &&
      paid.messages.length < 1 &&
      (!showNewPeople || !verificareCesionari()) &&
      home.customer.uniqueIdentifier.length > 0 &&
      home.customer.firstName.length > 0 &&
      (!(home.customer.personType === 0) ||
        home.customer.lastName.length > 0) &&
      (IsProprietar ||
        (contractant.nume.length > 0 &&
          contractant.prenume.length > 0 &&
          contractant.cnp.length > 0)) &&
      (!adresaBoolean ||
        (adresa.county !== "100" &&
          adresa.streetType !== "select" &&
          adresa.city !== "9999999999" &&
          adresa.postCode !== "select"))
    ) {
      fetchData();
    } else {
      setShowPopup(false);
    }
  }, [paid]);

  function functionPaid() {
    if (
      dateSuplimentare.date &&
      dateSuplimentare.number.length > 9 &&
      validateEmail(dateSuplimentare.email) &&
      (!showNewPeople ||
        (!verificareCesionari() && home.assignmentDetails.length > 0)) &&
      home.customer.uniqueIdentifier.length > 0 &&
      home.customer.firstName.length > 0 &&
      (!(home.customer.personType === 0) ||
        home.customer.lastName.length > 0) &&
      (IsProprietar ||
        (contractant.nume.length > 0 &&
          contractant.prenume.length > 0 &&
          contractant.cnp.length > 0)) &&
      (!adresaBoolean ||
        (adresa.county !== "100" &&
          adresa.streetType !== "select" &&
          adresa.city !== "9999999999" &&
          adresa.postCode !== "select"))
    ) {
      setIntrat(1);
      setError(false);

      functionRefresh();
    } else setError(true);
  }

  const [cookies, setCookie] = useCookies([
    "quoteRequest",
    "oferta",
    "dateSuplimentare",
    "contractant",
    "adressFacturare",
  ]);

  async function functionPay() {
    setShowPopup(true);
    if (pc === true && tc === true && ofr === true) {
      const requestOptions1 = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quoteRequest: home,
          oferta: oferta,
          dateSuplimentare: dateSuplimentare,
          contractant: contractant,
          adressFacturare: adresa,
        }),
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/homeProtectionPaid`,
          requestOptions1
        );
        const json = await response.json();

        if (json.error === false) {
          const cookieSettings = {
            quoteRequest: home,
            oferta: oferta,
            dateSuplimentare: dateSuplimentare,
            contractant: contractant,
            adressFacturare: adresa,
          };
          Object.entries(cookieSettings).forEach(
            ([cookieName, cookieValue]) => {
              setCookie(cookieName, cookieValue);
            }
          );

          window.location.assign(json.formUrl);
        }
      } catch (error) {
        navigate("/eroare");
      }
    }
  }

  const handleInputChange = (index, field, value) => {
    const value1 = value.replace(/\D/g, "");
    const updatedPeople = [...cesionari];
    updatedPeople[index][field] = value1;
    setCesionari(updatedPeople);
  };

  const handleRemoveLastPerson = () => {
    setModificare(true);

    if (cesionari.length > 1) {
      const updatedPeople = cesionari.slice(0, -1);
      setCesionari(updatedPeople);
    } else if (cesionari.length === 1) {
      setCesionari([{ id: "", percentage: "" }]);
      setHome((prevHome) => ({
        ...prevHome,
        assignmentDetails: "",
      }));
      setShowNewPeople(false);
    }
  };
  const handleAddPerson = () => {
    setModificare(true);

    setCesionari([...cesionari, { id: "", percentage: "" }]);
  };

  const handleViewPerson = () => {
    setModificare(true);

    setShowNewPeople(true);
  };

  const { t, i18n } = useLanguage();

  const [orasFactura, setOrasFactura] = useState([
    { code: "9999999999", description: "Selecteaza" },
  ]);

  const [codPostalFactura, setCodPostalFactura] = useState([
    { code: "9999999999", description: "Selecteaza" },
  ]);

  async function handleJudetChange(event) {
    setModificare(true);

    const judet = event.target.value;

    if (judet !== "100") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getCities?parentCode=${judet}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setAdresa((prevHome) => ({
          ...prevHome,
          county: judet,
          city: "9999999999",
        }));
        setOrasFactura([
          { code: "9999999999", description: "Selecteaza" },
          ...data,
        ]);
        setCodPostalFactura([
          { code: "9999999999", description: "Selecteaza" },
        ]);
      } catch (error) {
        navigate("/eroare");
      }
      //}
    } else {
      setAdresa((prevHome) => ({
        ...prevHome,
        county: judet,
        city: "9999999999",
      }));
      setOrasFactura([{ code: "9999999999", description: "Selecteaza" }]);
    }
  }

  async function handleOrasChange(event) {
    setModificare(true);

    const oras = event.target.value;
    setAdresa((prevHome) => ({
      ...prevHome,
      city: oras,
    }));

    if (oras !== "9999999999") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getPostCodes?postCodes=${oras}`,
          //`http://localhost:8080/getPostCodes?postCodes=${oras}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        setCodPostalFactura([
          { code: "9999999999", description: "Selecteaza" },
          ...data,
        ]);
      } catch (error) {
        navigate("/eroare");
      }
    }
  }

  const handleCesionarChange = (value, index) => {
    setModificare(true);

    const selectedIndex = value;
    setCesionari((prevCesionari) => {
      const updatedCesionari = [...prevCesionari];
      updatedCesionari[index].id = selectedIndex; // Setăm id-ul în funcție de valoarea selectată
      return updatedCesionari;
    });
  };

  function verificareCesionari() {
    let total = 0;
    let veri = 0;
    for (let i = 0; i < cesionari.length; i++) {
      const percentage = parseInt(cesionari[i].percentage);
      if (!isNaN(percentage)) {
        total += percentage;
      }
      if (cesionari[i].id === "") veri = 1;
    }
    if (total !== 100) setErrorSuma(true);
    else setErrorSuma(false);

    if (veri === 0) setErrorSelect(false);
    else setErrorSelect(true);

    if (total !== 100 || veri !== 0) return true;
    else return false;
  }

  const [loadingPDF, setLoadingPDF] = useState(false);

  const handleDownloadPdf = () => {
    setLoadingPDF(true);
    // Construiește URL-ul cu parametrul ofertaId
    const apiUrl = `${process.env.REACT_APP_API_URL}/pdfHPTC?ofertaId=${oferta.offerId}`;

    // Setează opțiunile pentru cererea GET
    const requestOptions = {
      method: "GET",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    };

    // Trimite cererea GET către API-ul specificat
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);

        // Creează un element de ancoră pentru descărcarea fișierului
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "ConditiiAsigurareCasa.pdf";
        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(blobUrl);

        setLoadingPDF(false);
      })
      .catch((error) => {
        setLoadingPDF(false);
      });
  };

  return (
    <section className="sectionLocuinte">
      <div className="LocuinteSpace">
        <PassList yellowCount={page} />
      </div>

      <Container className="containerLocuinte">
        <div className="sectionTravel_buy__form_back">
          <img
            src={sageata}
            alt=""
            className="image-with-hover-cursor sageata_travel"
            onClick={() => {
              window.scrollTo(0, 0);
              setPage(page - 1);
              setModificare(true);
            }}
          />
          <p
            className="sectionTravel_buy_button_back image-with-hover-cursor"
            onClick={() => {
              window.scrollTo(0, 0);
              setPage(page - 1);
            }}
          >
            {t("travelEurolife.back")}
          </p>
        </div>
        <Row>
          <Col xl="9" lg="9" md="9" sm="12">
            <Row className="detaliiRowFinal">
              <Col xl="7" lg="7" md="7" sm="12">
                <div>
                  <label className="detaliiTitle1Final">
                    {t("homeProtect.final.subtitle6")} <br />
                  </label>

                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <label
                      className={
                        IsProprietar === true
                          ? "buttonLocuinteProprietaraBlue image-with-hover-cursor"
                          : "buttonLocuinteProprietara image-with-hover-cursor"
                      }
                      onClick={(event) => {
                        setContractant({
                          nume: "",
                          prenume: "",
                          cnp: "",
                        });
                        setIsProprietar(true);

                        setModificare(true);
                      }}
                    >
                      {t("homeProtect.final.da")}
                    </label>
                    <label
                      className={
                        IsProprietar === false
                          ? "buttonLocuinteProprietaraBlue image-with-hover-cursor"
                          : "buttonLocuinteProprietara image-with-hover-cursor"
                      }
                      style={{ marginLeft: "10px" }}
                      onClick={(event) => {
                        setIsProprietar(false);
                        setModificare(true);
                      }}
                    >
                      {t("homeProtect.final.nu")}
                    </label>
                  </div>
                </div>
                <div>
                  <label className="detaliiTitle1Final">
                    {t("homeProtect.final.title")} <br />
                  </label>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <label
                    className={
                      home.customer.personType === 0
                        ? "buttonLocuinteBlue image-with-hover-cursor"
                        : "buttonLocuinte image-with-hover-cursor"
                    }
                    onClick={(event) => {
                      setHome((prevHome) => ({
                        ...prevHome,
                        customer: {
                          ...prevHome.customer,
                          personType: 0,
                        },
                      }));
                      setModificare(true);
                    }}
                  >
                    {t("homeProtect.final.pf")}
                  </label>
                  <label
                    className={
                      home.customer.personType === 1
                        ? "buttonLocuinteBlue image-with-hover-cursor"
                        : "buttonLocuinte image-with-hover-cursor"
                    }
                    style={{ marginLeft: "10px" }}
                    onClick={(event) => {
                      setHome((prevHome) => ({
                        ...prevHome,
                        customer: {
                          ...prevHome.customer,
                          personType: 1,
                          lastName: "",
                        },
                      }));
                      setModificare(true);
                    }}
                  >
                    {t("homeProtect.final.pj")}
                  </label>
                </div>
                {/* <div>
                  <label className="detaliiMiniTitleFinal">
                    {t("homeProtect.final.cetatenie")}
                    <select
                      className="centered-select"
                      onChange={(event) => {
                        setHome((prevHome) => ({
                          ...prevHome,

                          customer: {
                            ...prevHome.customer,
                            citizenship: event.target.value,
                            uniqueIdentifier: "",
                          },
                        }));
        
                      }}
                      value={home.customer.citizenship}
                    >
                      {citizenship.map((citizenship, index) => (
                        <option key={citizenship.code} value={citizenship.code}>
                          {citizenship.description}
                        </option>
                      ))}
                    </select>
                  </label>
                </div> */}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {home.customer.personType === 0 && (
                    <label
                      className={
                        home.customer.lastName.length < 1 && error === true
                          ? "detaliiMiniTitleFinalRed"
                          : "detaliiMiniTitleFinal"
                      }
                    >
                      {t("homeProtect.final.request1")}
                      <br />
                      <input
                        type="text"
                        name="name"
                        placeholder={t("homeProtect.pasul1.subtitle61")}
                        value={home.customer.lastName}
                        onChange={(event) => {
                          setHome((prevHome) => ({
                            ...prevHome,
                            customer: {
                              ...prevHome.customer,
                              lastName: event.target.value,
                            },
                          }));
                          setModificare(true);
                        }}
                      />
                    </label>
                  )}
                  <label
                    className={
                      home.customer.firstName.length < 1 && error === true
                        ? "detaliiMiniTitleFinalRed"
                        : "detaliiMiniTitleFinal"
                    }
                  >
                    {home.customer.personType === 0 &&
                      t("homeProtect.final.request2")}
                    {home.customer.personType === 1 &&
                      t("homeProtect.final.request5")}

                    <br />
                    <input
                      type="text"
                      name="name"
                      placeholder={t("homeProtect.pasul1.subtitle61")}
                      value={home.customer.firstName}
                      onChange={(event) => {
                        setHome((prevHome) => ({
                          ...prevHome,
                          customer: {
                            ...prevHome.customer,
                            firstName: event.target.value,
                          },
                        }));
                        setModificare(true);
                      }}
                    />
                  </label>

                  <label
                    className={
                      home.customer.uniqueIdentifier.length < 1 &&
                      error === true
                        ? "detaliiMiniTitleFinalRed"
                        : "detaliiMiniTitleFinal"
                    }
                  >
                    {home.customer.personType === 0 &&
                      t("homeProtect.final.request3")}
                    {home.customer.personType === 1 &&
                      t("homeProtect.final.request4")}
                    <br />
                    <input
                      type="text"
                      name="name"
                      placeholder={t("homeProtect.pasul1.subtitle61")}
                      value={home.customer.uniqueIdentifier}
                      onChange={(event) => {
                        setHome((prevHome) => ({
                          ...prevHome,
                          customer: {
                            ...prevHome.customer,
                            uniqueIdentifier: event.target.value,
                          },
                        }));
                        setModificare(true);
                      }}
                    />
                  </label>
                </div>
                {!IsProprietar ? (
                  <div>
                    <div>
                      <label className="detaliiTitle1Final">
                        {t("homeProtect.final.tableTile2")} <br />
                      </label>
                    </div>
                    <label
                      className={
                        contractant.nume.length < 1 && error === true
                          ? "detaliiMiniTitleFinalRed"
                          : "detaliiMiniTitleFinal"
                      }
                    >
                      {t("homeProtect.final.request1")}
                      <br />
                      <input
                        type="text"
                        name="name"
                        placeholder={t("homeProtect.pasul1.subtitle61")}
                        value={contractant.nume}
                        onChange={(event) => {
                          setContractant((prevHome) => ({
                            ...prevHome,
                            nume: event.target.value,
                          }));
                          setModificare(true);
                        }}
                      />
                    </label>
                    <label
                      className={
                        contractant.prenume.length < 1 && error === true
                          ? "detaliiMiniTitleFinalRed"
                          : "detaliiMiniTitleFinal"
                      }
                    >
                      {t("homeProtect.final.request2")}
                      <br />
                      <input
                        type="text"
                        name="name"
                        placeholder={t("homeProtect.pasul1.subtitle61")}
                        value={contractant.prenume}
                        onChange={(event) => {
                          setContractant((prevHome) => ({
                            ...prevHome,
                            prenume: event.target.value,
                          }));
                          setModificare(true);
                        }}
                      />
                    </label>
                    <label
                      className={
                        contractant.cnp.length < 1 && error === true
                          ? "detaliiMiniTitleFinalRed"
                          : "detaliiMiniTitleFinal"
                      }
                    >
                      {t("homeProtect.final.request3")}
                      <br />
                      <input
                        type="text"
                        name="name"
                        placeholder={t("homeProtect.pasul1.subtitle61")}
                        value={contractant.cnp}
                        onChange={(event) => {
                          setContractant((prevHome) => ({
                            ...prevHome,
                            cnp: event.target.value,
                          }));
                          setModificare(true);
                        }}
                      />
                    </label>
                  </div>
                ) : null}

                <div>
                  <label className="detaliiMiniTitleFinal">
                    {t("homeProtect.final.title3")}
                    <br />
                  </label>

                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <label
                      className={
                        adresaBoolean === true
                          ? "buttonLocuinteProprietaraBlue image-with-hover-cursor"
                          : "buttonLocuinteProprietara image-with-hover-cursor"
                      }
                      onClick={(event) => {
                        setAdresaBoolean(true);
                        setModificare(true);
                      }}
                    >
                      {t("homeProtect.final.da")}
                    </label>
                    <label
                      className={
                        adresaBoolean === false
                          ? "buttonLocuinteProprietaraBlue image-with-hover-cursor"
                          : "buttonLocuinteProprietara image-with-hover-cursor"
                      }
                      style={{ marginLeft: "10px" }}
                      onClick={(event) => {
                        setAdresaBoolean(false);
                        setAdresa((prevHome) => ({
                          ...prevHome,
                          county: "100",
                          city: "9999999999",
                          postCode: "select",
                          street: "",
                          number: "",
                          building: "",
                          entrance: "",
                          floor: "",
                          appartment: "",
                        }));
                      }}
                    >
                      {t("homeProtect.final.nu")}
                    </label>
                  </div>
                  {adresaBoolean ? (
                    <div>
                      <div>
                        <label className="detaliiTitle1Final">
                          {t("homeProtect.final.title4")}
                          <br />
                        </label>
                      </div>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <label
                            className={
                              adresa.county === "100" && error === true
                                ? "detaliiAdresaFinalRed"
                                : "detaliiAdresaFinal"
                            }
                          >
                            {t("homeProtect.pasul1.subtitle1")}*<br />
                            <select
                              className="centered-select"
                              onChange={handleJudetChange}
                              value={adresa.county}
                            >
                              {judete.map((judet, index) => (
                                <option key={judet.code} value={judet.code}>
                                  {judet.description}
                                </option>
                              ))}
                            </select>
                          </label>
                        </Col>
                        <Col xl="4" lg="6" md="12" sm="12">
                          <label
                            className={
                              adresa.city === "9999999999" && error === true
                                ? "detaliiAdresaFinalRed"
                                : "detaliiAdresaFinal"
                            }
                          >
                            {t("homeProtect.pasul1.subtitle13")}* <br />
                            <select
                              className="centered-select"
                              onChange={handleOrasChange}
                              value={adresa.city}
                              //style={{ fontSize: "small" }} // Setează dimensiunea textului la 'small'
                            >
                              {orasFactura.map((judet, index) => (
                                <option key={judet.code} value={judet.code}>
                                  {judet.description}
                                </option>
                              ))}
                            </select>
                          </label>
                        </Col>
                        <Col xl="4" lg="6" md="6" sm="12">
                          <label
                            className={
                              adresa.postCode === "select" && error === true
                                ? "detaliiAdresaFinalRed"
                                : "detaliiAdresaFinal"
                            }
                          >
                            {t("homeProtect.pasul1.subtitle7")}* <br />
                            <select
                              className="centered-select"
                              onChange={(event) => {
                                setAdresa((prevHome) => ({
                                  ...prevHome,
                                  postCode: event.target.value,
                                }));
                                setModificare(true);
                              }}
                              value={adresa.postCode}
                            >
                              {codPostalFactura.map((codP, index) => (
                                <option
                                  key={codP.code}
                                  value={codP.description}
                                >
                                  {codP.description}
                                </option>
                              ))}
                            </select>
                          </label>
                        </Col>
                        <Col xl="4" lg="6" md="6" sm="12">
                          <label
                            className={
                              adresa.streetType === "select" && error === true
                                ? "detaliiAdresaFinalRed"
                                : "detaliiAdresaFinal"
                            }
                          >
                            {t("homeProtect.pasul1.subtitle8")}*
                            <br />
                            <select
                              className="centered-select"
                              value={adresa.streetType}
                              onChange={(event) => {
                                setAdresa((prevHome) => ({
                                  ...prevHome,
                                  streetType: event.target.value,
                                }));
                                setModificare(true);
                              }}
                            >
                              {Object.keys(TipuriStrazi).map((key, index) => (
                                <option key={index} value={key}>
                                  {TipuriStrazi[key]}
                                </option>
                              ))}
                            </select>
                          </label>
                        </Col>
                        <Col xl="4" lg="6" md="6" sm="12">
                          <label className="detaliiAdresaFinal">
                            {t("homeProtect.pasul1.subtitle9")}
                            <br />
                            <input
                              type="text"
                              name="name"
                              placeholder={t("homeProtect.pasul1.subtitle61")}
                              value={adresa.street}
                              onChange={(event) => {
                                setAdresa((prevHome) => ({
                                  ...prevHome,
                                  street: event.target.value,
                                }));
                                setModificare(true);
                              }}
                            />
                          </label>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <label className="detaliiAdresaFinal">
                            {t("homeProtect.pasul1.subtitle10")} <br />
                            <input
                              type="text"
                              name="name"
                              placeholder={t("homeProtect.pasul1.subtitle61")}
                              value={adresa.number}
                              onChange={(event) => {
                                setAdresa((prevHome) => ({
                                  ...prevHome,
                                  number: event.target.value,
                                }));
                                setModificare(true);
                              }}
                            />
                          </label>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <div className="calLocuinteSpace">
                            <label className="detaliiAdresaFinal">
                              {t("homeProtect.pasul1.subtitle11")} <br />
                              <input
                                type="text"
                                name="name"
                                placeholder={t("homeProtect.pasul1.subtitle61")}
                                value={adresa.building}
                                onChange={(event) => {
                                  setAdresa((prevHome) => ({
                                    ...prevHome,
                                    building: event.target.value,
                                  }));
                                  setModificare(true);
                                }}
                              />
                            </label>
                          </div>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <label className="detaliiAdresaFinal">
                            {t("homeProtect.pasul1.subtitle14")} <br />
                            <input
                              type="text"
                              name="name"
                              placeholder={t("homeProtect.pasul1.subtitle61")}
                              value={adresa.entrance}
                              onChange={(event) => {
                                setAdresa((prevHome) => ({
                                  ...prevHome,
                                  entrance: event.target.value,
                                }));
                                setModificare(true);
                              }}
                            />
                          </label>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <label className="detaliiAdresaFinal">
                            {t("homeProtect.pasul1.subtitle16")}
                            <br />
                            <input
                              type="text"
                              name="name"
                              placeholder={t("homeProtect.pasul1.subtitle61")}
                              value={adresa.appartment}
                              onChange={(event) => {
                                setAdresa((prevHome) => ({
                                  ...prevHome,
                                  appartment: event.target.value,
                                }));
                                setModificare(true);
                              }}
                            />
                          </label>
                        </Col>
                        <Col xl="4" lg="4" md="6" sm="12">
                          <label
                            className={
                              adresa.floor === "" && error === true
                                ? "detaliiAdresaFinalRed"
                                : "detaliiAdresaFinal"
                            }
                          >
                            {t("homeProtect.pasul1.subtitle15")}

                            <br />
                            <select
                              className="centered-select"
                              value={adresa.floor}
                              onChange={(event) => {
                                setAdresa((prevHome) => ({
                                  ...prevHome,
                                  floor: event.target.value,
                                }));
                                setModificare(true);
                              }}
                            >
                              {Object.entries(etaje).map(([key, value]) => (
                                <option key={key} value={key}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </label>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
                <div style={{ marginTop: "20px" }}>
                  <label
                    className={
                      dateSuplimentare.number.length < 10 && error === true
                        ? "detaliiMiniTitleFinalRed"
                        : "detaliiMiniTitleFinal"
                    }
                  >
                    {t("homeProtect.final.telefon")}
                    <br />
                    <input
                      type="text"
                      name="name"
                      placeholder={t("homeProtect.pasul1.subtitle61")}
                      value={dateSuplimentare.number}
                      onChange={validateNumber}
                    />
                  </label>
                  <label
                    className={
                      !validateEmail(dateSuplimentare.email) && error === true
                        ? "detaliiMiniTitleFinalRed"
                        : "detaliiMiniTitleFinal"
                    }
                  >
                    {t("homeProtect.final.mail")}
                    <br />
                    <input
                      type="text"
                      name="name"
                      placeholder={t("homeProtect.pasul1.subtitle61")}
                      value={dateSuplimentare.email}
                      onChange={(event) => {
                        setDateSuplimentare((prevState) => ({
                          ...prevState,
                          email: event.target.value,
                        }));

                        setModificare(true);
                      }}
                    />
                  </label>
                </div>
                <label className="detaliiTitle1Final">
                  {t("homeProtect.final.cesionari")}
                  <br />
                </label>
                <div>
                  {!showNewPeople ? (
                    <div
                      style={{
                        marginTop: "-35px",
                      }}
                    >
                      <img
                        src={Add}
                        alt=""
                        className="image-with-hover-cursor"
                        onClick={handleViewPerson}
                      />
                      <label
                        className="sub_title_Travel_buy subtitle_Travel_spatiu image-with-hover-cursor"
                        onClick={handleViewPerson}
                      >
                        {t("homeProtect.final.adCesionari")}
                      </label>
                    </div>
                  ) : (
                    <div>
                      {cesionari.map((person, index) => (
                        <div key={index}>
                          <label
                            className={
                              person.id === "" && errorSelect === true
                                ? "detaliiAdresaFinalCesionariRed"
                                : "detaliiAdresaFinalCesionari"
                            }
                            style={{
                              marginRight: "20px",
                            }}
                          >
                            {t("homeProtect.final.cesionar")}
                            <br />
                            <select
                              className="centered-select small-text"
                              // onChange={handleCesionarChange}
                              onChange={(e) =>
                                handleCesionarChange(e.target.value, index)
                              }
                              value={person.id}
                            >
                              {assignees.map((assignee, index) => (
                                <option key={assignee.id} value={assignee.id}>
                                  {assignee.description}
                                </option>
                              ))}
                            </select>
                          </label>

                          <label
                            className={
                              errorSuma === true
                                ? "detaliiMiniTitleFinalRed"
                                : "detaliiMiniTitleFinal"
                            }
                          >
                            {t("homeProtect.final.procent")}
                            <br />
                            <input
                              type="text"
                              value={person.percentage}
                              //pattern="[0-9]*"
                              onChange={(e) => {
                                handleInputChange(
                                  index,
                                  "percentage",
                                  e.target.value.toUpperCase()
                                );
                                setModificare(true);
                              }}
                            />
                          </label>
                        </div>
                      ))}
                      <label
                        style={{
                          marginRight: 30,
                        }}
                        className={
                          home.assignmentDetails.length < 1 && error === true
                            ? "detaliiAdresaFinalCesionariRed"
                            : "detaliiAdresaFinalCesionari"
                        }
                      >
                        {t("homeProtect.final.titleDetalii")}
                        <br />
                        <input
                          type="text"
                          value={home.assignmentDetails}
                          onChange={(e) =>
                            setHome((prevHome) => ({
                              ...prevHome,
                              assignmentDetails: e.target.value,
                            }))
                          }
                        />
                      </label>

                      {errorSuma && (
                        <div>
                          <label className="errorTextLocuinte">
                            {t("homeProtect.final.error1Cesionar")}
                          </label>
                        </div>
                      )}
                      {errorSelect && (
                        <div>
                          <label className="errorTextLocuinte">
                            {t("homeProtect.final.errorCesionar")}
                          </label>
                        </div>
                      )}

                      <div>
                        <img
                          src={Add}
                          alt=""
                          className="image-with-hover-cursor"
                          onClick={handleRemoveLastPerson}
                        />
                        <label
                          className="sub_title_Travel_buy image-with-hover-cursor"
                          onClick={handleRemoveLastPerson}
                        >
                          {t("homeProtect.final.elCesionari")}
                        </label>
                      </div>
                      {cesionari.length < 2 ? (
                        <div>
                          <img
                            src={Add}
                            alt=""
                            className="image-with-hover-cursor"
                            onClick={handleAddPerson}
                          />
                          <label
                            className="sub_title_Travel_buy subtitle_Travel_spatiu image-with-hover-cursor"
                            onClick={handleAddPerson}
                          >
                            {t("homeProtect.final.adCesionari")}
                          </label>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div style={{ marginTop: "20px" }}>
                  <label
                    className={
                      !dateSuplimentare.date && error
                        ? "detaliiMiniTitleFinalDataRed"
                        : "detaliiMiniTitleFinalData"
                    }
                  >
                    {t("homeProtect.final.data")}
                    <br />
                    <DatePicker
                      selected={
                        dateSuplimentare.date
                          ? new Date(dateSuplimentare.date)
                          : null
                      }
                      dateFormat="dd-MM-yyyy"
                      minDate={today}
                      maxDate={maxDateInceput}
                      onChange={handleChangeDataInceput}
                      placeholderText={t("travelEurolife.select")}
                    />
                  </label>
                </div>
                <label className="detaliiTitle1Final">
                  {t("homeProtect.final.textPaid")}{" "}
                  <label
                    className="letterMare"
                    onMouseEnter={(e) => handleMouseEnter(e)}
                    onMouseLeave={handleMouseLeave}
                  >
                    i
                  </label>
                </label>

                {afiseazaPopUpPaid && (
                  <PopUpInfo
                    top={pozitieCursor.top}
                    left={pozitieCursor.left}
                    text={t("homeProtect.final.infoPad")}
                    title={""}
                  />
                )}
                <div
                  style={{
                    marginLeft: "20px",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <img
                    src={pass}
                    alt=""
                    className="image-with-hover-cursor buttonPaid"
                    onClick={functionPaid}
                  />

                  <div
                    className="locuinte_buttonText1 image-with-hover-cursor"
                    onClick={functionPaid}
                  >
                    {t("homeProtect.final.button1")}
                  </div>
                </div>
                {error && (
                  <div>
                    <label className="errorTextLocuinte">
                      {t("homeProtect.final.error")}
                    </label>
                  </div>
                )}
                {!error &&
                  modificare === false &&
                  paid.messages.length < 1 &&
                  paid.hasActivePad !== undefined &&
                  paid.hasActivePad === true &&
                  paid.hasExpiringPad === false && (
                    <div>
                      <label className="errorTextLocuinte">
                        {t("homeProtect.final.paidValidation4")}
                      </label>
                    </div>
                  )}
                {!error &&
                  modificare === false &&
                  paid.messages.length < 1 &&
                  paid.hasActivePad !== undefined &&
                  paid.hasActivePad === false &&
                  paid.hasExpiringPad === false && (
                    <div>
                      <label className="errorTextLocuinte">
                        {t("homeProtect.final.paidValidation2")}
                      </label>
                    </div>
                  )}
                {!error &&
                  paid.messages.length < 1 &&
                  modificare === false &&
                  paid.hasActivePad !== undefined &&
                  paid.hasActivePad === true &&
                  paid.hasExpiringPad === true && (
                    <div>
                      <label className="errorTextLocuinte">
                        {t("homeProtect.final.paidValidation")}
                        {paid.padValueInRon} RON
                      </label>
                    </div>
                  )}

                {error && dateSuplimentare.number.length < 10 && (
                  <div>
                    <label className="errorTextLocuinte">
                      {t("homeProtect.final.allert1")}
                    </label>
                  </div>
                )}
                {error && !validateEmail(dateSuplimentare.email) && (
                  <div>
                    <label className="errorTextLocuinte">
                      {t("homeProtect.final.allert2")}
                    </label>
                  </div>
                )}
                {paid.messages.length > 0 && modificare === false && (
                  <div>
                    {paid.messages.map((message, index) => (
                      <label key={index} className="errorTextLocuinte">
                        {message.content}
                      </label>
                    ))}
                  </div>
                )}
              </Col>
              <Col xl="5" lg="5" md="5" sm="12">
                <div>
                  {!error &&
                    paid.messages.length < 1 &&
                    paid.hasActivePad !== undefined &&
                    modificare === false && (
                      <div>
                        <div>
                          <label className="detaliiTitle1Final">
                            {t("homeProtect.final.subtitle2")} <br />
                          </label>
                        </div>
                        <div>
                          <label className="detaliiMiniTitleInfo">
                            {t("homeProtect.final.tip")}{" "}
                            <b>{home.house.technicalDetails.houseType}</b>
                          </label>
                        </div>
                        <div>
                          <label className="detaliiMiniTitleInfo">
                            {t("homeProtect.final.prop")}{" "}
                            <b>
                              {home.customer.firstName} {home.customer.lastName}
                            </b>
                          </label>
                        </div>
                        <div>
                          <label className="detaliiMiniTitleInfo">
                            {t("homeProtect.final.adress")}{" "}
                            <b>
                              {
                                judete.find(
                                  (judet) =>
                                    judet.code === home.house.address.county
                                )?.description
                              }
                              ,{" "}
                              {
                                orase.find(
                                  (oras) =>
                                    oras.code === home.house.address.city
                                )?.description
                              }
                              ,{" "}
                              {home.house.address.streetType &&
                                ` ${home.house.address.streetType}, `}
                              {home.house.address.street &&
                                ` ${t("homeProtect.pasul1.subtitle9")} ${
                                  home.house.address.street
                                }, `}
                              {home.house.address.number &&
                                ` ${t("homeProtect.pasul1.subtitle10")} ${
                                  home.house.address.number
                                }, `}
                              {home.house.address.building &&
                                ` ${t("homeProtect.pasul1.subtitle11")} ${
                                  home.house.address.building
                                }, `}
                              {home.house.address.entrance &&
                                ` ${t("homeProtect.pasul1.subtitle14")} ${
                                  home.house.address.entrance
                                }, `}
                              {home.house.address.floor &&
                                `  ${home.house.address.floor}, `}
                              {home.house.address.appartment &&
                                ` ${t("homeProtect.pasul1.subtitle16")} ${
                                  home.house.address.appartment
                                } `}
                            </b>
                          </label>
                        </div>
                        <div>
                          <label className="detaliiMiniTitleInfo">
                            {t("homeProtect.final.suprafata")}{" "}
                            <b>{home.house.technicalDetails.usableArea} m2</b>
                          </label>
                        </div>
                        <div>
                          <label className="detaliiTitle1Final">
                            {t("homeProtect.final.subtitle3")} <br />
                          </label>
                        </div>
                        <div>
                          <label className="detaliiMiniTitleInfo">
                            {t("homeProtect.final.perioada")}{" "}
                            <b>
                              {t("homeProtect.final.perioada1")}{" "}
                              {formatDateview(dateSuplimentare.date)}
                            </b>
                          </label>
                        </div>
                        <div>
                          <label className="detaliiMiniTitleInfo">
                            {t("homeProtect.final.prima")}{" "}
                            <b>
                              {
                                oferta.frequencies.find(
                                  (frequency) =>
                                    frequency.frequency === home.frequency
                                ).premium
                              }{" "}
                              {home.currency}
                            </b>
                          </label>
                        </div>
                        <div>
                          <label className="detaliiMiniTitleInfo">
                            {t("homeProtect.final.plata")}{" "}
                            <b>{oferta.selectedFrequency}</b>
                          </label>
                        </div>
                        {!error &&
                          paid.hasActivePad !== undefined &&
                          (paid.hasActivePad === false ||
                            (paid.hasActivePad === true &&
                              paid.hasExpiringPad === true)) && (
                            <div>
                              <div>
                                <label className="detaliiTitle1Final">
                                  {t("homeProtect.final.subtitle4")} <br />
                                </label>
                              </div>
                              <div>
                                <label className="detaliiMiniTitleInfo">
                                  {t("homeProtect.final.perioada")}{" "}
                                  <b>{t("homeProtect.final.perioada2")} </b>
                                </label>
                              </div>

                              <div>
                                <label className="detaliiMiniTitleInfo">
                                  {t("homeProtect.final.prima")}{" "}
                                  <b>{paid.padValueInRon} RON</b>
                                </label>
                              </div>

                              <div>
                                <label className="detaliiMiniTitleInfo">
                                  {t("homeProtect.final.plata")}{" "}
                                  <b> {t("homeProtect.final.plata2")}</b>
                                </label>
                              </div>
                            </div>
                          )}
                        <div className="detePersonaleCheckbox">
                          <label>
                            <input
                              type="checkbox"
                              required="required"
                              onChange={(e) => setTc(e.target.checked)}
                            />
                            {t("homeProtect.final.check1")}

                            <a href="/termeni-conditii" target="_blank">
                              {t("homeProtect.final.check11")}
                            </a>
                          </label>
                        </div>
                        <div className="detePersonaleCheckbox">
                          <label>
                            <input
                              type="checkbox"
                              required="required"
                              onChange={(e) => setPc(e.target.checked)}
                            />
                            {t("homeProtect.final.check2")}

                            <a
                              href="/politica-confidentialitate"
                              target="_blank"
                            >
                              {t("homeProtect.final.check21")}
                            </a>
                          </label>
                        </div>
                        <div className="detePersonaleCheckbox">
                          <label>
                            <input
                              type="checkbox"
                              required="required"
                              onChange={(e) => setOfr(e.target.checked)}
                            />
                            {t("travelEurolife.polita.check3")}{" "}
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDownloadPdf();
                              }}
                              disabled={loadingPDF}
                            >
                              {t("travelEurolife.polita.dw")}
                            </a>
                          </label>
                        </div>
                        {(pc !== true || tc !== true || ofr !== true) && (
                          <div>
                            <label className="errorTextLocuinte">
                              {t("homeProtect.final.errorfinal")}
                            </label>
                          </div>
                        )}
                        <p className="detaliiTitle1Final">
                          <b> {t("homeProtect.final.buttonTotal")} </b>{" "}
                          {dateSuplimentare.totalPlata} RON
                        </p>

                        <div
                          style={{
                            marginLeft: "10px",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                        >
                          <img
                            src={pass}
                            alt=""
                            className="image-with-hover-cursor buttonRePlata"
                            onClick={functionPay}
                          />

                          <div
                            className="locuinte_buttonPlataText1 image-with-hover-cursor"
                            onClick={functionPay}
                          >
                            {t("homeProtect.final.button2")}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "80px",
          }}
        >
          <Col xl="1" lg="0" md="0" sm="0"></Col>
          <Col xl="3" lg="4" md="6" sm="12"></Col>
          <Col xl="5" lg="5" md="6" sm="12"></Col>

          <Col xl="3" lg="3" md="12" sm="12">
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={contactfooter} alt="" className="imgFooterNext" />
              <img
                style={{ width: "260px", height: "auto", marginTop: "-200px" }}
                src={whatsapp}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              />
              <p
                className="whatsappLocuinte image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              >
                {t("homeProtect.pasul1.whatsapp")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {showPopup && (
        <div className="popup-wrapper">
          <div className="popup-overlay" />
          <div className="popup-containerTransparent">
            <Spinner
              animation="border"
              role="status"
              style={{
                height: "90px",
                width: "90px",
              }}
            ></Spinner>
          </div>
        </div>
      )}
    </section>
  );
}

export default DatePersonale;
