import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./Footer_Down_RCA.css";

import { useLanguage } from "../utils/utils.jsx";

const Footer_Down_RCA = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="Footer_Down_RCA">
      <Container>
        <Row>
          <Col lg="6" md="6" className="Footer_Down_RCA_center_left">
            <div>
              <p>© 2023 Senflua. All Rights Reserved.</p>
            </div>
          </Col>
          <Col lg="6" md="6" className="Footer_Down_RCA_center_right">
            <div>
              <a href="/politica-confidentialitate">{t("footerGrey.text3")}</a>
              <a href="/termeni-conditii">{t("footerGrey.text4")}</a>
              <a href="/page-coockies">{t("footerGrey.text5")}</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer_Down_RCA;
