import React, { useState, useEffect } from "react";
import "react-range-slider-input/dist/style.css";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import "./HomeProtectNew.css";
import DownloadPDFButton from "./components/DownloadPDFButton/DownloadPDFButton";

import { Container, Row, Col } from "reactstrap";

import { useTranslation } from "react-i18next";

import PassList from "./components/passList/PassList";
import ChatBubble from "./components/chatBubble/ChatBubble";
import SenfluaPas1 from "../../assests/images/HomeProtect/SenfluaPas1.svg";

import sageata from "../../assests/images/RCA/sageata.svg";
import contactfooter from "../../assests/images/HomeProtect/contact.svg";

import pass from "../../assests/images/HomeProtect/pass.png";
import whatsapp from "../../assests/images/HomeProtect/whatsapp.png";

import "../../i18n";

function Fransiza({
  home,
  setHome,
  judete,
  setJudete,
  orase,
  setOrase,
  constructia,
  currency,
  covers,
  setCovers,
  page,
  setPage,
  oferta,
  setOferta,
  franchises,
  setFranchises,
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [errorSuma, setErrorSuma] = useState(false);

  const calculateVecini = (locuintaValue) => {
    return locuintaValue * 0.2;
  };

  let intr = 0;

  useEffect(() => {
    if (intr === 0) {
      functionRefresh();
      intr = 1;
    }
  }, []);

  useEffect(() => {
    functionRefresh();
  }, [home.franchise]);

  async function functionRefresh() {
    setErrorSuma(false);
    setShowPopup(true);
    const requestOptions1 = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quoteRequest: home, covers: covers }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertaHouse1`,
        requestOptions1
      );
      const json = await response.json();
      if (json.error === false) {
        setOferta((prevOferta) => {
          const updatedOferta = {
            ...prevOferta,
            error: false,
            badRequest: {
              ...prevOferta.badRequest,
              code: "",
              message: "",
            },
          };

          // Actualizează valorile existente din json în oferta
          for (const key in json) {
            if (json.hasOwnProperty(key)) {
              if (key === "covers") {
                // Dacă cheia este "covers", actualizează individual covers
                updatedOferta[key] = updatedOferta[key].map((cover) => {
                  const jsonCover = json[key].find(
                    (jsonCover) =>
                      jsonCover.productCoverCode === cover.productCoverCode
                  );

                  if (jsonCover) {
                    jsonCover.name = cover.name; // utilizează numele din oferta curentă
                  }
                  return jsonCover || cover;
                });
              } else {
                updatedOferta[key] = json[key];
              }
            }
          }

          // Pentru cheile care nu există în json, setează insuredSum pe 0 în covers
          if (updatedOferta.covers) {
            updatedOferta.covers.forEach((cover) => {
              if (
                !json.covers.find(
                  (jsonCover) =>
                    jsonCover.productCoverCode === cover.productCoverCode
                )
              ) {
                cover.insuredSum = 0;
                cover.premium = 0;
              }
            });
          }

          return updatedOferta;
        });
      } else {
        setOferta((prevOferta) => ({
          ...prevOferta,
          error: true,
          offerId: "0",
          series: "0",
          number: "0",
          badRequest: {
            ...prevOferta.badRequest,
            code: json.badRequest.code,
            message: json.badRequest.message,
          },
          covers: prevOferta.covers.map((cover) => ({
            ...cover,
            premium: 0,
          })),
        }));
        setCovers((prevState) => ({
          ...prevState,
          electrice: 0,
        }));
      }
    } catch (error) {
      navigate("/eroare");
    }
    setShowPopup(false);
  }

  const updateHome = () => {
    if (oferta.error === false) {
      window.scrollTo(0, 0);
      setPage(page + 1);
    }
    setPage(page + 1);
  };

  return (
    <section className="containerHP">
      <div className="LocuinteSpace">
        <PassList yellowCount="3" />
      </div>

      <Container>
        <div className="sectionTravel_buy__form_back">
          <img
            src={sageata}
            alt=""
            className="image-with-hover-cursor sageata_travel"
            onClick={() => {
              window.scrollTo(0, 0);
              setPage(page - 1);
            }}
          />
          <p
            className="sectionTravel_buy_button_back image-with-hover-cursor"
            onClick={() => {
              window.scrollTo(0, 0);
              setPage(page - 1);
            }}
          >
            {t("travelEurolife.back")}
          </p>
        </div>

        <Row className="LocuinteSpace">
          <Col xl="2" lg="1" md="1" sm="12" className="locuinteColImg">
            <img
              src={SenfluaPas1}
              alt=""
              style={{
                height: "147px",
                width: "auto",
                marginTop: "10px",
              }}
              className="locuinteImg"
            />
          </Col>
          <Col xl="7" lg="7" md="7" sm="12" className="locuintaColFransiza">
            <Row className="sectionLocuinte_BunuriRow">
              <ChatBubble
                message={t("homeProtect.pasul3.title")}
                position="left"
                dimention="CB-title2"
              />
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="sectionlocuinta_Bunuri">
                  <label className="sectionLocuinta_FransizaTitle">
                    {t("homeProtect.pasul3.title1")}
                  </label>
                  <div>
                    <label className="sectionLocuinta_FransizaTitle">
                      {t("homeProtect.pasul3.title2")}
                    </label>
                  </div>
                  <label className="sectionLocuinta_FransizaTitle">
                    {t("homeProtect.pasul3.title3")}
                  </label>
                  <div className="containerFransiza">
                    <label className="detaliiMiniTitle5">
                      <select
                        className="centered-select"
                        onChange={(event) => {
                          setHome((prevHome) => ({
                            ...prevHome,
                            franchise: event.target.value,
                          }));
                        }}
                        value={home.franchise}
                      >
                        {franchises.map((franchise, index) => (
                          <option key={franchise.code} value={franchise.code}>
                            {franchise.description}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label
                      style={{
                        marginTop: "20px",
                      }}
                      className="detaliiMiniTitle5"
                    >
                      {" "}
                      {home.currency}
                    </label>
                  </div>
                  <p className="sectionLocuinta_FransizaEnd">
                    {t("homeProtect.pasul3.under")}
                  </p>
                </div>
              </Col>
            </Row>
            <div
              style={{
                marginTop: "-35px",
                textAlign: "center",
              }}
            >
              <DownloadPDFButton />
            </div>
          </Col>

          <Col
            xl="2"
            lg="2"
            md="2"
            sm="12"
            style={{
              marginLeft: "60px",
            }}
          >
            {/* <img src={money} alt="" /> */}
            <div className="sticker">
              <p className="ofertaTitleLocuinte">
                {t("homeProtect.pret.title")}
              </p>
              <p className="ofertaPretLocuinte">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "LUNARA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.luna")}
              </p>
              <p className="ofertaPretLocuinte2">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "TRIMESTRIALA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.trimestru")}
              </p>
              <p className="ofertaPretLocuinte2">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "SEMESTRIALA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.semestru")}
              </p>
              <p className="ofertaPretLocuinte2">
                {
                  oferta.frequencies.find(
                    (frequency) => frequency.frequency === "ANUALA"
                  ).premium
                }{" "}
                {home.currency} / {t("homeProtect.pret.an")}
              </p>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "100px",
          }}
        >
          <Col xl="2" lg="0" md="0" sm="0"></Col>
          <Col xl="2" lg="4" md="6" sm="12">
            <div
              className="sectionTravel_Travel__form_back"
              style={{
                marginLeft: "20px",
              }}
            >
              <img
                src={sageata}
                alt=""
                className="image-with-hover-cursor sageata_travel"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              />
              <p
                className="sectionTravel_buy_button_back image-with-hover-cursor"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              >
                {t("travelEurolife.back")}
              </p>
            </div>
          </Col>
          <Col xl="4" lg="4" md="6" sm="12">
            <div>
              <img
                src={pass}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "276px",
                  height: "auto",
                }}
                onClick={updateHome}
              />

              <div
                className="HP_buttonText image-with-hover-cursor"
                style={{
                  marginLeft: "56px",
                  marginTop: "-35px",
                }}
                onClick={updateHome}
              >
                {t("homeProtect.pasul1.button")}
              </div>
            </div>
          </Col>

          <Col xl="4" lg="4" md="6" sm="12">
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={contactfooter} alt="" className="imgFooterNext" />
              <img
                style={{ width: "260px", height: "auto", marginTop: "-200px" }}
                src={whatsapp}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              />
              <p
                className="whatsappLocuinte image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              >
                {t("homeProtect.pasul1.whatsapp")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {showPopup && (
        <div className="popup-wrapper">
          <div className="popup-overlay" />
          <div className="popup-containerTransparent">
            <Spinner
              animation="border"
              role="status"
              style={{
                height: "90px",
                width: "90px",
              }}
            ></Spinner>
          </div>
        </div>
      )}
    </section>
  );
}
export default Fransiza;
