import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

import Autoturism from "./Autoturism";
import Informatii from "./Informatii";
import Oferte from "./Oferte";
import Polita from "./Polita";
import "./RCA_buy.css";

import rca1 from "../../assests/images/RCA/rca1.svg";
import rca2 from "../../assests/images/RCA/rca2.svg";
import sageata from "../../assests/images/RCA/sageata.svg";
import next from "../../assests/images/RCA/next.png";
import polite from "../../assests/images/RCA/polite.png";
import hand from "../../assests/images/RCA/hand.svg";
import contacteeazaSenflua from "../../assests/images/contacteeazaSenflua.svg";

import { useTranslation } from "react-i18next";
import "../../i18n";

import { useLanguage } from "../utils/utils.jsx";

const RCA_buy = () => {
  const { t, i18n } = useLanguage();

  useEffect(() => {
    document.title = "Senflua - Asigurare RCA";
  }, []);

  const navigate = useNavigate();

  const [page, setPage] = useState(3);
  const [oferta, setOferta] = useState({});

  const [errorPage1Necompletat, setErrorPage1Necompletat] = useState(false);
  const [errorPage1Sasiu, setErrorPage1Sasiu] = useState(false);

  const [errorPage2Necompletat, setErrorPage2Necompletat] = useState(false);
  const [errorPage2TC, setErrorPage2TC] = useState(false);

  const [oras, setOras] = useState(false);
  const [judet, setJudet] = useState(false);

  const [orasLeasing, setOrasLeasing] = useState(false);
  const [judetLeasing, setJudetLeasing] = useState(false);

  const [buttonNext, setButtonNext] = useState(false);

  const [judete, setJudete] = useState([]);

  const [marci, setMarci] = useState([]);

  const [formData, setFormData] = useState({
    error: 0,
    numar_inmatriculare: "",
    serie_sasiu: "",
    stare_imatriculare: t("rca.inmatriculare.inmatriculat"),
    tip_utilizare: t("rca.utilizare.personal"),
    serie_carte_auto: "",
    leasing: "",
    companie_leasing: false,
    alegeLeasing: "Alta",
    cuiLeasing: "",
    numeLeasing: "",
    orasLeasing: "",
    judetLeasing: "Alba",
    codJudetLeasing: "",
    codSirutaLeasing: "",
    marca: "",
    model: "",
    an_fabricatie: "",
    combustibil: "nimic",
    capacitate_cilindrica: "",
    putere: "",
    masa: "",
    nr_locuri: "",
    tarif: "false",
    perioada: 12,
    ensure: "allianz",
    mail: "hello@senflua.ro",
    telefon: "0724542277",
    cnp: "",
    serie_ci: "",
    nr_ci: "",
    nume: "",
    prenume: "",
    strada: "",
    numar: "",
    bloc: "",
    scara: "",
    eataj: "",
    ap: "",
    cod_postal: "",
    oras: "",
    judet: "Alba",
    cod_judet: "",
    cod_siruta: "",
    data_start: "",
    cui: "",
    mapare_categorii: "1",
    categorie_nationala: "",
    categorie_ue: "",
    mapare_categori: "1",
    model_varianta: "",
    fizicJuridic: false,
    numeSRL: "",
    numar_km: 0,
    data_inmatriculare: "2023-03-21",
    data_expirare_itp: "2023-03-21",
    data_permis_conducere: "2023-03-21",
    termeniConditiiMatriculat: false,
    peste18: false,
    declar: false,
  });

  const [minDate, setMinDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setMinDate(tomorrow);

      try {
        const judeteResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/listJudete`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );
        const judeteData = await judeteResponse.json();
        setJudete(judeteData);

        const marcaRequestOptions = {
          method: "POST",
          headers: {
            "API-Key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        };

        const marciResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/listMarci`,
          marcaRequestOptions
        );
        const marciData = await marciResponse.json();

        setFormData({
          ...formData,
          marca: marciData[0].marca,
          data_start: tomorrow.toISOString().split("T")[0],
          an_fabricatie: tomorrow.getFullYear(),
          data_inmatriculare: tomorrow.toISOString().split("T")[0],
          data_permis_conducere: tomorrow.toISOString().split("T")[0],
          data_expirare_itp: tomorrow.toISOString().split("T")[0],
        });

        setMarci(marciData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleBackClick = () => {
    window.scrollTo(0, 0);
    setFormData({ ...formData, tarif: "false", perioada: 12 });
    setPage((currPage) => currPage - 1);
  };

  const handleNextClick = () => {
    if (
      errorPage1Necompletat === false &&
      errorPage1Sasiu === false &&
      !(formData.companie_leasing === true && formData.alegeLeasing === "Alta")
    ) {
      window.scrollTo(0, 0);
      setButtonNext(false);
      setPage((currPage) => currPage + 1);
    } else {
      setButtonNext(true);
    }
  };

  const handlePoliteClick = () => {
    if (errorPage2Necompletat === false && errorPage2TC === true) {
      window.scrollTo(0, 0);
      setButtonNext(false);
      setPage((currPage) => currPage + 1);
    } else {
      setButtonNext(true);
    }
  };

  const [FormTitles, setFormTitles] = useState([
    t("rca.title1"),
    t("rca.informatii.title"),
    t("rca.oferte.title"),
    t("rca.polita.title"),
  ]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setFormTitles([
        t("rca.title1"),
        t("rca.informatii.title"),
        t("rca.oferte.title"),
        t("rca.polita.title"),
      ]);
      setFormSubTitles(["", t("rca.informatii.subtitle"), "", ""]);
    };
    handleLanguageChange();
  }, [t]);

  const [FormSubTitles, setFormSubTitles] = useState([
    "",
    t("rca.informatii.subtitle"),
    "",
    "",
  ]);

  const renderError = (condition, message) => {
    if (condition) {
      return <p className="errorPage_RCAbuy">{message}</p>;
    }
    return null;
  };

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <Autoturism
          formData={formData}
          setFormData={setFormData}
          orasLeasing={orasLeasing}
          setOrasLeasing={setOrasLeasing}
          judetLeasing={judetLeasing}
          setJudetLeasing={setJudetLeasing}
          buttonNext={buttonNext}
          judete={judete}
          marci={marci}
          navigate={navigate}
        />
      );
    } else if (page === 1) {
      return (
        <Informatii
          formData={formData}
          setFormData={setFormData}
          oras={oras}
          setOras={setOras}
          judet={judet}
          setJudet={setJudet}
          errorPage2TC={errorPage2TC}
          setErrorPage2TC={setErrorPage2TC}
          buttonNext={buttonNext}
          minDate={minDate}
          judete={judete}
          navigate={navigate}
        />
      );
    } else if (page === 2) {
      return (
        <Oferte
          formData={formData}
          setFormData={setFormData}
          page={page}
          setPage={setPage}
          oferta={oferta}
          setOferta={setOferta}
        />
      );
    } else if (page === 3) {
      return (
        <Polita
          formData={formData}
          setFormData={setFormData}
          oferta={oferta}
          setOferta={setOferta}
        />
      );
    } else {
      return <Autoturism formData={formData} setFormData={setFormData} />;
    }
  };

  useEffect(() => {
    const errorPage1 = async () => {
      if (
        (formData.numar_inmatriculare.length < 1 &&
          formData.stare_imatriculare !== "in vederea inmatricularii") ||
        formData.serie_sasiu.length < 1 ||
        formData.serie_carte_auto.length < 1 ||
        formData.marca.length < 1 ||
        formData.model.length < 1 ||
        formData.an_fabricatie.length < 1 ||
        formData.combustibil.length < 1 ||
        formData.capacitate_cilindrica.length < 1 ||
        formData.putere.length < 1 ||
        formData.masa.length < 1 ||
        formData.nr_locuri.length < 1 ||
        formData.combustibil === "nimic"
      ) {
        setErrorPage1Necompletat(true);
      } else {
        setErrorPage1Necompletat(false);
      }
      if (
        formData.serie_sasiu.length < 17 ||
        formData.serie_sasiu.length > 17
      ) {
        setErrorPage1Sasiu(true);
      } else {
        setErrorPage1Sasiu(false);
      }
    };

    const errorPage2 = async () => {
      if (formData.fizicJuridic === false) {
        if (
          formData.cnp.length !== 13 ||
          formData.nume.length < 1 ||
          formData.prenume.length < 1 ||
          formData.serie_ci.length < 1 ||
          formData.nr_ci.length < 1 ||
          formData.judet.length < 1 ||
          formData.oras.length < 1 ||
          formData.strada.length < 2 ||
          formData.numar.length < 1
        ) {
          setErrorPage2Necompletat(true);
        } else {
          setErrorPage2Necompletat(false);
        }
      } else if (formData.fizicJuridic === true) {
        if (
          formData.cui.length < 1 ||
          formData.numeSRL.length < 1 ||
          formData.judet.length < 1 ||
          formData.oras.length < 1
        ) {
          setErrorPage2Necompletat(true);
        } else {
          setErrorPage2Necompletat(false);
        }
      }
    };
    errorPage1();

    errorPage2();
  });
  try {
    return (
      <section className="section_formRCA_buy">
        <Container className="containerRCA_rca_hand">
          <img src={hand} alt="" className="rca_hand" />
        </Container>
        <Container className="containerRCA_buy__form">
          <img
            src={contacteeazaSenflua}
            alt=""
            className="rca_sticky"
            onClick={(event) =>
              window.open("https://wa.me/40724542277", "_blank")
            }
          />
          <Row className="sectionRCA_buy__form">
            <Col lg="8" md="8" className="sectionRCA_buy__left">
              <div
                className={
                  page !== 0 ? "sectionRCA_buy__form_back" : "display-none"
                }
              >
                <img
                  src={sageata}
                  alt=""
                  className="image-with-hover-cursor sageata_rca"
                  onClick={handleBackClick}
                />
                <p
                  className="sectionRCA_buy_button_back"
                  onClick={handleBackClick}
                >
                  {t("rca.back")}
                </p>
              </div>
              <p
                className={
                  page === 0
                    ? "sectionRCA__left_title1"
                    : "sectionRCA__left_title12"
                }
              >
                {FormTitles[page]}
              </p>
              <p className="sectionRCA__left_title2">{FormSubTitles[page]}</p>
            </Col>

            {[0, 1, 2, 3].map((index) => (
              <Col
                lg="4"
                md="4"
                key={index}
                className={
                  page === index ? "sectionRCA_buy__right2" : "display-none"
                }
              >
                {[...Array(index + 1)].map((_, i) => (
                  <p key={i} className="circleRCA"></p>
                ))}
                {[...Array(5 - index - 1)].map((_, i) => (
                  <p key={i} className="circle_transparentRCA"></p>
                ))}
              </Col>
            ))}
          </Row>
          <div>{PageDisplay()}</div>
          {renderError(errorPage1Necompletat && page === 0, t("rca.allert11"))}
          {renderError(errorPage1Sasiu && page === 0, t("rca.allert12"))}
          {renderError(
            (errorPage2Necompletat || errorPage2TC === false) && page === 1,
            t("rca.allert11")
          )}

          <Row
            className={page !== 2 ? "sectionRCA_buy__form11" : "non_display"}
          >
            <Col lg="3" md="3" className="sectionRCA_buy__left1">
              <div
                className={page === 0 ? "image-button " : "non_display"}
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <img
                  src={next}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "243px",
                    height: "auto",
                  }}
                  onClick={handleNextClick}
                />

                <div
                  onClick={handleNextClick}
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  {t("rca.button1")}
                </div>
              </div>
              <div
                className={
                  page === 1
                    ? "image-button sub_title_rca_buy_next"
                    : "non_display"
                }
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <img
                  src={polite}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "303px",
                    height: "auto",
                  }}
                  onClick={handlePoliteClick}
                />
                <div
                  onClick={handlePoliteClick}
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "30px",
                    marginTop: "10px",
                  }}
                >
                  {t("rca.informatii.button1")}
                </div>
              </div>
            </Col>
            <Col lg="9" md="9" className="sectionRCA_buy__right_img">
              <img src={page === 0 ? rca1 : page === 1 ? rca2 : null} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
    );
  } catch (error) {
    //navigate("/eroare");
  }
};

export default RCA_buy;
