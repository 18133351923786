import React, { useState } from "react";

import Logo from "../../assests/images/logo.svg";

import "./Popup.css";

import { useTranslation } from "react-i18next";

const Popups = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const [popup, setPopup] = useState({
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
    question51: "",
  });

  const handleFeedback = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(popup),
    };

    const response1 = await fetch(
      `${process.env.REACT_APP_API_URL}/popup`,
      requestOptions
    );
    onClose();
  };

  return (
    <div className="popup-wrapper">
      <div className="popup-overlay" />
      <div
        className="popup-containerTab"
        style={{ width: "400px", height: "400px" }}
      >
        <button className="popup-exist" onClick={onClose}>
          X
        </button>

        <label className="popup-text popup-text-left1">
          <input
            type="checkbox"
            required="required"
            value={popup.question1}
            onChange={(e) =>
              setPopup({
                ...popup,
                question1: e.target.checked,
              })
            }
          />
          {t("popupOut.v1")}
        </label>
        <label className="popup-text popup-text-left">
          <input
            type="checkbox"
            required="required"
            value={popup.question2}
            onChange={(e) =>
              setPopup({
                ...popup,
                question2: e.target.checked,
              })
            }
          />
          {t("popupOut.v2")}
        </label>
        <label className="popup-text popup-text-left">
          <input
            type="checkbox"
            required="required"
            value={popup.question3}
            onChange={(e) =>
              setPopup({
                ...popup,
                question3: e.target.checked,
              })
            }
          />
          {t("popupOut.v3")}
        </label>
        <label className="popup-text popup-text-left">
          <input
            type="checkbox"
            required="required"
            value={popup.questio4}
            onChange={(e) =>
              setPopup({
                ...popup,
                question4: e.target.checked,
              })
            }
          />
          {t("popupOut.v4")}
        </label>

        <label className="popup-text popup-text-left">
          <input
            type="checkbox"
            required="required"
            value={popup.questio5}
            onChange={(e) =>
              setPopup({
                ...popup,
                question5: e.target.checked,
              })
            }
          />
          {t("popupOut.v5")}
        </label>
        {popup.question5 === true && (
          <label>
            <input
              className="valoare_input"
              type="text"
              name="text"
              value={popup.question51}
              onChange={(e) =>
                setPopup({
                  ...popup,
                  question51: e.target.value,
                })
              }
            />
          </label>
        )}

        <button className="popup-button" onClick={handleFeedback}>
          {t("popupOut.button")}
        </button>
        <div className="popup-logo">
          <img src={Logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Popups;
