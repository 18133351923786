import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

// import calatorie from "../../assests/images/RCA/calatorie.png";
import rca_3 from "../../assests/images/RCA/rca_3.svg";
import Check from "../../assests/images/RCA/check.svg";
import Consultant from "../../assests/images/Travel/contacteeazaSenflua.png";

import contacteeazaSenflua from "../../assests/images/contacteeazaSenflua.svg";
import "./Confirmare_RCA.css";
import loadingGif from "../../assests/images/Travel/loader100.gif";
import calatorie from "../../assests/images/Travel/calatorie_btn.png";

import { useLanguage } from "../utils/utils.jsx";

const Confirmare_RCA = () => {
  const { t, i18n } = useLanguage();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const [cookiesSession, setCookieSession] = useCookies([orderId]);

  const [cookies, setCookie, removeCookie] = useCookies(["formData"]);
  const [cookiesOferta, setCookieOferta, removeCookieOferta] = useCookies([
    "oferta",
  ]);
  const [cookiesOrderId, setCookieOrderId, removeCookieOrderId] = useCookies([
    "plata",
  ]);

  const [plata, setPlata] = useState([]);
  const [loading, setLoading] = useState(false);
  const formData = cookies.formData;
  const oferta = cookiesOferta.oferta;

  let intrat = 0;

  useEffect(() => {
    const fetchData = async () => {
      const requestOptions = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          auto: formData,
          oferta: oferta,
          orderId: orderId,
        }),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/validarePlata`,
        requestOptions
      );

      const json = await response.json();

      setPlata(json);
    };

    if (intrat === 0) {
      intrat = 1;
      const orderIdValue = cookiesSession[orderId];
      console.log(orderIdValue);

      if (!orderId) {
        window.location.href = "/";
      } else {
        if (!orderIdValue) {
          setCookieSession(orderId, Date.now());
          fetchData();
          document.title = "Senflua - Confirmare RCA";
        } else {
          window.location.href = "/";
        }
      }
    }
  }, []);

  useEffect(() => {
    if (plata.error === false || plata.error === true) {
      setLoading(true);
    }
  }, [plata]);

  return (
    <section className="section_formRCA">
      <Container className="containerRCA__form">
        <img
          src={contacteeazaSenflua}
          alt=""
          className="conRca_sticky"
          onClick={(event) =>
            (window.location.href = "https://wa.me/40724542277")
          }
        />
        <div className={"confirmare_rca_title"}>
          {loading === true ? (
            plata.error === false ? (
              <p>
                {t("rca.confirmare.title11")}
                <br /> {t("rca.confirmare.title12")}
                <img src={Check} alt="" className="check_btn" />
              </p>
            ) : (
              <p>{t("rca.confirmare.error")}</p>
            )
          ) : (
            <div className="loading_gf">
              <p className="sectionRCA__Loading">{t("rca.confirmare.wait")}</p>
              <img src={loadingGif} alt="Loading..." />
            </div>
          )}
        </div>
        <Row className="sectionRCA__formConfirmare">
          <Col xl="6" lg="6" md="12" sm="12" className="sectionRCA__Center">
            <img src={rca_3} alt="" className="" />
          </Col>
          <Col xl="6" lg="6" md="12" sm="12" className="sectionRCA__right">
            <p className={"sectionRCA__formConfirmare_subtitle"}>
              {t("rca.confirmare.subtitle11")}
              <br /> {t("rca.confirmare.subtitle12")}
            </p>
            <p className={"sectionRCA__formConfirmare_text"}>
              {t("rca.confirmare.spam")}
            </p>
            <p className={"sectionRCA__formConfirmare_text"}>
              {t("rca.confirmare.subtitle21")}
              <br /> {t("rca.confirmare.subtitle22")}
            </p>

            <div
              className="image-button rca_btn_calatorie"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={calatorie}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "303px",
                  height: "auto",
                }}
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
              />

              <div
                onClick={(event) =>
                  (window.location.href = "/asigurare-travel")
                }
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                {t("rca.confirmare.button1")}
              </div>
            </div>

            <p className={"sectionRCA__formConfirmare_text"}>
              {t("rca.confirmare.subtitle31")} <br />{" "}
              {t("rca.confirmare.subtitle32")}
            </p>

            <div
              className="image-button rca_btn_calatorie"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={Consultant}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "303px",
                  height: "auto",
                }}
                onClick={(event) =>
                  (window.location.href = "https://wa.me/40724542277")
                }
              />

              <div
                onClick={(event) =>
                  (window.location.href = "https://wa.me/40724542277")
                }
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                {t("rca.confirmare.button2")}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Confirmare_RCA;
