import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import "./Travel_buy_Eurolife_New.css";

import eurolifeFFH from "../../assests/images/ensure/stanga.png";
import Plata from "../../assests/images/RCA/Plata_si_gata_btn.png";
import loadingGif from "../../assests/images/Travel/loader100.gif";

import { useLanguage } from "../utils/utils.jsx";

function Polita({
  person,
  setPerson,
  oferta,
  setOferta,
  calatorie,
  setCalatorie,
  produs,
  setProdus,
  people,
  setPeople,
}) {
  const { t, i18n } = useLanguage();

  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("07");
  const [loading, setLoading] = useState(false);

  const [ofertaAux, setOfertaAux] = useState([]);

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(["person"]);
  const [cookiesPeople, setCookiePeople] = useCookies(["people"]);
  const [cookiesCalatorie, setCookieCalatorie] = useCookies(["calatorie"]);
  const [cookiesOferta, setCookieOferta] = useCookies(["oferta"]);
  const [cookiesOrderId, setCookieOrderId] = useCookies(["OrderId"]);
  const [cookiesProdus, setCookieProdus] = useCookies(["produs"]);

  const [tc, setTc] = useState(false);
  const [pc, setPc] = useState(false);
  const [ofr, setOfr] = useState(false);

  const textSuma =
    {
      0: "10 000 EURO",
      1: "30 000 EURO",
      default: "Eroare",
    }[produs.varianta] || "Eroare";

  const [plata, setPlata] = useState([]);

  const validateNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loadingPDF, setLoadingPDF] = useState(false);

  function formatDate(inputDate) {
    const dataObiect = new Date(inputDate);
    const ziua = dataObiect.getDate();
    const luna = dataObiect.getMonth() + 1;
    const anul = dataObiect.getFullYear();
    return `${ziua.toString().padStart(2, "0")}.${luna
      .toString()
      .padStart(2, "0")}.${anul}`;
  }

  const fetchDataFinal = async () => {
    const updatedPerson = {
      ...person,
      contact: [
        {
          ...person.contact[0],
          valoare: email,
        },
        {
          ...person.contact[1],
          valoare: number,
        },
      ],
    };

    const requestOptions1 = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        persoana: updatedPerson,
        oferta: oferta,
        persoane: people,
      }),
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/getEurolifeAsiguratorLinkPlata`,
      requestOptions1
    );
    const json = await response.json();
    setPlata(json);

    if (json.error === false) {
      setCookieOferta("ofertaTravel", oferta);
      setCookie("person", updatedPerson);
      setCookiePeople("people", people);
      setCookieCalatorie("calatorie", calatorie);
      setCookieOrderId("plataTravel", json.orderId);
      setCookieProdus("produs", produs);

      window.location.assign(json.formUrl);
    } else navigate("/eroare");
  };

  const handleDownloadPdf = (oferta) => {
    setLoadingPDF(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(oferta),
    };

    fetch(`${process.env.REACT_APP_API_URL}/pdfOTC`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);

        // Creează un element de ancoră pentru descărcarea fișierului
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "ConditiiAsigurareTravel.pdf"; // Numele fișierului PDF
        document.body.appendChild(a);

        // Simulează clicul pe link pentru a începe descărcarea
        a.click();

        // Eliberează resursele URL
        window.URL.revokeObjectURL(blobUrl);

        // Actualizează starea pentru a indica că descărcarea a fost efectuată
        setLoadingPDF(false);
      })
      .catch((error) => {
        console.error("Eroare la descărcarea fișierului PDF", error);
        setLoadingPDF(false);
      });
  };

  return (
    <div>
      <Row className="sectionTravel_buy__form1">
        <Col xl="5" lg="5" md="11" sm="11" className="sectionTravel_buy__left">
          <label className="sectionTreval_buy__Polita_subtitle">
            <strong>{t("travelEurolife.polita.text")}</strong>
          </label>
          <br />
          {person.rezident === "1" && person.traveler === true ? (
            <div>
              <label className="sectionTravel_buy__Polita_info">
                {person.nume} {person.prenume}
              </label>
              <br />
              <label className="sectionTravel_buy__Polita_info">
                CNP: {person.cnp_cui}
              </label>
              <br />
            </div>
          ) : null}
          {person.rezident === "0" && person.traveler === true ? (
            <div>
              <label className="sectionTravel_buy__Polita_info">
                {person.nume} {person.prenume}
              </label>
              <br />
              <label className="sectionTravel_buy__Polita_info">
                {t("travelEurolife.informatii.request19")}:{" "}
                {formatDate(person.data_nasterii)}
              </label>
              <br />
            </div>
          ) : null}

          {person.rezident === "1" && person.traveler === true ? (
            <label className="sectionTravel_buy__Polita_info">
              {t("travelEurolife.polita.text7")} {person.adresa.judet},{" "}
              {person.adresa.localitate}
            </label>
          ) : person.traveler === true ? (
            <label className="sectionTravel_buy__Polita_info">
              {t("travelEurolife.polita.text7")} {person.adresa.taraName}
            </label>
          ) : null}

          {people.map((person, index) =>
            person.nume.length > 0 &&
            person.prenume.length > 0 &&
            person.cnp_cui.length > 0 ? (
              <div key={index}>
                <br />
                <label className="sectionTravel_buy__Polita_info">
                  {person.nume} {person.prenume}
                </label>
                <br />
                <label className="sectionTravel_buy__Polita_info">
                  CNP: {person.cnp_cui}
                </label>
              </div>
            ) : null
          )}

          <br />

          <label className="sub_title_Travel_buy">
            <strong>{t("travelEurolife.polita.request1")}</strong>{" "}
            {t("travelEurolife.polita.request12")}
            <br />
            <input
              type="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </label>
          <br />
          <label className="sub_title_Travel_buy">
            <strong>{t("travelEurolife.polita.request2")}</strong>
            <br />
            <input type="text" value={number} onChange={validateNumber} />
          </label>
          <p className="errorPage_RCAbuy_polita">
            {number.length < 10 ? t("travelEurolife.polita.allert1") : null}
          </p>
          <p className="errorPage_RCAbuy_polita">
            {!validateEmail(email) ? t("travelEurolife.polita.allert2") : null}
          </p>
        </Col>

        <Col
          xl="6"
          lg="6"
          md="10"
          sm="10"
          className="sectionTravel_buy__Polita_right"
        >
          <Row>
            <Col lg="3" md="3">
              <img
                src={eurolifeFFH}
                alt=""
                style={{
                  width: "219px",
                  height: "auto",
                }}
              />
            </Col>

            <br />
            <label className="sectionTreval_buy__Polita_subtitle">
              <strong>{t("travelEurolife.polita.titleGri1")}</strong>
            </label>
            <label className="sectionTravel_buy__Polita_info">
              {t("travelEurolife.polita.text4")}:{" "}
              <strong> {produs.numeTara_destinatie}</strong>
            </label>
            <label className="sectionTravel_buy__Polita_info">
              {t("travelEurolife.polita.text3")}:{" "}
              <strong>
                {formatDate(calatorie.valabilitate_inceput)} -{" "}
                {formatDate(calatorie.valabilitate_sfarsit)}
              </strong>
            </label>
            <label className="sectionTravel_buy__Polita_info">
              {t("travelEurolife.polita.titleGri2")}:
            </label>
            <ul style={{ listStyleType: "disc", marginLeft: "50px" }}>
              <li>
                {t("travelEurolife.oferte.am")}{" "}
                {produs.varianta === 0
                  ? "50000 EURO " + t("travelEurolife.polita.politaPers")
                  : produs.varianta === 1
                  ? "30000 EURO " + t("travelEurolife.polita.politaPers")
                  : ""}
              </li>
              <li>{t("travelEurolife.oferte.rc")} 5000 EURO/polita</li>
              {oferta.detaliiOferta.anularea &&
                oferta.detaliiOferta.anulareaPret !== null && (
                  <div>
                    <li>
                      {t("travelEurolife.oferte.ac")}{" "}
                      {oferta.detaliiOferta.anulareaPret.replace(/\.00/, "")}{" "}
                      {t("travelEurolife.polita.politaPers")}
                    </li>
                    <li>
                      {t("travelEurolife.oferte.ic")}{" "}
                      {produs.anularea_calatoriei_varianta === 0
                        ? "1500 EURO/pers"
                        : produs.anularea_calatoriei_varianta === 1
                        ? "1000 EURO/pers"
                        : produs.anularea_calatoriei_varianta === 2
                        ? "500 EURO/pers"
                        : ""}
                    </li>
                  </div>
                )}

              {oferta.detaliiOferta.bagaj &&
                oferta.detaliiOferta.bagajPret !== null && (
                  <li>
                    {t("travelEurolife.oferte.bg")}{" "}
                    {oferta.detaliiOferta.bagajPret.replace(/\.00/, "")}{" "}
                    {t("travelEurolife.polita.politaPers")}
                  </li>
                )}

              {oferta.detaliiOferta.fransizei &&
                oferta.detaliiOferta.fransizeiPret !== null && (
                  <li>
                    {t("travelEurolife.oferte.fr")}{" "}
                    {oferta.detaliiOferta.fransizeiPret.replace(/\.00/, "")}{" "}
                    {t("travelEurolife.polita.politaP")}
                  </li>
                )}
              {oferta.detaliiOferta.rutiera &&
                oferta.detaliiOferta.rutieraPret !== null && (
                  <li>
                    {t("travelEurolife.oferte.ar")}{" "}
                    {oferta.detaliiOferta.rutieraPret.replace(/\.00/, "")}{" "}
                    {t("travelEurolife.polita.politaP")}
                  </li>
                )}
            </ul>
            <label className="sectionTravel_buy__Polita_info">
              {t("travelEurolife.polita.sporturi")}:{" "}
              <strong>
                {produs.sport_extrem === true
                  ? t("travelEurolife.polita.sporturi1")
                  : produs.sport_extrem === false
                  ? t("travelEurolife.polita.sporturi2")
                  : ""}
              </strong>
            </label>
          </Row>
          <hr className="sectionTravel_buy_Polita_line"></hr>
          <br />

          <Row className="sectionRCA_buy__left_0"></Row>
          <Row className="sectionRCA_buy__left_0">
            <Col lg="6" md="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_subtitle">
                {t("travelEurolife.polita.text6")}
              </label>
            </Col>

            <Col lg="6" md="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_subtitle_right">
                {oferta.premium} lei
              </label>
            </Col>
          </Row>
        </Col>
        <div className="sectionRCA__left_check1">
          <label>
            <input
              type="checkbox"
              required="required"
              onChange={(e) => setTc(e.target.checked)}
            />
            {t("travelEurolife.polita.check1")}
            <a href="/termeni-conditii" target="_blank">
              {t("travelEurolife.polita.check11")}
            </a>
          </label>
        </div>
        <div className="sectionRCA__left_check">
          <label>
            <input
              type="checkbox"
              required="required"
              onChange={(e) => setPc(e.target.checked)}
            />
            {t("travelEurolife.polita.check2")}
            <a href="/politica-confidentialitate" target="_blank">
              {t("travelEurolife.polita.check21")}
            </a>
          </label>
        </div>
        <div className="sectionRCA__left_check">
          <label>
            <input
              type="checkbox"
              required="required"
              onChange={(e) => setOfr(e.target.checked)}
            />
            {t("travelEurolife.polita.check3")}{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDownloadPdf(oferta);
              }}
              disabled={loadingPDF}
            >
              {t("travelEurolife.polita.dw")}
            </a>
          </label>
        </div>
        {loading === false ? (
          <div className="sectionRCA_buy_center sectionRCA_buy_center">
            <div
              className="image-with-hover-cursor image-button img_BuyTravel"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={Plata}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "303px",
                  height: "auto",
                }}
                onClick={() => {
                  if (
                    validateEmail(email) &&
                    number.length > 9 &&
                    tc === true &&
                    pc === true &&
                    ofr === true
                  ) {
                    setPerson((prevState) => ({
                      ...prevState,
                      contact: [
                        {
                          ...prevState.contact[0],
                          valoare: email,
                        },
                        {
                          ...prevState.contact[1],
                          valoare: number,
                        },
                      ],
                    }));
                    setLoading(true);
                    fetchDataFinal();
                  }
                }}
              />

              <div
                onClick={() => {
                  if (
                    validateEmail(email) &&
                    number.length > 9 &&
                    tc === true &&
                    pc === true &&
                    ofr === true
                  ) {
                    setPerson((prevState) => ({
                      ...prevState,
                      contact: [
                        {
                          ...prevState.contact[0],
                          valoare: email,
                        },
                        {
                          ...prevState.contact[1],
                          valoare: number,
                        },
                      ],
                    }));
                    setLoading(true);
                    fetchDataFinal();
                  }
                }}
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                }}
              >
                {t("travelEurolife.polita.button1")}
              </div>
            </div>
          </div>
        ) : (
          <div className="loading_gf">
            <img src={loadingGif} alt="Loading..." />
          </div>
        )}
      </Row>
    </div>
  );
}

export default Polita;
