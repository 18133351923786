import React from "react";

import Casa from "../../assests/images/SenfluaForm/casa.svg";
import Masina from "../../assests/images/SenfluaForm/masina.svg";
import Motocicleta from "../../assests/images/SenfluaForm/moto.svg";
import alteBunuri from "../../assests/images/SenfluaForm/alteBunuri.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipAsigurareThings({ formAig, setFormAig, page, setPage }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.tipAsigurareThings.title")}</p>
      <p className="namesubTitle"></p>

      <div>
        <label
          className={"senfluaFormTipAsigLife"}
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                asig: 1,
              },
            }));
            setPage(17);
          }}
          style={{ width: "85px", height: "101px" }}
        >
          <img
            src={Casa}
            className="senfluaFormTipAsigImgLife2 image-with-hover-cursor"
          />
          {t("typeForm.tipAsigurareThings.v1")}
        </label>

        <label
          className="senfluaFormTipAsigLife"
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                asig: 2,
              },
            }));
            setPage(21);
          }}
          style={{ width: "117px", height: "101px" }}
        >
          <img
            src={Masina}
            className="senfluaFormTipAsigImgLife2 image-with-hover-cursor"
          />
          {t("typeForm.tipAsigurareThings.v2")}
        </label>

        <label
          className="senfluaFormTipAsigLife image-with-hover-cursor"
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                asig: 3,
              },
            }));
            setPage(26);
          }}
          style={{ width: "136px", height: "101px" }}
        >
          <img src={Motocicleta} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipAsigurareThings.v3")}
        </label>
        <label
          className="senfluaFormTipAsigLife image-with-hover-cursor"
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                asig: 4,
              },
            }));
            setPage(34);
          }}
          style={{ width: "130px", height: "101px" }}
        >
          <img src={alteBunuri} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipAsigurareThings.v4")}
        </label>
      </div>
    </div>
  );
}

export default TipAsigurareThings;
