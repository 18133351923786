import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

import "./Travel_buy_Eurolife_New.css";
import info from "../../assests/images/Travel/info.svg";
import alege from "../../assests/images/Travel/alege_btn.png";

import eurolifeFFH from "../../assests/images/ensure/stanga.png";

import loadingGif from "../../assests/images/Travel/loader100.gif";

import { useLanguage } from "../utils/utils.jsx";

function Oferte({
  person,
  setPerson,
  page,
  setPage,
  oferta,
  setOferta,
  calatorie,
  setCalatorie,
  produs,
  setProdus,
  people,
  setPeople,
}) {
  const { t, i18n } = useLanguage();

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [oferta1, setOferta1] = useState({});

  const [ofertaVarianta0, setOfertaVarianta0] = useState({});
  const [ofertaVarianta1, setOfertaVarianta1] = useState({});
  const [ofertaVarianta2, setOfertaVarianta2] = useState({});

  const [ofertaVarianta3, setOfertaVarianta3] = useState({});
  const [ofertaVarianta4, setOfertaVarianta4] = useState({});
  const [ofertaVarianta5, setOfertaVarianta5] = useState({});

  const [oferta2, setOferta2] = useState({});

  let intrat = 0;

  const [produsB, setProdusB] = useState({
    ...produs,
    varianta: 1,
  });

  const navigate = useNavigate();

  const fetchData = async (anulareVariant, produss) => {
    setLoading1(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calatorie: calatorie,
        produs: { ...produss, anularea_calatoriei_varianta: anulareVariant },
        persoana: person,
        persoane: people,
      }),
    };

    const request1 = await fetch(
      `${process.env.REACT_APP_API_URL}/ofertaEurolifeAsigurator`,
      requestOptions
    );
    const json1 = await request1.json();
    return json1;
  };

  useEffect(() => {
    const fetchDataForVariants = async () => {
      setLoading1(true);

      if (produs.anularea_calatoriei) {
        const responses = await Promise.all([
          fetchData(0, produs),
          fetchData(1, produs),
          fetchData(2, produs),
        ]);

        setOfertaVarianta0(responses[0]);
        setOfertaVarianta1(responses[1]);
        setOfertaVarianta2(responses[2]);
        setLoading1(false);
      } else {
        const responses = await fetchData(0, produs);
        setOferta1(responses);
        setLoading1(false);
      }
    };

    const fetchDataForVariants2 = async () => {
      setLoading1(true);

      if (produs.anularea_calatoriei) {
        const responses = await Promise.all([
          fetchData(0, produsB),
          fetchData(1, produsB),
          fetchData(2, produsB),
        ]);

        setOfertaVarianta3(responses[0]);
        setOfertaVarianta4(responses[1]);
        setOfertaVarianta5(responses[2]);

        setLoading2(false);
      } else {
        const responses = await fetchData(0, produsB);
        setOferta2(responses);
        setLoading2(false);
      }
    };

    if (intrat === 0) {
      window.scrollTo(0, 0);
      intrat = 1;
      fetchDataForVariants();
      fetchDataForVariants2();
    }
  }, []);

  const [showPopupCoditii, setShowPopupCoditii] = useState(false);

  function handleShowPopupConsultantaClick() {
    setShowPopupCoditii(true);
  }

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const downloadPDF = () => {
    window.open("../../assests/images/Travel/conditiile.pdf");
  };

  const [loadingPDF, setLoadingPDF] = useState(false);

  const handleDownloadPdf = (oferta) => {
    // Marchează aplicația ca fiind în stare de încărcare
    setLoadingPDF(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(oferta),
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/pdfOTC`,
      //"http://localhost:8080/pdfOTC",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        // Crează un obiect URL pentru fișierul blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Creează un element de ancoră pentru descărcarea fișierului
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "ConditiiAsigurareTravel.pdf"; // Numele fișierului PDF
        document.body.appendChild(a);

        // Simulează clicul pe link pentru a începe descărcarea
        a.click();

        // Eliberează resursele URL
        window.URL.revokeObjectURL(blobUrl);

        // Actualizează starea pentru a indica că descărcarea a fost efectuată
        setLoadingPDF(false);
      })
      .catch((error) => {
        console.error("Eroare la descărcarea fișierului PDF", error);
        setLoadingPDF(false);
      });
  };

  return (
    <div>
      {produs.anularea_calatoriei === false ? (
        <Row className="sectionTravel_buy__form1">
          <Col xl="2" lg="2" md="0" sm="0"></Col>
          <Col xl="4" lg="4" md="6" sm="12" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>50 000 EURO</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading1 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>

                  {oferta1.detaliiOferta.bagaj &&
                    oferta1.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {oferta1.detaliiOferta.bagajPret.replace(/\.00/, "")}
                      </label>
                    )}

                  {oferta1.detaliiOferta.fransizei &&
                    oferta1.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {oferta1.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {oferta1.detaliiOferta.rutiera &&
                    oferta1.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {oferta1.detaliiOferta.rutieraPret.replace(/\.00/, "")}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(oferta1)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {t("travelEurolife.oferte.dw")}
              </div>
              <br />
              {loading1 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.text2")}
                  </p>
                  <p className="Travel_sum_detaliiStanga">
                    {oferta1.premium} lei
                  </p>
                </div>
              ) : null}
              {loading1 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                      });
                      setOferta(oferta1);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                      });
                      setOferta(oferta1);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>

          <Col xl="4" lg="4" md="6" sm="12" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>30 000 EUR</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading2 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>

                  {oferta2.detaliiOferta.bagaj &&
                    oferta2.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {oferta2.detaliiOferta.bagajPret.replace(/\.00/, "")}
                      </label>
                    )}

                  {oferta2.detaliiOferta.fransizei &&
                    oferta2.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {oferta2.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {oferta2.detaliiOferta.rutiera &&
                    oferta2.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {oferta2.detaliiOferta.rutieraPret.replace(/\.00/, "")}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(oferta1)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {/* {t("travelEurolife.oferte.cc")} */}
                {t("travelEurolife.oferte.dw")}
              </div>
              <br />
              {loading2 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p>{t("travelEurolife.oferte.text2")}</p>
                  <p className="Travel_sum_detaliiStanga">
                    {oferta2.premium} lei
                  </p>
                </div>
              ) : null}
              {loading2 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                      });
                      setOferta(oferta2);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                      });
                      setOferta(oferta2);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>

          <Col xl="2" lg="2" md="0" sm="0"></Col>
        </Row>
      ) : (
        <Row className="sectionTravel_buy__form1">
          <Col xl="1" lg="0"></Col>
          <Col xl="3" lg="4" md="6" sm="6" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>50 000 EURO</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading1 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>

                  {ofertaVarianta0.detaliiOferta.anularea &&
                    ofertaVarianta0.detaliiOferta.anulareaPret !== null && (
                      <div>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ac")}{" "}
                          {ofertaVarianta0.detaliiOferta.anulareaPret.replace(
                            /\.00/,
                            ""
                          )}
                        </label>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ic")} 1500 EURO
                        </label>
                      </div>
                    )}

                  {ofertaVarianta0.detaliiOferta.bagaj &&
                    ofertaVarianta0.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {ofertaVarianta0.detaliiOferta.bagajPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}

                  {ofertaVarianta0.detaliiOferta.fransizei &&
                    ofertaVarianta0.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {ofertaVarianta0.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {ofertaVarianta0.detaliiOferta.rutiera &&
                    ofertaVarianta0.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {ofertaVarianta0.detaliiOferta.rutieraPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(ofertaVarianta0)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {/* {t("travelEurolife.oferte.cc")} */}
                {t("travelEurolife.oferte.dw")}
              </div>

              {loading1 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p>{t("travelEurolife.oferte.text2")}</p>
                  <p className="Travel_sum_detaliiStanga">
                    {ofertaVarianta0.premium} lei
                  </p>
                </div>
              ) : null}
              {loading1 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                        anularea_calatoriei_varianta: 0,
                      });
                      setOferta(ofertaVarianta0);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                        anularea_calatoriei_varianta: 0,
                      });
                      setOferta(ofertaVarianta0);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>

          <Col xl="3" lg="4" md="6" sm="6" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>50 000 EURO</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading1 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>
                  {ofertaVarianta1.detaliiOferta.anularea &&
                    ofertaVarianta1.detaliiOferta.anulareaPret !== null && (
                      <div>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ac")}{" "}
                          {ofertaVarianta1.detaliiOferta.anulareaPret.replace(
                            /\.00/,
                            ""
                          )}
                        </label>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ic")} 1000 EURO
                        </label>
                      </div>
                    )}

                  {ofertaVarianta1.detaliiOferta.bagaj &&
                    ofertaVarianta1.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {ofertaVarianta1.detaliiOferta.bagajPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}

                  {ofertaVarianta1.detaliiOferta.fransizei &&
                    ofertaVarianta1.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {ofertaVarianta1.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {ofertaVarianta1.detaliiOferta.rutiera &&
                    ofertaVarianta1.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {ofertaVarianta1.detaliiOferta.rutieraPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(ofertaVarianta1)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {/* {t("travelEurolife.oferte.cc")} */}
                {t("travelEurolife.oferte.dw")}
              </div>

              {loading1 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p>{t("travelEurolife.oferte.text2")}</p>
                  <p className="Travel_sum_detaliiStanga">
                    {ofertaVarianta1.premium} lei
                  </p>
                </div>
              ) : null}
              {loading1 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                        anularea_calatoriei_varianta: 1,
                      });
                      setOferta(ofertaVarianta1);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                        anularea_calatoriei_varianta: 1,
                      });
                      setOferta(ofertaVarianta1);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>
          <Col xl="3" lg="4" md="6" sm="6" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>50 000 EURO</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading1 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>
                  {ofertaVarianta2.detaliiOferta.anularea &&
                    ofertaVarianta2.detaliiOferta.anulareaPret !== null && (
                      <div>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ac")}{" "}
                          {ofertaVarianta2.detaliiOferta.anulareaPret.replace(
                            /\.00/,
                            ""
                          )}
                        </label>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ic")} 500 EURO
                        </label>
                      </div>
                    )}

                  {ofertaVarianta2.detaliiOferta.bagaj &&
                    ofertaVarianta2.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {ofertaVarianta2.detaliiOferta.bagajPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}

                  {ofertaVarianta2.detaliiOferta.fransizei &&
                    ofertaVarianta2.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {ofertaVarianta2.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {ofertaVarianta2.detaliiOferta.rutiera &&
                    ofertaVarianta2.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {ofertaVarianta2.detaliiOferta.rutieraPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(ofertaVarianta2)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {/* {t("travelEurolife.oferte.cc")} */}
                {t("travelEurolife.oferte.dw")}
              </div>

              {loading1 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p>{t("travelEurolife.oferte.text2")}</p>
                  <p className="Travel_sum_detaliiStanga">
                    {ofertaVarianta2.premium} lei
                  </p>
                </div>
              ) : null}
              {loading1 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                        anularea_calatoriei_varianta: 2,
                      });
                      setOferta(ofertaVarianta2);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 0,
                        anularea_calatoriei_varianta: 2,
                      });
                      setOferta(ofertaVarianta2);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>

          <Col xl="1" lg="0" md="1" sm="1"></Col>
        </Row>
      )}
      {produs.anularea_calatoriei === true ? (
        <Row className="sectionTravel_buy__form1">
          <Col xl="1" lg="0"></Col>
          <Col xl="3" lg="4" md="6" sm="6" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>30 000 EURO</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading2 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>
                  {ofertaVarianta3.detaliiOferta.anularea &&
                    ofertaVarianta3.detaliiOferta.anulareaPret !== null && (
                      <div>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ac")}{" "}
                          {ofertaVarianta3.detaliiOferta.anulareaPret.replace(
                            /\.00/,
                            ""
                          )}
                        </label>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ic")} 1500 EURO
                        </label>
                      </div>
                    )}

                  {ofertaVarianta3.detaliiOferta.bagaj &&
                    ofertaVarianta3.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {ofertaVarianta3.detaliiOferta.bagajPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}

                  {ofertaVarianta3.detaliiOferta.fransizei &&
                    ofertaVarianta3.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {ofertaVarianta3.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {ofertaVarianta3.detaliiOferta.rutiera &&
                    ofertaVarianta3.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {ofertaVarianta3.detaliiOferta.rutieraPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(ofertaVarianta3)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {/* {t("travelEurolife.oferte.cc")} */}
                {t("travelEurolife.oferte.dw")}
              </div>

              {loading2 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p>{t("travelEurolife.oferte.text2")}</p>
                  <p className="Travel_sum_detaliiStanga">
                    {ofertaVarianta3.premium} lei
                  </p>
                </div>
              ) : null}
              {loading2 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                        anularea_calatoriei_varianta: 0,
                      });
                      setOferta(ofertaVarianta3);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                        anularea_calatoriei_varianta: 0,
                      });
                      setOferta(ofertaVarianta3);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>

          {/* {loading === false ? ( */}
          <Col xl="3" lg="4" md="6" sm="6" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>30 000 EURO</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading2 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>
                  {ofertaVarianta4.detaliiOferta.anularea &&
                    ofertaVarianta4.detaliiOferta.anulareaPret !== null && (
                      <div>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ac")}{" "}
                          {ofertaVarianta4.detaliiOferta.anulareaPret.replace(
                            /\.00/,
                            ""
                          )}
                        </label>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ic")} 1000 EURO
                        </label>
                      </div>
                    )}

                  {ofertaVarianta4.detaliiOferta.bagaj &&
                    ofertaVarianta4.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {ofertaVarianta4.detaliiOferta.bagajPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}

                  {ofertaVarianta4.detaliiOferta.fransizei &&
                    ofertaVarianta4.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {ofertaVarianta4.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {ofertaVarianta4.detaliiOferta.rutiera &&
                    ofertaVarianta4.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {ofertaVarianta4.detaliiOferta.rutieraPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(ofertaVarianta4)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {/* {t("travelEurolife.oferte.cc")} */}
                {t("travelEurolife.oferte.dw")}
              </div>

              {loading2 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p>{t("travelEurolife.oferte.text2")}</p>
                  <p className="Travel_sum_detaliiStanga">
                    {ofertaVarianta4.premium} lei
                  </p>
                </div>
              ) : null}
              {loading2 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                        anularea_calatoriei_varianta: 1,
                      });
                      setOferta(ofertaVarianta4);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                        anularea_calatoriei_varianta: 1,
                      });
                      setOferta(ofertaVarianta4);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>
          <Col xl="3" lg="4" md="6" sm="6" className="sectionTravel_buy__left">
            <div className="Travel_sum_oferta">
              <p>{t("travelEurolife.oferte.text1")}</p>
              <h1>30 000 EURO</h1>
            </div>
            <div className="Travel_sum_detalii">
              <img src={eurolifeFFH} alt="" className="eurolifeFFH" />
              {loading2 === false ? (
                <div>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.am")}{" "}
                  </label>
                  <label className="Travel_sum_detaliis">
                    {t("travelEurolife.oferte.rc")} 5000 EURO
                  </label>
                  {ofertaVarianta5.detaliiOferta.anularea &&
                    ofertaVarianta5.detaliiOferta.anulareaPret !== null && (
                      <div>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ac")}{" "}
                          {ofertaVarianta5.detaliiOferta.anulareaPret.replace(
                            /\.00/,
                            ""
                          )}
                        </label>
                        <label className="Travel_sum_detaliis">
                          {t("travelEurolife.oferte.ic")} 500 EURO
                        </label>
                      </div>
                    )}

                  {ofertaVarianta5.detaliiOferta.bagaj &&
                    ofertaVarianta5.detaliiOferta.bagajPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.bg")}{" "}
                        {ofertaVarianta5.detaliiOferta.bagajPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}

                  {ofertaVarianta5.detaliiOferta.fransizei &&
                    ofertaVarianta5.detaliiOferta.fransizeiPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.fr")}{" "}
                        {ofertaVarianta5.detaliiOferta.fransizeiPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                  {ofertaVarianta5.detaliiOferta.rutiera &&
                    ofertaVarianta5.detaliiOferta.rutieraPret !== null && (
                      <label className="Travel_sum_detaliis">
                        {t("travelEurolife.oferte.ar")}{" "}
                        {ofertaVarianta5.detaliiOferta.rutieraPret.replace(
                          /\.00/,
                          ""
                        )}
                      </label>
                    )}
                </div>
              ) : null}
              <div
                onClick={() => handleDownloadPdf(ofertaVarianta5)}
                disabled={loadingPDF}
                className="Travel_sum_detalii_label image-with-hover-cursor"
              >
                <img src={info} alt="" />
                {/* {t("travelEurolife.oferte.cc")} */}
                {t("travelEurolife.oferte.dw")}
              </div>

              {loading2 === false ? (
                <div className="sectionTravel_buy__left_sec">
                  <p>{t("travelEurolife.oferte.text2")}</p>
                  <p className="Travel_sum_detaliiStanga">
                    {ofertaVarianta5.premium} lei
                  </p>
                </div>
              ) : null}
              {loading2 === false ? (
                <div
                  className="img_travel_alegeFinal image-button"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={alege}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "210px",
                      height: "auto",
                    }}
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                        anularea_calatoriei_varianta: 2,
                      });
                      setOferta(ofertaVarianta5);
                      setPage(3);
                    }}
                  />

                  <div
                    onClick={() => {
                      setProdus({
                        ...produs,
                        varianta: 1,
                        anularea_calatoriei_varianta: 2,
                      });
                      setOferta(ofertaVarianta5);
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("travel.oferte.button1")}
                  </div>
                </div>
              ) : (
                <img src={loadingGif} alt="Loading..." />
              )}
            </div>
          </Col>
          <Col xl="1" lg="0" md="1" sm="1"></Col>
        </Row>
      ) : null}
    </div>
  );
}

export default Oferte;
