import React from "react";
import { Container, Row, Col } from "reactstrap";

import RCAbtn from "../../assests/images/RCA_btn.png";
import Travelbtn from "../../assests/images/Travel_btn.png";
import IaLegatura from "../../assests/images/iaLegatura_btn.png";
import teleportareaeInviitor from "../../assests/images/teleportareaeInviitor.svg";

import "./SectionTextTermeniConditii.css";
const SectionTextTermeniConditii = () => {
  return (
    <section className="sectionCondtii">
      <Container>
        <div>
          <p className="sectionCondtii">
            1.Definiții <br /> Termenii și condițiile generale prevăzute în
            continuare se vor aplica tuturor vânzărilor de bunuri de
            către Senflua și partenerii săi, prin intermediul magazinului
            virtual www.senflua.ro către Cumpărător și pot fi modificate oricând
            de către Senflua fără notificare prealabilă.
            <br /> Astfel, următorii termeni vor însemna:
            <br /> Cumpărător – persoană fizică / persoană juridică sau altă
            entitate juridică ce emite o Comandă.
            <br /> Vânzător – Senflua, cu denumirea comercială Senflua Insurance
            SRL, având sediul social în Strada Constantin Brancusi, Nr 11,
            Parter, Bloc D16, Scara 7, Ap 242, Sector 3, Bucuresti, CUI
            46500691, nr. de înregistrare la Registrul Comerțului
            J40/13827/19.07.2022, in parteneriat cu Inter Broker de Asigurare
            SRL, autorizat de Comisia de Supraveghere a Asigurarilor si
            inregistrat in Registrul Brokerilor sub numarul RBK-690/29.08.2011,
            avand J05/1228/2011 si C.U.I. 28647300. cu Sediul Central in Bihor,
            Oradea, str. Nojoridului, nr. 270
            <br /> Bunuri – orice produs, inclusiv documentele și serviciile
            menționate în Comandă, care urmează a fi furnizate de către Vânzător
            Cumpărătorului.
            <br /> Comandă – un document electronic ce intervine ca formă de
            comunicare între Vânzător și Cumpărător, prin care Vânzătorul este
            de acord să livreze Bunurile și Cumpărătorul este de acord să
            primească aceste Bunuri și să facă plată acestora. <br />
            Contract – o Comandă confirmată de către Vânzător.
            <br /> Drepturi de Proprietate Intelectuală – toate drepturile
            imateriale cum ar fi know-how, dreptul de autor și drepturi în
            natură de autor, drepturile de bază de date, drepturi de proiectare,
            drepturi de model, patente, mărci înregistrate și înregistrări ale
            numelor de domenii pentru oricare din cele de mai sus. <br />
            Site – domeniul www.senflua.ro și subdomeniile acestuia.
            <br /> 2.Documente Contractuale
            <br /> Prin lansarea unei Comenzi electronice pe site-ul
            www.senflua.ro, Cumpărătorul este de acord cu forma de comunicare
            (e-mail, telefon) prin care Vânzatorul își derulează operațiunile.
            <br />
            Comanda va fi compusă din următoarele documente: Comanda (împreună
            cu mențiunile clare asupra datelor de livrare și facturare) și
            condițiile sale specifice. <br />
            Termeni și condiții Daca Vânzătorul confirmă Comanda, acest lucru
            implică o acceptare completă a termenilor Comenzii.
            <br /> Acceptarea Comenzii de către Vânzător se consideră finalizată
            atunci când există o confirmare electronică (e-mail) din partea
            Vânzătorului către Cumpărător, fără a necesita o confirmare de
            primire din partea acestuia. <br />
            Vânzătorul nu consideră în nici un moment o comandă neconfirmată ca
            având valoarea unui Contract. Confirmarea Comenzii se face
            electronic (e-mail).
            <br /> Prețurile produselor din comandă sunt valabile 3 zile
            lucrătoare de la data înregistrării comenzii. Termenii și condițiile
            generale de vânzare vor sta la baza Contractului astfel încheiat.
            <br />
            3.Obligațiile Vânzătorului
            <br /> Vânzătorul își va utiliza cunoștințele sale profesionale și
            tehnice pentru a atinge rezultatul stipulat în Comandă și va livra
            Bunurile care îndeplinesc cerințele și specificațiile Cumpărătorului
            exprimate în Comandă;
            <br /> Informațiile prezentate pe site-urile Vânzătorului au
            caracter informativ și pot fi modificate de către Vânzător, fără o
            anunțare prealabilă.
            <br /> Descrierile produselor pot fi incomplete, însă vânzătorul
            face eforturi de a prezenta informațiile cele mai relevante, pentru
            ca produsul să fie utilizat în parametrii pentru care a fost
            achiziționat;
            <br /> 4.Dreptul de proprietate intelectuală și industrială
            <br />
            Utilizatorul/Cumpărătorul înțelege dreptul de proprietate
            intelectuală și nu va dezvălui unei terțe părți sau va face publice
            nici una dintre informările primite de la Vânzător.
            <br /> Toate desenele, elementele de grafică și design care apar pe
            site, numele site-ului precum și însemnele grafice sunt mărci
            înregistrate în proprietatea Senflua și nu pot fi preluate, copiate
            sau folosite, fără acordul scris al proprietarului.
            <br /> Toate elementele de conținut cum sunt descrierile, desenele,
            elementele de grafică și design care apar pe site, incluzând dar
            nelimitându-se la logo-uri, reprezentări stilizate, simboluri
            comerciale, imagini statice, imagini dinamice, text și/sau conținut
            multimedia prezentate pe site, sunt proprietatea exclusivă
            a Senflua, acestuia fiindu-i rezervate toate drepturile obținute în
            acest sens în mod direct sau indirect prin licențe de utilizare
            și/sau publicare. <br />
            Utilizatorului/Cumpărătorului nu îi este permisă copierea,
            distribuirea, publicarea, transferul către terțe părți, modificarea
            și/sau altfel alterarea, utilizarea, legarea la, expunerea,
            includerea oricărui element conținut de mai sus în orice alt context
            decât cel original intenționat de Senflua, includerea oricărui
            element de conținut în afara Site-ului, îndepărtarea însemnelor care
            semnifică dreptul de autor al Senflua asupra elementelor de conținut
            precum și participarea la transferul, vânzarea, distribuția unor
            materiale realizate prin reproducerea, modificarea sau afișarea
            elementelor de conținut, decât cu acordul scris expres al Senflua.
            <br />
            5.Drepturile asupra conținutului site-ului
            <br /> Întreg continutul site-ului si elementele de grafica,
            inclusiv dar fara a se limita la acestea, respectiv tot continutul
            in format text, precum si sursele tehnice ale tuturor serviciilor si
            facilitatilor prezente si viitoare – cu exceptia cazului cand este
            mentionat expres un alt proprietar – sursele paginilor dar si orice
            alt material, transmis sub orice forma de si catre Utilizatori (prin
            vizualizare directa pe site, prin newslettere etc.) apartin Senflua.
            <br />
            Continutul site-ului, indiferent de zona in care se afla in site si
            indiferent de tip, poate fi utilizat exclusiv in scopuri personale.
            <br />
            Orice utilizare de continut de catre terte persoane in alte scopuri
            decat cel personal, se poate face numai cu acordul scris, expres si
            prealabil al Senflua.
            <br /> Prin urmare, este interzisa copierea, preluarea,
            reproducerea, publicarea, transmiterea, vanzarea, distributia
            partiala, integrala sau modificata a continutului acestui site sau a
            oricarei parti a acestuia efectuate in alte scopuri decat cel
            personal, cu urmatoarele exceptii:
            <br /> (i) este permisa reproducerea (pe site-uri noncomerciale,
            forumuri, articole din presa etc.) a unor mici fragmente din
            articolele publicate (max. 400 de caractere), fiind obligatorie
            specificarea sursei informatiilor preluate, cu link, sub urmatoarea
            forma: (Sursa: nume site – link catre continutul site-ului).
            <br /> (ii) sunt permise link-urile catre site-ul www.senflua.ro,
            iar specificarea sursei informatiilor se va face dupa fiecare link
            sau in finalul articolului, dupa cum urmeaza: „Informatii furnizate
            prin amabilitatea Senflua – link catre continutul site-ului)
            Utilizatorii se obliga sa respecte toate drepturile de autor si
            toate drepturile conexe acestuia si orice alte drepturi de
            proprietate intelectuala pe care Administratorul site-ului si
            partenerii sai le detin asupra/in legatura cu site-ul
             www.senflua.ro. <br />
            Senflua isi rezerva dreptul de a actiona in instanta orice persoana
            si/sau entitate care incalca in orice mod prevederile de mai sus.
            <br />
            Cererile de utilizare a continutului site-ului in orice scop altul
            decat cel personal pot fi facute prin e-mail la adresa mail
            @ Senflua.ro, cu specificatia „In atentia agenției”. Orice persoana
            care transmite sau publica in orice mod informatii ori materiale
            catre site isi asuma obligatia de a nu prejudicia in nici un mod
            drepturile de autor pe care o terta persoana le-ar putea invoca in
            legatura cu materialele si informatiile transmise in orice mod catre
            site, iar persoanele care expediaza in orice mod informatii ori
            materiale inteleg si accepta ca incalcarea in orice mod a acestei
            obligatii nu poate angaja in niciun mod raspunderea Senflua, ci
            numai raspunderea persoanelor respective.
            <br /> Senflua poate derula in orice moment campanii publicitare
            si/sau promotii in orice sectiune din site, fara ca aceasta
            operatiune sa necesite acordul Utilizatorilor site-ului. Spatiile si
            dimensiunea campaniilor publicitare si promotiilor nu necesita
            acordul Utilizatorilor site-ului si pot fi schimbate oricand fara a
            necesita anuntarea prealabila.
            <br /> Senflua nu isi asuma responsabilitatea pentru prejudiciile
            sau pierderile rezultate din campaniile publicitare sau promotiile
            organizate in site, altele decat cele organizate strict pe site.
            <br />
            6.Limitarea răspunderii administratorului site-ului
            <br /> Senflua nu isi asuma obligatia si nu garanteaza implicit sau
            expres, pentru continutul site-ului, respectiv pentru continutul
            oferit de partenerii sai sau de Utilizatorii site-ului. <br /> Cu
            toate acestea, Senflua va depune toate eforturile rezonabile pentru
            a asigura acuratetea si maniera profesionista in care vor fi
            furnizate informatiile pe site, pentru a dobandi si mentine
            increderea Utilizatorilor in site. <br /> In acest sens, Senflua va
            incerca sa corecteze in cel mai scurt timp posibil erorile si
            omisiunile semnalate. <br /> Administratorul site-ului nu ofera
            niciun fel de garantii pentru continutul site-ului si in nicio
            situatie nu poate fi tinut raspunzator pentru nicio pierdere sau
            prejudiciu ce ar putea rezulta din utilizarea oricarei
            parti/secvente/pagini de pe site sau din imposibilitatea utilizarii
            acesteia, indiferent de cauza acesteia ori din interpretarea eronata
            a oricaror prevederi ale continutului site-ului. <br /> Informatiile
            furnizate prin intermediul site-ului sunt oferite cu buna-credinta,
            din surse apreciate ca fiind de incredere. <br />
            In cazul in care vreunul din articolele publicate sau orice alta
            informatie intra sub incidenta legii dreptului de autor, rugam
            Utilizatorii sa ne contacteze la adresa de e-mail gdpr@senflua.ro,
            pentru a putea lua masurile ce se impun. <br /> Totodată,
            Utilizatorii trebuie sa aibă în vedere faptul ca informatia
            prezentata poate include eventuale informatii inexacte (de ex.: date
            de ordin tehnic sau erori de tastat). <br />
             Administratorul site-ului va face toate diligentele necesare pentru
            a corecta in cel mai scurt timp posibil aceste aspecte. <br />{" "}
            Utilizatorii inteleg si accepta faptul ca Senflua nu garanteaza: ca
            informatiile continute pe site sunt pe deplin complete; ca
            informatiile introduse de Utilizatorii website-ului sunt reale,
            corecte si nu isi asuma raspunderea pentru modul in care vizitatorii
            le folosesc; ca informatiile sau serviciile de pe site  vor
            satisface toate cerintele Utilizatorilor, iar pentru folosirea
            inadecvata a acestora Utilizatorii isi asuma intreaga
            responsabilitate; pentru rezultatele obtinute de Utilizatori ca
            urmare a folosirii informatiilor sau serviciilor disponibile prin
            intermediul site-ului utilizarea informatiilor si a serviciilor
            facandu-se de catre Utilizatori pe propria raspundere; ca serviciile
            disponibile prin intermediul site-ului vor functiona constant,
            neintrerupt, fara erori – fata de acest aspect, Senflua nu isi asuma
            responsabilitatea pentru eventualele pagube pe care Utilizatorii
            le-ar putea avea din cauza nefunctionarii temporare sau defectuoase
            a site-ului sau pentru folosirea informatiilor obtinute prin
            utilizarea link-urilor de pe site catre alte site-uri (utilizarea
            lor este la latitudinea Utilizatorilor). <br /> De asemenea,
            Utilizatorii inteleg si accepta faptul ca Senflua nu este
            responsabila pentru eventuale inadvertente, erori sau omisiuni in
            cadrul informatiilor furnizate pe site de catre Utilizatori. <br />{" "}
            Totodata, Utilizatorii inteleg si accepta ca Senflua este absolvita
            de orice raspundere pentru mesajele publicitare postate pe site sau
            prin intermediul serviciilor oferite prin site, precum si pentru
            bunurile sau serviciile furnizate de catre autorii acestor mesaje
            publicitare. <br /> In mod expres, Utilizatorii site-ului sunt de
            acord sa exonereze de raspundere Senflua pentru orice actiune
            judiciara sau extrajudiciara, care provine ca urmare a utilizarii
            incorecte sau frauduloase a site-ului. <br /> Pentru cazuri de forta
            majora, Senflua si/sau operatorii, directorii, angajatii,
            sucursalele, filialele si reprezentantii sai, este exonerata total
            de orice raspundere. <br /> Cazurile de forta majora includ, dar nu
            se limiteaza la, erori de functionare ale echipamentului tehnic
            al Senflua, lipsa functionarii conexiunii la internet, lipsa
            functionarii conexiunilor de telefon, virusii informatici, accesul
            neautorizat in sistemele Site-ului, erorile de operare, etc.
            Utilizatorii sunt de acord sa protejeze si sa asigure Senflua si/sau
            pe operatorii, directorii, angajatii, sucursalele, filialele si
            reprezentantii sai de si impotriva oricaror cereri, pretentii,
            actiuni, impuneri, pierderi, daune, costuri (incluzand, fara nici un
            fel de limitare, onorariile avocatilor), cheltuieli, judecati,
            decizii, amenzi, regularizari sau alte obligatii rezultate sau
            relationate cu orice alta actiune a Utilizatorilor in legatura cu
            utilizarea site-ului sau a serviciilor oferite prin intermediul
            acestuia. <br /> Senflua nu ofera nicio garantie, nici in mod expres
            si nici implicit, in ceea ce priveste inclusiv, dar fara limitare la
            functionarea site-ului www.senflua.ro, informatia, continutul,
            materialele sau produsele de pe site, precum si potrivirea lor
            pentru un anumit scop. <br /> Utilizatorii sunt de acord in mod
            expres ca folosirea acestui site si aplicarea informatiilor se face
            pe propriul lor risc. <br /> 7.Abonarea Utilizatorilor la
            newslwettere si alerte
            <br /> Utilizatorii site-ului au posibilitatea de a primi
            newslettere si alerte prin posta electronica, existand posibilitatea
            ca Utilizatorii sa poata opta oricand pentru varianta de a nu mai
            primi astfel de notificari, cu un singur click pe link-ul de
            dezabonare din newsletter/alerta primita pe mailul introdus explicit
            la abonare. <br /> Fata de faptul ca accesul la produsele oferite
            prin intermediul site-ului se realizeaza prin intermediul unui cont
            activ, asadar pe baza unui nume de utilizator si a unei parole,
            recomandam Utilizatorilor ca aceste elemente sa nu fie dezvaluite
            unor terte persoane, chiar daca acestea afirma ca va contacteaza din
            partea site-ului. <br /> De asemenea, in vederea asigurarii unui
            nivel sporit de securitate, la sfarsitul vizitei pe site recomandam
            inchiderea ferestrei browser-ului in care s-a lucrat sau click pe
            „Sign out”/”Log off” in pagina vizitata. <br /> 8.Politica de
            cookie-uri <br /> Un cookie este un fişier text care conţine mici
            fragmente de informaţii trimise navigatorului tău şi memorate în
            computerul, telefonul tău mobil sau alt dispozitiv, atunci când
            vizitezi un site. <br /> Acest fişier cookie trimite informaţii
            înapoi către site ori de câte ori îl revizitezi. <br /> Cookie-urile
            pot fi permanente (cunoscute drept cookie-uri persistente), care
            rămân în computerul tău până când le ştergi,
            sau temporare (cunoscute drept cookie-uri pentru sesiune) care sunt
            valabile doar până când închizi fereastra navigatorului tău. <br />{" "}
            Cookie-urile pot fi ale părţii-gazdă (first-party), care sunt
            configurate de site-ul pe care îl vizitezi, sau ale unor părţi
            terţe, care sunt configurate de un site diferit de cel pe care îl
            vizitezi. <br /> Cum utilizeaza Senflua cookie-urile? Utilizăm
            cookie-urile pentru a îmbunătăţi funcţionalitatea site-urilor
            noastre, pentru a te ajuta să navighezi mai eficient de la o pagină
            la alta, pentru a memora preferinţele tale şi, în general, pentru a
            îmbunătăţi experienţa utilizatorilor. <br /> Cookie-urile pe care le
            folosim pe site-urile noastre pot fi din următoarele categorii:
            Cookie-uri strict necesare: Aceste cookie-uri sunt esenţiale pentru
            ca tu să poţi naviga pe site şi să foloseşti serviciile pe care
            le-ai solicitat, cum ar fi accesarea zonelor sigure ale site-ului.{" "}
            <br /> Folosim acest tip de cookie-uri pentru a administra
            înregistrarea şi autentificarea utilizatorului. <br /> Fără aceste
            cookie-uri, serviciile pe care le-ai solicitat nu pot fi furnizate.{" "}
            <br /> Aceste cookie-uri sunt ale părţii-gazdă (first-party) şi pot
            fi permanente sau temporare. <br /> Pe scurt, site-urile noastre nu
            vor funcţiona corespunzător fără aceste cookie-uri. <br />{" "}
            Cookie-uri pentru performanţă: Aceste cookie-uri colectează
            informaţii despre cum utilizează vizitatorii un site, de exemplu
            care sunt paginile cele mai vizitate. <br /> Aceste cookie-uri nu
            colectează informaţii care identifică vizitatori individuali. <br />{" "}
            Toate informaţiile colectate de aceste cookie-uri sunt cumulate şi
            anonime. <br /> Folosim aceste cookie-uri pentru a: realiza
            statistici despre cum sunt utilizate site-urile noastre măsura
            impactul campaniilor noastre publicitare. <br /> Aceste cookie-uri
            pot fi permanente sau temporare, ale părţii-gazdă sau ale unor părţi
            terţe. <br /> Pe scurt, aceste cookie-uri colectează informaţii
            anonime despre paginile vizitate şi reclamele vizualizate. <br />{" "}
            Cookie-uri pentru funcţionalitate: Aceste cookie-uri permit unui
            site să memoreze lucrurile alese de tine (cum ar fi numele de
            utilizator, limba sau ţara în care te afli) şi oferă opţiuni
            îmbunătăţite, mai personale. <br />
            Aceste cookie-uri pot fi de asemenea utilizate pentru a furniza
            servicii pe care le-ai solicitat, cum ar fi vizionarea unui
            videoclip sau comentariul pe un blog. <br /> Informaţiile utilizate
            de aceste cookie-uri sunt anonimizate şi ele nu pot înregistra
            activităţile tale de navigare pe alte site-uri. <br /> Folosim
            aceste cookie-uri pentru a: memora dacă ai beneficiat deja de un
            anumit serviciu îmbunătăţi experienţa în general pe tot site-ul,
            memorând preferinţele tale. <br /> Cookie-uri pentru publicitate:
            Aceste cookie-uri sunt utilizate pentru a limita de câte ori vezi o
            reclamă, precum şi pentru a măsura impactul campaniilor publicitare.{" "}
            <br /> Cookie-urile pentru publicitate sunt folosite pentru a
            gestiona publicitatea pe tot site-ul. <br /> Cookie-urile pentru
            publicitate sunt plasate de terţe părţi, cum ar fi companiile de
            publicitate şi agenţii lor, şi pot fi cookie-uri permanente sau
            temporare. <br /> Pe scurt, ele sunt legate de serviciile de
            publicitate furnizate pe site-ul nostru de terţe părţi. <br />{" "}
            Cookie-uri pentru sociale: – aceste cookies sunt folosite de
            retelele de socializare (Facebook, Twitter, Google+, YouTube,
            Instagram, Pinterest, etc) si permit distribuirea continutului de pe
            www.senflua.ro pe respectivele retele. Senflua.ro nu controleaza
            aceste cookies, deci, pentru mai multe informatii despre cum
            functioneaza, te rugam sa verifici paginile retelelor de
            socializare. <br /> Cum să administrezi & ştergi cookie-uri Dacă
            vrei să impui restricţii, să blochezi sau să ştergi fişiere cookie,
            poţi s-o faci modificând setările navigatorului tău de web. <br />
            Utilizarea www.senflua.ro fără respingerea cookie-urilor sau a
            tehnologiilor similare denotă consimţământul vizitatorilor pentru
            utilizarea de către noi a unor astfel de tehnologii şi pentru
            prelucrarea informaţiilor. <br /> 9.Facturare și plăți <br />{" "}
            Prețul, modalitatea de plată și termenul de plată sunt specificate
            în Comandă. Vânzătorul va emite către Cumpărător o factură pentru
            Bunurile livrate, obligația Cumpărătorului fiind să furnizeze toate
            informațiile necesare emiterii facturii conform cu legislația în
            vigoare. <br /> Pentru o corectă comunicare a facturii aferente
            Comenzii, Cumpărătorului îi revin obligațiile de a-și actualiza ori
            de câte ori este cazul datele din Contul său și de a accesa
            informațiile și documentele aferente fiecărei Comenzi existente în
            Cont. <br /> Prin trimiterea Comenzii, Cumpărătorul își exprimă
            acordul să primească facturile și în format electronic prin
            intermediul poștei electronice, la adresa de e-mail menționată în
            Contul său. <br />
            10.Responsabilități Vânzătorulse <br /> obligă să expedieze Bunurile
            și Serviciile în sistem de curierat door-to-door către Cumpărător.{" "}
            <br />
            Vânzătorulse eliberează de riscurile și responsabilitățile asociate
            cu Bunurile și Serviciile în momentul predării acestora către
            societatea de curierat intern cu care Vânzătorul colaborează sau
            către reprezentantul Cumpărătorului. <br /> Vânzătorulva asigura
            ambalarea corespunzătoare a Bunurilor și Serviciilor și va asigura
            transmiterea documentelor însoțitoare. <br /> Vânzătorulnu poate fi
            responsabil pentru daune de orice fel pe care Cumpărătorul sau
            oricare terță parte o poate suferi ca rezultat al îndeplinirii de
            către Vânzător a oricărei din obligațiile sale conform Comenzii și
            pentru daune care rezultă din utilizarea Bunurilor după livrare și
            în special pentru pierderea produselor. <br />
             Vânzătorul va fi răspunzător în cazul în care sub-contractanții
            și/sau partenerii săi de orice fel implicați în executarea Comenzii
            nu îndeplinesc oricare din obligațiile contractuale. <br />{" "}
            Vânzătorulnu își asumă responsabilitatea pentru descrierile
            produselor prezentate pe site. <br /> Imaginile sunt prezentate pe
            site cu titlu de exemplu, iar produsele livrate pot diferi de
            imaginile și descrierile expuse pe site în orice mod, datorită
            modificării caracteristicilor și design-ului fără notificare
            prealabilă. <br />
             Vânzătorul își rezervă dreptul să completeze și să modifice orice
            informație de pe site fără notificare prealabilă. <br />{" "}
            Vânzătorulnu garantează disponibilitatea în stoc a produselor
            afișate, motiv pentru care va avea dreptul de a nu livra parțial sau
            integral o anumită comandă în cazul în care anumite produse nu mai
            apar în oferta curentă sau nu sunt disponibile. <br /> În cazul în
            care prețurile sau alte detalii referitoare la produse au fost
            afișate greșit, inclusiv din cauza faptului că au fost introduse
            greșit în baza de date, Vânzătorulîși alocă dreptul de a anula
            livrarea respectivului produs și de a anunța clientul în cel mai
            scurt timp despre eroarea aparută, dacă livrarea nu s-a efectuat
            încă. <br /> Vânzătorulnu răspunde pentru prejudiciile create ca
            urmare a nefuncționării site-ului precum și pentru cele rezultând
            din imposibilitatea accesării anumitor link-uri publicate pe site.{" "}
            <br />
            Valoareamaximă a obligațiilor Vânzătorului față de orice client în
            cazul nelivrării sau livrării necorespunzătoare este valoarea
            sumelor încasate de Vânzător de la clientul respectiv. <br />{" "}
            Produsele comercializate pe site sunt destinate utilizării
            personale, iar revânzarea acestora este strict interzisă, conform
            Codului Fiscal. <br />
            11.Livrarea produselor <br /> Livrarea nu este gratuită și
            se face prin curier rapid așa cum este descrisă în secțiunea
            "Livrări". <br /> Produsele noastre sunt realizate câteodată pe bază
            de comandă, ceea ce face ca durata de producție și
            livrare să fie de până la 14 zile lucrătoare începând cu ziua
            procesării comenzii. <br /> Comenzile sunt procesate cât mai curând
            după plasarea comenzii, în intervalul Luni – Vineri 10:00-18:00.{" "}
            <br /> În cazul comenzilor plasate în afara orelor de program (Luni
            – Vineri 10:00-18:00) sau în weekend, acestea vor fi procesate în
            prima zi lucrătoare. <br />{" "}
            De sărbători și în perioadele de reduceri,
            termenul de livrare se poate prelungi. <br /> Dacă pachetul nu poate
            fi livrat (destinatarul nu răspunde, adresa specificată de
            dumneavoastră este greșită etc), veți fi contactat telefonic de
            către curier. <br /> Dacă nu se poate lua contact cu destinatarul,
            coletele rămân la curierul zonal 7 zile, după care revin la
            Expeditor. <br /> Nu suntem răspunzători pentru expedieri
            întârziate, pierderea, distrugerea, deteriorarea, ne livrarea sau
            livrarea greșită a unei expedieri sau a unei părți din aceasta în
            cazul în care sunt generate de următoarele situații/circumstanțe
            independente de voința noastră, dar nelimitându-se la acestea: –
            blocaje rutiere (căderi de copaci, stânci, coliziuni în lanț),
            surpări de teren în apropierea șoselei; – căderi de poduri, astupări
            de tunele montane, deraierea trenurilor în apropierea șoselei,
            blocări naturale, greve neautorizate, revolte spontane regionale,
            condiții meteorologice nefavorabile bunei desfășurări a
            itinerariului propus; – cauze naturale: seisme, cataclisme, furtuni
            devastatoare, tornade, incendii naturale, inundații, deversări de
            fluvii, părăsiri de albii etc; – cauze umane: starea de război,
            starea de asediu, etatizarea forțată (trecerea în proprietatea
            statului), revoluții, revolte populare etc; – nerespectarea
            comenzilor de către furnizori și terți. <br /> 12.Acceptarea
            Acceptarea <br /> va fi facută atunci când Bunurile sunt conforme cu
            caracteristicile tehnice menționate în Comandă. <br /> În cazul în
            care Cumpărătorul descoperă că Produsele livrate nu sunt conforme
            specificațiilor tehnice, atunci Vânzătorul va aduce la conformitate
            Produsele. <br /> De asemenea, pentru produsele vândute și livrate
            de Senflua, Cumpărătorul beneficiază de returul produselor în 14
            zile. <br /> 13.Transferul proprietății <br /> Proprietatea asupra
            Bunurilor va fi transferată la livrare, după efectuarea plății din
            partea Cumpărătorului în locația indicată în comandă (întelegând
            prin livrare – semnarea de primire a documentului de transport
            furnizat de curier sau semnarea de primire pe factura fiscală în
            cazul livrărilor efectuate de personalul Vânzătorului). <br /> În
            cazul livrării prin curier, acesta nu este autorizat de
            către Vânzător să permită Cumpărătorului deschiderea coletelor
            înainte de semnarea de livrare, ci doar ulterior semnării de livrare
            și achitării eventualei contravalori a acestora. <br />{" "}
            14.Returnarea produselor <br />
            Cumpărătorul poate solicita returnarea produselor în următoarele
            situații: Coletele prezintă deteriorări severe; Produsele au fost
            livrate / facturate greșit. <br /> Livrarea altor produse decât cele
            solicitate trebuie semnalată imediat. <br /> Cumpărătorul poate
            solicita returnarea acestuia pentru înlocuire, iar dacă produsul nu
            mai este pe stoc, poate opta pentru înlocuire sau rambursarea
            integrală a contravalorii. <br /> Daca se agreează înlocuirea cu un
            produs de o valoare mai mare, va plăti diferența, respectiv dacă
            valoarea este mai mică, va primi o rambursare parțială până la
            valoarea produsului înlocuitor. <br /> Costurile de retur și de
            transport pentru produsul înlocuitor, dacă este cazul, sunt
            suportate de client. <br /> Produsele prezintă defecte de
            fabricație; Cumpărătorul are dreptul să notifice în scris
            Vânzătorului că renunța la cumpărare, fără penalități şi fără
            invocarea unui motiv, în termen de 14 zile de la primirea
            produsului. <br /> De asemenea, în conformitate cu art. 7 alin. 1
            din O.G. 130/2000, Cumpărătorul are dreptul de a denunța unilateral
            contractul la distanță, în scris, în termen de 14 zile de la data
            primirii produsului/produselor, fără penalități și fără invocarea
            vreunui motiv. <br /> În acest caz, cheltuielile directe de
            returnare a produselor vor cădea, conform legii, în sarcina
            Cumpărătorului. <br />
            Produsele trebuie să fie returnate în ambalajul original, cu factura
            atașată, să nu prezinte urme de uzură fizică sau deteriorare. <br />
            Produsele personalizate nu pot fi returnate. <br /> Vă rugăm să
            aveți în vedere că aceste produse sunt create după configurațiile
            specificate de dumneavoastră, deci nu pot fi schimbate sau
            returnate. <br /> Dacă se agreează înlocuirea cu un produs de o
            valoare mai mare, Cumpărătorul va plăti diferența, respectiv dacă
            valoarea este mai mică, va primi o rambursare parțială până la
            valoarea produsului înlocuitor. <br />
            Costurile de retur și de transport pentru produsul înlocuitor, dacă
            este cazul, sunt suportate de Cumpărător. <br /> În cazul în care
            produsele a căror returnare se solicită prezintă ambalaje
            deteriorate sau incomplete, urme de uzură, zgârieturi, lovituri, ne
            rezervăm dreptul de a decide acceptarea returului sau de a opri o
            sumă, suma ce va fi comunicată după evaluarea prejudiciilor aduse.{" "}
            <br /> În cazul exercitării dreptului legal de returnare a
            produsului, rambursarea contravalorii acestuia se va face prin
            virament bancar în contul indicat de Cumpărător în cel mult 14 de
            zile de la primirea produsului returnat. <br /> 15.Prelucrarea
            datelor cu caracter personal <br /> Pentru a asigura respectarea
            dreptului utilizatorilor site-ului la protecția datelor cu caracter
            personal, am implementat măsuri specifice de protecție, luând în
            considerare legislația română, precum și cerințele stabilite de
            Regulamentul (UE) 2017/679 –  aplicabil în întreaga Uniune Europeană
            începând cu data de 25 mai 2018 („Regulamentul”). <br /> Datele cu
            caracter personal reprezintă orice informație prin care dvs. puteți
            fi identificat, în special printr-un element de identificare cum ar
            fi un nume, un număr de identificare, date de localizare, un
            identificator online sau unul sau mai multe elemente aflate în
            legătură cu identitatea dvs. fizică, fiziologică, genetică, psihică,
            economică, culturală sau socială. <br /> Societatea noastră ia toate
            măsurile necesare pentru a asigura respectarea dreptului dvs. <br />{" "}
            la protecția datelor cu caracter personal, iar prezentele condiţii
            reprezintă notificareastabilită de art. 13 sau 14 din Regulament
            prin care  explicăm de ce colectăm datele dvs. cu caracter personal,
            modul în care protejăm aceste date, precum și care sunt drepturile
            dvs. în legătură cu această colectare de date. Vă încurajăm să
            citiți cu atenție documentul și să ne solicitați orice informație
            suplimentară sau orice lămurire pe care o considerați necesară, cu
            privire la conținutul acestei informări. 1) Considerații generale
            privind datele dumneavoastră personale  In conformitate cu
            Regulamentul (UE) 2017/679, Senflua va administra in conditii de
            siguranta si numai pentru scopurile specificate, datele personale
            furnizate de Utilizatori. <br /> Prin Termeni si Conditii
            Utilizatorii sunt informati ca datele personale pe care le
            furnizeaza urmeaza a fi prelucrate in scopul oferirii in conditii
            optime de catre Senflua a serviciilor pe internet, a serviciilor de
            furnizare bunuri si servicii, a serviciilor de reclama, marketing si
            publicitate si a serviciilor de statistica. <br />{" "}
            Senflua realizează următoarele operaţiuni de prelucrare: colectarea,
            inregistrarea, organizarea, stocarea, adaptarea, modificarea,
            extragerea, consultarea, utilizarea si, in unele cazuri,
            transmiterea catre terti, in baza exclusiva si reglementata a unui
            contract comercial care asigura securitatea si confidentialitatea
            datelor, precum si respectarea drepturilor utilizatorului, a datelor
            personale ale Utilizatorilor site-ului pentru a administra, mentine,
            imbunatati si obtine informaţii cu privire la serviciile pe care le
            ofera, precum si pentru a preveni erori si scurgeri de informatii
            prin reteaua IT proprie, incalcari ale legii sau termenilor
            contractuali. <br /> Datele personale ce urmeaza a fi colectate vor
            putea fi folosite, inclusiv prin crearea automată de profiluri
            (pentru acei Utilizatori care şi-au exprimat consimţământul
            explicit) si pentru a personaliza intr-un grad cat mai mare
            serviciile oferite Utilizatorilor prin intermediul site-ului precum
            si in scopuri de marketing. <br />
             Crearea automată de profiluri nu va viza datele minorilor, datele
            personale ale acestora nefiind prelucrate în acest scop. <br />{" "}
            Datele personale vor fi furnizate de catre Utilizatori, la optiunea
            lor proprie, in momentul crearii unui cont valabil pe site sau
            abonarii la un serviciu disponibil pe site, pentru a beneficia de
            serviciile si produsele oferite prin intermediul acestuia. <br /> La
            inregistrarea in site sunt solicitate Utilizatorului anumite date
            personale, precum numele si prenumele, sexul, data nasterii, adresa
            de e-mail, telefon, profesie, obisnuinte/preferinte/comportament,
            dar si alte informatii cu caracter personal. <br /> Utilizatorul
            este singurul raspunzator de toate datele furnizate la momentul
            crearii contului de utilizator pe site. <br /> Pentru confirmarea
            datelor si contului, Utilizatorul va fi anuntat la adresa de e-mail
            declarata la crearea contului. <br /> Acest e-mail de confirmare are
            rolul stoparii actiunilor frauduloase ale unor utilizatori care
            folosesc adrese de e-mail ale altor persoane pentru a crea conturi
            fictive. <br /> Daca primiti un asemenea mesaj, in conditiile in
            care nu v-ati inregistrat personal pe site va rugam sa ne trimiteti
            un e-mail la adresa gdpr@senflua.ro pentru a sterge contul respectiv
            in maximum 3 zile lucratoare. <br />
             E-mailul va conţine, în format pdf şi versiunea în vigoare a
            prezentului Contract. <br /> Formularul de profil al Utilizatorului
            contine campuri care pot fi editate in cazul in care se doreste
            modificarea sau completarea datelor furnizate la crearea contului.{" "}
            <br /> Utilizatorul nu este obligat sa furnizeze aceste date, ele
            fiind necesare i) evidentei despre folosirea site-ului si ii) al
            oferirii in conditii optime a serviciilor prin intermediul acestuia,
            pentru aducerea la cunostinta a campaniilor promotionale, in scop de
            reclama, pentru actiuni de marketing si publicitate personalizate si
            pentru accesul Utilizatorului la facilitati suplimentare. <br />{" "}
            Refuzul Utilizatorului de a furniza datele solicitate determina
            neparticiparea la actiunile promotionale organizate prin intermediul
            site-ului si nefolosirea facilitatilor, serviciilor suplimentare
            oferite exclusiv Utilizatorilor cu un cont in acest website. <br />
            Website-ul va putea fi utilizat şi dacă Utilizatorul decide să nu
            creeze un profil prin furnizarea datelor personale, cu excepţiile
            stabilite în cadrul Politicii de Cookies (parte integrantă a
            prezentului Contract) Conform Regulamentul (UE) 2017/679 si Legii
            nr. 677/2001, Utilizatorii beneficiaza de dreptul de acces, de
            interventie asupra datelor, dreptul de a nu fi supus unei decizii
            individuale si dreptul de a se adresa justitiei. <br /> Totodata,
            Utilizatorii au dreptul sa se opuna prelucrarii datelor personale
            care ii privesc si sa solicite stergerea datelor. <br /> Pentru
            exercitarea acestor drepturi, Utilizatorii pot face o cerere in
            scris in acest sens, pe care o vor trimite prin e-mail la
            adresa gdpr@senflua.ro cu specificația „Solicitare date cu caracter
            personal”. <br /> Site-ul se angajează sa nu trimită mesaje spam
            (mesaje comerciale pentru care nu are acceptul prealabil explicit al
            Utilizatorului) si sa întreprindă toate mijloacele tehnice
            accesibile pentru a asigura securitatea si confidențialitatea
            datelor utilizatorului. <br /> Site-ul își rezerva dreptul de a
            desființa, fără aviz prealabil, conturile si accesul membrilor care
            încalcă Termenii și condițiile, care întreprind activități dovedite
            de frauda, calomnie sau atac la securitatea și confidențialitatea
            informațiilor din cadrul site-ului sau a companiei operatoare a
            site-ului. <br /> 2) Datele având caracter personal pe care le
            colectăm, temeiurile colectării și scopurile colectării În cadrul
            raportului de comunicare online stabilit intre site și utilizator,
            colectăm și prelucrăm datele dvs. având caracter personal,
            colectarea și prelucrarea acestor date fiind necesară furnizării de
            conținut informativ si promoțional către utilizator, în urma
            completării, de către acesta, a datelor cu caracter personal în mod
            voluntar, în cadrul site-ului, prin accesarea uneia sau mai multora
            dintre secțiunile: creare cont, actualizare cont, abonare la
            newsletter, completare chestionar online, completare formular
            online, pentru obținerea de informații personalizate sau beneficii
            comerciale, în cadrul campaniilor promovate pe site. <br />{" "}
            Temeiurile colectării de date cu caracter personal pe site pot fi
            următoarele: <br />
            1. persoana vizata și-a dat consimțământul pentru prelucrarea
            datelor cu caracter personal pentru unul sau mai multe scopuri
            specifice <br /> 2. prelucrarea este necesara pentru executarea unui
            contract la care persoana vizata este parte sau pentru a face
            demersuri la cererea persoanei vizate înainte de încheierea unui
            contract. <br /> 3. prelucrarea este necesara în vederea
            îndeplinirii unei obligații legale care ii revine operatorului.{" "}
            <br /> 4. prelucrarea este necesara pentru a proteja interesele
            vitale ale persoanei vizate sau ale altei persoane fizice <br /> 5.
            prelucrarea este necesara pentru îndeplinirea unei sarcini care
            servește unui interes public sau care rezulta în exercitarea
            autorității publice cu care este investit operatorul. <br /> 6.
            prelucrarea este necesara în scopul intereselor legitime urmărite de
            operator sau de o parte terța, cu excepția cazului în care
            prevalează interesele sau drepturile și libertățile fundamentale ale
            persoanei vizate, care necesita protejarea datelor cu caracter
            personal, în special atunci când persoana vizata este un copil.{" "}
            <br />   În enumerările de mai jos vă prezentăm în mod detaliat ce
            date cu caracter personal colectate precum și temeiul, scopul și
            durata colectării acestora. <br />
              Furnizarea datelor indicate mai jos nu este obligatorie pentru a
            vizualiza Website-ul, cu excepția celor care vizează Cookies. <br />{" "}
            Datele indicate sunt necesare numai pentru a presta servicii
            specifice pentru Utilizatorii care le transmit. <br /> Date având
            caracter personal pe care le colectăm:  Nume, prenume, adresa de
            e-mail, număr de telefon, date de localizare geografica, adresa de
            corespondenta, link profiluri social media Temeiurile colectării
            acestor date: 1), 6) Scopurile colectării datelor având caracter
            personal:  Pentru furnizarea accesului la conținut și răspunsuri la
            întrebări și solicitări transmise de către utilizator online, pentru
            transmiterea de comunicări, oferte și beneficii constând în acces la
            servicii și produse Modalitatea si perioada de colectare a datelor: 
            In cadrul bazei de date securizate, pana la exprimarea dorinței
            explicite de ștergere de către utilizator sau pana la 10 ani de la
            ultima activitate a utilizatorului pe site. <br /> După cei 10 ani,
            datele vor fi anonimitate electronic. <br /> Date având caracter
            personal pe care le colectăm:  Cookies, marcaje de timp (data și ora
            accesării), istoricul navigării pe site Temeiurile colectării
            acestor date: <br />  1), 6) Scopurile colectării datelor având
            caracter personal:  Pentru monitorizarea traficului site-ului și a
            istoricului accesărilor acestuia, pentru realizarea ierarhiei de
            conținut și identificarea conținutului cel mai relevant pentru
            utilizator. <br /> Modalitatea si perioada de colectare a datelor:{" "}
            <br /> În cadrul bazei de date securizate, pana la exprimarea
            dorinței explicite de ștergere de către utilizator sau pana la 10
            ani de la ultima activitate a utilizatorului pe site. După cei 10
            ani, datele vor fi anonimitate electronic. <br /> 3) Modul de
            păstrare a datelor dvs. având caracter personal, locație, perioadă
            de păstrare Vom stoca datele dvs. cu caracter personal pentru o
            perioadă care nu depășește perioada necesară îndeplinirii scopurilor
            în care sunt prelucrate datele, iar, în cazurile în care avem o
            obligația legală de a păstra datele dvs. cu caracter personal o
            anumită perioadă de timp, perioada de păstrare va fi cea prevăzută
            de lege. În funcție de situația concretă, această perioadă va varia,
            putând fi cuprinsă între 1 zi și 10 ani. <br /> Pentru o bună
            înțelegere a modului de păstrare, a locației în care datele dvs. cu
            caracter personal vor fi păstrate și a perioadei exacte pentru care
            aceste date vor fi păstrate în evidențele sau în sistemele noastre,
            vă rugăm să consultați tabelul de mai sus. În scopul garantării
            dreptului utilizatorului de a fi protejat în ceea ce privește
            prelucrarea datelor cu caracter personal, implementam, pentru
            anumite categorii de date având un caracter sensibil sau care sunt
            apte să afecteze drepturile într-o manieră semnificativă, măsuri
            speciale de natură tehnică și organizatorică, care să protejeze
            aceste categorii de date cu caracter personal. <br /> 4) Drepturile
            utilizatorilor cu privire la datele cu caracter
            personal și exercitarea acestora În vederea protejării la un
            standard cât mai ridicat a datelor utilizatorul are serie de
            drepturi reglementate de lege, pe care le prezentăm pe scurt în cele
            ce urmează, rugându-vă ca pentru orice detalii suplimentare să nu
            ezitați să contactați responsabilul cu protecția datelor cu caracter
            personal din cadrul Societății noastre, utilizând detaliile de
            contact următoare: gdpr@senflua.ro, Strada Constantin Brancusi, Nr
            11, Parter, Bloc D16, Scara 7, Ap 242, Sector 3,
            Bucuresti, telefon +40 (724) 542 277. <br /> Dreptul de acces
            Utilizatorul are dreptul de a obține accesul la datele sale cu
            caracter personal pe care le prelucrăm, precum și dreptul de a
            obține copii ale acestora. <br /> La solicitarea utilizatorului,
            prima astfel de copie vă va fi oferită de către noi în mod gratuit,
            urmând ca eventualele copii suplimentare pe care le veți solicita să
            poată fi supuse unei taxe corespunzătoare efortului necesar de
            extragere si formatare pentru transmitere. <br /> Copia datelor
            având caracter personal vă poate fi oferită fie în format
            electronic, fie în format fizic, în funcție de solicitarea dvs. şi
            de natura datei solicitate. <br /> Pentru solicitarea informațiilor
            despre datele cu caracter personal existente în site, precum si
            pentru a solicita ștergerea parțiala sau totala a acestor date, este
            nevoie sa fie adresata cererea folosind adresa de
            e-mail gdpr@senflua.ro sau sa fie expediata o scrisoare prin posta
            sau curierat la sediul social al Senflua. <br /> De asemenea,
            utilizatorul are dreptul să obțina orice informații suplimentare
            relevante (cum ar fi motivul prelucrării datelor cu caracter
            personal, categoriile de date cu caracter personal pe care le
            colectăm, informații privind prelucrarea și divulgarea acestor date
            și orice alte asemenea informații). <br /> Dreptul la rectificarea
            datelor Utilizatorul are dreptul de a obține rectificarea oricăror
            inexactități ce vizează datele sale cu caracter personal prelucrate
            de către noi. <br /> De asemenea, are dreptul de a obține
            completarea oricăror date cu caracter personal care sunt incomplete.{" "}
            <br /> Orice utilizator este incurajat sa contacteze site-ul la
            adresa gdpr@senflua.ro ori de câte ori observa că există o
            inexactitate în privința datelor sale cu caracter personal sau că
            datele sale cu caracter personal prelucrate de către Senflua sunt
            incomplete. <br /> Dreptul la ștergerea datelor Utilizatorul are
            dreptul la ștergerea datelor având caracter personal. <br /> Acest
            drept nu este un drept absolut, ceea ce înseamnă că legea instituie
            anumite limitări în privința exercitării acestui drept („dreptul de
            a fi uitat„). <br /> Dreptul la restricționarea prelucrării datelor
            Utilizatorul are dreptul de a obține restricționarea prelucrării
            datelor sale cu caracter personal pe care le colectăm și prelucrăm,
            în special în cazul în care contesta exactitatea datelor, în cazul
            în care prelucrarea datelor este ilegală ori în cazul în care
            prelucrarea acestor date nu mai este necesară, potrivit legii.{" "}
            <br /> Dreptul de a obiecta Utilizatorul are dreptul de a obiecta la
            prelucrarea datelor având caracter personal de către noi, în special
            în cazul în care prelucrarea se realizează în scopuri de marketing
            sau din motive legate de situația particulară în care se afla
            acesta, caz in care datele acestuia trebuie anonimitate în cel mai
            scurt timp de la sesizarea obiectării din baza de date a site-ului
            și anonimizarea acestora trebuie confirmata utilizatorului. <br />{" "}
            Dreptul la retragerea consimțământului Pentru datele cu caracter
            personal prelucrate pe baza consimțământului, utilizatorul are
            dreptul de a isi retrage consimțământul in orice moment, la fel de
            ușor cum l-a și acordat inițial. <br /> Retragerea consimțământului
            nu va afecta însă legalitatea prelucrării datelor pe care am
            realizat-o înainte de retragerea consimțământului. <br /> Dreptul la
            retragerea consimțământului nu este unul absolut, ceea ce înseamnă
            că există cazuri în care datele nu vor fi șterse ca urmare a
            retragerii consimțământului (de exemplu în cazul în care datele
            având caracter personal sunt folosite pentru ca site-ul să se
            conformeze unei obligații legale). <br /> Aplicarea retragerii
            consimțământului se aplica din momentul înregistrării acesteia si
            operarea retragerii consimțământului se va realiza in maximum 3 zile
            lucrătoare de la înregistrare. <br /> Dreptul de a depune o plângere
            în fața autorității competente Utilizatorul are dreptul de a depune
            o plângere la Autoritatea Națională de Supraveghere a Prelucrării
            Datelor cu Caracter Personal cu referire la aspectele ce privesc
            prelucrarea datelor cu caracter personal de către Societatea
            noastră. <br /> Procesul decizional automatizat, inclusiv crearea de
            profiluri și dreptul de a solicita ca deciziile bazate pe
            prelucrarea automată a datelor  sau care  afectează într-o măsură
            semnificativă să fie luate de persoane fizice, nu exclusiv de
            computere Datele cu caracter personal sunt colectate și prelucrate
            printr-un proces decizional automatizat în vederea personalizării
            informațiilor şi comunicărilor comerciale adresare dumneavoastră.{" "}
            <br />
            Procesul decizional automatizat ce implică datele de sănătate are la
            bază consimțământul dumneavoastră expres. <br /> În cadrul acestor
            procese datele dumneavoastră sunt protejate prin măsuri de
            securizare specială de tipul criptării datelor și securității
            avansate la nivel de server al bazei de date. <br /> Aveți dreptul
            de a solicita modificarea modului în care sunt prelucrate automat
            datele dumneavoastră personale, solicitând verificarea procesului
            automatizat prin intervenție umană. <br /> În acest scop vă rugăm să
            ne contactați la adresa de email gdpr@senflua.ro. <br />{" "}
            5) Exercitarea drepturilor Pentru ca utilizatorul sa își poată
            exercita drepturile precizate mai sus, pentru a ne adresa orice
            întrebare referitoare la aceste drepturi sau pentru a ne cere
            lămuriri cu privire la oricare dintre prevederile acestei informări,
            vă rugăm să ne contactați oricând, utilizând informațiile de
            contact. <br /> Cum se pot transmite cererile sau plângerile –
            persoana de contact Pentru a afla mai multe informații cu privire la
            datele cu caracter personal, cu privire la modul în care datele sunt
            colectate, prelucrate și protejate sau pentru a cere orice lămuriri
            referitoare la cele menționate în acești Temeri și Condiții orice
            utilizator poate contacta la orice moment responsabilul cu protecția
            datelor cu caracter personal din cadrul companiei, adresându-se prin
            mail la gdpr@senflua.ro sau prin corespondenta postala la Strada
            Constantin Brancusi, Nr 11, Parter, Bloc D16, Scara 7, Ap 242,
            Sector 3, Bucuresti. 16 Forță majoră Nici una din părți nu va fi
            răspunzătoare pentru neexecutarea obligațiilor sale contractuale,
            dacă o astfel de neexecutare este datorată unui eveniment de forță
            majoră. <br /> Forța majoră este evenimentul imprevizibil, în afara
            controlului părților și care nu poate fi evitat. <br />  17.Legea
            aplicabilă  Prezentul contract este supus legii Române. <br />{" "}
            Eventualele litigii apărute între Senflua și
            utilizatori/clienți/cumpărători se vor rezolva pe cale amiabilă sau,
            în cazul în care aceasta nu va fi posibilă, litigiile vor fi
            soluționate de instanțele judecătorești Române competente. <br /> 
            18. Oferte speciale Senflua nu are în desfășurare campanii cu oferte
            speciale. <br />  19. Modificarea termenilor și condițiilor
            Senflua are dreptul de a modifica oricând și în orice mod oricare
            dintre prevederile cuprinse în Termeni și condiții sau Termenii și
            Condițiile în întregime, fără nicio notificare prealabila și fără a
            fi obligata sa îndeplinească vreo alta formalitate fata de
            Utilizatori. <br /> Orice modificare este acceptata deplin și
            necondiționat de către Utilizatorii site-ului prin simpla utilizare
            sau accesare a site-ului sau a oricărei facilitați oferite de site,
            intervenite oricând după operarea modificării, iar neacceptarea
            oricărei modificări atrage obligația respectivului Utilizator de a
            înceta de îndată accesarea site-ului și/sau utilizarea în orice mod
            a serviciilor oferite prin intermediul acestuia.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default SectionTextTermeniConditii;
