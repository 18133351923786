import React, { useState, useEffect } from "react";

import { useLanguage } from "../utils/utils.jsx";

function Redirectionare({ page, setPage }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ marginTop: "20px" }}>
        #senflua.LIFE
      </p>
      <p className="nameTitle">{t("typeForm.redirectionare.title")}</p>
      <spam className="namesubTitle"></spam>

      <a href="/Travel" target="_blank" className="senfluaFormRedirectionare1">
        {t("typeForm.redirectionare.subtitle1")}
      </a>
      <spam className="namesubsubTitle">
        {t("typeForm.redirectionare.subtitle2")}
      </spam>
      <label
        className="senfluaFormRedirectionare2 image-with-hover-cursor"
        onClick={(event) => setPage((currPage) => currPage + 1)}
      >
        {t("typeForm.redirectionare.subtitle3")}
      </label>
    </div>
  );
}

export default Redirectionare;
