import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useCookies } from "react-cookie";

import { HashLink } from "react-router-hash-link";
import { Container, Row, Col } from "reactstrap";

import "./HeaderRCA.css";

import Logo from "../../assests/images/logo.svg";
import Whatsapp_btn from "../../assests/images/Whatsapp_btn.png";

import en from "../../assests/images/language/english.svg";
import ro from "../../assests/images/language/romania.svg";

import { useTranslation } from "react-i18next";
import "../../i18n";

const HeaderRCA = () => {
  const { t, i18n } = useTranslation();

  const [cookies, setCookie] = useCookies(["Senflua.language"]);

  const language = cookies["Senflua.language"];
  const [selected, setSelected] = useState("ro");

  useEffect(() => {
    if (language) {
      setSelected(language);
    }
  }, []);

  const images = {
    en: en,
    ro: ro,
  };

  const navLinks = [
    {
      display: t("header.menu1"),
      url: "/#o-viata-altfel",
    },
    {
      display: t("header.menu2"),
      url: "/#tele-Portezi",
    },
    {
      display: t("header.menu3"),
      url: "/#pasi-de-facut",
    },
    {
      display: t("header.menu4"),
      url: "/pagina-contact",
    },
    {
      display: "Hai sa povestim",
      url: "https://wa.me/40724542277",
    },
  ];

  const handleLanguageChange = (event) => {
    if (i18n.language === "ro") {
      i18n.changeLanguage("en");
      setCookie("Senflua.language", "en");
    } else {
      i18n.changeLanguage("ro");
      setCookie("Senflua.language", "ro");
    }
  };

  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  const handleSelect = (key) => {
    setSelected(key);
    setShowDropdown(false);
    handleLanguageChange();
  };

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <section className="headerRCA">
      <Container>
        <Row>
          <Col lg="3" md="3" sm="6" xs="6" className="logo d-flex">
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </Col>
          <Col lg="9" md="9" sm="6" xs="6" className="menu">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list">
                {navLinks.map((item, index) => (
                  <li
                    key={index}
                    className={index !== 4 ? "nav__item" : "meniu_mobile_close"}
                  >
                    {/* <a href={item.url}>{item.display}</a> */}
                    <HashLink to={item.url}>{item.display}</HashLink>
                  </li>
                ))}
              </ul>
              {/* Whatsapp_btn */}
              <div className="image-button">
                <img
                  src={Whatsapp_btn}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "170px",
                    height: "auto",
                  }}
                  onClick={(event) =>
                    (window.location.href = "https://wa.me/40724542277")
                  }
                />

                <div
                  onClick={(event) =>
                    (window.location.href = "https://wa.me/40724542277")
                  }
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "15px",
                    marginTop: "17px",
                  }}
                >
                  {t("header.menu5")}
                  <a href="https://wa.me/40724542277">
                    <svg
                      className="whats"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
                        fill="rgba(0,0,0,1)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <img
                src={images[selected]}
                alt={selected}
                className="language_img"
                onClick={() => setShowDropdown(!showDropdown)}
              />
              {showDropdown && (
                <ul
                  style={{
                    listStyleType: "none",
                    marginTop: "-30px",
                  }}
                >
                  {Object.keys(images)
                    .filter((key) => key !== selected)
                    .map((key) => (
                      <li key={key} onClick={() => handleSelect(key)}>
                        <img
                          src={images[key]}
                          alt={key}
                          className="language_img"
                        />
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className="mobile__menu">
              <i
                className={isOpen === false ? "ri-menu-line" : "ri-close-line"}
                onClick={toggle}
              ></i>
              <a href="https://wa.me/40724542277">
                <svg
                  className="whats"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="30"
                  height="30"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </a>
              <img
                src={images[selected]}
                alt={selected}
                className="language_img"
                onClick={() => setShowDropdown(!showDropdown)}
              />
              {showDropdown && (
                <ul
                  style={{
                    listStyleType: "none",
                    marginTop: "-30px",
                  }}
                >
                  {Object.keys(images)
                    .filter((key) => key !== selected)
                    .map((key) => (
                      <li key={key} onClick={() => handleSelect(key)}>
                        <img
                          src={images[key]}
                          alt={key}
                          className="language_img"
                        />
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </Col>
        </Row>
        <div
          className={
            isOpen === false ? "meniu_mobile_close" : "meniu_mobile_open"
          }
          onClick={toggle}
        >
          <Row>
            <Col>
              <HashLink to={navLinks[0].url}>{navLinks[0].display}</HashLink>
              <HashLink to={navLinks[1].url}>{navLinks[1].display}</HashLink>

              {/* <a href={navLinks[0].url}>{navLinks[0].display}</a>
              <a className="meniu_mobile_open2" href={navLinks[1].url}>
                {navLinks[1].display}
              </a> */}
            </Col>
            <Col>
              <HashLink to={navLinks[2].url}>{navLinks[2].display}</HashLink>
              <HashLink to={navLinks[3].url}>{navLinks[3].display}</HashLink>

              {/* <a href={navLinks[2].url}>{navLinks[2].display}</a>
              <a className="meniu_mobile_open2" href={navLinks[3].url}>
                {navLinks[3].display}
              </a> */}
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default HeaderRCA;
