import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";

import rca_hello from "../../assests/images/RCA/rca_hello.svg";
import rca_3 from "../../assests/images/RCA/rca_3.svg";
import vreau_RCA_btn from "../../assests/images/RCA/vreau_RCA_btn.png";
import asiguratori from "../../assests/images/RCA/asiguratori.png";

import { useTranslation } from "react-i18next";
import "../../i18n";

import RCAbtn from "../../assests/images/RCA/rca_btn.png";

import "./LP_RCA.css";

const LP_RCA = () => {
  useEffect(() => {
    document.title = "Senflua - RCA";
  }, []);

  const { t, i18n } = useTranslation();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const slides = [
    <div key={1}>
      <Container className="containerRCA__lp">
        <Row className="sectionRCA__form">
          <Col xl="6" lg="12" md="12" sm="12" className="sectionRCA__left">
            <p className="sectionRCA__left_title1">{t("rca.lpRCA.title11")}</p>
            <p className="sectionRCA__left_title2_blue">
              {t("rca.lpRCA.title12")}
            </p>
            <p className="sectionRCA__left_text1">
              {t("rca.lpRCA.subtitle11")}
              <br />
              {t("rca.lpRCA.subtitle12")}
            </p>

            <div
              className="image-button rca_btn"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={RCAbtn}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "243px",
                  height: "auto",
                }}
                onClick={(event) => (window.location.href = "/asigurare-rca")}
              />

              <div
                onClick={(event) => (window.location.href = "/asigurare-rca")}
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {t("rca.lpRCA.button1")}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="12" md="12" sm="12" className="sectionRCA__lp">
            <p className="sectionRCA__right_title1"> #SENFLUA.THINGS</p>
            <img src={rca_hello} alt="" className="rca_right1" />
          </Col>
        </Row>
      </Container>
    </div>,
    <div key={2}>
      <Container className="containerRCA__form">
        <Row className="sectionRCA__form">
          <Col xl="6" lg="12" md="12" sm="12" className="sectionRCA__left">
            <p className="sectionRCA__left_title1">{t("rca.lpRCA.title21")}</p>
            <p className="sectionRCA__left_title3">{t("rca.lpRCA.title22")}</p>
            <p className="sectionRCA__left_text1">
              {t("rca.lpRCA.subtitle21")} <br />
              {t("rca.lpRCA.subtitle22")}
              <br /> {t("rca.lpRCA.subtitle23")}
              <br /> {t("rca.lpRCA.subtitle24")}
            </p>

            <div
              className="image-button rca_btn"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={RCAbtn}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "243px",
                  height: "auto",
                }}
                onClick={(event) => (window.location.href = "/asigurare-rca")}
              />

              <div
                onClick={(event) => (window.location.href = "/asigurare-rca")}
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {t("rca.lpRCA.button1")}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="12" md="12" sm="12" className="sectionRCA__lp">
            <p className="sectionRCA__right_title1"> #SENFLUA.THINGS</p>
            <img src={asiguratori} alt="" className="rca_right2" />
          </Col>
        </Row>
      </Container>
    </div>,
    <div key={3}>
      <Container className="containerRCA__form">
        <Row className="sectionRCA__form">
          <Col xl="6" lg="12" md="12" sm="12" className="sectionRCA__left">
            <p className="sectionRCA__left_title1">{t("rca.lpRCA.title31")}</p>
            <p className="sectionRCA__left_title3">{t("rca.lpRCA.title32")}</p>
            <p className="sectionRCA__left_text1">
              {t("rca.lpRCA.subtitle31")}
              <br />
              {t("rca.lpRCA.subtitle32")} <br /> {t("rca.lpRCA.subtitle33")}
              <br /> {t("rca.lpRCA.subtitle34")} <br />
            </p>

            <div
              className="image-button rca_btn"
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={RCAbtn}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "243px",
                  height: "auto",
                }}
                onClick={(event) => (window.location.href = "/asigurare-rca")}
              />

              <div
                onClick={(event) => (window.location.href = "/asigurare-rca")}
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {t("rca.lpRCA.button1")}
              </div>
            </div>
          </Col>
          <Col xl="6" lg="12" md="12" sm="12" className="sectionRCA__lp">
            <p className="sectionRCA__right_title1"> #SENFLUA.THINGS</p>
            <img src={rca_3} alt="" className="rca_right3" />
          </Col>
        </Row>
      </Container>
    </div>,
  ];

  return (
    <section className="section_formRCA">
      <Slider {...settings}>{slides}</Slider>
    </section>
  );
};

export default LP_RCA;
