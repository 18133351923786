import React, { useState, useEffect } from "react";

import MinKm from "../../assests/images/SenfluaForm/8000km.svg";
import Mijkm from "../../assests/images/SenfluaForm/15000km.svg";
import Maxkm from "../../assests/images/SenfluaForm/15000kmMax.svg";

import { useLanguage } from "../utils/utils.jsx";

function KmMasina({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.KmMasina.title")}</p>
      <spam className="namesubTitle">{t("typeForm.KmMasina.subtitle")}</spam>
      <div>
        <label
          className={
            formAig.senfluaThinhs.putin8000
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                putin8000: !formAig.senfluaThinhs.putin8000,
              },
            }));
          }}
          style={{ width: "210px", height: "101px" }}
        >
          <img src={MinKm} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.KmMasina.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.maimult8000
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                maimult8000: !formAig.senfluaThinhs.maimult8000,
              },
            }));
          }}
          style={{ width: "174px", height: "101px" }}
        >
          <img src={Mijkm} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.KmMasina.v2")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.maimult15000
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                maimult15000: !formAig.senfluaThinhs.maimult15000,
              },
            }));
          }}
          style={{ width: "220px", height: "101px" }}
        >
          <img src={Maxkm} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.KmMasina.v3")}
        </label>
      </div>
    </div>
  );
}

export default KmMasina;
