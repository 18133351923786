import React, { useState, useEffect } from "react";

import inundatie from "../../assests/images/SenfluaForm/inundatie.svg";
import furt from "../../assests/images/SenfluaForm/furt.svg";
import catastrofe from "../../assests/images/SenfluaForm/catastrofe.svg";

import { useLanguage } from "../utils/utils.jsx";

function FricaCasa({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.fricaCasa.title")}</p>
      <p className="namesubTitle">{t("typeForm.fricaCasa.subtitle")}</p>

      <div>
        <label
          className={
            formAig.senfluaThinhs.inundatie
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                inundatie: !formAig.senfluaThinhs.inundatie,
              },
            }));
          }}
          style={{ width: "204px", height: "101px" }}
        >
          <img src={inundatie} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.fricaCasa.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.furst
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                furst: !formAig.senfluaThinhs.furst,
              },
            }));
          }}
          style={{ width: "168px", height: "101px" }}
        >
          <img src={furt} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.fricaCasa.v2")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.catastrofe
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                catastrofe: !formAig.senfluaThinhs.catastrofe,
              },
            }));
          }}
          style={{ width: "204px", height: "101px" }}
        >
          <img src={catastrofe} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.fricaCasa.v3")}
        </label>
      </div>
    </div>
  );
}

export default FricaCasa;
