import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";

import inapoi_icon from "../../assests/images/RCA/inapoi_icon.svg";

import { useTranslation } from "react-i18next";
import "../../i18n";

import "./RCA_buy.css";

function Informatii({
  formData,
  setFormData,
  oras,
  setOras,
  judet,
  setJudet,
  errorPage2TC,
  setErrorPage2TC,
  buttonNext,
  minDate,
  judete,
  navigate,
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (formData.oras === "") {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/listOrase?judet=${formData.judet}`,
            {
              headers: {
                "API-Key": process.env.REACT_APP_API_KEY,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();

          setFormData({ ...formData, oras: data[0].oras });
          setOrase(data);
        } catch (error) {
          navigate("/eroare");
        }
      } else {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/listOrase?judet=${formData.judet}`,
            {
              headers: {
                "API-Key": process.env.REACT_APP_API_KEY,
              },
            }
          );
          const data = await response.json();

          setOrase(data);
        } catch (error) {
          navigate("/eroare");
          //console.error(error);
        }
      }
    };

    fetchData();
  }, []);

  const [orase, setOrase] = useState([]);

  async function handleJudetChange(event) {
    const judet = event.target.value;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listOrase?judet=${judet}`,
        {
          headers: {
            "API-Key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      const data = await response.json();

      setFormData({
        ...formData,
        judet: judet,
        oras: data[0].oras,
      });

      setOrase(data);
    } catch (error) {
      navigate("/eroare");
    }
  }

  const handleChange = (event) => {
    const date = new Date(event.target.value);
    setFormData({
      ...formData,
      data_start: date.toISOString().split("T")[0],
    });
  };

  function handleOrasChange(event) {
    const selectedText = event.target.options[event.target.selectedIndex].text;

    setFormData({
      ...formData,
      oras: selectedText,
    });
  }

  useEffect(() => {
    if (formData.peste18 === true && formData.declar === true)
      setErrorPage2TC(true);
    else setErrorPage2TC(false);
  }, [formData.peste18, formData.declar]);

  return (
    <div>
      <Row className="sectionRCA_buy__form"></Row>

      {formData.fizicJuridic === false && (
        <Row className="sectionRCA_buy__form1">
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.cnp.length !== 13 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.informatii.request1")}
              <br />
              <input
                type="text"
                name="name"
                value={formData.cnp}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    cnp: event.target.value,
                  })
                }
              />
            </label>
          </Col>

          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.nume.length < 2 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.informatii.request2")}
              <br />
              <input
                type="text"
                name="name"
                value={formData.nume}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    nume: event.target.value.toUpperCase(),
                  })
                }
              />
            </label>
          </Col>
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.prenume.length < 2 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.informatii.request3")}
              <br />
              <input
                type="text"
                name="name"
                value={formData.prenume}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    prenume: event.target.value.toUpperCase(),
                  })
                }
              />
            </label>
          </Col>
        </Row>
      )}

      {formData.fizicJuridic === false && (
        <Row className="sectionRCA_buy__form1">
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.serie_ci.length < 2 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.informatii.request4")}
              <br />
              <input
                type="text"
                name="name"
                value={formData.serie_ci}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    serie_ci: event.target.value.toUpperCase(),
                  })
                }
              />
            </label>
          </Col>
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.nr_ci.length < 2 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.informatii.request5")}
              <br />
              <input
                type="text"
                name="name"
                value={formData.nr_ci}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    nr_ci: event.target.value,
                  })
                }
              />
            </label>
          </Col>
        </Row>
      )}

      {formData.fizicJuridic === true && (
        <Row className="sectionRCA_buy__form1">
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.cui.length < 2 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.informatii.request15")}
              <br />
              <input
                type="text"
                name="name"
                value={formData.cui}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    cui: event.target.value,
                  })
                }
              />
            </label>
          </Col>
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.numeSRL.length < 2 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.informatii.request16")}
              <br />
              <input
                type="text"
                name="name"
                value={formData.numeSRL}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    numeSRL: event.target.value,
                  })
                }
              />
            </label>
          </Col>
        </Row>
      )}
      <div
        style={{
          borderTop: "1px solid #EEF5FC",
          marginTop: 25,
          marginLeft: 30,
          marginRight: 30,
        }}
      ></div>

      <div className="sectionRCA__left_subtitle2">
        <p>{t("rca.informatii.subtitle2")}</p>
      </div>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            // className="sub_title_rca_buy_gri"
            className={
              formData.strada.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy_gri"
            }
          >
            {t("rca.informatii.request6")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.strada}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  strada: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            // className="sub_title_rca_buy_gri"
            className={
              formData.numar.length < 1 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy_gri"
            }
          >
            {t("rca.informatii.request7")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.numar}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  numar: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy_gri">
            {t("rca.informatii.request8")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.bloc}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  bloc: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy_gri">
            {t("rca.informatii.request9")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.scara}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  scara: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="2" lg="3" md="3  " sm="12" className="sectionRCA_buy__left_2">
          <label className="sub_title_rca_buy_gri">
            {t("rca.informatii.request10")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.eataj}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  eataj: event.target.value,
                })
              }
            />
          </label>
        </Col>
        <Col xl="2" lg="3" md="3" sm="12" className="sectionRCA_buy__left_2">
          <label className="sub_title_rca_buy_gri">
            {t("rca.informatii.request11")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.ap}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  ap: event.target.value,
                })
              }
            />
          </label>
        </Col>
        <Col xl="2" lg="3" md="3" sm="12" className="sectionRCA_buy__left_2">
          <label
            className={
              formData.cod_postal.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy_gri"
            }
          >
            {t("rca.informatii.request12")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.cod_postal}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  cod_postal: event.target.value,
                })
              }
            />
            <a
              href=" https://www.posta-romana.ro/cauta-cod-postal.html"
              target="_blank"
              rel="noopener noreferrer"
              className="rca_info_a"
            >
              {t("rca.informatii.text1")}
              <img
                src={inapoi_icon}
                alt=""
                className=""
                onClick={(event) =>
                  (window.location.href =
                    " https://www.posta-romana.ro/cauta-cod-postal.html")
                }
              />
            </a>
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy">
            {t("rca.informatii.request13")}
            <br />
            <select value={formData.judet} onChange={handleJudetChange}>
              {judete.map((judet, index) => (
                <option key={index} value={judet.judet}>
                  {judet.judet}
                </option>
              ))}
            </select>
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy">
            {t("rca.informatii.request14")}
            <br />
            <select
              value={formData.oras}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  oras: event.target.options[event.target.selectedIndex].text,
                })
              }
            >
              {/* <option value="">Selectează un oraș</option> */}
              {orase.map((oras, index) => (
                <option key={index} value={oras.oras}>
                  {oras.oras}
                </option>
              ))}
            </select>
          </label>
        </Col>
      </Row>

      <div className="sectionRCA__left_subtitle2">
        <p>
          {t("rca.informatii.subtitle3")} <br />
        </p>
        <label className="sub_title_rca_buy_data">
          <input
            className="formRAC_in"
            type="date"
            min={minDate.toISOString().split("T")[0]}
            value={formData.data_start}
            onChange={handleChange}
          />
          <a
            href="https://www.aida.info.ro/polite-rca"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("rca.informatii.text2")}
            <img
              src={inapoi_icon}
              alt=""
              className=""
              onClick={(event) =>
                (window.location.href = "https://www.aida.info.ro/polite-rca")
              }
            />
          </a>
        </label>
      </div>

      <div className="sectionRCA__left_check1">
        <label>
          <input
            type="checkbox"
            required="required"
            value={formData.peste18}
            // onChange={(e) => setTc(e.target.checked)}
            onChange={(e) =>
              setFormData({
                ...formData,
                peste18: e.target.checked,
              })
            }
          />
          {t("rca.informatii.check1")}
        </label>
      </div>
      <div className="sectionRCA__left_check">
        <label>
          <input
            type="checkbox"
            required="required"
            value={formData.declar}
            // onChange={(e) => setPc(e.target.checked)}
            onChange={(e) =>
              setFormData({
                ...formData,
                declar: e.target.checked,
              })
            }
          />
          {t("rca.informatii.check2")}
        </label>
      </div>
    </div>
  );
}

export default Informatii;
