import React, { Fragment } from "react";
import HeaderRCA from "../HeaderRCA/HeaderRCA";
import FooterRCA from "../FooterRCA/FooterRCA";
import Confirmare_RCA from "../Confirmare_RCA/Confirmare_RCA.jsx";

const ConfirmareRCA = () => {
  return (
    <Fragment>
      <HeaderRCA />
      <Confirmare_RCA />
      <FooterRCA />
    </Fragment>
  );
};

export default ConfirmareRCA;
