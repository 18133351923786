import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ListCombustibil from "./ListCombustibil";

import Whatsapp_btn from "../../assests/images/Whatsapp_btn.png";
import Logo from "../../assests/images/logo.svg";

import "./RCA_buy.css";

import { useTranslation } from "react-i18next";
import "../../i18n";

function Autoturism({
  formData,
  setFormData,
  orasLeasing,
  setOrasLeasing,
  judetLeasing,
  setJudetLeasing,
  buttonNext,
  judete,
  marci,
  navigate,
}) {
  const { t, i18n } = useTranslation();

  const [inSasiu, setInSasiu] = useState(false);

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  const [oras, setOras] = useState([]);

  const years = [];
  const tomorrow = new Date();
  for (let i = tomorrow.getFullYear(); i >= 1900; i--) {
    years.push(i);
  }

  const [subcategorii, setSubcategorii] = useState([
    { id: 1, categorie: "1", nume: "Autoturism" },
    { id: 2, categorie: "1", nume: "Automobil mixt" },
    { id: 3, categorie: "1", nume: "Autoturism de teren" },
    { id: 5, categorie: "2", nume: "Autorulota" },
    { id: 4, categorie: "2", nume: "Autosanitara" },
    { id: 7, categorie: "3", nume: "Autobuz" },
    { id: 9, categorie: "3", nume: "Autobuz articulat" },
    { id: 10, categorie: "3", nume: "Autobuz special" },
    { id: 8, categorie: "3", nume: "Autocar" },
    { id: 6, categorie: "3", nume: "Microbuz" },
    { id: 11, categorie: "3", nume: "Tramvai" },
    { id: 12, categorie: "3", nume: "Troleibuz" },
    { id: 16, categorie: "4", nume: "Autobasculanta" },
    { id: 23, categorie: "4", nume: "Autobetoniera" },
    { id: 19, categorie: "4", nume: "Autocamion" },
    { id: 24, categorie: "4", nume: "Autocisterna" },
    { id: 14, categorie: "4", nume: "Autofurgon" },
    { id: 22, categorie: "4", nume: "Autoexcavator" },
    { id: 25, categorie: "4", nume: "Autogreder" },
    { id: 26, categorie: "4", nume: "Autoincarcator" },
    { id: 27, categorie: "4", nume: "Automacara" },
    { id: 20, categorie: "4", nume: "Autospeciala" },
    { id: 21, categorie: "4", nume: "Autospecializata" },
    { id: 13, categorie: "4", nume: "Autoutilitara" },
    { id: 17, categorie: "4", nume: "Basculanta" },
    { id: 28, categorie: "4", nume: "Buldoexcavator" },
    { id: 18, categorie: "4", nume: "Camion" },
    { id: 29, categorie: "5", nume: "Autotractor" },
    { id: 15, categorie: "4", nume: "Furgon" },
    { id: 30, categorie: "5", nume: "Autotractor cu sa" },
    { id: 31, categorie: "5", nume: "Cap tractor" },
    { id: 32, categorie: "6", nume: "Tractor rutier" },
    { id: 41, categorie: "6", nume: "Tractor agricol" },
    { id: 36, categorie: "7", nume: "ATV" },
    { id: 34, categorie: "7", nume: "Moped" },
    { id: 35, categorie: "7", nume: "Scuter" },
    { id: 33, categorie: "7", nume: "Motocicleta" },
    { id: 37, categorie: "8", nume: "Remorca" },
    { id: 39, categorie: "8", nume: "Rulota" },
    { id: 38, categorie: "8", nume: "Semiremorca" },
    { id: 40, categorie: "9", nume: "Utilaje" },
  ]);

  const [showPopupTC, setShowPopupTC] = useState(false);

  useEffect(() => {
    if (formData.stare_imatriculare === "in vederea inmatricularii") {
      setShowPopupTC(true);
    }
  }, [formData.stare_imatriculare]);

  const [orase, setOrase] = useState([]);

  async function handleJudetChange(event) {
    const judet = event.target.value;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/listOrase?judet=${judet}`,
        {
          headers: {
            "API-Key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      setFormData({
        ...formData,
        judetLeasing: judet,
        orasLeasing: data[0].oras,
      });
      setOrase(data);
    } catch (error) {
      navigate("/eroare");
      //console.error(error);
    }
  }

  function handleAnFabricatieChange(event) {
    const selectedText = event.target.options[event.target.selectedIndex].text;

    setFormData({
      ...formData,
      an_fabricatie: selectedText,
    });
  }

  function utilizare(event) {
    const selectedText = event.target.options[event.target.selectedIndex].value;

    setFormData({
      ...formData,
      tip_utilizare: selectedText,
    });
  }
  function inmatriculare(event) {
    const selectedText = event.target.options[event.target.selectedIndex].value;

    if (selectedText === "in vederea inmatricularii") {
      setFormData({
        ...formData,
        numar_inmatriculare: "",
        stare_imatriculare: selectedText,
      });
    } else {
      setFormData({
        ...formData,
        stare_imatriculare: selectedText,
      });
    }
  }

  function categorieAuto(event) {
    const selectedText = event.target.value;

    const subcategory = subcategorii.find(
      (item) => item.categorie === selectedText
    );
    setFormData({
      ...formData,
      mapare_categori: selectedText,
      mapare_categorii: subcategory.id,
    });
  }

  function subcategorieAuto(event) {
    const selectedText = event.target.value;

    setFormData({
      ...formData,
      mapare_categorii: selectedText,
    });
  }

  const [check, setCheck] = useState({
    stare_imatriculare: false,
    numar_inmatriculare: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setFormData({ ...formData, tarif: "false" });

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/listOrase?judet=${formData.judetLeasing}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (formData.orasLeasing === "") {
          setFormData({ ...formData, orasLeasing: data[0].oras });
        }
        setOrase(data);
      } catch (error) {
        //console.error(error);
        navigate("/eroare");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const loadPost = async () => {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/identificareVehicul?serie_sasiu=${formData.serie_sasiu}`,
        {
          headers: {
            "API-Key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      setPosts(data);

      const subcategory = subcategorii.find(
        (item) => item.categorie === data.mapare_categori
      );

      if (data.error === 0) {
        setFormData({
          ...formData,
          an_fabricatie: data.an_fabricatie,
          marca: data.marca,
          model: data.model,
          masa: data.masa,
          nr_locuri: data.nr_locuri,
          putere: data.putere,
          capacitate_cilindrica: data.capacitate_cilindrica,
          mapare_categori: data.mapare_categori,
          mapare_categorii: subcategory.id,
        });
      }
      setLoading(false);
    };

    // Call the function
    if (formData.serie_sasiu.length === 17) {
      //loadPost();
      loadPost();
      setInSasiu(true);
    }
  }, [formData.serie_sasiu]);

  const [showPopup, setShowPopup] = useState(false);

  function handleButtonClick() {
    setShowPopup(true);
  }

  return (
    <div>
      <Row className="sectionRCA_buy__form1">
        {formData.stare_imatriculare !== "in vederea inmatricularii" && (
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.numar_inmatriculare.length < 2 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.request11")} <br />
              <input
                type="text"
                name="name"
                value={formData.numar_inmatriculare}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    numar_inmatriculare: event.target.value.toUpperCase(),
                  })
                }
              />
            </label>
          </Col>
        )}
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.serie_sasiu.length < 17 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request12")} <br />
            <input
              type="text"
              name="name"
              value={formData.serie_sasiu}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  serie_sasiu: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy">
            {t("rca.request14")}
            <br />
            <select
              //defaultValue={formData.stare_imatriculare}
              value={formData.stare_imatriculare}
              onChange={inmatriculare}
            >
              <option value="inmatriculat">
                {t("rca.inmatriculare.inmatriculat")}
              </option>
              <option value="inregistrat">
                {t("rca.inmatriculare.inregistrat")}
              </option>
              <option value="in vederea inmatricularii">
                {t("rca.inmatriculare.in vederea inmatricularii")}
              </option>
              <option value="in vederea inregistrarii">
                {t("rca.inmatriculare.in vederea inregistrarii")}
              </option>
            </select>
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form1">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy">
            {t("rca.request13")}
            <br />
            <select value={formData.tip_utilizare} onChange={utilizare}>
              <option value="personal">{t("rca.utilizare.personal")}</option>
              <option value="transport international">
                {t("rca.utilizare.transport_international")}
              </option>
              <option value="transport persoane">
                {t("rca.utilizare.transport_persoane")}
              </option>
              <option value="taxi">{t("rca.utilizare.taxi")}</option>
              <option value="inchiriere">
                {t("rca.utilizare.inchiriere")}
              </option>
              <option value="scoala de soferi">
                {t("rca.utilizare.scoala_de_soferi")}
              </option>
              <option value="paza si protectie">
                {t("rca.utilizare.paza_si_protectie")}
              </option>
              <option value="curierat">{t("rca.utilizare.curierat")}</option>
              <option value="institutie_publica">
                {t("rca.utilizare.institutie_publica")}
              </option>
              <option value="constructii">
                {t("rca.utilizare.constructii")}
              </option>
              <option value="distributie">
                {t("rca.utilizare.distributie")}
              </option>
              <option value="masina de serviciu">
                {t("rca.utilizare.masina_de_serviciu")}
              </option>
              <option value="transport produse farmaceutice">
                {t("rca.utilizare.transport_produse_farmaceutice")}
              </option>
            </select>
          </label>
        </Col>
        <Col xl="6" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <div className="sub_title_rca_FizicJuridic">
            <Button
              className={
                formData.fizicJuridic === false
                  ? "sub_title_rca_select_active dimentionFJ"
                  : "sub_title_rca_select dimentionFJ"
              }
              onClick={(e) =>
                setFormData({
                  ...formData,
                  fizicJuridic: false,
                })
              }
            >
              {t("rca.request15")}
            </Button>
            <Button
              className={
                formData.fizicJuridic === true
                  ? "sub_title_rca_select_active dimentionFJ"
                  : "sub_title_rca_select dimentionFJ"
              }
              onClick={(e) =>
                setFormData({
                  ...formData,
                  fizicJuridic: true,
                })
              }
            >
              {t("rca.request16")}
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form1">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy_blue">
            {t("rca.request17")}
            <br />
            <select value={formData.mapare_categori} onChange={categorieAuto}>
              <option value="1">Autoturism / Automobil mixt</option>
              <option value="2">Autosanitara / Autorulota</option>
              <option value="3">Autovehicul transport persoane</option>
              <option value="4">Autovehicul transport marfa</option>
              <option value="5">Autotractor</option>
              <option value="6">Tractor rutier</option>
              <option value="7">Motocicleta / Moped / Atas / ATV</option>
              <option value="8">Remorca / Semiremorca / Rulota</option>
              <option value="9">Utilaje</option>
            </select>
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy">
            {t("rca.request18")}
            <br />
            <select onChange={subcategorieAuto}>
              {subcategorii
                .filter(
                  (subcategorie) =>
                    subcategorie.categorie === formData.mapare_categori
                )
                .map((subcategorie) => (
                  <option key={subcategorie.id} value={subcategorie.id}>
                    {subcategorie.nume}
                  </option>
                ))}
            </select>
          </label>
        </Col>
      </Row>

      <div className="sub_titleForm_rca_buy">
        <p>{t("rca.subtitle1")}</p>
      </div>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.serie_carte_auto.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy_gri"
            }
          >
            {t("rca.request19")} <br />
            <input
              type="text"
              name="name"
              value={formData.serie_carte_auto}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  serie_carte_auto: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="4" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy">{t("rca.request110")}</label>
          <div>
            <Button
              key={1}
              className={
                formData.companie_leasing === true
                  ? "sub_title_rca_select_active dimentionDN"
                  : "sub_title_rca_select dimentionDN"
              }
              //onClick={(e) => setleasing_rcaValue1(0)}
              onClick={(event) =>
                setFormData({ ...formData, companie_leasing: true })
              }
            >
              {t("rca.request122")}
            </Button>
            <Button
              key={2}
              className={
                formData.companie_leasing === false
                  ? "sub_title_rca_select_active dimentionDN"
                  : "sub_title_rca_select dimentionDN"
              }
              // onClick={(e) => setleasing_rcaValue1(1)}
              onClick={(event) =>
                setFormData({ ...formData, companie_leasing: false })
              }
            >
              {t("rca.request123")}
            </Button>
          </div>
        </Col>
        <Col xl="3" lg="3" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.companie_leasing === true
                ? "sub_title_rca_buy"
                : "non_display"
            }
          >
            {t("rca.request125")}
            <select
              defaultValue={"0"}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  alegeLeasing:
                    event.target.options[event.target.selectedIndex].text,
                })
              }
            >
              <option value="0">Alta</option>
            </select>
          </label>
        </Col>
      </Row>
      <Row
        className={
          formData.companie_leasing === true && formData.alegeLeasing === "Alta"
            ? "sectionRCA_buy__form11"
            : "non_display"
        }
      >
        <Col
          xl="3"
          lg="6"
          md="6"
          sm="12"
          className={
            formData.companie_leasing === true &&
            formData.alegeLeasing === "Alta"
              ? "sectionRCA_buy__left"
              : "non_display"
          }
        >
          <label
            className={
              formData.cuiLeasing.length < 2 &&
              buttonNext === true &&
              formData.companie_leasing === true &&
              formData.alegeLeasing === "Alta"
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request126")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.cuiLeasing}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  cuiLeasing: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col
          xl="3"
          lg="6"
          md="6"
          sm="12"
          className={
            formData.companie_leasing === true &&
            formData.alegeLeasing === "Alta"
              ? "sectionRCA_buy__left"
              : "non_display"
          }
        >
          <label
            className={
              formData.numeLeasing.length < 2 &&
              buttonNext === true &&
              formData.companie_leasing === true &&
              formData.alegeLeasing === "Alta"
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request127")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.numeLeasing}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  numeLeasing: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
      </Row>
      <Row
        className={
          formData.companie_leasing === true && formData.alegeLeasing === "Alta"
            ? "sectionRCA_buy__form11"
            : "non_display"
        }
      >
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.orasLeasing.length < 2 &&
              buttonNext === true &&
              formData.companie_leasing === true &&
              formData.alegeLeasing === "Alta"
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request128")}
            <br />
            <select
              value={formData.orasLeasing}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  orasLeasing:
                    event.target.options[event.target.selectedIndex].text,
                })
              }
            >
              {orase.map((oras, index) => (
                <option key={index} value={oras.oras}>
                  {oras.oras}
                </option>
              ))}
            </select>
          </label>
        </Col>
        <Col
          xl="3"
          lg="6"
          md="6"
          sm="12"
          className={
            formData.companie_leasing === true &&
            formData.alegeLeasing === "Alta"
              ? "sectionRCA_buy__left"
              : "non_display"
          }
        >
          <label
            className={
              formData.judetLeasing.length < 2 &&
              buttonNext === true &&
              formData.companie_leasing === true &&
              formData.alegeLeasing === "Alta"
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request129")}
            <br />
            <select value={formData.judetLeasing} onChange={handleJudetChange}>
              {judete.map((judet, index) => (
                <option key={index} value={judet.judet}>
                  {judet.judet}
                </option>
              ))}
            </select>
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy_blue">
            {t("rca.request111")}
            <br />
            <select
              value={formData.marca}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  marca: event.target.options[event.target.selectedIndex].text,
                })
              }
            >
              {marci.map((marca) => (
                <option key={marca.marca} value={marca.marca}>
                  {marca.marca}
                </option>
              ))}
            </select>
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.model.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request112")}
            <br />
            <input
              type="text"
              name="name"
              value={formData.model}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  model: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy_blue">
            {t("rca.request113")}
            <br />
            <select
              value={formData.an_fabricatie}
              onChange={handleAnFabricatieChange}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.combustibil.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request114")}
            <br />
            <ListCombustibil formData={formData} setFormData={setFormData} />
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.capacitate_cilindrica.length < 1 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request115")}
            <br />
            <input
              type="text"
              name="name"
              pattern="[0-9]*"
              inputMode="numeric"
              value={formData.capacitate_cilindrica}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  capacitate_cilindrica: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.putere.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request116")}
            <br />
            <input
              type="text"
              name="name"
              pattern="[0-9]*"
              inputMode="numeric"
              value={formData.putere}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  putere: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.masa.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request117")}
            <br />
            <input
              type="text"
              name="name"
              pattern="[0-9]*"
              inputMode="numeric"
              value={formData.masa}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  masa: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.nr_locuri.length < 1 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy"
            }
          >
            {t("rca.request118")} <br />
            <input
              type="text"
              name="name"
              pattern="[0-9]*"
              inputMode="numeric"
              value={formData.nr_locuri}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  nr_locuri: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
      </Row>
      <Row className="sectionRCA_buy__form11">
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.masa.length < 2 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy_gri"
            }
          >
            {t("rca.request119")}
            <br />
            <input
              type="text"
              name="name"
              pattern="[0-9]*"
              inputMode="numeric"
              value={formData.numar_km}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  numar_km: event.target.value,
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label
            className={
              formData.nr_locuri.length < 1 && buttonNext === true
                ? "sub_title_rca_buy_red"
                : "sub_title_rca_buy_gri"
            }
          >
            {t("rca.request120")} <br />
            <DatePicker
              selected={new Date(formData.data_inmatriculare)}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={20}
              scrollableYearDropdown
              onChange={(data) =>
                setFormData({
                  ...formData,
                  data_inmatriculare: data.toISOString().split("T")[0],
                })
              }
            />
          </label>
        </Col>
      </Row>
      <Row className="sectionRCA_buy__form11">
        {formData.fizicJuridic === false ? (
          <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
            <label
              className={
                formData.nr_locuri.length < 1 && buttonNext === true
                  ? "sub_title_rca_buy_red"
                  : "sub_title_rca_buy_gri"
              }
            >
              {t("rca.request121")} <br />
              <DatePicker
                selected={new Date(formData.data_permis_conducere)}
                dateFormat="MM-yyyy"
                showMonthYearPicker
                onChange={(data) => {
                  setFormData({
                    ...formData,
                    data_permis_conducere: data.toISOString().split("T")[0],
                  });
                }}
              />
            </label>
          </Col>
        ) : null}
        <Col xl="3" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy_gri">
            {t("rca.request1211")} <br />
            <DatePicker
              selected={new Date(formData.data_expirare_itp)}
              //value={new Date(formData.data_expirare_itp)}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={20}
              scrollableYearDropdown
              onChange={(data) =>
                setFormData({
                  ...formData,
                  data_expirare_itp: data.toISOString().split("T")[0],
                })
              }
            />
          </label>
        </Col>
      </Row>

      <Row className="sectionRCA_buy__form11">
        <Col xl="6" lg="6" md="6" sm="12" className="sectionRCA_buy__left">
          <label className="sub_title_rca_buy">{t("rca.request124")}</label>
          <div>
            <Button
              key={1}
              className="sub_title_rca_select dimentionDN"
              //onClick={(e) => setleasing_rcaValue1(0)}
              // onClick={(event) => alert("FORM SUBMITTED1")}
              onClick={handleButtonClick}
            >
              {t("rca.request122")}
            </Button>
            <Button
              key={2}
              className="sub_title_rca_select_active dimentionDN"
              // onClick={(e) => setleasing_rcaValue1(1)}
            >
              {t("rca.request123")}
            </Button>
          </div>
        </Col>
      </Row>
      {showPopup && (
        <div className="popup-wrapper">
          <div className="popup-overlay" onClick={() => setShowPopup(false)} />
          <div className="popup-container">
            <p className="popup-text">
              {t("rca.pop-up1.text1")}
              <a href="/page-contact" target="_blank">
                {" "}
                {t("rca.pop-up1.form")}{" "}
              </a>
              {t("rca.pop-up1.text2")}{" "}
              <div className="image-button">
                <img
                  src={Whatsapp_btn}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "170px",
                    height: "auto",
                  }}
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                />

                <div
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "15px",
                    marginTop: "7px",
                  }}
                >
                  {t("header.menu5")}
                  <a href="https://wa.me/40724542277" target="_blank">
                    <svg
                      className="whats"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
                        fill="rgba(0,0,0,1)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {t("rca.pop-up1.text3")}
            </p>

            <button
              onClick={() => setShowPopup(false)}
              className="popup-button"
            >
              {t("rca.pop-up1.button2")}
            </button>
            <div className="popup-logo">
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>
      )}
      {showPopupTC && (
        <div className="popup-wrapper">
          <div className="popup-overlay" />
          <div className="popup-container">
            <p className="popup-text">
              Declar ca am inregistrat contractul vanzare-cumparare la ANAF
            </p>
            <button
              onClick={() => {
                setShowPopupTC(false);
                setFormData({
                  ...formData,
                  stare_imatriculare: "in vederea inmatricularii",
                  termeniConditiiMatriculat: true,
                });
              }}
              className="popup-button"
            >
              DA
            </button>
            <button
              onClick={() => {
                setShowPopupTC(false);
                setFormData({
                  ...formData,
                  stare_imatriculare: "inmatriculat",
                  termeniConditiiMatriculat: false,
                });
              }}
              className="popup-button"
            >
              NU
            </button>
            <div className="popup-logo">
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Autoturism;
