import React, { useState, useEffect } from "react";

import { useLanguage } from "../utils/utils.jsx";

function Telefon({ formPers, setFormPers }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="nameTitle">
        {t("typeForm.telefon.title")} {formPers.name},
      </p>
      <p className="nameTitle2">{t("typeForm.telefon.subtitle1")}</p>
      <spam className="namesubTitle">{t("typeForm.telefon.subtitle2")}</spam>
      <spam className="namesubTitle" style={{ marginBottom: "20px" }}>
        {t("typeForm.telefon.subtitle3")}
      </spam>
      <input
        type="tel"
        name="tel"
        value={formPers.telefon}
        onChange={(event) =>
          setFormPers({
            ...formPers,
            telefon: event.target.value,
          })
        }
        placeholder="07xx xxx xxx"
        className="snfluaNameInput"
      />
    </div>
  );
}

export default Telefon;
