import React, { useState, useEffect } from "react";

import luna from "../../assests/images/SenfluaForm/luna.svg";
import treiAn from "../../assests/images/SenfluaForm/treian.svg";
import unuAn from "../../assests/images/SenfluaForm/unuan.svg";

import { useLanguage } from "../utils/utils.jsx";

function FrecventaCalatorie({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ marginTop: "20px" }}>
        #senflua.LIFE
      </p>
      <p className="nameTitle2">{t("typeForm.frecventaCalatorie.title")}</p>
      <p className="namesubTitle">
        {t("typeForm.frecventaCalatorie.subtitle")}
      </p>
      <div>
        <label
          className={
            formAig.senfluaLife.luna1
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                luna1: !formAig.senfluaLife.luna1,
              },
            }));
          }}
          style={{ width: "156px", height: "98px" }}
        >
          <img src={luna} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.frecventaCalatorie.v1")}
        </label>

        <label
          className={
            formAig.senfluaLife.luna3
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                luna3: !formAig.senfluaLife.luna3,
              },
            }));
          }}
          style={{ width: "150px", height: "101px" }}
        >
          <img src={treiAn} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.frecventaCalatorie.v2")}
        </label>

        <label
          className={
            formAig.senfluaLife.an
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                an: !formAig.senfluaLife.an,
              },
            }));
          }}
          style={{ width: "142px", height: "101px" }}
        >
          <img src={unuAn} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.frecventaCalatorie.v3")}
        </label>
      </div>
    </div>
  );
}

export default FrecventaCalatorie;
