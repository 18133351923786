import React from "react";

import profesional from "../../assests/images/SenfluaForm/profesional.svg";
import personal from "../../assests/images/SenfluaForm/personal.svg";

import { useLanguage } from "../utils/utils.jsx";

function Calatorie({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ marginTop: "20px" }}>
        #senflua.LIFE
      </p>
      <p className="nameTitle">{t("typeForm.calatorie.title")}</p>
      <p className="namesubTitle">{t("typeForm.calatorie.subtitle")}</p>
      <div>
        <label
          className={
            formAig.senfluaLife.profesional
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                profesional: !formAig.senfluaLife.profesional,
              },
            }));
          }}
          style={{ width: "116px", height: "101px" }}
        >
          <img src={profesional} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.calatorie.v1")}
        </label>

        <label
          className={
            formAig.senfluaLife.personal
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                personal: !formAig.senfluaLife.personal,
              },
            }));
          }}
          style={{ width: "94px", height: "101px" }}
        >
          <img src={personal} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.calatorie.v2")}
        </label>
      </div>
    </div>
  );
}

export default Calatorie;
