import React from "react";

import urban from "../../assests/images/SenfluaForm/urban.svg";
import scuter from "../../assests/images/SenfluaForm/scuter.svg";
import adv from "../../assests/images/SenfluaForm/adv.svg";
import chopper from "../../assests/images/SenfluaForm/chopper.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipMoto({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.tipMoto.title")}</p>
      <spam className="namesubTitle">{t("typeForm.tipMoto.subtitle")}</spam>
      <div>
        <label
          className={
            formAig.senfluaThinhs.urban
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                urban: !formAig.senfluaThinhs.urban,
              },
            }));
          }}
          style={{ width: "119px", height: "101px" }}
        >
          <img src={urban} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipMoto.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.scuter
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                scuter: !formAig.senfluaThinhs.scuter,
              },
            }));
          }}
          style={{ width: "98px", height: "101px" }}
        >
          <img src={scuter} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipMoto.v2")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.adv
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                adv: !formAig.senfluaThinhs.adv,
              },
            }));
          }}
          style={{ width: "148px", height: "101px" }}
        >
          <img src={adv} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipMoto.v3")}
        </label>
        <label
          className={
            formAig.senfluaThinhs.chopper
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                chopper: !formAig.senfluaThinhs.chopper,
              },
            }));
          }}
          style={{ width: "138px", height: "101px" }}
        >
          <img src={chopper} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipMoto.v4")}
        </label>
      </div>
    </div>
  );
}

export default TipMoto;
