import React from "react";

import building from "../../assests/images/SenfluaForm/building.svg";
import logisticsDelivery from "../../assests/images/SenfluaForm/logistics-delivery.svg";
import lifeInsurance from "../../assests/images/SenfluaForm/life-insurance.svg";
import car from "../../assests/images/SenfluaForm/car.svg";
import selfEmployed from "../../assests/images/SenfluaForm/self-employed.svg";
import business from "../../assests/images/SenfluaForm/business.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipAsigurareBUSINESS({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#FE9D35" }}
      >
        #SENFLUA.BUSINESS
      </p>
      <p className="nameTitle2">{t("typeForm.tipAsigurareBUSINESS.title")}</p>

      <spam className="namesubTitle">
        {t("typeForm.tipAsigurareBUSINESS.subtitle1")}
      </spam>
      <spam className="namesubTitle">
        {t("typeForm.tipAsigurareBUSINESS.subtitle2")}
      </spam>
      <div>
        <label
          className={
            formAig.senfluaBusiness.cladire
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaBusiness: {
                ...prevState.senfluaBusiness,
                cladire: !formAig.senfluaBusiness.cladire,
              },
            }));
          }}
          style={{ width: "163px", height: "101px" }}
        >
          <img src={building} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareBUSINESS.v1")}
        </label>

        <label
          className={
            formAig.senfluaBusiness.auto
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaBusiness: {
                ...prevState.senfluaBusiness,
                auto: !formAig.senfluaBusiness.auto,
              },
            }));
          }}
          style={{ width: "110px", height: "101px" }}
        >
          <img src={car} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareBUSINESS.v2")}
        </label>

        <label
          className={
            formAig.senfluaBusiness.cmr
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaBusiness: {
                ...prevState.senfluaBusiness,
                cmr: !formAig.senfluaBusiness.cmr,
              },
            }));
          }}
          style={{ width: "150px", height: "101px" }}
        >
          <img src={logisticsDelivery} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareBUSINESS.v3")}
        </label>
        <label
          className={
            formAig.senfluaBusiness.malpraxis
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaBusiness: {
                ...prevState.senfluaBusiness,
                malpraxis: !formAig.senfluaBusiness.malpraxis,
              },
            }));
          }}
          style={{ width: "164px", height: "134px" }}
        >
          <img src={lifeInsurance} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareBUSINESS.v4")}
        </label>
      </div>
      <div className="SectionBUSINESS">
        <label
          className={
            formAig.senfluaBusiness.sanatate
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaBusiness: {
                ...prevState.senfluaBusiness,
                sanatate: !formAig.senfluaBusiness.sanatate,
              },
            }));
          }}
          style={{ width: "166px", height: "101px" }}
        >
          <img src={selfEmployed} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareBUSINESS.v5")}
        </label>
        <label
          className={
            formAig.senfluaBusiness.alte
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaBusiness: {
                ...prevState.senfluaBusiness,
                alte: !formAig.senfluaBusiness.alte,
              },
            }));
          }}
          style={{ width: "116px", height: "101px" }}
        >
          <img src={business} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareBUSINESS.v6")}
        </label>
      </div>
    </div>
  );
}

export default TipAsigurareBUSINESS;
