import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import Mail from "../../../assests/images/RCA/Mail.svg";
import Phone from "../../../assests/images/RCA/Phone.svg";

import rca_3 from "../../../assests/images/RCA/rca_3.svg";
import hello from "../../../assests/images/Hello.svg";
import Fa from "../../../assests/images/share_footer/fa_footer.svg";
import Insta from "../../../assests/images/share_footer/insta_footer.svg";
import In from "../../../assests/images/share_footer/in_footer.svg";

import "./ConfirmareMesaj.css";

import { useTranslation } from "react-i18next";
import "../../../i18n";

const ConfirmareMesaj = ({ title }) => {
  useEffect(() => {
    document.title = "Senflua - Confirmare " + title;
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <section className="section_confirmareMesaj">
      <Container className="containerConfirmareMesaj__form">
        <div className={"confirmareMesaj_title"}>
          <img src={hello} alt="" className="" />
          <h3>{t("contact.confirmare.title")}</h3>
          <p>{t("contact.confirmare.subtitle1")}</p>
        </div>

        <div className={"confirmareMesaj_subtitle"}>
          <p>{t("contact.confirmare.subtitle2")}</p>
        </div>
        <div className="onfirmareMesaj_SM">
          <a href="https://www.instagram.com/senflua/" target="_blank">
            <img src={Insta} alt="" />
          </a>
          <a href="https://www.facebook.com/Senflua" target="_blank">
            <img src={Fa} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/senflua-asigurari/"
            target="_blank"
          >
            <img src={In} alt="" />
          </a>
        </div>
      </Container>

      <Row className="confirmareMesaj_TC">
        <Col xl="6" lg="6" md="6" sm="6" className="confirmareMesaj_TC1">
          <a href="/politica-confidentialitate">
            {t("contact.confirmare.check11")}
          </a>
        </Col>

        <Col xl="6" lg="6" md="6" sm="6" className="confirmareMesaj_TC2">
          <a href="/termeni-conditii">{t("contact.confirmare.check21")}</a>
        </Col>
      </Row>
      <div className="confirmareMesaj_info">
        <p>
          Senflua Insurance SRL este o societate inregistrata si autorizata de
          Autoritatea de Supraveghere Financiara sub RAJ 537472 cu sediul in
          Bucuresti cu numarul de ordina in registrul comertului
          J40/13827/19.07.2022, CUI 46500691
        </p>
      </div>
    </section>
  );
};

export default ConfirmareMesaj;
