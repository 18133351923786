import React, { Fragment } from "react";
import HeaderRCA from "../HeaderRCA/HeaderRCA";
import FooterRCA from "../FooterRCA/FooterRCA";
import LP_RCA from "../LP_RCA/LP_RCA";
import TabBar from "../TabBar/TabBar";

const RCA_LandingPage = () => {
  return (
    <Fragment>
      <TabBar />
      <HeaderRCA />
      <LP_RCA />
      <FooterRCA />
    </Fragment>
  );
};

export default RCA_LandingPage;
