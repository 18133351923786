import React, { Fragment } from "react";
import HeaderTravel from "../HeaderTravel/HeaderTravel";
import FooterTravel from "../FooterTravel/FooterTravel";
import Confirmare_Eurolife from "../Confirmare_Eurolife/Confirmare_Eurolife.jsx";

const ConfirmareEurolife = () => {
  return (
    <Fragment>
      <HeaderTravel />
      <Confirmare_Eurolife />
      <FooterTravel />
    </Fragment>
  );
};

export default ConfirmareEurolife;
