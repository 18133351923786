import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

import "./SenfluaForm.css";
import Name from "./Name";
import Telefon from "./Telefon";
import Email from "./Email";
//import SelectAsig from "./SelectAsig";
import TipAsigurare from "./TipAsigurare";

import TipAsigurareLife from "./TipAsigurareLife";
import PeCineAsiguri from "./PeCineAsiguri";
import TipAsigurareLifePentru from "./TipAsigurareLifePentru";
import TipAsigurareLifeDetalii from "./TipAsigurareLifeDetalii";

import AltProdus from "./AltProdus";

import Redirectionare from "./Redirectionare";
import Calatorie from "./Calatorie";
import FrecventaCalatorie from "./FrecventaCalatorie";
import UndeCalatorie from "./UndeCalatorie";

import TipAsigurareThings from "./TipAsigurareThings";
import CasaThings from "./CasaThings";
import TipCasa from "./TipCasa";
import FricaCasa from "./FricaCasa";

import RedirectionareRCA from "./RedirectionareRCA";
import TipAsigurareMasina from "./TipAsigurareMasina";
import TipMasina from "./TipMasina";
import KmMasina from "./KmMasina";

import TipMoto from "./TipMoto";

import TipAlte from "./TipAlte";
import StareObiect from "./StareObiect";

import TipAsigurareBUSINESS from "./TipAsigurareBUSINESS";
import Contactare from "./Contactare";
import CanalComunicare from "./CanalComunicare";

import teleportareaeInviitor from "../../assests/images/teleportareaeInviitor.svg";
import contacteeazaSenflua from "../../assests/images/contacteeazaSenflua.svg";

import SageataUp from "../../assests/images/SenfluaForm/sageata_up.svg";

import next from "../../assests/images/SenfluaForm/grup-mai-departe_btn.png";

import { useLanguage } from "../utils/utils.jsx";

const SenfluaForm = () => {
  useEffect(() => {
    document.title = "Senflua - Formular";
  }, []);

  const { t, i18n } = useLanguage();

  const [page, setPage] = useState(0);

  const [checkbox, setCheckbox] = useState({
    checkbox1: false,
    checkbox2: false,
  });

  const [formPers, setFormPers] = useState({
    name: "",
    telefon: "",
    email: "",
    orar: {
      dimineata: false,
      dupaMasa: false,
      seara: false,
    },
    contact: {
      apel: false,
      email: false,
      whatsapp: false,
      cod: "",
    },
  });

  const [formAig, setFormAig] = useState({
    asigurare: 0,
    senfluaLife: {
      viataCalatorie: 0,
      pemine: false,
      pertener: false,
      copil: false,
      protectiafamilie: false,
      studii: false,
      calatorit: false,
      altproiect: false,
      economie: false,
      investitii: false,
      protectieFinanciara: false,
      acoperireMedicala: false,
      profesional: false,
      personal: false,
      luna1: false,
      luna3: false,
      an: false,
      romania: false,
      europa: false,
      alteRegiuni: false,
    },
    senfluaThinhs: {
      asig: 0,
      casa: false,
      apartament: false,
      resedinta: false,
      munca: false,
      resedintaSecundara: false,
      inundatie: false,
      furst: false,
      catastrofe: false,
      rca: false,
      casco: false,
      gep: false,
      autoturism: false,
      hybrid: false,
      putin8000: false,
      maimult8000: false,
      maimult15000: false,
      urban: false,
      scuter: false,
      adv: false,
      chopper: false,
      alteAsig: false,
      telefon: false,
      laptop: false,
      bicicleta: false,
      altele: false,
      nou: false,
      utilizat: false,
      degradat: false,
    },
    senfluaBusiness: {
      cladire: false,
      auto: false,
      cmr: false,
      malpraxis: false,
      sanatate: false,
      alte: false,
    },
  });

  const [formAigurari, setFormAigurari] = useState([]);

  const sectiuni = [
    <Name
      formPers={formPers}
      setFormPers={setFormPers}
      checkbox={checkbox}
      setCheckbox={setCheckbox}
    />,
    <Telefon formPers={formPers} setFormPers={setFormPers} />,
    <Email formPers={formPers} setFormPers={setFormPers} />,
    <TipAsigurare
      formAig={formAig}
      setFormAig={setFormAig}
      page={page}
      setPage={setPage}
    />,
    <Contactare formPers={formPers} setFormPers={setFormPers} />,
    <CanalComunicare formPers={formPers} setFormPers={setFormPers} />,
  ];
  const sectiuniLifeViata = [
    <TipAsigurareLife
      formAig={formAig}
      setFormAig={setFormAig}
      page={page}
      setPage={setPage}
    />,
    <PeCineAsiguri formAig={formAig} setFormAig={setFormAig} />,
    <TipAsigurareLifePentru formAig={formAig} setFormAig={setFormAig} />,
    <TipAsigurareLifeDetalii formAig={formAig} setFormAig={setFormAig} />,
    <AltProdus
      page={page}
      setPage={setPage}
      formAigurari={formAigurari}
      setFormAigurari={setFormAigurari}
      formAig={formAig}
      setFormAig={setFormAig}
    />,
  ];
  const sectiuniLifeCalatorie = [
    <Redirectionare page={page} setPage={setPage} />,
    <Calatorie formAig={formAig} setFormAig={setFormAig} />,
    <FrecventaCalatorie formAig={formAig} setFormAig={setFormAig} />,
    <UndeCalatorie formAig={formAig} setFormAig={setFormAig} />,
    <AltProdus
      page={page}
      setPage={setPage}
      formAigurari={formAigurari}
      setFormAigurari={setFormAigurari}
      formAig={formAig}
      setFormAig={setFormAig}
    />,
  ];
  const sectiuniThingsCasa = [
    <TipAsigurareThings
      formAig={formAig}
      setFormAig={setFormAig}
      page={page}
      setPage={setPage}
    />,
    <CasaThings formAig={formAig} setFormAig={setFormAig} />,
    <TipCasa formAig={formAig} setFormAig={setFormAig} />,
    <FricaCasa formAig={formAig} setFormAig={setFormAig} />,
    <AltProdus
      page={page}
      setPage={setPage}
      formAigurari={formAigurari}
      setFormAigurari={setFormAigurari}
      formAig={formAig}
      setFormAig={setFormAig}
    />,
  ];
  const sectiuniThingsMasina = [
    <TipAsigurareThings
      formAig={formAig}
      setFormAig={setFormAig}
      page={page}
      setPage={setPage}
    />,
    <TipAsigurareMasina formAig={formAig} setFormAig={setFormAig} />,
    <RedirectionareRCA page={page} setPage={setPage} />,
    <TipMasina formAig={formAig} setFormAig={setFormAig} />,
    <KmMasina formAig={formAig} setFormAig={setFormAig} />,
    <AltProdus
      page={page}
      setPage={setPage}
      formAigurari={formAigurari}
      setFormAigurari={setFormAigurari}
      formAig={formAig}
      setFormAig={setFormAig}
    />,
  ];
  const sectiuniThingsMoto = [
    <TipAsigurareThings
      formAig={formAig}
      setFormAig={setFormAig}
      page={page}
      setPage={setPage}
    />,
    <TipAsigurareMasina />,
    <RedirectionareRCA page={page} setPage={setPage} />,
    <TipMoto formAig={formAig} setFormAig={setFormAig} />,
    <KmMasina formAig={formAig} setFormAig={setFormAig} />,
    <AltProdus
      page={page}
      setPage={setPage}
      formAigurari={formAigurari}
      setFormAigurari={setFormAigurari}
      formAig={formAig}
      setFormAig={setFormAig}
    />,
  ];
  const sectiuniThingsAlte = [
    <TipAsigurareThings
      formAig={formAig}
      setFormAig={setFormAig}
      page={page}
      setPage={setPage}
    />,
    <TipAlte formAig={formAig} setFormAig={setFormAig} />,
    <StareObiect formAig={formAig} setFormAig={setFormAig} />,
    <AltProdus
      page={page}
      setPage={setPage}
      formAigurari={formAigurari}
      setFormAigurari={setFormAigurari}
      formAig={formAig}
      setFormAig={setFormAig}
    />,
  ];

  const sectiuniBusiness = [
    <TipAsigurareThings
      formAig={formAig}
      setFormAig={setFormAig}
      page={page}
      setPage={setPage}
    />,
    <TipAsigurareBUSINESS formAig={formAig} setFormAig={setFormAig} />,
    <AltProdus
      page={page}
      setPage={setPage}
      formAigurari={formAigurari}
      setFormAigurari={setFormAigurari}
      formAig={formAig}
      setFormAig={setFormAig}
    />,
  ];

  const handleBackClick = () => {
    if (page === 11) setPage(7);
    else if (page === 21) setPage(16);
    else if (page === 26) setPage(16);
    else if (page === 34) setPage(16);
    else if (page === 6 || page === 16 || page === 37) setPage(3);
    else setPage((currPage) => currPage - 1);
  };

  const navigate = useNavigate();

  const submit = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formPers: formPers,
        asigurare: formAigurari.map((formAig) => ({
          asigurare: formAig.asigurare,
          senfluaLife: formAig.senfluaLife,
          senfluaThinhs: formAig.senfluaThinhs,
          senfluaBusiness: formAig.senfluaBusiness,
        })),
      }),
    };

    try {
      const response = fetch(
        `${process.env.REACT_APP_API_URL}/formSenflua`,
        requestOptions
      );
      const data = response;

      navigate("/confirmare-senflua-form");
    } catch (error) {
      window.location.href = "/eroare";
    }
  };

  return (
    <section className="senfluaContact">
      <Container>
        <Row>
          <Col xl="10" lg="10" md="9" sm="12" className="senfluaContact_left">
            {page != 0 && (
              <div className="senfluaContact_top">
                <img
                  src={SageataUp}
                  alt=""
                  className="senfluaContact_BackImg image-with-hover-cursor"
                  onClick={handleBackClick}
                  // onClick={(event) => setPage((currPage) => currPage - 1)}
                />
                <a
                  className="senfluaContact_Back image-with-hover-cursor"
                  onClick={handleBackClick}
                >
                  Intrebarea anterioară
                </a>
              </div>
            )}
            {page < 6 && sectiuni[page]}
            {page > 5 && sectiuniLifeViata[page - 6]}
            {page > 10 && sectiuniLifeCalatorie[page - 11]}
            {page > 15 && sectiuniThingsCasa[page - 16]}
            {page > 20 && sectiuniThingsMasina[page - 20]}
            {page > 25 && sectiuniThingsMoto[page - 24]}
            {page > 32 && sectiuniThingsAlte[page - 33]}
            {page > 36 && sectiuniBusiness[page - 36]}
            {page != 5 &&
              page != 3 &&
              page != 6 &&
              page != 10 &&
              page != 11 &&
              page != 15 &&
              page != 16 &&
              page != 20 &&
              page != 22 &&
              page != 25 &&
              page != 26 &&
              page != 29 &&
              page != 33 &&
              page != 36 &&
              page != 38 && (
                <div
                  className=" image-button"
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <img
                    src={next}
                    alt=""
                    className="image-with-hover-cursor"
                    style={{
                      width: "276px",
                      height: "auto",
                    }}
                    onClick={(event) => {
                      if (
                        checkbox.checkbox1 === true &&
                        checkbox.checkbox2 === true &&
                        (!(page === 1) || formPers.telefon.length >= 10)
                      )
                        setPage((currPage) => currPage + 1);
                    }}
                  />

                  <div
                    onClick={(event) => {
                      if (
                        checkbox.checkbox1 === true &&
                        checkbox.checkbox2 === true &&
                        (!(page === 1) || formPers.telefon.length >= 10)
                      )
                        setPage((currPage) => currPage + 1);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {t("typeForm.button1")}
                  </div>
                </div>
              )}

            {page === 5 && (
              <div
                className="image-button"
                style={{
                  marginTop: "30px",
                }}
              >
                <img
                  src={next}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "276px",
                    height: "auto",
                  }}
                  onClick={submit}
                />

                <div
                  onClick={submit}
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  {t("typeForm.button2")}
                </div>
              </div>
            )}

            <div className="senfluaContact_bottom">
              <img
                src={contacteeazaSenflua}
                alt=""
                className="senfluaContactButton image-with-hover-cursor"
                onClick={(event) =>
                  (window.location.href = "https://wa.me/40724542277")
                }
              />
            </div>
          </Col>

          <Col xl="2" lg="2" md="3" sm="12" className="senfluaContact_right">
            <img
              src={teleportareaeInviitor}
              alt=""
              className="imgSenfluaContact"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SenfluaForm;
