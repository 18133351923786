import React from "react";

import Footer_up_RCA from "../Footer_up_RCA/Footer_up_RCA";
import Footer_Down_RCA from "../Footer_Down_RCA/Footer_Down_RCA";

import "./FooterRCA.css";

const FooterRCA = () => {
  return (
    <footer className="FooterRCA" id="contact">
      <Footer_up_RCA />
      <Footer_Down_RCA />
    </footer>
  );
};

export default FooterRCA;
