import React from "react";

import phoneCall from "../../assests/images/SenfluaForm/phone-call.svg";
import email from "../../assests/images/SenfluaForm/email.svg";
import whatsapp from "../../assests/images/SenfluaForm/whatsapp.svg";

import { useLanguage } from "../utils/utils.jsx";

function CanalComunicare({ formPers, setFormPers }) {
  const { t, i18n } = useLanguage();
  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ color: "#FE9D35" }}>
        #SENFLUA.BUSINESS
      </p>
      <p className="nameTitle2">{t("typeForm.canalComunicare.title")}</p>

      <spam className="namesubTitle">
        {t("typeForm.canalComunicare.subtitle1")}
      </spam>
      <spam className="namesubTitle">
        {t("typeForm.canalComunicare.subtitle2")}
      </spam>
      <div>
        <label
          className={
            formPers.contact.apel
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormPers((prevState) => ({
              ...prevState,
              contact: {
                ...prevState.contact,
                apel: !formPers.contact.apel,
              },
            }));
          }}
          style={{ width: "148px", height: "101px" }}
        >
          <img src={phoneCall} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.canalComunicare.v1")}
        </label>
        <label
          className={
            formPers.contact.email
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormPers((prevState) => ({
              ...prevState,
              contact: {
                ...prevState.contact,
                email: !formPers.contact.email,
              },
            }));
          }}
          style={{ width: "86px", height: "101px" }}
        >
          <img src={email} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.canalComunicare.v2")}
        </label>

        <label
          className={
            formPers.contact.whatsapp
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormPers((prevState) => ({
              ...prevState,
              contact: {
                ...prevState.contact,
                whatsapp: !formPers.contact.whatsapp,
              },
            }));
          }}
          style={{ width: "124px", height: "101px" }}
        >
          <img src={whatsapp} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.canalComunicare.v3")}
        </label>
      </div>
      <input
        type="text"
        name="name"
        onChange={(event) =>
          setFormPers((prevState) => ({
            ...prevState,
            contact: {
              ...prevState.contact,
              cod: event.target.value,
            },
          }))
        }
        placeholder="scrie aici codul promotional"
        className="snfluaNameInput"
      />
    </div>
  );
}

export default CanalComunicare;
