import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import Mail from "../../../assests/images/RCA/Mail.svg";
import Phone from "../../../assests/images/RCA/Phone.svg";
import RCAbtn from "../../../assests/images/RCA/rca_btn.png";
import Travelbtn from "../../../assests/images/Travel_btn.png";
import rca_3 from "../../../assests/images/RCA/Sad_Senflua.svg";
import btn from "../../../assests/images/Travel/calatorie_btn.png";

import { useLanguage } from "../../utils/utils.jsx";

import "./Eroare.css";

const Eroare = () => {
  const { t, i18n } = useLanguage();

  useEffect(() => {
    document.title = "Senflua - Error";
  }, []);

  return (
    <section className="section_error">
      <Container className="containerRCA__form">
        <div className={"error_rca_title"}>
          <p>
            {t("error.title")} <br />
            {t("error.title2")}
          </p>
        </div>

        <div className={"error_rca_subtitle"}>
          <p>
            {t("error.subtitle")}
            <br /> {t("error.subtitle2")}
          </p>
        </div>

        {/* <a href="/asigurare-rca">
          <img src={RCAbtn} alt="" className="image-with-hover-cursor" />
        </a> */}

        <div
          className="image-button Travel_btn"
          style={{
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <img
            src={btn}
            alt=""
            className="image-with-hover-cursor"
            onClick={(event) => (window.location.href = "/asigurare-travel")}
            style={{
              width: "243px",
              height: "53px",
            }}
          />

          <div
            onClick={(event) => (window.location.href = "/asigurare-travel")}
            className="button-text image-with-hover-cursor"
            style={{
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            {t("travel.lpTravel.button1")}
          </div>
        </div>
        <div
          className="image-button rca_btn"
          style={{
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <img
            src={RCAbtn}
            alt=""
            className="image-with-hover-cursor"
            style={{
              width: "243px",
              height: "auto",
            }}
            onClick={(event) => (window.location.href = "/asigurare-rca")}
          />

          <div
            onClick={(event) => (window.location.href = "/asigurare-rca")}
            className="button-text image-with-hover-cursor"
            style={{
              marginLeft: "10px",
              marginTop: "10px",
            }}
          >
            {t("rca.lpRCA.button1")}
          </div>
        </div>
        {/* <a href="/asigurare-travel">
          <img src={Travelbtn} alt="" className="image-with-hover-cursor" />
        </a> */}

        <div className={"error_rca_subtitle"}>
          <p>
            {t("error.text1")} <br />
            {t("error.text2")} <br /> {t("error.text3")}
          </p>
        </div>

        <Row className="sectionRCA__error">
          <Col xl="4" lg="4" md="4" sm="12">
            <a
              href="tel:0753555525"
              className="image-with-hover-cursor"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <img src={Phone} alt="" target="_blank" />
              +40 753 555 525
            </a>
          </Col>
          <Col xl="4" lg="4" md="4" sm="12">
            <img src={rca_3} alt="" className="" />
          </Col>
          <Col xl="4" lg="4" md="4" sm="12">
            <a
              href="mailto:hello@senflua.ro"
              className="image-with-hover-cursor"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <img src={Mail} alt="" />
              hello@senflua.ro
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Eroare;
