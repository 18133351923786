import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./Travel_buy_Eurolife_New.css";

import { useLanguage } from "../utils/utils.jsx";

function Calatorie({
  person,
  setPerson,
  calatorie,
  setCalatorie,
  produs,
  setProdus,
  minDate2,
  setMinDate2,
  MaxDate,
  setMaxDate,
  tari,
  setTari,
  buttonNext,
  maxDateInceput,
}) {
  const { t, i18n } = useLanguage();

  const today = new Date();

  const [transport, setTransport] = useState({
    selecteaza: t("travelEurolife.select"),
    Avion: t("travelEurolife.calatorie.v61"),
    AvionSiMasinaInchiriata: t("travelEurolife.calatorie.v62"),
    Masina: t("travelEurolife.calatorie.v63"),
    Vapor: t("travelEurolife.calatorie.v64"),
    Autocar: t("travelEurolife.calatorie.v65"),
  });

  useEffect(() => {
    const handleLanguageChange = () => {
      setTransport({
        selecteaza: t("travelEurolife.select"),
        Avion: t("travelEurolife.calatorie.v61"),
        AvionSiMasinaInchiriata: t("travelEurolife.calatorie.v62"),
        Masina: t("travelEurolife.calatorie.v63"),
        Vapor: t("travelEurolife.calatorie.v64"),
        Autocar: t("travelEurolife.calatorie.v65"),
      });
    };
    handleLanguageChange();
  }, [t]);

  const [minDate, setMinDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleChangeDataInceput = (date) => {
    const valabilitateSfarsitDate = new Date(calatorie.valabilitate_sfarsit);

    const date1 = new Date(date);
    date1.setDate(date1.getDate() + 1);

    if (valabilitateSfarsitDate < date1 && calatorie.valabilitate_sfarsit) {
      setCalatorie({
        ...calatorie,
        valabilitate_inceput: formatDate(date),
        valabilitate_sfarsit: formatDate(date1),
      });
    } else {
      setCalatorie({
        ...calatorie,
        valabilitate_inceput: formatDate(date),
      });
    }

    setProdus((prevState) => ({
      ...prevState,
      anularea_calatoriei: false,
    }));

    const updatedMaxDate = new Date(date);
    updatedMaxDate.setDate(updatedMaxDate.getDate() + 29);
    setMaxDate(updatedMaxDate);

    setMinDate2(date1);
  };

  const handleChangeDataPlata = (date) => {
    setProdus({
      ...produs,
      data_platii: formatDate(date),
    });

    setProdus((prevState) => ({
      ...prevState,
      anularea_calatoriei: false,
    }));
  };

  const handleChangeDataSfarsit = (date) => {
    setCalatorie({
      ...calatorie,
      valabilitate_sfarsit: formatDate(date),
    });
  };

  return (
    <div>
      <Row className="sectionTravel_buy__form1">
        <Col xl="3" lg="6" md="12" className="sectionTravel_buy__left">
          <label
            className={
              produs.tara_destinatie === "selecteaza" && buttonNext === true
                ? "sub_title_Travel_buy_data_red"
                : "sub_title_Travel_buy_data"
            }
          >
            {t("travelEurolife.calatorie.request1")}
            <br />
            <select
              value={produs.tara_destinatie}
              onChange={(event) =>
                setProdus((prevState) => ({
                  ...prevState,
                  tara_destinatie: event.target.value,
                  numeTara_destinatie:
                    event.target.options[event.target.selectedIndex].text,
                  sport_extrem: false,
                  asistenta_rutiera: false,
                  anularea_calatoriei: false,
                  bagaje: false,
                  franciza: false,
                }))
              }
            >
              {tari.map((tara) => (
                <option key={tara.code} value={tara.code}>
                  {tara.description}
                </option>
              ))}
            </select>
          </label>
        </Col>
        <Col xl="3" lg="6" md="12" className="sectionTravel_buy__left">
          <label
            className={
              produs.modalitate_transport === "selecteaza" &&
              buttonNext === true
                ? "sub_title_Travel_buy_data_red"
                : "sub_title_Travel_buy_data"
            }
          >
            {t("travelEurolife.calatorie.request6")}
            <br />
            <select
              value={produs.modalitate_transport}
              onChange={(event) =>
                setProdus({
                  ...produs,
                  modalitate_transport: event.target.value,
                  sport_extrem: false,
                  asistenta_rutiera: false,
                  anularea_calatoriei: false,
                  bagaje: false,
                  franciza: false,
                })
              }
            >
              {Object.entries(transport).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        </Col>
      </Row>
      <Row className="sectionTravel_buy__form1">
        <Col xl="3" lg="6" md="12" className="sectionTravel_buy__left">
          <label
            className={
              !calatorie.valabilitate_inceput && buttonNext === true
                ? "sub_title_Travel_buy_data_red"
                : "sub_title_Travel_buy_data"
            }
          >
            {t("travelEurolife.calatorie.request2")}
            <br />
            <DatePicker
              selected={
                calatorie.valabilitate_inceput
                  ? new Date(calatorie.valabilitate_inceput)
                  : null
              }
              dateFormat="dd-MM-yyyy"
              minDate={today}
              maxDate={maxDateInceput}
              onChange={handleChangeDataInceput}
              placeholderText={t("travelEurolife.select")}
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="12" className="sectionTravel_buy__left">
          <label
            className={
              !calatorie.valabilitate_sfarsit && buttonNext === true
                ? "sub_title_Travel_buy_data_red"
                : "sub_title_Travel_buy_data"
            }
          >
            {t("travelEurolife.calatorie.request3")}
            <br />
            <DatePicker
              selected={
                calatorie.valabilitate_sfarsit
                  ? new Date(calatorie.valabilitate_sfarsit)
                  : null
              }
              dateFormat="dd-MM-yyyy"
              maxDate={MaxDate}
              minDate={minDate2}
              onChange={handleChangeDataSfarsit}
              placeholderText={t("travelEurolife.select")}
            />
          </label>
        </Col>
      </Row>

      <div className="sub_titleForm_Travel_buy">
        <label>{t("travelEurolife.calatorie.request4")}</label>
        <br />
      </div>
      <div className="sectionTravel_buy__button">
        <Button
          className={
            produs.scopul_calatoriei === "Vacanta"
              ? "sub_title_Travel_select_active"
              : "sub_title_Travel_select"
          }
          onClick={(event) =>
            setProdus({
              ...produs,
              scopul_calatoriei: "Vacanta",
            })
          }
        >
          {t("travelEurolife.calatorie.v41")}
        </Button>

        <Button
          className={
            produs.scopul_calatoriei === "Delegatie"
              ? "sub_title_Travel_select_active"
              : "sub_title_Travel_select"
          }
          onClick={(event) =>
            setProdus({
              ...produs,
              scopul_calatoriei: "Delegatie",
            })
          }
        >
          {t("travelEurolife.calatorie.v42")}
        </Button>
        <Button
          className={
            produs.scopul_calatoriei === "Studii"
              ? "sub_title_Travel_select_active"
              : "sub_title_Travel_select"
          }
          onClick={(event) =>
            setProdus({
              ...produs,
              scopul_calatoriei: "Studii",
            })
          }
        >
          {t("travelEurolife.calatorie.v43")}
        </Button>
      </div>
      <Row className="sectionTravel_buy__form1">
        <Col xl="3" lg="6" md="12" className="sectionTravel_buy__left">
          <label
            className={
              !produs.data_platii && buttonNext === true
                ? "sub_title_Travel_buy_data_red"
                : "sub_title_Travel_buy_data"
            }
          >
            {t("travelEurolife.calatorie.request5")}
            <br />
            <DatePicker
              selected={
                produs.data_platii ? new Date(produs.data_platii) : null
              }
              dateFormat="dd-MM-yyyy"
              maxDate={today}
              onChange={handleChangeDataPlata}
              placeholderText={t("travelEurolife.select")}
            />
          </label>
        </Col>
        <Col xl="3" lg="6" md="12" className="sectionTravel_buy__left">
          <label className="sub_title_Travel_buy_data">
            <strong>Voucher</strong>
            <br />
            <input
              type="text"
              name="voucher"
              value={person.voucher}
              onChange={(event) =>
                setPerson({
                  ...person,
                  voucher: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
      </Row>
    </div>
  );
}

export default Calatorie;
