import React from "react";

import investitieMecanisme from "../../assests/images/SenfluaForm/investitieMecanisme.svg";
import posibilitateEconomisire from "../../assests/images/SenfluaForm/posibilitateEconomisire.svg";
import protectieFinanciara from "../../assests/images/SenfluaForm/protectieFinanciara.svg";
import acoperireeMedicala from "../../assests/images/SenfluaForm/acoperireeMedicala.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipAsigurareLifeDetalii({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ marginTop: "20px" }}>
        #senflua.LIFE
      </p>
      <p className="nameTitle2">
        {t("typeForm.tipAsigurareLifeDetalii.title")}
      </p>
      <spam className="namesubTitle">
        {t("typeForm.tipAsigurareLifeDetalii.subtitle")}
      </spam>
      <div className="senfluaFormTipAsigLifeDiv">
        <label
          className={
            formAig.senfluaLife.economie
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                economie: !formAig.senfluaLife.economie,
              },
            }));
          }}
          style={{ width: "148px", height: "121px" }}
        >
          <img
            src={investitieMecanisme}
            className="senfluaFormTipAsigImgLife"
          />
          {t("typeForm.tipAsigurareLifeDetalii.v1")}
        </label>

        <label
          className={
            formAig.senfluaLife.investitii
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                investitii: !formAig.senfluaLife.investitii,
              },
            }));
          }}
          style={{ width: "220px", height: "102px" }}
        >
          <img
            src={posibilitateEconomisire}
            className="senfluaFormTipAsigImgLife"
          />
          {t("typeForm.tipAsigurareLifeDetalii.v2")}
        </label>

        <label
          className={
            formAig.senfluaLife.protectieFinanciara
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                protectieFinanciara: !formAig.senfluaLife.protectieFinanciara,
              },
            }));
          }}
          style={{ width: "184px", height: "104px" }}
        >
          <img
            src={protectieFinanciara}
            className="senfluaFormTipAsigImgLife"
          />
          {t("typeForm.tipAsigurareLifeDetalii.v3")}
        </label>

        <label
          className={
            formAig.senfluaLife.acoperireMedicala
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                acoperireMedicala: !formAig.senfluaLife.acoperireMedicala,
              },
            }));
          }}
          style={{ width: "178px", height: "121px" }}
        >
          <img src={acoperireeMedicala} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareLifeDetalii.v4")}
        </label>
      </div>
    </div>
  );
}

export default TipAsigurareLifeDetalii;
