import React, { useState, useEffect } from "react";
import "react-range-slider-input/dist/style.css";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import contactfooter from "../../assests/images/HomeProtect/contact.svg";
import "./HomeProtectNew.css";
import DownloadPDFButton from "./components/DownloadPDFButton/DownloadPDFButton";
import { Container, Row, Col } from "reactstrap";

import { useTranslation } from "react-i18next";

import PassList from "./components/passList/PassList";
import ChatBubble from "./components/chatBubble/ChatBubble";

import sageata from "../../assests/images/RCA/sageata.svg";
import umbrela from "../../assests/images/HomeProtect/umbrela1.svg";
import whatsapp from "../../assests/images/HomeProtect/whatsapp.png";
import pass from "../../assests/images/HomeProtect/pass.png";

import "../../i18n";

function Delatalii({
  home,
  setHome,
  judete,
  setJudete,
  orase,
  setOrase,
  constructia,
  currency,
  covers,
  setCovers,
  page,
  setPage,
  oferta,
  setOferta,
  franchises,
  setFranchises,
  codText,
  setCodText,
  textCod,
  setTextCod,
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [errorSuma, setErrorSuma] = useState(false);

  const calculateVecini = (locuintaValue) => {
    return locuintaValue * 0.2;
  };

  let intr = 0;

  useEffect(() => {
    if (intr === 0) {
      functionRefresh();
      intr = 1;
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function functionAplicaCod() {
    setShowPopup(true);
    const requestOptions1 = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quoteRequest: home, covers: covers }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/QuotationHouse`,
        requestOptions1
      );
      const json = await response.json();
      setTextCod(
        json
          .filter((item) => item.code === "campaignCode")
          .map((item) => item.content)
      );

      functionRefresh();
    } catch (error) {
      navigate("/eroare");
    }
    setShowPopup(false);
  }

  async function functionRefresh() {
    setErrorSuma(false);
    setShowPopup(true);
    const requestOptions1 = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quoteRequest: home, covers: covers }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ofertaHouse1`,
        requestOptions1
      );
      const json = await response.json();
      if (json.error === false) {
        setOferta((prevOferta) => {
          const updatedOferta = {
            ...prevOferta,
            error: false,
            badRequest: {
              ...prevOferta.badRequest,
              code: "",
              message: "",
            },
          };

          // Actualizează valorile existente din json în oferta
          for (const key in json) {
            if (json.hasOwnProperty(key)) {
              if (key === "covers") {
                // Dacă cheia este "covers", actualizează individual covers
                updatedOferta[key] = updatedOferta[key].map((cover) => {
                  const jsonCover = json[key].find(
                    (jsonCover) =>
                      jsonCover.productCoverCode === cover.productCoverCode
                  );
                  if (jsonCover) {
                    jsonCover.name = cover.name; // utilizează numele din oferta curentă
                  }
                  return jsonCover || cover;
                });
              } else {
                updatedOferta[key] = json[key];
              }
            }
          }

          // Pentru cheile care nu există în json, setează insuredSum pe 0 în covers
          if (updatedOferta.covers) {
            updatedOferta.covers.forEach((cover) => {
              if (
                !json.covers.find(
                  (jsonCover) =>
                    jsonCover.productCoverCode === cover.productCoverCode
                )
              ) {
                cover.insuredSum = 0;
                cover.premium = 0;
              }
            });
          }

          return updatedOferta;
        });
      } else {
        setOferta((prevOferta) => ({
          ...prevOferta,
          error: true,
          offerId: "0",
          series: "0",
          number: "0",
          badRequest: {
            ...prevOferta.badRequest,
            code: json.badRequest.code,
            message: json.badRequest.message,
          },
          covers: prevOferta.covers.map((cover) => ({
            ...cover,
            premium: 0,
          })),
        }));
        setCovers((prevState) => ({
          ...prevState,
          electrice: 0,
        }));
      }
    } catch (error) {
      navigate("/eroare");
    }
    setShowPopup(false);
  }

  const updateHome = () => {
    if (oferta.error === false) {
      window.scrollTo(0, 0);
      setPage(page + 1);
    }
    setPage(page + 1);
  };

  return (
    <section className="containerHP">
      <div className="LocuinteSpace">
        <PassList yellowCount="3" />
      </div>
      <Container>
        <Row>
          <Col xl="2" lg="2" md="2" sm="12" className="locuinteColImg">
            <img src={umbrela} alt="" className="locuinteImg" />
          </Col>
          <Col xl="9" lg="8" md="8" sm="12" className="locuintaColFransiza">
            <ChatBubble
              message={t("homeProtect.pasul4.title")}
              position="left"
              dimention="CB-title2"
            />
          </Col>
        </Row>

        <Row>
          <Col xl="2" lg="2" md="2" sm="0"></Col>
          <Col xl="7" lg="8" md="8" sm="12">
            <div className="sectionTravel_buy__form_back">
              <img
                src={sageata}
                alt=""
                className="image-with-hover-cursor sageata_travel"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              />
              <p
                className="sectionTravel_buy_button_back image-with-hover-cursor"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              >
                {t("travelEurolife.back")}
              </p>
            </div>
            <div className="sectionHP_Bunuri">
              <p className="sectionHP_DetaliiTitle">
                {t("homeProtect.pasul4.title1")}
              </p>

              <Row
                style={{
                  marginBottom: "10px",
                }}
              >
                <Col xl="4" lg="4" md="4" sm="12">
                  <p className="sectionHP_Tabletitle1">
                    {t("homeProtect.pasul4.tipulConstructiei")}:{" "}
                    <b>{home.house.technicalDetails.houseType}</b>
                  </p>
                </Col>
                <Col xl="4" lg="4" md="4" sm="12">
                  <p className="sectionHP_Tabletitle">
                    {t("homeProtect.pasul1.subtitle3")}:{" "}
                    <b>{home.house.technicalDetails.usableArea}</b>
                  </p>
                </Col>
                <Col xl="4" lg="4" md="4" sm="12">
                  <p className="sectionHP_Tabletitle">
                    {t("homeProtect.pasul1.subtitle4")}:{" "}
                    <b>{home.house.technicalDetails.constructionYear}</b>
                  </p>
                </Col>
              </Row>
              {windowWidth > 750 && (
                <Row className="sectionHP_Table">
                  <Col xl="4" lg="4" md="4" sm="12">
                    <p className="">{t("homeProtect.pasul4.tableTile")}</p>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="12">
                    <p className="sectionHP_Tabletitle">
                      {t("homeProtect.pasul4.tableTile2")} ({home.currency})
                    </p>
                  </Col>
                  <Col xl="4" lg="4" md="4" sm="12">
                    <p className="sectionHP_Tabletitle">
                      {t("homeProtect.pasul4.tableTile3")} ({home.currency})
                    </p>
                  </Col>
                </Row>
              )}
              {oferta.covers.map((cover, index) => (
                <React.Fragment key={`${cover.productCoverCode}_${index}`}>
                  {cover.insuredSum !== 0 && (
                    <Row className="sectionHP_Table1">
                      <Col xl="4" lg="4" md="4" sm="12">
                        <p className="sectionHP_Tabletitle1">
                          {cover.name.charAt(0).toUpperCase() +
                            cover.name.slice(1).toLowerCase()}
                        </p>
                      </Col>
                      <Col xl="4" lg="4" md="4" sm="12">
                        {windowWidth <= 750 && (
                          <p className="sectionHP_Tabletitle">
                            {t("homeProtect.pasul4.tableTile2")} (
                            {home.currency})
                          </p>
                        )}
                        <p className="sectionHP_Tabletitle">
                          {cover.insuredSum}
                        </p>
                      </Col>
                      <Col xl="4" lg="4" md="4" sm="12">
                        {windowWidth <= 750 && (
                          <p className="sectionHP_Tabletitle">
                            {t("homeProtect.pasul4.tableTile3")} (
                            {home.currency})
                          </p>
                        )}
                        <p className="sectionHP_Tabletitle">
                          {cover.productCoverCode === "0814_LOCUINT"
                            ? (
                                parseFloat(cover.premium) +
                                parseFloat(
                                  oferta.covers.find(
                                    (covers) =>
                                      covers.productCoverCode === "0814_ASIS_TH"
                                  ).premium || 0
                                )
                              ).toFixed(2)
                            : cover.productCoverCode === "0814_ASIS_TH"
                            ? "Inclusa"
                            : cover.productCoverCode === "0814_B_2000"
                            ? (
                                parseFloat(cover.premium) +
                                parseFloat(
                                  oferta.covers.find(
                                    (covers) =>
                                      covers.productCoverCode === "0814_DOCUMEN"
                                  ).premium || 0
                                )
                              ).toFixed(2)
                            : cover.productCoverCode === "0814_DOCUMEN"
                            ? "Inclusa"
                            : cover.productCoverCode === "0814_AA_INST"
                            ? (
                                parseFloat(cover.premium) +
                                parseFloat(
                                  oferta.covers.find(
                                    (covers) =>
                                      covers.productCoverCode === "0814_APA_CO"
                                  ).premium || 0
                                )
                              ).toFixed(2)
                            : cover.productCoverCode === "0814_APA_CO"
                            ? "Inclusa"
                            : parseFloat(cover.premium).toFixed(2)}
                        </p>
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              ))}
              <div className="sectionHP_Total" style={{ marginTop: "-10px" }}>
                <label className="sectionHP_TotalTitle">
                  {t("homeProtect.pasul4.primaTotala")}
                </label>
                <label className="sectionHP_TotalSuma">
                  {
                    oferta.frequencies.find(
                      (frequency) => frequency.frequency === home.frequency
                    ).totalPremium
                  }{" "}
                  {home.currency}
                </label>
              </div>

              <div className="sectionHP_Total" style={{ marginTop: "-10px" }}>
                <label className="sectionHP_TotalTitle">
                  {t("homeProtect.pasul4.text")}

                  {home.frequency === "ANUALA"
                    ? t("homeProtect.pret.an")
                    : home.frequency === "SEMESTRIALA"
                    ? t("homeProtect.pret.semestru")
                    : home.frequency === "TRIMESTRIALA"
                    ? t("homeProtect.pret.trimestru")
                    : home.frequency === "LUNARA"
                    ? t("homeProtect.pret.luna")
                    : ""}
                </label>
                <label className="sectionHP_TotalSuma">
                  {
                    oferta.frequencies.find(
                      (frequency) => frequency.frequency === home.frequency
                    ).premium
                  }{" "}
                  {home.currency}
                </label>
              </div>
              <p className="detaliiSubTitleF">
                {t("homeProtect.pasul4.subtitle")}
                <input
                  className="section_BunuriTextInput"
                  type="text"
                  name="name"
                  placeholder={t("homeProtect.pasul1.subtitle241")}
                  defaultValue={
                    franchises.find(
                      (franchise) => franchise.code === home.franchise
                    ).description
                  }
                  readOnly
                />
                {home.currency}
              </p>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <label className="detaliiMiniTitle2">
                  {t("homeProtect.pasul1.subtitle6")} <br />
                  <input
                    type="text"
                    name="name"
                    placeholder={t("homeProtect.pasul1.subtitle61")}
                    value={home.campaignCode}
                    onChange={(e) =>
                      setHome((prevHome) => ({
                        ...prevHome,
                        campaignCode: e.target.value,
                      }))
                    }
                  />
                </label>
                <div
                  style={{
                    marginLeft: "30px",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <img
                    src={pass}
                    alt=""
                    className="image-with-hover-cursor buttonPaid"
                    onClick={functionAplicaCod}
                  />

                  <div
                    style={{
                      marginLeft: "50px",
                    }}
                    className="locuinte_buttonText1 image-with-hover-cursor"
                    onClick={functionAplicaCod}
                  >
                    {t("homeProtect.pasul4.button")}
                  </div>
                </div>
                <div>
                  <label>{textCod}</label>
                </div>
              </div>
              <p className="sectionHP_BunuriText">
                {t("homeProtect.pasul4.subtitle1")}
              </p>
              <div>
                <label
                  className={
                    home.frequency === "LUNARA"
                      ? "detaliiButtonPlataBlue image-with-hover-cursor"
                      : "detaliiButtonPlata image-with-hover-cursor"
                  }
                  onClick={(event) => {
                    setHome((prevHome) => ({
                      ...prevHome,
                      frequency: "LUNARA",
                    }));
                    functionRefresh();
                  }}
                >
                  {
                    oferta.frequencies.find(
                      (frequency) => frequency.frequency === "LUNARA"
                    ).premium
                  }{" "}
                  {home.currency}/{t("homeProtect.pret.luna")}
                </label>

                <label
                  className={
                    home.frequency === "TRIMESTRIALA"
                      ? "detaliiButtonPlataBlue image-with-hover-cursor"
                      : "detaliiButtonPlata image-with-hover-cursor"
                  }
                  onClick={(event) => {
                    setHome((prevHome) => ({
                      ...prevHome,
                      frequency: "TRIMESTRIALA",
                    }));
                    functionRefresh();
                  }}
                >
                  {
                    oferta.frequencies.find(
                      (frequency) => frequency.frequency === "TRIMESTRIALA"
                    ).premium
                  }{" "}
                  {home.currency}/{t("homeProtect.pret.trimestru")}
                </label>
                <label
                  className={
                    home.frequency === "SEMESTRIALA"
                      ? "detaliiButtonPlataBlue image-with-hover-cursor"
                      : "detaliiButtonPlata image-with-hover-cursor"
                  }
                  onClick={(event) => {
                    setHome((prevHome) => ({
                      ...prevHome,
                      frequency: "SEMESTRIALA",
                    }));
                    functionRefresh();
                  }}
                >
                  {
                    oferta.frequencies.find(
                      (frequency) => frequency.frequency === "SEMESTRIALA"
                    ).premium
                  }{" "}
                  {home.currency}/{t("homeProtect.pret.semestru")}
                </label>
                <label
                  className={
                    home.frequency === "ANUALA"
                      ? "detaliiButtonPlataBlue image-with-hover-cursor"
                      : "detaliiButtonPlata image-with-hover-cursor"
                  }
                  onClick={(event) => {
                    setHome((prevHome) => ({
                      ...prevHome,
                      frequency: "ANUALA",
                    }));
                    functionRefresh();
                  }}
                >
                  {
                    oferta.frequencies.find(
                      (frequency) => frequency.frequency === "ANUALA"
                    ).premium
                  }{" "}
                  {home.currency}/{t("homeProtect.pret.an")}
                </label>
              </div>
              <p
                className="sectionHP_Tabletitle1"
                style={{
                  marginTop: "30px",
                }}
              >
                {t("homeProtect.pasul4.infoFinal")}
              </p>
            </div>
            <div
              style={{
                marginTop: "-30px",
                textAlign: "center",
              }}
            >
              <DownloadPDFButton />
            </div>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "100px",
          }}
        >
          <Col xl="2" lg="0" md="0" sm="0"></Col>
          <Col xl="2" lg="4" md="6" sm="12">
            <div
              className="sectionTravel_Travel__form_back"
              style={{
                marginLeft: "10px",
              }}
            >
              <img
                src={sageata}
                alt=""
                className="image-with-hover-cursor sageata_travel"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              />
              <p
                className="sectionTravel_buy_button_back image-with-hover-cursor"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage(page - 1);
                }}
              >
                {t("travelEurolife.back")}
              </p>
            </div>
          </Col>
          <Col xl="4" lg="4" md="6" sm="12">
            <div>
              <img
                src={pass}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "276px",
                  height: "auto",
                }}
                onClick={updateHome}
              />

              <div
                className="HP_buttonText image-with-hover-cursor"
                style={{
                  marginLeft: "56px",
                  marginTop: "-35px",
                }}
                onClick={updateHome}
              >
                {t("homeProtect.pasul1.button")}
              </div>
            </div>
          </Col>

          <Col xl="4" lg="4" md="6" sm="12">
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img src={contactfooter} alt="" className="imgFooterNext" />
              <img
                style={{ width: "260px", height: "auto", marginTop: "-200px" }}
                src={whatsapp}
                alt=""
                className="image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              />
              <p
                className="whatsappLocuinte image-with-hover-cursor"
                onClick={(event) =>
                  window.open("https://wa.me/40724542277", "_blank")
                }
              >
                {t("homeProtect.pasul1.whatsapp")}
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {showPopup && (
        <div className="popup-wrapper">
          <div className="popup-overlay" />
          <div className="popup-containerTransparent">
            <Spinner
              animation="border"
              role="status"
              style={{
                height: "90px",
                width: "90px",
              }}
            ></Spinner>
          </div>
        </div>
      )}
    </section>
  );
}
export default Delatalii;
