import React, { Fragment } from "react";

import Header from "../header/Header";
import HeroCondtii from "../HeroCondtii/HeroCondtii";
import SectionTextCOOKIES from "../SectionTextCOOKIES/SectionTextCOOKIES";

import Footer from "../Footer/Footer";

const PageCOOKIES = () => {
  return (
    <Fragment>
      <Header />
      <HeroCondtii title="COOKIES" />
      <SectionTextCOOKIES />
      <Footer />
    </Fragment>
  );
};

export default PageCOOKIES;
