import React from "react";

import { useLanguage } from "../utils/utils.jsx";

function TipAsigurareMasina({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.tipAsigurareMasina.title")}</p>
      <p className="namesubTitle">
        {t("typeForm.tipAsigurareMasina.subtitle")}
      </p>
      <spam className="namesubTitle"></spam>
      <div>
        <label
          className={
            formAig.senfluaThinhs.rca
              ? "senfluaFormAltProdus_active image-with-hover-cursor"
              : "senfluaFormAltProdus image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                rca: !formAig.senfluaThinhs.rca,
              },
            }));
          }}
        >
          {t("typeForm.tipAsigurareMasina.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.casco
              ? "senfluaFormAltProdus_active image-with-hover-cursor"
              : "senfluaFormAltProdus image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                casco: !formAig.senfluaThinhs.casco,
              },
            }));
          }}
        >
          {t("typeForm.tipAsigurareMasina.v2")}
        </label>
      </div>
    </div>
  );
}

export default TipAsigurareMasina;
