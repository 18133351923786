import React from "react";

import FooterBlack from "../FooterBlack/FooterBlack";
import FooterGrey from "../FooterGrey/FooterGrey";

const Footer = () => {
  return (
    <footer id="contact">
      <FooterBlack />
      <FooterGrey />
    </footer>
  );
};

export default Footer;
