import React, { useState, useEffect } from "react";

import utilizat from "../../assests/images/SenfluaForm/utilizat.svg";
import nou from "../../assests/images/SenfluaForm/nou.svg";
import degradat from "../../assests/images/SenfluaForm/degradat.svg";

import { useLanguage } from "../utils/utils.jsx";

function StareObiect({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.stareObiect.title")}</p>
      <p className="namesubTitle">{t("typeForm.stareObiect.subtitle")}</p>

      <div>
        <label
          className={
            formAig.senfluaThinhs.nou
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                nou: !formAig.senfluaThinhs.nou,
              },
            }));
          }}
          style={{ width: "124px", height: "71px" }}
        >
          <img
            src={nou}
            className="senfluaFormTipAsigImgLife2 image-with-hover-cursor"
          />
          {t("typeForm.stareObiect.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.utilizat
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                utilizat: !formAig.senfluaThinhs.utilizat,
              },
            }));
          }}
          style={{ width: "124px", height: "71px" }}
        >
          <img
            src={utilizat}
            className="senfluaFormTipAsigImgLife2 image-with-hover-cursor"
          />
          {t("typeForm.stareObiect.v2")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.degradat
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                degradat: !formAig.senfluaThinhs.degradat,
              },
            }));
          }}
          style={{ width: "124px", height: "71px" }}
        >
          <img src={degradat} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.stareObiect.v3")}
        </label>
      </div>
    </div>
  );
}

export default StareObiect;
