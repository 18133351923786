import React from "react";

import Copilul from "../../assests/images/SenfluaForm/Copilul.svg";
import Economisire from "../../assests/images/SenfluaForm/Economisire.svg";
import AsigCalatorie from "../../assests/images/SenfluaForm/AsigCalatorie.svg";
import AltProict from "../../assests/images/SenfluaForm/AltProict.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipAsigurareLifePentru({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ marginTop: "20px" }}>
        #senflua.LIFE
      </p>
      <p className="nameTitle2">{t("typeForm.tipAsigurareLifePentru.title")}</p>
      <spam className="namesubTitle">
        {t("typeForm.tipAsigurareLifePentru.subtitle1")}
      </spam>
      <spam className="namesubTitle">
        {t("typeForm.tipAsigurareLifePentru.subtitle2")}
      </spam>
      <div>
        <label
          className={
            formAig.senfluaLife.protectiafamilie
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                protectiafamilie: !formAig.senfluaLife.protectiafamilie,
              },
            }));
          }}
          style={{ width: "164px", height: "101px" }}
        >
          <img src={Copilul} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareLifePentru.v1")}
        </label>

        <label
          className={
            formAig.senfluaLife.studii
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                studii: !formAig.senfluaLife.studii,
              },
            }));
          }}
          style={{ width: "238px", height: "101px" }}
        >
          <img src={Economisire} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareLifePentru.v2")}
        </label>

        <label
          className={
            formAig.senfluaLife.calatorit
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                calatorit: !formAig.senfluaLife.calatorit,
              },
            }));
          }}
          style={{ width: "202px", height: "101px" }}
        >
          <img src={AsigCalatorie} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareLifePentru.v3")}
        </label>

        <label
          className={
            formAig.senfluaLife.altproiect
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                altproiect: !formAig.senfluaLife.altproiect,
              },
            }));
          }}
          style={{ width: "108px", height: "101px" }}
        >
          <img src={AltProict} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.tipAsigurareLifePentru.v4")}
        </label>
      </div>
    </div>
  );
}

export default TipAsigurareLifePentru;
