import React, { Fragment } from "react";
import Header from "../header/Header";
import SenfluaForm1 from "../SenfluaForm/SenfluaForm";
import Footer from "../Footer/Footer";
import TabBar from "../TabBar/TabBar";

const SenfluaForm = () => {
  return (
    <Fragment>
      <TabBar />
      <Header />
      <SenfluaForm1 />
      <Footer />
    </Fragment>
  );
};

export default SenfluaForm;
