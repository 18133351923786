import React from "react";
import { Container, Row, Col } from "reactstrap";

import RCAbtn from "../../assests/images/RCA_btn.png";
import Travelbtn from "../../assests/images/Travel_btn.png";
import IaLegatura from "../../assests/images/iaLegatura_btn.png";
import teleportareaeInviitor from "../../assests/images/teleportareaeInviitor.svg";

import "./SectionTextPoliticaConf.css";
const SectionTextPoliticaConf = () => {
  return (
    <section className="sectionCondtii">
      <Container>
        <div>
          <p className="sectionCondtiiText">
            Data actualizarii 01-08-2022 <br /> Data intrarii in vigoare
            01-08-2022
            <br />
            <br />
            Aceasta Politica de confidentialitate descrie politica societatii
            Senflua Insurance SRL, Strada Constantin Brancusi, Nr 11, Parter,
            Bloc D16, Scara 7, Ap 242, Sector 3, Bucuresti, email:
            gdpr@senflua.ro, telefon: +40 (724) 542 277, cu privire la
            colectarea, utilizarea si partajarea datelor personale, date
            colectate daca utilizati site-ul nostru (www.senflua.ro), numit
            “Serviciul”. Prin accesarea si utilizarea Serviciului nostru, iti
            exprimi acordul cu privire la colectarea, utilizarea si partajarea
            informatiilor si datelor personale puse la dispozitie in
            conformitate cu aceasta Politica de confidentialitate. Daca nu
            sunteti de acord cu aceasta, va rugam sa nu accesati sau sa
            utilizati Serviciul nostru.
            <br /> Putem modifica aceasta Politica de confidentialitate in orice
            moment, fara notificare prealabila, si vom posta varianta revizuita
            si data intrarii in vigoare. Politica revizuita va intra in vigoare
            in termen de 180 de zile de la data publicarii si pentru
            utilizatorii care au acceptat vechea Politica. Accesul la Serviciu
            si utilizarea acestuia dupa acest termen implica acceptarea automata
            la Politicii revizuite. In consecinta, recomandam consultarea
            periodica a paginii de Politica de confidentialitate. INFORMATIILE
            PE CARE LE <br />
            <br /> Colectam si procesam urmatoarele informatii puse la
            dispozitile de care utilizator in legatura cu accesarea si
            utilizarea Serviciului:
            <br /> Nume, Adresa email, Telefon, Adresa
            <br />
            <br /> CUM COLECTAM ACESTE INFORMATII Colectam / Procesam
            informatile in situatiile urmatoare:
            <br /> (1) La deschiderea unui cont nou pe site: Nu;
            <br /> (2) Atunci cand interactionezi cu site-ul: Cookie-uri cum ar
            fi Google Analytics, alte cookie-uri in conformitate cu Politica
            Cookie, etc.;
            <br /> (3) Pe baza de consimtamant (ex: abonare la Newsletter): Da;
            <br />
            (4) Cand ne contactezi direct sau prin formularele de contact;
            <br /> (5) Cand efectuezi o plata: Da. Nu stocam datele cardurilor
            pe serverul nostru, plata se face securizat prin intermediul unui
            procesator pe plati (ex: paypal, stripe, mobilpay, payu, etc);
            <br /> CUM FOLOSIM INFORMATIILE COLECTATE Folosim informatiile
            colectate in urmatoarele scopuri:
            <br />
            <br /> Comentarii useri
            <br /> Daca vom intentiona sa folosim informatiile colectate pentru
            oricare alt scop, iti vom cere inainte de utilizare consimtamantul,
            si numai dupa primirea consimtamantului, si numai pentru scopurile
            pentru care a fost cerut, vor fi folosite in consecinta. Exceptia
            poate fi numai in legatura cu cerinte sau obligatii impuse de lege.
            <br />
            <br />
            CU CINE PARTAJAM INFORMATIILE:
            <br /> Nu vom partaja informatiile personale colectate catre terte
            parti, fara consimtamantul tau, cu exceptia unor situatii limitate,
            cum ar fi cele de mai jos. Conditionam transferul catre aceste terte
            parti de utilizarea informatiilor numai in scopul pentru care au
            fost colectate si transferate, si ne-stocarea acestora mai mult
            decat termenul agreat la colectare pentru indeplinirea scopului.
            <br />
            Este posibil sa partajam informatiile colectate pentru urmatoarele:
            (1) pentru respectarea unor obligatii legale, sau aplicarea unor
            decizii judecatoresti; (2) sa demonstram colectarea acordului pentru
            aceasta Politica de confidentialitate; (3) sa raspundem unor
            reclamatii in legatura cu incalcarea unor drepturi ale unor terte
            parti, de catre Serviciul nostru.In situatia in care Serviciul
            nostru sau compania, fuzioneaza sau este achizitionata de catre o
            alta companie, informatiile colectate vor reprezenta active
            transferate catre noul proprietar.
            <br />
            <br /> STOCAREA INFORMATIILOR COLECTATE Vom pastra informatiile
            colectate pentru Maxim 24 luni sau atat timp cat este necsar sa fie
            indeplinit scopul pentru care au fost colectate, asa cum a fost
            detaliat in prezenta Politica de confidentialitate. Vom fi obligati
            sa pastram anumite informatii pe termen mai lung, cum ar fi
            inregistrarile contabile impuse de legislatia in vigoare, sau pentru
            alte scopuri legitime, cum ar fi: aplicarea legii, prevenirea
            fraudelor. Datele anonime si informatiile agregate, care nu te pot
            identifica (direct sau indirect), ar putea fi stocate pe termen
            nedeterminat.
            <br />
            <br /> DREPTURILE TALE
            <br /> In functie de legislatia aplicabila, ai dreptul de acces si
            rectificare sau stergere a datelor tale personale colectate de catre
            noi, ai dreptul sa primesti o copie a datelor colectate, ai dreptul
            de a te opune sau restictiona procesarea anumitor date personale, ai
            dreptul de a te adresa autoritatii competente cu petitii sau
            reclamatii cu privire la drepturile tale. Pentru a-ti exercita
            aceste drepturi, ne poti contacta astfel: Tiberiu Marin, Strada
            Constantin Brancusi, Nr 11, Parter, Bloc D16, Scara 7, Ap 242,
            Sector 3, Bucuresti, gdpr@senflua.ro. Iti vom raspunde la cerere in
            conformitate cu legislatia aplicabila, in termen de 30 zile.
            <br /> <br /> Ai posibilitatea sa te opui prelucrarii pentru
            comunicari de marketing sau analizei pentru publicitate
            personalizata, prin modificarea setarilor sau direct prin email la
            adresa gdpr@senflua.ro. Te rugam sa iei in considerare ca anumite
            informatii colectate sunt necesare pentru functionarea Serviciului,
            iar retragerea consimtamantului poate face ca acesta sa devina
            indisponibil pentru utilizare. COOKIE-URI, URMARIRE
            <br /> Pentru mai multe detalii cu privire la cookie-urile si
            tehnologiile utilizate, te rugam sa consulti Politica Cookie.
            <br />
            SECURITATE
            <br /> Securitatea datelor si informatiilor colectate este
            importanta pentru noi, si vom implementa masuri de securitate
            adecvate pentru a preveni pierderea, alterarea si accesul
            neautorizat la datele colectate.
            <br /> Totusi, date fiind riscurile inerente, nu putem garanta
            securitate absoluta si in consecinta nu putem garanta securitatea
            informatiilor colectate
            <br /> LINK-URI EXTERNE
            <br /> Serviciul nostru poate afisa link-uri catre alte site-uri
            care nu sunt operate de noi. Aceasta Politica de confidentialitate
            nu are legatura cu politicile si practicile altor site-uri, inclusiv
            dar fara a se limita la site-uri accesate prin intermediul unor
            link-uri publicate de acest Serviciu.
            <br /> Iti recomandam sa consulti Politicile de confidentialitate
            ale oricaror alte site-uri pe care le vizitezi, pornind de la acest
            Serviciu.
            <br /> Nu avem control si nu ne asumam responsabilitatea pentru
            continutul, politicile de confidentialitate si practicile altor
            site-uri.
            <br /> CONTACT / DPO
            <br /> Daca ai intrebari sau nelamuriri cu privire la procesarea
            informatiilor / datelor personale colectate de catre noi, te rugam
            sa ne contactezi, „In atentia Responsabilului cu datele personale”,
            la adresa: Tiberiu Marin, Strada Constantin Brancusi, Nr 11, Parter,
            Bloc D16, Scara 7, Ap 242, Sector 3, Bucuresti, email:
            gdpr@senflua.ro. Vom raspunde solicitarii in concordanta cu
            prevederile legale aplicabile in vigoare.
            <br /> Politica de confidentialitate a fost generata de , ©
            copyright si drepturi de proprietate intelectuala.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default SectionTextPoliticaConf;
