import React, { useState, useEffect } from "react";

import SenfluaLife from "../../assests/images/senfluaLife.svg";
import SenfluaTHINGS from "../../assests/images/senfluaTHINGS.svg";
import senfluaMalpraxis from "../../assests/images/SenfluaForm/senfluaMalpraxis.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipAsigurare({ page, setPage, formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="nameTitle2" style={{ marginTop: "53px" }}>
        {t("typeForm.tipAsigurare.title")}
      </p>
      <p className="namesubTitle"></p>
      <div style={{ marginBottom: "30px" }}>
        <label
          className="senfluaFormTipAsigTip image-with-hover-cursor"
          style={{ width: "213px", height: "179px" }}
          onClick={(event) => {
            setPage(6);
            setFormAig({
              ...formAig,
              asigurare: 1,
            });
          }}
        >
          <img src={SenfluaLife} className="senfluaFormTipAsigImg" />
          Senflua Life
          <spam style={{ color: "white" }}> .</spam>
        </label>

        <label
          className="senfluaFormTipAsigTip image-with-hover-cursor"
          style={{ width: "213px", height: "179px" }}
          onClick={(event) => {
            setPage(16);
            setFormAig({
              ...formAig,
              asigurare: 2,
            });
          }}
        >
          <img
            src={SenfluaTHINGS}
            className="senfluaFormTipAsigImg"
            onClick={(event) => setPage(16)}
          />
          Senflua Things
          <spam style={{ color: "white" }}> .</spam>
        </label>

        <label
          className="senfluaFormTipAsigTip image-with-hover-cursor"
          style={{ width: "213px", height: "179px" }}
          onClick={(event) => {
            setPage(37);
            setFormAig({
              ...formAig,
              asigurare: 3,
            });
          }}
        >
          <img src={senfluaMalpraxis} className="senfluaFormTipAsigImg" />
          Senflua Business
          <spam>& Senflua Malpraxis</spam>
        </label>
      </div>
    </div>
  );
}

export default TipAsigurare;
