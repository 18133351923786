import React, { Fragment } from "react";
import HeaderRCA from "../HeaderRCA/HeaderRCA";
import FooterRCA from "../FooterRCA/FooterRCA";
import Eroare1 from "./../message/Eroare/Eroare";

const Eroare = () => {
  return (
    <Fragment>
      <HeaderRCA />
      <Eroare1 />
      <FooterRCA />
    </Fragment>
  );
};

export default Eroare;
