import React, { useState, useEffect } from "react";

import { useLanguage } from "../utils/utils.jsx";

function AltProdus({
  page,
  setPage,
  formAigurari,
  setFormAigurari,
  formAig,
  setFormAig,
}) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="nameTitle2">{t("typeForm.altProdus.title")}</p>
      <spam className="namesubTitle"></spam>
      <div>
        <label
          className="senfluaFormAltProdus image-with-hover-cursor"
          onClick={(event) => {
            setPage(3);
            setFormAigurari([...formAigurari, formAig]);
            setFormAig({
              ...formAig,
              asigurare: 0,
              senfluaLife: {
                ...formAig.senfluaLife,
                viataCalatorie: 0,
                pemine: false,
                pertener: false,
                copil: false,
                protectiafamilie: false,
                studii: false,
                calatorit: false,
                altproiect: false,
                economie: false,
                investitii: false,
                protectieFinanciara: false,
                acoperireMedicala: false,
                profesional: false,
                personal: false,
                luna1: false,
                luna3: false,
                an: false,
                romania: false,
                europa: false,
                alteRegiuni: false,
              },
              senfluaThinhs: {
                ...formAig.senfluaThinhs,
                asig: 0,
                casa: false,
                apartament: false,
                resedinta: false,
                munca: false,
                resedintaSecundara: false,
                inundatie: false,
                furst: false,
                catastrofe: false,
                rca: false,
                casco: false,
                gep: false,
                autoturism: false,
                hybrid: false,
                putin8000: false,
                maimult8000: false,
                maimult15000: false,
                urban: false,
                scuter: false,
                adv: false,
                chopper: false,
                alteAsig: false,
                telefon: false,
                laptop: false,
                bicicleta: false,
                altele: false,
                nou: false,
                utilizat: false,
                degradat: false,
              },
              senfluaBusiness: {
                ...formAig.senfluaBusiness,
                cladire: false,
                auto: false,
                cmr: false,
                malpraxis: false,
                sanatate: false,
                alte: false,
              },
            });
          }}
        >
          {t("typeForm.altProdus.v1")}
        </label>

        <label
          className="senfluaFormAltProdus image-with-hover-cursor"
          onClick={(event) => {
            setFormAigurari([...formAigurari, formAig]);
            setFormAig((prevFormAig) => ({
              ...prevFormAig,
              asigurare: "",
              senfluaLife: Object.fromEntries(
                Object.entries(prevFormAig.senfluaLife).map(([key, value]) => [
                  key,
                  false,
                ])
              ),
              senfluaThinhs: Object.fromEntries(
                Object.entries(prevFormAig.senfluaThinhs).map(
                  ([key, value]) => [key, false]
                )
              ),
              senfluaBusiness: Object.fromEntries(
                Object.entries(prevFormAig.senfluaBusiness).map(
                  ([key, value]) => [key, false]
                )
              ),
            }));
            setPage(4);
          }}
        >
          {t("typeForm.altProdus.v2")}
        </label>
      </div>
    </div>
  );
}

export default AltProdus;
