import React, { useState, useEffect } from "react";

import { useLanguage } from "../utils/utils.jsx";

function Name({ formPers, setFormPers, checkbox, setCheckbox }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <spam className="nameTitle" style={{ marginTop: "20px" }}>
        {t("typeForm.name.hello")}
      </spam>
      <spam className="nameTitle2">{t("typeForm.name.title")}</spam>
      <p className="namesubTitle">{t("typeForm.name.subtitle1")}</p>
      <input
        type="text"
        name="name"
        value={formPers.name}
        onChange={(event) =>
          setFormPers({
            ...formPers,
            name: event.target.value,
          })
        }
        placeholder={t("typeForm.name.text")}
        className="snfluaNameInput"
      />
      <div>
        <label className="senfluaFormCkeck">
          <input
            type="checkbox"
            required="required"
            checked={checkbox.checkbox1}
            onChange={(event) =>
              setCheckbox({
                ...checkbox,
                checkbox1: event.target.checked,
              })
            }
          />
          {t("typeForm.name.subtitle2")}
        </label>
        <label className="senfluaFormCkeck">
          <input
            type="checkbox"
            required="required"
            checked={checkbox.checkbox2}
            onChange={(event) =>
              setCheckbox({
                ...checkbox,
                checkbox2: event.target.checked,
              })
            }
          />
          {t("typeForm.name.subtitle3")}
        </label>
      </div>
    </div>
  );
}

export default Name;
