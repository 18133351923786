import React, { useState, useEffect } from "react";

import smartphone from "../../assests/images/SenfluaForm/smartphone.svg";
import laptop from "../../assests/images/SenfluaForm/laptop.svg";
import bicicleta from "../../assests/images/SenfluaForm/bicicleta.svg";
import altele from "../../assests/images/SenfluaForm/altele.svg";

import { useLanguage } from "../utils/utils.jsx";

function TipAlte({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.tipAlte.title")}</p>
      <p className="namesubTitle">{t("typeForm.tipAlte.subtitle")}</p>

      <div>
        <label
          className={
            formAig.senfluaThinhs.telefon
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                telefon: !formAig.senfluaThinhs.telefon,
              },
            }));
          }}
          style={{ width: "216px", height: "101px" }}
        >
          <img
            src={smartphone}
            className="senfluaFormTipAsigImgLife2 image-with-hover-cursor"
          />
          {t("typeForm.tipAlte.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.laptop
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                laptop: !formAig.senfluaThinhs.laptop,
              },
            }));
          }}
          style={{ width: "98px", height: "101px" }}
        >
          <img
            src={laptop}
            className="senfluaFormTipAsigImgLife2 image-with-hover-cursor"
          />
          {t("typeForm.tipAlte.v2")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.bicicleta
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                bicicleta: !formAig.senfluaThinhs.bicicleta,
              },
            }));
          }}
          style={{ width: "113px", height: "101px" }}
        >
          <img src={bicicleta} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipAlte.v3")}
        </label>
        <label
          className={
            formAig.senfluaThinhs.altele
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                altele: !formAig.senfluaThinhs.altele,
              },
            }));
          }}
          style={{ width: "86px", height: "101px" }}
        >
          <img src={altele} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.tipAlte.v4")}
        </label>
      </div>
    </div>
  );
}

export default TipAlte;
