import "./App.css";
import React from "react";

import Eroare from "./components/Pages/Eroare";
import Home from "./components/Pages/Home";
import FormRCA from "./components/Pages/FormRCA";
import RCA_buyTBI from "./components/Pages/RCA_buyTBI";

import TermeniConditii from "./components/Pages/TermeniConditii";
import PoliticaConf from "./components/Pages/PoliticaConf";
import PageCOOKIES from "./components/Pages/PageCOOKIES";
import ConfirmareRCA from "./components/Pages/ConfirmareRCA";
import RCALandingPage from "./components/Pages/RCA_LandingPage";
import PageConfirmareMesaj from "./components/Pages/PageConfirmareMesaj";
import SenfluaForm from "./components/Pages/SenfluaForm";
import PageContact from "./components/Pages/PageContact";
import TravelLandingPage from "./components/Pages/Travel_LandingPage";
import ConfirmareEurolife from "./components/Pages/ConfirmareEurolife";

import NewTravel from "./components/Pages/NewTravel";
import ConfirmareHomeProtect from "./components/Pages/ConfirmareHomeProtect";

import Locuinte from "./components/Pages/Locuinte";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import ReactGA from "react-ga";
// const TRACKING_ID = "GTM-W3VWVMV";
// ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/asigurare-rca" element={<FormRCA />} />
        <Route path="/rca" element={<RCALandingPage />} />
        <Route path="/rca-confirmare/*" element={<ConfirmareRCA />} />
        <Route path="/travel" element={<TravelLandingPage />} />
        <Route path="/eroare/*" element={<Eroare />} />
        <Route path="/pagina-contact/" element={<PageContact />} />
        <Route path="/termeni-conditii" element={<TermeniConditii />} />
        <Route path="/politica-confidentialitate" element={<PoliticaConf />} />
        <Route path="/page-coockies" element={<PageCOOKIES />} />
        <Route
          path="/confirmare-mesaj"
          element={<PageConfirmareMesaj title="formular" />}
        />
        <Route
          path="/confirmare-senflua-form"
          element={<PageConfirmareMesaj title="SenfluaAsistent" />}
        />
        <Route path="/senflua-form" element={<SenfluaForm />} />
        {/* <Route path="/asigurare-travel" element={<FormTravelEuroLife />} /> */}
        <Route path="/confirmare-travel/*" element={<ConfirmareEurolife />} />

        <Route path="/RCA_buyTBI" element={<RCA_buyTBI />} />
        {/* <Route path="/homeprotect" element={<HomeProtect />} /> */}
        <Route path="/asigurare-travel" element={<NewTravel />} />

        <Route path="/asigurare-casa-ta" element={<Locuinte />} />
        <Route
          path="/confirmare-casa-ta/*"
          element={<ConfirmareHomeProtect />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
