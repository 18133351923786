import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

import "./Travel_buy_Eurolife_New.css";
import Calatoria1 from "../../assests/images/Travel/calatoria1.svg";
import sageata from "../../assests/images/RCA/sageata.svg";

import next from "../../assests/images/RCA/next.png";
import polite from "../../assests/images/RCA/polite.png";

import Calatorie from "./Calatorie";
import Calator from "./Calator";
import Oferte from "./Oferte";
import Polita from "./Polita";

import hand from "../../assests/images/RCA/hand.svg";
import contacteeazaSenflua from "../../assests/images/contacteeazaSenflua.svg";

import { useLanguage } from "../utils/utils.jsx";

const Travel_buy_Eurolife_New = () => {
  useEffect(() => {
    document.title = "Senflua - Asigurare Travel Eurolife";
  }, []);

  const { t, i18n } = useLanguage();

  const field1Ref = useRef(null);
  const field2Ref = useRef(null);
  const field3Ref = useRef(null);

  const [page, setPage] = useState(0);
  const [oferta, setOferta] = useState([]);

  const [errorPage1Necompletat, setErrorPage1Necompletat] = useState(false);
  const [errorPage2Necompletat, setErrorPage2Necompletat] = useState(false);

  const [errorPage2TC, setErrorPage2TC] = useState(false);
  const [oras, setOras] = useState(false);
  const [judet, setJudet] = useState(false);
  const [tari, setTari] = useState([]);

  const [showNewPeople, setShowNewPeople] = useState(false);

  const [validareCNP, setValidareCNP] = useState(false);

  const [buttonNext, setButtonNext] = useState(false);

  const [judete, setJudete] = useState([]);

  const navigate = useNavigate();

  const [person, setPerson] = useState({
    tip_persoana: 1,
    cnp_cui: "",
    nume: "",
    prenume: "",
    cetatenie: "146",
    varsta: 0,
    data_nasterii: null,
    rezident: "1",
    traveler: false,
    voucher: "",
    adresa: {
      tip: "",
      tara: 300,
      taraName: t("travelEurolife.select"),
      judetNo: 1,
      oraseNo: 1,
      judet: "select",
      localitate: "",
      localitate_siruta: "",
      indicativJudet: "",
      strada: "",
      numar: "",
      bloc: "",
      etaj: "",
      scara: "",
      apartament: "",
      cod_postal: "",
    },
    contact: [
      {
        tip: "EMAIL",
        valoare: "hello@senflua.ro",
      },
      {
        tip: "TELEFON_MOBIL",
        valoare: "0724542277",
      },
    ],
    actDeIndentitates: {
      tip: "CI",
      serie: "",
      numar: "",
    },
  });

  const [people, setPeople] = useState([
    { nume: "", prenume: "", cnp_cui: "" },
  ]);

  const [calatorie, setCalatorie] = useState({
    valabilitate_inceput: null,
    valabilitate_sfarsit: null,
  });

  const [produs, setProdus] = useState({
    scopul_calatoriei: "",
    tara_destinatie: "selecteaza",
    numeTara_destinatie: "",
    modalitate_transport: "selecteaza",
    nr_inmatriculare: "",
    sport_extrem: false,
    asistenta_rutiera: false,
    anularea_calatoriei: false,
    anularea_calatoriei_varianta: 0,
    bagaje: false,
    franciza: false,
    varianta: 0,
    data_platii: null,
  });

  const [FormTitles, setFormTitles] = useState([
    t("travelEurolife.calatorie.title11"),
    t("travelEurolife.informatii.title"),
    t("travelEurolife.oferte.title"),
    t("travelEurolife.polita.title"),
  ]);

  useEffect(() => {
    const handleLanguageChange = () => {
      setFormTitles([
        t("travelEurolife.calatorie.title11"),
        t("travelEurolife.informatii.title"),
        t("travelEurolife.oferte.title"),
        t("travelEurolife.polita.title"),
      ]);

      const tariActualizate = tari.map((tara) => {
        if (tara.code === "selecteaza") {
          return { ...tara, description: t("travelEurolife.select") };
        }
        return tara;
      });
      setTari(tariActualizate);
    };
    handleLanguageChange();
  }, [t]);

  async function handleNext2(event) {
    if (person.cnp_cui.length === 13) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/validareCNP?cnp=${person.cnp_cui}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setValidareCNP(data);
      } catch (error) {
        console.error(error);
        navigate("/eroare");
      }
    }

    if (
      errorPage2Necompletat === false &&
      errorPage2TC === true &&
      !validareCNP
    ) {
      window.scrollTo(0, 0);
      setPage((currPage) => currPage + 1);
    } else {
      if (
        person.nume.length === 0 ||
        person.prenume.length === 0 ||
        validareCNP ||
        (person.rezident === "1" && person.cnp_cui.length !== 13)
      ) {
        field1Ref.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        person.rezident === "1" &&
        (person.adresa.strada.length === 0 || person.adresa.numar.length === 0)
      ) {
        field2Ref.current.scrollIntoView({ behavior: "smooth" });
      } else if (person.adresa.cod_postal.length === 0) {
        field3Ref.current.scrollIntoView({ behavior: "smooth" });
      } else {
        field3Ref.current.scrollIntoView({ behavior: "smooth" });
      }
      setButtonNext(true);
    }
  }

  const europeanCountryCodes = [
    "AL",
    "AD",
    "AT",
    "BY",
    "BE",
    "BA",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FO",
    "FI",
    "FR",
    "DE",
    "GI",
    "GR",
    "HU",
    "IS",
    "IE",
    "IM",
    "IT",
    "XK",
    "LV",
    "LI",
    "LT",
    "LU",
    "MK",
    "MT",
    "MD",
    "MC",
    "ME",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SM",
    "RS",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "UA",
    "GB",
    "VA",
    "CircuitE",
  ];

  function isCountryInEurope(countryCode) {
    return europeanCountryCodes.includes(countryCode);
  }

  const [minDate2, setMinDate2] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });
  const [maxDateInceput, setMaxDateinceput] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 365);
    return tomorrow;
  });
  const [MaxDate, setMaxDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 29);
    return tomorrow;
  });

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <Calatorie
          person={person}
          setPerson={setPerson}
          calatorie={calatorie}
          setCalatorie={setCalatorie}
          produs={produs}
          setProdus={setProdus}
          minDate2={minDate2}
          setMinDate2={setMinDate2}
          MaxDate={MaxDate}
          setMaxDate={setMaxDate}
          tari={tari}
          setTari={setTari}
          buttonNext={buttonNext}
          maxDateInceput={maxDateInceput}
        />
      );
    } else if (page === 1) {
      return (
        <Calator
          person={person}
          setPerson={setPerson}
          oras={oras}
          setOras={setOras}
          judet={judet}
          setJudet={setJudet}
          errorPage2TC={errorPage2TC}
          setErrorPage2TC={setErrorPage2TC}
          judete={judete}
          produs={produs}
          setProdus={setProdus}
          people={people}
          setPeople={setPeople}
          showNewPeople={showNewPeople}
          setShowNewPeople={setShowNewPeople}
          calatorie={calatorie}
          buttonNext={buttonNext}
          setButtonNext={setButtonNext}
          tari={tari}
          setTari={setTari}
          field1Ref={field1Ref}
          field2Ref={field2Ref}
          field3Ref={field3Ref}
          validareCNP={validareCNP}
          europeanCountryCodes={europeanCountryCodes}
        />
      );
    } else if (page === 2) {
      return (
        <Oferte
          person={person}
          setPerson={setPerson}
          page={page}
          setPage={setPage}
          oferta={oferta}
          setOferta={setOferta}
          calatorie={calatorie}
          setCalatorie={setCalatorie}
          produs={produs}
          setProdus={setProdus}
          people={people}
          setPeople={setPeople}
        />
      );
    } else if (page === 3) {
      return (
        <Polita
          person={person}
          setPerson={setPerson}
          oferta={oferta}
          setOferta={setOferta}
          calatorie={calatorie}
          setCalatorie={setCalatorie}
          produs={produs}
          setProdus={setProdus}
          people={people}
          setPeople={setPeople}
        />
      );
    }
  };

  function isFieldEmpty(person, field) {
    return !person[field].trim();
  }

  useEffect(() => {
    const errorPage = async () => {
      if (page === 1) {
        if (
          person.nume.length > 0 &&
          person.prenume.length > 0 &&
          (!(person.rezident === "1") ||
            (person.cnp_cui.length === 13 &&
              person.adresa.strada.length > 0 &&
              person.adresa.numar.length > 0 &&
              person.adresa.judet !== "select" &&
              person.adresa.localitate !== "select")) &&
          person.adresa.cod_postal.length > 0 &&
          (!showNewPeople ||
            !people.every(
              (person) =>
                !person.nume.trim() &&
                !person.prenume.trim() &&
                !person.cnp_cui.trim()
            )) &&
          (!(
            produs.modalitate_transport === "Masina" &&
            isCountryInEurope(produs.tara_destinatie) &&
            produs.asistenta_rutiera === true
          ) ||
            produs.nr_inmatriculare.length > 1) &&
          (showNewPeople || person.traveler)
        ) {
          setErrorPage2Necompletat(false);
        } else {
          setErrorPage2Necompletat(true);
        }
      }
      if (
        page === 0 &&
        (produs.tara_destinatie === "selecteaza" ||
          produs.modalitate_transport === "selecteaza" ||
          !calatorie.valabilitate_inceput ||
          !calatorie.valabilitate_sfarsit ||
          produs.scopul_calatoriei.length === 0 ||
          !produs.data_platii)
      ) {
        setErrorPage1Necompletat(true);
      } else {
        setErrorPage1Necompletat(false);
      }
    };
    errorPage();
  });

  useEffect(() => {
    const fetchData = async () => {
      const tomorrow = new Date();
      const today = new Date();
      const afterTomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      afterTomorrow.setDate(afterTomorrow.getDate() + 4);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/listJudete`,

          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        setJudete([{ judet: "select" }, ...data]);
      } catch (error) {
        console.error(error);
        navigate("/eroare");
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getEurolifeTariApi`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        const tariSpeciale = [
          { code: "CircuitE", description: "Circuit Europa" },
          { code: "CircuitL", description: "Circuit Lume" },
          { code: "CFSC", description: "Circuit Lume fara SUA si Canada" },
        ];

        const dataNonSpeciale = data.filter(
          (tara) => !tariSpeciale.some((special) => special.code === tara.code)
        );

        setTari([
          { code: "selecteaza", description: t("travelEurolife.select") },
          ...tariSpeciale,
          ...dataNonSpeciale,
        ]);
      } catch (error) {
        console.error(error);
        navigate("/eroare");
      }
    };

    fetchData();
  }, []);

  return (
    <section className="section_formTravel_buy">
      <Container className="containerRCA_Travel_hand">
        <img src={hand} alt="" className="travel_hand" />
      </Container>
      <Container className="containerTravel_buy__form">
        <img
          src={contacteeazaSenflua}
          alt=""
          className="rca_sticky"
          onClick={(event) =>
            (window.location.href = "https://wa.me/40724542277")
          }
        />
        <Row className="sectionTravel_buy__form">
          <Col xl="8" lg="8" md="8" sm="12" className="sectionTravel_buy__left">
            <div
              className={
                page !== 0 ? "sectionTravel_buy__form_back" : "display-none"
              }
            >
              <img
                src={sageata}
                alt=""
                className="image-with-hover-cursor sageata_travel"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage((currPage) => currPage - 1);
                  setButtonNext(false);
                  setProdus({
                    ...produs,
                    varianta: 0,
                    anularea_calatoriei_varianta: 0,
                  });
                }}
              />
              <p
                className="sectionTravel_buy_button_back image-with-hover-cursor"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage((currPage) => currPage - 1);
                  setProdus({
                    ...produs,
                    varianta: 0,
                    anularea_calatoriei_varianta: 0,
                  });
                }}
              >
                {t("travelEurolife.back")}
              </p>
            </div>
            <p
              className={
                page === 0
                  ? "sectionRCA__left_title1"
                  : "sectionRCA__left_title12"
              }
              ref={field1Ref}
            >
              {FormTitles[page]}
            </p>
            {page === 1 ? (
              <p className="sectionTravel__left_title2" ref={field1Ref}>
                {t("travelEurolife.informatii.subtitle3")}
              </p>
            ) : null}
          </Col>

          {[0, 1, 2, 3].map((index) => (
            <Col
              lg="4"
              md="4"
              key={index}
              className={
                page === index ? "sectionTravel_buy__right2" : "display-none"
              }
            >
              {[...Array(index + 1)].map((_, i) => (
                <p key={i} className="circle"></p>
              ))}
              {[...Array(5 - index - 1)].map((_, i) => (
                <p key={i} className="circle_transparent"></p>
              ))}
            </Col>
          ))}
        </Row>

        <div>{PageDisplay()}</div>
        <p className="errorPage_RCAbuy">
          {(errorPage2Necompletat === true ||
            errorPage2TC === false ||
            errorPage1Necompletat === true) &&
          buttonNext === true
            ? t("travelEurolife.informatii.error")
            : null}
        </p>

        <Row className={page !== 2 ? "sectionRCA_buy__form11" : "non_display"}>
          <Col xl="3" lg="3" md="6" sm="12" className="sectionTravel_buy__left">
            <div
              className={
                page === 0
                  ? " image-with-hover-cursor image-button"
                  : "non_display"
              }
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={next}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "243px",
                  height: "auto",
                }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  if (errorPage1Necompletat === false) {
                    setPage((currPage) => currPage + 1);
                    setButtonNext(false);
                  } else {
                    setButtonNext(true);
                  }
                }}
              />

              <div
                onClick={() => {
                  window.scrollTo(0, 0);

                  if (errorPage1Necompletat === false) {
                    setPage((currPage) => currPage + 1);
                    setButtonNext(false);
                  } else {
                    setButtonNext(true);
                  }
                }}
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {t("travel.calatorie.button1")}
              </div>
            </div>
            <div
              className={
                page === 1
                  ? "image-with-hover-cursor image-button"
                  : "non_display"
              }
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <img
                src={polite}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "303px",
                  height: "auto",
                }}
                onClick={handleNext2}
              />

              <div
                onClick={handleNext2}
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                }}
              >
                {t("travelEurolife.informatii.button1")}
              </div>
            </div>
          </Col>
          <Col
            xl="8"
            lg="8"
            md="6"
            sm="12"
            className="sectionTravel_buy__right_img"
          >
            <img
              src={Calatoria1}
              alt=""
              className={page === 0 ? "" : "non_display"}
            />
          </Col>
          <Col lg="1" md="1"></Col>
        </Row>
      </Container>
    </section>
  );
};

export default Travel_buy_Eurolife_New;
