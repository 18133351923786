import React, { Fragment } from "react";
import Header from "../header/Header";

import ConfirmareMesaj from "../message/ConfirmareMesaj/ConfirmareMesaj";

const PageConfirmareMesaj = ({ title }) => {
  return (
    <Fragment>
      <Header />
      <ConfirmareMesaj title={title} />
    </Fragment>
  );
};

export default PageConfirmareMesaj;
