import React from "react";

import dimineata from "../../assests/images/SenfluaForm/dimineata.svg";
import dupaAmiaza from "../../assests/images/SenfluaForm/dupa-amiaza.svg";
import seara from "../../assests/images/SenfluaForm/seara.svg";

import { useLanguage } from "../utils/utils.jsx";

function Contactare({ formPers, setFormPers }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ color: "#FE9D35" }}>
        #SENFLUA.BUSINESS
      </p>
      <p className="nameTitle2">{t("typeForm.contactare.title")}</p>

      <spam className="namesubTitle">{t("typeForm.contactare.subtitle1")}</spam>
      <spam className="namesubTitle">{t("typeForm.contactare.subtitle2")}</spam>
      <div>
        <label
          className={
            formPers.orar.dimineata
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormPers((prevState) => ({
              ...prevState,
              orar: {
                ...prevState.orar,
                dimineata: !formPers.orar.dimineata,
              },
            }));
          }}
          style={{ width: "124px", height: "101px" }}
        >
          <img src={dimineata} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.contactare.v1")}
        </label>
        <label
          className={
            formPers.orar.dupaMasa
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormPers((prevState) => ({
              ...prevState,
              orar: {
                ...prevState.orar,
                dupaMasa: !formPers.orar.dupaMasa,
              },
            }));
          }}
          style={{ width: "148px", height: "101px" }}
        >
          <img src={dupaAmiaza} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.contactare.v2")}
        </label>

        <label
          className={
            formPers.orar.seara
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormPers((prevState) => ({
              ...prevState,
              orar: {
                ...prevState.orar,
                seara: !formPers.orar.seara,
              },
            }));
          }}
          style={{ width: "85px", height: "101px" }}
        >
          <img src={seara} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.contactare.v3")}
        </label>
      </div>
    </div>
  );
}

export default Contactare;
