import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

import Popup from "./Popups";

const TabBar = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [cookiesPopUp, setCookiePopUp] = useCookies(["popupDisplayedSenflua"]);

  useEffect(() => {
    const popupDisplayedSenflua = cookiesPopUp.popupDisplayedSenflua;
    if (!popupDisplayedSenflua) {
      setCookiePopUp("popupDisplayedSenflua", true);
    }

    const handleMouseOut = (event) => {
      if (
        event.clientY <= 0 &&
        !event.relatedTarget &&
        !popupDisplayedSenflua &&
        window.innerWidth > 768
      ) {
        setCookiePopUp("popupDisplayedSenflua", true);
        setShowPopup(true);
      }
    };

    window.addEventListener("mouseout", handleMouseOut);

    return () => {
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, []);

  const handleClosePopup = () => {
    setCookiePopUp("popupDisplayedSenflua", true);
    setShowPopup(false);
    window.location.reload();
  };

  return (
    <div>
      {showPopup && window.innerWidth > 768 && (
        <Popup onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default TabBar;
