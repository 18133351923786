import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

export function useLanguage() {
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["Senflua.language"]);
  const language = cookies["Senflua.language"];

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, []);

  return { t, i18n };
}
