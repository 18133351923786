import React from "react";

import Casa from "../../assests/images/SenfluaForm/casa.svg";
import Apartament from "../../assests/images/SenfluaForm/apartament.svg";

import { useLanguage } from "../utils/utils.jsx";

function CasaThings({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();
  return (
    <div className="nameSection">
      <p
        className="numeTileTip"
        style={{ marginTop: "20px", color: "#66B6FF" }}
      >
        #senflua.THINGS
      </p>
      <p className="nameTitle2">{t("typeForm.casaThings.title")}</p>
      <p className="namesubTitle">{t("typeForm.casaThings.subtitle")}</p>

      <div>
        <label
          className={
            formAig.senfluaThinhs.casa
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                casa: !formAig.senfluaThinhs.casa,
              },
            }));
          }}
          style={{ width: "85px", height: "101px" }}
        >
          <img src={Casa} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.casaThings.v1")}
        </label>

        <label
          className={
            formAig.senfluaThinhs.apartament
              ? "senfluaFormTipAsigLife_active image-with-hover-cursor"
              : "senfluaFormTipAsigLife image-with-hover-cursor"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaThinhs: {
                ...prevState.senfluaThinhs,
                apartament: !formAig.senfluaThinhs.apartament,
              },
            }));
          }}
          style={{ width: "138px", height: "101px" }}
        >
          <img src={Apartament} className="senfluaFormTipAsigImgLife2" />
          {t("typeForm.casaThings.v2")}
        </label>
      </div>
    </div>
  );
}

export default CasaThings;
