import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Spinner from "react-bootstrap/Spinner";

import "./RCA_buy.css";

import Allianz from "../../assests/images/ensure/Allianz.svg";
import Grawe from "../../assests/images/ensure/Grawe.svg";
import Generali from "../../assests/images/ensure/Generalli.svg";
import Asirom from "../../assests/images/ensure/asirom.png";
import Uniqa from "../../assests/images/ensure/Uniqa.svg";
import Omniasig from "../../assests/images/ensure/Omniasig.svg";
import Euroins from "../../assests/images/ensure/Euroins.svg";
import Groupama from "../../assests/images/ensure/Groupama.svg";
import Axeria from "../../assests/images/ensure/Axeria.svg";
import Ergo from "../../assests/images/ensure/Ergo.svg";
import Eurolife from "../../assests/images/ensure/Eurolife.svg";
import Gothaer from "../../assests/images/ensure/Gothaer.svg";
import Hellas from "../../assests/images/ensure/hellasdirect.png";

import TBIBank from "../../assests/images/homePage/Travel_btn.png";

import Plata from "../../assests/images/RCA/Plata_si_gata_btn.png";
// import loadingGif from "../../assests/images/Travel/loader100.gif";

import { useTranslation } from "react-i18next";
import "../../i18n";

function Informatii({ formData, setFormData, oferta, setOferta }) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["formData"]);
  const [cookiesOferta, setCookieOferta] = useCookies(["oferta"]);
  const [cookiesOrderId, setCookieOrderId] = useCookies(["plata"]);

  const [loading, setLoading] = useState(false);
  const [oferta1, setOferta1] = useState([]);
  const [plata, setPlata] = useState([]);

  const [plataTBI, setPlataTBI] = useState([]);
  const [oferta2, setOferta2] = useState([]);

  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("07");

  const poze = {
    Allianz: Allianz,
    Grawe: Grawe,
    Generali: Generali,
    Asirom: Asirom,
    Uniqa: Uniqa,
    Omniasig: Omniasig,
    Euroins: Euroins,
    Groupama: Groupama,
    Axeria: Axeria,
    Ergo: Ergo,
    Eurolife: Eurolife,
    Gothaer: Gothaer,
    Hellas: Hellas,
  };

  const [tc, setTc] = useState(false);
  const [pc, setPc] = useState(false);

  let intr1 = 0;
  useEffect(() => {
    const linkPlata = async () => {
      const requestOptions1 = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ auto: formData, oferta: oferta2 }),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tbiBanckOferta`,
        requestOptions1
      );

      const json = await response.json();

      if (json.error === false) {
        window.location.assign(json.link);
      } else {
        navigate("/eroare_RCA");
      }
      setPlataTBI(json);
    };
    if (number.length > 9 && intr === 0) {
      if (oferta1.error === true) {
        window.location.assign(plata.formUrl);
        navigate("/eroare_RCA");
        intr1 = 1;
      } else {
        intr1 = 1;
        linkPlata();
      }
    }
  }, [oferta2]);

  let intr2 = 0;
  const fetchDataFinalTBI = async () => {
    if (intr2 === 0) {
      intr2 = 1;
      const updatedFormData = {
        ...formData,
        ensure: oferta.societate_nume,
        mail: email,
        telefon: number,
      };

      setFormData({
        ...formData,
        ensure: oferta.societate_nume,
        mail: email,
        telefon: number,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      };

      const response1 = await fetch(
        `${process.env.REACT_APP_API_URL}/getCotatieFinal`,
        requestOptions
      );

      const json1 = await response1.json();

      setOferta2(json1);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  };

  const validateNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };

  const fetchDataFinal = async () => {
    const updatedFormData = {
      ...formData,
      ensure: oferta.societate_nume,
      mail: email,
      telefon: number,
    };

    setFormData({
      ...formData,
      ensure: oferta.societate_nume,
      mail: email,
      telefon: number,
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFormData),
    };

    const response1 = await fetch(
      `${process.env.REACT_APP_API_URL}/getCotatieFinal`,
      requestOptions
    );

    const json1 = await response1.json();

    setOferta1(json1);
  };
  let intr = 0;
  useEffect(() => {
    const linkPlata = async () => {
      setCookieOferta("oferta", oferta1);
      setCookie("formData", formData);

      const requestOptions1 = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ auto: formData, oferta: oferta1 }),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/generareUrlPlata`,
        requestOptions1
      );

      const json = await response.json();

      setPlata(json);
    };
    if (number.length > 9 && intr === 0) {
      if (oferta1.error === true) {
        window.location.assign(plata.formUrl);
        navigate("/eroare_RCA");
        intr = 1;
      } else {
        linkPlata();
      }
    }
  }, [oferta1]);

  useEffect(() => {
    if (plata.error === false && number.length > 9) {
      setCookieOrderId("plata", plata.orderId);
      window.location.assign(plata.formUrl);
    } else if (number.length > 9 && plata.error === true) {
      navigate("/eroare_RCA");
    }
  }, [plata]);

  return (
    <div>
      <Row className="sectionRCA_buy__form">
        <Col xl="5" lg="5" md="12" sm="12" className="sectionRCA_buy__left">
          <label className="sectionRCA_buy__Polita_subtitle">
            {t("rca.polita.text")}
          </label>
          <br />
          <label className="sectionRCA_buy__Polita_info">
            {formData.nume} {formData.prenume}
          </label>
          <br />
          <label className="sectionRCA_buy__Polita_info">
            {formData.numar_inmatriculare}
          </label>
          <br />
          <label className="sectionRCA_buy__Polita_info">
            {formData.marca} {formData.model}
          </label>
          <br />
          <label className="sectionRCA_buy__Polita_info">
            {formData.serie_sasiu}
          </label>
          <br />
          <label className="sub_title_rca_buy">
            <strong> {t("rca.polita.request1")} </strong>{" "}
            {t("rca.polita.request12")}
            <br />
            <input
              type="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </label>
          <br />
          <label className="sub_title_rca_buy">
            <strong>{t("rca.polita.request2")}</strong>
            <br />
            <input type="text" value={number} onChange={validateNumber} />
          </label>

          <p className="errorPage_RCAbuy_polita">
            {number.length < 10 ? t("rca.polita.allert1") : null}
          </p>
          <p className="errorPage_RCAbuy_polita">
            {!validateEmail(email) ? t("rca.polita.allert2") : null}
          </p>
        </Col>

        <Col
          xl="6"
          lg="6"
          md="12"
          sm="12"
          className="sectionRCA_buy__Polita_right"
        >
          <label className="sectionRCA_buy__Polita_subtitle">
            {t("rca.polita.text2")} {oferta.societate_nume}{" "}
            {t("rca.polita.text3")} {formData.data_start}
          </label>
          <Row className="sectionRCA_buy__left_det">
            <Col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              className="sectionRCA_buy__left_border"
            >
              <label className="sectionRCA_buy__Polita_detalii">
                {formData.perioada} {t("rca.polita.text1")}
              </label>
            </Col>
            <Col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              className="sectionRCA_buy__left_det"
            >
              <label className="sectionRCA_buy__Polita_detalii">
                {oferta.prima}
              </label>
            </Col>
            <Col
              xl="6"
              lg="6"
              md="6"
              sm="12"
              className="sectionRCA_buy__left_det_img"
            >
              <img
                src={poze[oferta.societate_nume]}
                alt=""
                className="sectionRCA_buy__Oferte_img_border"
              />
            </Col>
          </Row>
          <hr className="sectionRCA_buy_Polita_line"></hr>
          <br />

          <Row className="sectionRCA_buy__left_0">
            <Col xl="6" lg="6" md="6" sm="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_subtitle">
                {t("rca.polita.text5")}
              </label>
            </Col>

            <Col xl="6" lg="6" md="6" sm="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_rigt">
                {t("rca.polita.text6")}
              </label>
            </Col>
          </Row>
          <Row className="sectionRCA_buy__left_0">
            <Col xl="6" lg="6" md="6" sm="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_subtitle">
                {t("rca.polita.text7")}
              </label>
            </Col>

            <Col xl="6" lg="6" md="6" sm="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_rigt">
                {oferta.comision_procent} %
              </label>
            </Col>
          </Row>
          <Row className="sectionRCA_buy__left_0">
            <Col xl="6" lg="6" md="6" sm="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_subtitle">
                {t("rca.polita.text8")}
              </label>
            </Col>

            <Col xl="6" lg="6" md="6" sm="6" className="sectionRCA_buy__left_0">
              <label className="sectionRCA_buy__Polita_subtitle_right">
                {oferta.prima} lei
              </label>
            </Col>
          </Row>
        </Col>
        <Col lg="1" md="1" className="sectionRCA_buy__left"></Col>
      </Row>

      <div className="sectionRCA__left_check1">
        <label>
          <input
            type="checkbox"
            required="required"
            onChange={(e) => setTc(e.target.checked)}
          />
          {t("rca.polita.check1")}
          <a href="/termeni-conditii" target="_blank">
            {t("rca.polita.check11")}{" "}
          </a>
        </label>
      </div>
      <div className="sectionRCA__left_check">
        <label>
          <input
            type="checkbox"
            required="required"
            onChange={(e) => setPc(e.target.checked)}
          />
          {t("rca.polita.check2")}
          <a href="/politica-confidentialitate" target="_blank">
            {t("rca.polita.check21")}
          </a>
        </label>
      </div>

      <div className="sectionRCA_buy__Polita_title_subsol">
        {loading === false ? (
          <div
            className="image-button"
            style={{
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <img
              src={Plata}
              alt=""
              className="image-with-hover-cursor"
              style={{
                width: "303px",
                height: "auto",
              }}
              onClick={() => {
                if (
                  validateEmail(email) &&
                  number.length > 9 &&
                  tc === true &&
                  pc === true
                ) {
                  setLoading(true);
                  fetchDataFinal();
                }
              }}
            />

            <div
              onClick={() => {
                if (
                  validateEmail(email) &&
                  number.length > 9 &&
                  tc === true &&
                  pc === true
                ) {
                  setLoading(true);
                  fetchDataFinal();
                }
              }}
              className="button-text image-with-hover-cursor"
              style={{
                marginLeft: "10px",
                marginTop: "-30px",
              }}
            >
              {t("rca.polita.button1")}
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "20px",
              }}
            >
              <p>sau</p>
            </div>
            <div
              className="image-button"
              onClick={() => {
                if (
                  validateEmail(email) &&
                  number.length > 9 &&
                  tc === true &&
                  pc === true
                ) {
                  setLoading(true);
                  fetchDataFinalTBI();
                }
              }}
            >
              <img
                src={TBIBank}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "230px",
                  height: "auto",
                }}
              />

              <div
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "15px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  if (
                    validateEmail(email) &&
                    number.length > 9 &&
                    tc === true &&
                    pc === true
                  ) {
                    setLoading(true);
                    fetchDataFinalTBI();
                  }
                }}
              >
                Plata in rate prin TBI Bank
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Spinner
              animation="border"
              role="status"
              style={{
                width: "100px",
                height: "100px",
              }}
            ></Spinner>
          </div>
        )}
      </div>
    </div>
  );
}

export default Informatii;
