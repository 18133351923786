import React from "react";
import { Container, Row, Col } from "reactstrap";

import RCAbtn from "../../assests/images/RCA_btn.png";
import Travelbtn from "../../assests/images/Travel_btn.png";
import IaLegatura from "../../assests/images/iaLegatura_btn.png";
import teleportareaeInviitor from "../../assests/images/teleportareaeInviitor.svg";

import "./SectionTextCOOKIES.css";

const SectionTextCOOKIES = () => {
  return (
    <section className="sectionCOOKIES">
      <Container>
        <div>
          <p className="sectionCOOKIESText">
            Pe acest site se folosesc cookie-uri. Acestea nu dăunează
            computerului dvs. şi nu conţin viruşi, ci au rolul de a contribui la
            o utilizare mai facilă, eficientă şi sigură a site-ului. Ele
            reprezintă fişiere-texte de mici dimensiuni care sunt salvate pe
            computerul dvs. şi sunt salvate de browser-ul utilizat.
            <br />
            <br /> Multe dintre cookie-urile folosite se numesc „session
            cookies”, care sunt şterse automat după vizita pe acest site. Altele
            rămân în memoria computerului dvs. până la ştergerea acestora de
            către dvs., acestea făcând posibilă recunoaşterea browser-ului la o
            vizită ulterioară.
            <br />
            <br /> Puteți configura browser-ul dvs. pentru a vă informa cu
            privire la utilizarea cookie-urilor, astfel încât să puteți decide
            de la caz la caz dacă să acceptați sau să respingeți un cookie. În
            mod alternativ, browser-ul dvs. poate fi configurat să accepte
            automat cookie-urile în anumite condiții sau să le respingă
            întotdeauna sau să șterge automat cookie-urile la închiderea
            browser-ului. Dezactivarea modulelor cookie poate limita
            funcționalitatea acestui site web.
            <br />
            <br /> Cookie-urile care sunt necesare pentru a permite
            comunicațiile electronice sau pentru a oferi anumite funcții pe care
            doriți să le utilizați (cum ar fi coșul de cumpărături) sunt stocate
            în conformitate cu prevederile art. 6 alin.1 lit. f) din GDPR,
            conform cărora prelucrarea este legală numai dacă şi în măsura în
            care este necesară în scopul intereselor legitime urmărite de
            operator sau de o parte terţă. Prin urmare, operatorul acestui
            website are un interes legitim în stocarea anumitor cookie-uri,
            pentru a asigura o optimizare fără erori tehnice. Alte cookie-uri
            (cum ar fi, spre exemplu, cele utilizate pentru analizarea
            comportamentului dvs. de navigare) sunt de asemenea stocate şi vor
            fi tratate separat în acest document.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default SectionTextCOOKIES;
