import React from "react";
import { Container, Row, Col } from "reactstrap";

import Accordion from "../../Accordion/Accordion";

import Faq_teleport_human from "../../../assests/images/faq_teleport_human.svg";
import Faw_teleport_senflua from "../../../assests/images/faw_teleport_senflua.svg";
import StartTeleportare_btn from "../../../assests/images/homePage/StartTeleportare_btn.png";

import "./Section8.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section8 = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="section8">
      <Container>
        <div className="section8_container">
          <div className="section8_Title">
            <p>{t("section8.title")}</p>
          </div>
          <Accordion />
        </div>

        <Row>
          <Col lg="3" md="3">
            <div className="section8_foodter_left">
              <img src={Faq_teleport_human} alt="" />
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className="section8_foodter_center">
              <p>{t("section8.subtitle")}</p>
            </div>
            <div className="section8_button">
              <a href="./senflua-form">
                <div className="image-button">
                  <img
                    src={StartTeleportare_btn}
                    alt=""
                    className="image-with-hover-cursor"
                    onClick={(event) =>
                      (window.location.href = "/senflua-form")
                    }
                    style={{
                      width: "206px",
                      height: "auto",
                    }}
                  />
                  <div
                    className="button-text image-with-hover-cursor"
                    style={{
                      color: "white",
                      marginLeft: "10px",
                      marginTop: "7px",
                    }}
                  >
                    {t("section7.button")}
                  </div>
                </div>
              </a>
            </div>
          </Col>
          <Col lg="3" md="3">
            <div className="section8_foodter_right">
              <img src={Faw_teleport_senflua} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section8;
