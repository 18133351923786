import React from "react";
import { Container, Row, Col } from "reactstrap";

import Notificari from "../../../assests/images/benefits/con_notificari.svg";
import Informatii from "../../../assests/images/benefits/icon_informatii.svg";
import Birocratie from "../../../assests/images/benefits/Icon_lipsaBirocratie.svg";
import Transparent from "../../../assests/images/benefits/icon_procesTransparent.svg";
import Sfaturi from "../../../assests/images/benefits/Icon_sfaturi.svg";
import LaIndemana from "../../../assests/images/benefits/icon_totulLaIndemana.svg";
import CumMaPortez_btn from "../../../assests/images/homePage/cumMaPortez_btn.png";

import "./Section5.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section5 = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="section5">
      <Container>
        <div className="section5__Title" id="o-viata-altfel">
          <p>{t("section5.title")}</p>
        </div>
        <div className="section5__subTitle">
          <p>{t("section5.subtitle")}</p>
        </div>

        <Row className="section5__beneficii_1">
          <Col lg="2" md="2">
            <div className="section5__content">
              <img src={Sfaturi} alt="" className="Sfaturi" />
            </div>
          </Col>

          <Col lg="4" md="4" className="section5__textBeneficii">
            <div className="section5__textBeneficii_Title">
              <p>{t("section5.titleList1")}</p>
            </div>
            <div className="section5__textBeneficii_subTitle">
              <p>{t("section5.descriptionList1")}</p>
            </div>
          </Col>

          <Col lg="2" md="2">
            <div className="section5__content">
              <img src={Informatii} alt="" className="Informatii" />
            </div>
          </Col>

          <Col lg="4" md="4" className="section5__textBeneficii">
            <div className="section5__textBeneficii_Title">
              <p>{t("section5.titleList2")}</p>
            </div>
            <div className="section5__textBeneficii_subTitle">
              <p>{t("section5.descriptionList2")}</p>
            </div>
          </Col>
        </Row>

        <Row className="section5__beneficii">
          <Col lg="2" md="2">
            <div className="section5__content">
              <img src={Birocratie} alt="" className="Birocratie" />
            </div>
          </Col>

          <Col lg="4" md="4" className="section5__textBeneficii">
            <div className="section5__textBeneficii_Title">
              <p>{t("section5.titleList3")}</p>
            </div>
            <div className="section5__textBeneficii_subTitle">
              <p>{t("section5.descriptionList3")}</p>
            </div>
          </Col>

          <Col lg="2" md="2">
            <div className="section5__content">
              <img src={LaIndemana} alt="" className="LaIndemana" />
            </div>
          </Col>

          <Col lg="4" md="4" className="section5__textBeneficii">
            <div className="section5__textBeneficii_Title">
              <p>T{t("section5.titleList4")}</p>
            </div>
            <div className="section5__textBeneficii_subTitle">
              <p>{t("section5.descriptionList4")}</p>
            </div>
          </Col>
        </Row>

        <Row className="section5__beneficii">
          <Col lg="2" md="2">
            <div className="section5__content">
              <img src={Transparent} alt="" className="Transparent" />
            </div>
          </Col>

          <Col lg="4" md="4" className="section5__textBeneficii">
            <div className="section5__textBeneficii_Title">
              <p>{t("section5.titleList5")}</p>
            </div>
            <div className="section5__textBeneficii_subTitle">
              <p>{t("section5.descriptionList5")}</p>
            </div>
          </Col>

          <Col lg="2" md="2">
            <div className="section5__content">
              <img src={Notificari} alt="" className="Notificari" />
            </div>
          </Col>

          <Col lg="4" md="4" className="section5__textBeneficii">
            <div className="section5__textBeneficii_Title">
              <p>{t("section5.titleList6")}</p>
            </div>
            <div className="section5__textBeneficii_subTitle">
              <p>{t("section5.descriptionList6")}</p>
            </div>
          </Col>
        </Row>

        <div className="section5__Btn">
          <div className="image-button">
            <a className="meniu_mobile_open2" href="#pasi-de-facut">
              <img
                src={CumMaPortez_btn}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "166px",
                  height: "auto",
                }}
              />
            </a>

            <div
              className="button-text image-with-hover-cursor"
              onClick={(event) => (window.location.href = "/senflua-form")}
              style={{
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              {t("section5.button")}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Section5;
