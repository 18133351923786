import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

import teleportareaeInviitor from "../../assests/images/teleportareaeInviitor.svg";

import "./HeroCondtii.css";

function HeroCondtii({ title }) {
  const location = useLocation();

  return (
    <section className="heroConditii">
      <Container>
        <Row>
          <Col lg="8" md="12" sm="12" xs="12" className="heroConditiiColText">
            <p className="heroConditiiText">{title}</p>
          </Col>
          <Col lg="4" md="12" sm="12" xs="12" className="heroConditiiColFoto">
            <img
              src={teleportareaeInviitor}
              alt=""
              className="heroConditiiFoto"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeroCondtii;
