import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useNavigate } from "react-router-dom";

import "./Travel_buy_Eurolife_New.css";
import Bagaj from "../../assests/images/Travel/bagaj.svg";
import Add from "../../assests/images/Travel/add.svg";
import inapoi_icon from "../../assests/images/RCA/inapoi_icon.svg";

import Whatsapp_btn from "../../assests/images/Whatsapp_btn.png";
import Logo from "../../assests/images/logo.svg";

import { useLanguage } from "../utils/utils.jsx";

function Calator({
  person,
  setPerson,
  oras,
  setOras,
  judet,
  setJudet,
  errorPage2TC,
  setErrorPage2TC,
  judete,
  produs,
  setProdus,
  people,
  setPeople,
  showNewPeople,
  setShowNewPeople,
  calatorie,
  buttonNext,
  setButtonNext,
  tari,
  setTari,
  field1Ref,
  field2Ref,
  field3Ref,
  validareCNP,
  europeanCountryCodes,
}) {
  const { t, i18n } = useLanguage();
  const navigate = useNavigate();

  const [tc, setTc] = useState(false);
  const [pc, setPc] = useState(false);
  const [showPopupCons, setShowPopupCons] = useState(false);
  const [showPopupInfo, setShowPopupInfo] = useState(false);

  const today = new Date();
  const formattedToday = today.toISOString().slice(0, 10);
  const dataInceput = new Date(calatorie.valabilitate_inceput);
  const [differenceInDays, setDifferenceInDays] = useState(0);

  const [tariNo, setTariNo] = useState([]);
  const [judeteNo, setJudeteNo] = useState([]);
  const [oraseNo, setOraseNo] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tariNoRezident`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        setTariNo([
          { code: 300, description: t("travelEurolife.select") },
          ...data,
        ]);
      } catch (error) {
        navigate("/eroare");
        console.error(error);
      }
      if (person.adresa.tara !== 300) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/judeteNoRezident?tara=${person.adresa.tara}`,
            {
              headers: {
                "API-Key": process.env.REACT_APP_API_KEY,
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          setJudeteNo(data);

          setPerson((prevState) => ({
            ...prevState,
            adresa: {
              ...prevState.adresa,
              judetNo: data[0].code,
            },
          }));
        } catch (error) {
          navigate("/eroare");
          console.error(error);
        }

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/orasNoRezident?judet=${person.adresa.judetNo}`,
            {
              headers: {
                "API-Key": process.env.REACT_APP_API_KEY,
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          setOraseNo(data);

          if (data.length > 0) {
            setPerson((prevState) => ({
              ...prevState,
              adresa: {
                ...prevState.adresa,
                oraseNo: data[0].code,
              },
            }));
          }
        } catch (error) {
          console.error(error);
          navigate("/eroare");
        }
      }
    };

    fetchData();
  }, [person.adresa.tara, person.adresa.judetNo]);

  function isCountryInEurope(countryCode) {
    return europeanCountryCodes.includes(countryCode);
  }

  const [nationalities, setNationalities] = useState({
    1: "Afghan",
    2: "Albanian",
    3: "Algerian",
    4: "American",
    5: "Andorran",
    6: "Angolan",
    7: "Antiguans",
    8: "Argentinean",
    9: "Armenian",
    10: "Australian",
    11: "Austrian",
    12: "Azerbaijani",
    13: "Bahamian",
    14: "Bahraini",
    15: "Bangladeshi",
    16: "Barbadian",
    17: "Barbudans",
    18: "Batswana",
    19: "Belarusian",
    20: "Belgian",
    21: "Belizean",
    22: "Beninese",
    23: "Bhutanese",
    24: "Bolivian",
    25: "Bosnian",
    26: "Brazilian",
    27: "British",
    28: "Bruneian",
    29: "Bulgarian",
    30: "Burkinabe",
    31: "Burmese",
    32: "Burundian",
    33: "Cambodian",
    34: "Cameroonian",
    35: "Canadian",
    36: "Cape Verdean",
    37: "Central African",
    38: "Chadian",
    39: "Chilean",
    40: "Chinese",
    41: "Colombian",
    42: "Comoran",
    43: "Congolese",
    44: "Costa Rican",
    45: "Croatian",
    46: "Cuban",
    47: "Cypriot",
    48: "Czech",
    49: "Danish",
    50: "Djibouti",
    51: "Dominican",
    52: "Dutch",
    53: "East Timorese",
    54: "Ecuadorean",
    55: "Egyptian",
    56: "Emirian",
    57: "Equatorial Guinean",
    58: "Eritrean",
    59: "Estonian",
    60: "Ethiopian",
    61: "Fijian",
    62: "Filipino",
    63: "Finnish",
    64: "French",
    65: "Gabonese",
    66: "Gambian",
    67: "Georgian",
    68: "German",
    69: "Ghanaian",
    70: "Greek",
    71: "Grenadian",
    72: "Guatemalan",
    73: "Guinea-Bissauan",
    74: "Guinean",
    75: "Guyanese",
    76: "Haitian",
    77: "Herzegovinian",
    78: "Honduran",
    79: "Hungarian",
    80: "Icelander",
    81: "Indian",
    82: "Indonesian",
    83: "Iranian",
    84: "Iraqi",
    85: "Irish",
    86: "Israeli",
    87: "Italian",
    88: "Ivorian",
    89: "Jamaican",
    90: "Japanese",
    91: "Jordanian",
    92: "Kazakhstani",
    93: "Kenyan",
    94: "Kittian and Nevisian",
    95: "Kuwaiti",
    96: "Kyrgyz",
    97: "Laotian",
    98: "Latvian",
    99: "Lebanese",
    100: "Liberian",
    101: "Libyan",
    102: "Liechtensteiner",
    103: "Lithuanian",
    104: "Luxembourger",
    105: "Macedonian",
    106: "Malagasy",
    107: "Malawian",
    108: "Malaysian",
    109: "Maldivan",
    110: "Malian",
    111: "Maltese",
    112: "Marshallese",
    113: "Mauritanian",
    114: "Mauritian",
    115: "Mexican",
    116: "Micronesian",
    117: "Moldovan",
    118: "Monacan",
    119: "Mongolian",
    120: "Moroccan",
    121: "Mosotho",
    122: "Motswana",
    123: "Mozambican",
    124: "Namibian",
    125: "Nauruan",
    126: "Nepalese",
    127: "Netherlander",
    128: "New Zealander",
    129: "Ni-Vanuatu",
    130: "Nicaraguan",
    131: "Nigerian",
    132: "Nigerien",
    133: "North Korean",
    134: "Northern Irish",
    135: "Norwegian",
    136: "Omani",
    137: "Pakistani",
    138: "Palauan",
    139: "Panamanian",
    140: "Papua New Guinean",
    141: "Paraguayan",
    142: "Peruvian",
    143: "Polish",
    144: "Portuguese",
    145: "Qatari",
    146: "Romanian",
    147: "Russian",
    148: "Rwandan",
    149: "Saint Lucian",
    150: "Salvadoran",
    151: "Samoan",
    152: "San Marinese",
    153: "Sao Tomean",
    154: "Saudi",
    155: "Scottish",
    156: "Senegalese",
    157: "Serbian",
    158: "Seychellois",
    159: "Sierra Leonean",
    160: "Singaporean",
    161: "Slovakian",
    162: "Slovenian",
    163: "Solomon Islander",
    164: "Somali",
    165: "South African",
    166: "South Korean",
    167: "Spanish",
    168: "Sri Lankan",
    169: "Sudanese",
    170: "Surinamer",
    171: "Swazi",
    172: "Swedish",
    173: "Swiss",
    174: "Syrian",
    175: "Taiwanese",
    176: "Tajik",
    177: "Tanzanian",
    178: "Thai",
    179: "Togolese",
    180: "Tongan",
    181: "Trinidadian or Tobagonian",
    182: "Tunisian",
    183: "Turkish",
    184: "Tuvaluan",
    185: "Ugandan",
    186: "Ukrainian",
    187: "Uruguayan",
    188: "Uzbekistani",
    189: "Venezuelan",
    190: "Vietnamese",
    191: "Welsh",
    192: "Yemenite",
    193: "Zambian",
    194: "Zimbabwean",
  });

  function handleShowPopupConsultantaClick() {
    setShowPopupCons(true);
  }
  function handleShowPopupInfoClick() {
    setShowPopupInfo(true);
  }

  // function formatDate(date) {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // }

  function dateCompare() {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    const year = sevenDaysAgo.getFullYear();
    const month = String(sevenDaysAgo.getMonth() + 1).padStart(2, "0");
    const day = String(sevenDaysAgo.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  async function handleJudetChange(event) {
    const judet = event.target.value;
    if (judet !== "select") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/listOrase?judet=${judet}`,
          //`http://localhost:8080/listOrase1?judet=${judet}`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setPerson((prevState) => ({
          ...prevState,
          adresa: {
            ...prevState.adresa,
            judet: judet,
            localitate: "select",
          },
        }));

        setOrase([{ oras: "select" }, ...data]);
      } catch (error) {
        console.error(error);
        navigate("/eroare");
      }
    } else {
      setPerson((prevState) => ({
        ...prevState,
        adresa: {
          ...prevState.adresa,
          judet: judet,
        },
      }));
      setOrase([{}]);
    }
  }

  function handleOrasChange(event) {
    const selectedText = event.target.options[event.target.selectedIndex].text;

    setPerson((prevState) => ({
      ...prevState,
      adresa: {
        ...prevState.adresa,
        localitate: event.target.value,
      },
    }));
  }

  const [orase, setOrase] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (person.adresa.judet !== "select") {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/listOrase?judet=${person.adresa.judet}`,

            {
              headers: {
                "API-Key": process.env.REACT_APP_API_KEY,
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          if (person.adresa.localitate === "select")
            setPerson((prevState) => ({
              ...prevState,
              adresa: {
                ...prevState.adresa,
                localitate: "select",
              },
            }));

          setOrase([{ oras: "select" }, ...data]);
        } catch (error) {
          console.error(error);
          navigate("/eroare");
        }
      }
    };
    if (person.adresa.judet !== "select") fetchData();
  }, []);

  useEffect(() => {
    const differenceInMilliseconds = dataInceput - today;
    const differenceInDays1 = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    setDifferenceInDays(differenceInDays1);
  }, []);

  useEffect(() => {
    if (pc === true && tc === true) setErrorPage2TC(true);
    else setErrorPage2TC(false);
  }, [tc]);

  useEffect(() => {
    if (pc === true && tc === true) setErrorPage2TC(true);
    else setErrorPage2TC(false);
  }, [pc]);

  const handleChangeData = (date) => {
    setPerson((prevState) => ({
      ...prevState,
      data_nasterii: date.toISOString().split("T")[0],
    }));
  };

  const handleAddPerson = () => {
    setPeople([...people, { nume: "", prenume: "", cnp_cui: "" }]);
  };

  const handleRemoveLastPerson = () => {
    if (people.length > 1) {
      const updatedPeople = people.slice(0, -1);
      setPeople(updatedPeople);
    } else if (people.length === 1) {
      setPeople([{ nume: "", prenume: "", cnp_cui: "" }]);
      setShowNewPeople(false);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedPeople = [...people];
    updatedPeople[index][field] = value;
    setPeople(updatedPeople);
  };

  const handleViewPerson = () => {
    setShowNewPeople(true);
  };

  const [minBirthday, setBirthday] = useState(() => {
    const minDate = new Date(2005, 11, 31); // 31 decembrie 2005
    return minDate;
  });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <div>
      <Row className="sectionTravel_buy__form1">
        <Col xl="3" lg="4" md="12" className="sectionTravel_buy__left">
          <label
            className={
              person.nume.length < 1 && buttonNext === true
                ? "sub_title_Travel_buy_red"
                : "sub_title_Travel_buy"
            }
          >
            {t("travelEurolife.informatii.request1")}
            <br />
            <input
              type="text"
              name="fname"
              value={person.nume}
              onChange={(event) =>
                setPerson({
                  ...person,
                  nume: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="4" md="12" className="sectionTravel_buy__left">
          <label
            className={
              person.prenume.length < 1 && buttonNext === true
                ? "sub_title_Travel_buy_red"
                : "sub_title_Travel_buy"
            }
          >
            {t("travelEurolife.informatii.request2")}
            <br />
            <input
              type="text"
              name="lname"
              value={person.prenume}
              onChange={(event) =>
                setPerson({
                  ...person,
                  prenume: event.target.value.toUpperCase(),
                })
              }
            />
          </label>
        </Col>
        <Col xl="3" lg="4" md="12" className="sectionTravel_buy__left">
          <label className="sub_title_Travel_buy">
            {t("travelEurolife.informatii.request3")}
          </label>
          <div>
            <Button
              className={
                person.rezident === "1"
                  ? "sub_title_rca_select_CetatenieActiv dimentionCetatenie"
                  : "sub_title_rca_select_Cetatenie dimentionCetatenie"
              }
              onClick={(event) =>
                setPerson((prevState) => ({
                  ...prevState,
                  rezident: "1",
                }))
              }
            >
              {t("travelEurolife.informatii.v1")}
            </Button>
            <Button
              className={
                person.rezident === "0"
                  ? "sub_title_rca_select_CetatenieActiv dimentionCetatenie"
                  : "sub_title_rca_select_Cetatenie dimentionCetatenie"
              }
              onClick={(event) =>
                setPerson((prevState) => ({
                  ...prevState,
                  rezident: "0",
                }))
              }
            >
              {t("travelEurolife.informatii.v2")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="sectionTravel_buy__form2">
        <Col xl="3" lg="4" md="12" className="sectionTravel_buy__left">
          {person.rezident === "1" ? (
            <label
              className={
                (person.cnp_cui.length !== 13 || validareCNP) &&
                buttonNext === true
                  ? "sub_title_Travel_buy_data_red"
                  : "sub_title_Travel_buy_data"
              }
            >
              {t("travelEurolife.informatii.request4")}
              <br />
              <input
                type="text"
                name="name"
                value={person.cnp_cui}
                onChange={(event) =>
                  setPerson({
                    ...person,
                    cnp_cui: event.target.value,
                  })
                }
              />
            </label>
          ) : (
            <label className="sub_title_Travel_buy_data">
              {t("travelEurolife.informatii.request19")}
              <br />
              <DatePicker
                selected={
                  person.data_nasterii ? new Date(person.data_nasterii) : null
                }
                placeholderText={t("travelEurolife.select")}
                dateFormat="dd-MM-yyyy"
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={30}
                scrollableYearDropdown
                maxDate={minBirthday}
                onChange={(data) =>
                  setPerson({
                    ...person,
                    data_nasterii: formatDate(data),
                  })
                }
              />
            </label>
          )}
        </Col>
        <Col xl="3" lg="3" md="12" className="sectionTravel_buy__left">
          <label className="sub_title_Travel_buy_data">
            {t("travelEurolife.informatii.request5")}
            <br />
            <select
              value={person.cetatenie}
              onChange={(event) =>
                setPerson((prevState) => ({
                  ...prevState,
                  cetatenie: event.target.value,
                }))
              }
            >
              {Object.entries(nationalities).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </label>
        </Col>
      </Row>
      <div
        style={{
          borderTop: "1px solid #EEF5FC",
          marginTop: 25,
          marginLeft: 30,
          marginRight: 30,
        }}
      ></div>
      <Row className="sectionTravel_buy__form11">
        <div className="sectionRCA__left_subtitle2" ref={field2Ref}>
          <p>{t("travelEurolife.informatii.subtitle")}</p>
        </div>
        {person.rezident === "1" ? (
          <Col xl="7" lg="12" md="12" className="sectionTravel_buy__left">
            <Row className="sectionTravel_buy__form11">
              <Col lg="6" md="6" className="sectionTravel_buy__left_mini">
                <label
                  className={
                    person.adresa.strada.length < 1 && buttonNext === true
                      ? "sub_title_Travel_buy_red"
                      : "sub_title_Travel_buy"
                  }
                >
                  {t("travelEurolife.informatii.request8")} <br />
                  <input
                    type="text"
                    name="address"
                    value={person.adresa.strada}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          strada: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                </label>
              </Col>
              <Col lg="6" md="6" className="sectionTravel_buy__left_mini">
                <label
                  className={
                    person.adresa.numar.length < 1 && buttonNext === true
                      ? "sub_title_Travel_buy_red"
                      : "sub_title_Travel_buy"
                  }
                >
                  {t("travelEurolife.informatii.request9")}
                  <br />
                  <input
                    type="text"
                    name="unit"
                    value={person.adresa.numar}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          numar: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                </label>
              </Col>
            </Row>

            <Row className="sectionTravel_buy__form11">
              <Col lg="6" md="6" className="sectionTravel_buy__left_mini">
                <label className="sub_title_Travel_buy">
                  {t("travelEurolife.informatii.request10")} <br />
                  <input
                    type="text"
                    name="name"
                    value={person.adresa.bloc}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          bloc: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                </label>
              </Col>
              <Col lg="6" md="6" className="sectionTravel_buy__left_mini">
                <label className="sub_title_Travel_buy" ref={field3Ref}>
                  {t("travelEurolife.informatii.request11")}
                  <br />
                  <input
                    type="text"
                    name="name"
                    value={person.adresa.scara}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          scara: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                </label>
              </Col>
            </Row>

            <Row className="sectionTravel_buy__form11_mini3">
              <Col lg="4" md="4" className="sectionTravel_buy__left_mini1">
                <label className="sub_title_Travel_buy">
                  {t("travelEurolife.informatii.request12")}
                  <br />
                  <input
                    type="text"
                    name="name"
                    value={person.adresa.etaj}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          etaj: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                </label>
              </Col>
              <Col lg="4" md="4" className="sectionTravel_buy__left_mini1">
                <label className="sub_title_Travel_buy">
                  {t("travelEurolife.informatii.request13")}
                  <br />
                  <input
                    type="text"
                    name="name"
                    value={person.adresa.apartament}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          apartament: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                </label>
              </Col>
              <Col lg="4" md="4" className="sectionTravel_buy__left_mini1">
                <label
                  className={
                    person.adresa.cod_postal.length < 1 && buttonNext === true
                      ? "sub_title_Travel_buy_red"
                      : "sub_title_Travel_buy"
                  }
                >
                  {t("travelEurolife.informatii.request14")}
                  <br />
                  <input
                    type="text"
                    name="postcode"
                    value={person.adresa.cod_postal}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          cod_postal: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                  <a
                    href=" https://www.posta-romana.ro/cauta-cod-postal.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rca_info_a"
                  >
                    {t("travelEurolife.informatii.text")}
                    <img
                      src={inapoi_icon}
                      alt=""
                      className=""
                      onClick={(event) =>
                        (window.location.href =
                          " https://www.posta-romana.ro/cauta-cod-postal.html")
                      }
                    />
                  </a>
                </label>
              </Col>
            </Row>
            <Row className="sectionTravel_buy__form11">
              <Col lg="6" md="6" className="sectionTravel_buy__left_mini">
                <label
                  className={
                    person.adresa.judet === "select" && buttonNext === true
                      ? "sub_title_Travel_buy_data_red"
                      : "sub_title_Travel_buy_data"
                  }
                >
                  {t("travelEurolife.informatii.request15")}
                  <br />
                  <select
                    value={person.adresa.judet}
                    onChange={handleJudetChange}
                  >
                    {judete.map((judet, index) => (
                      <option key={index} value={judet.judet}>
                        {judet.judet}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <Col lg="6" md="6" className="sectionTravel_buy__left_mini">
                <label
                  className={
                    person.adresa.localitate === "select" && buttonNext === true
                      ? "sub_title_Travel_buy_data_red"
                      : "sub_title_Travel_buy_data"
                  }
                >
                  {t("travelEurolife.informatii.request16")}
                  <br />
                  <select
                    value={person.adresa.localitate}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          localitate:
                            event.target.options[event.target.selectedIndex]
                              .text,
                        },
                      }))
                    }
                  >
                    {/* <option value="">Selectează un oraș</option> */}
                    {orase.map((oras, index) => (
                      <option key={index} value={oras.oras}>
                        {oras.oras}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>
              <div className="sectionTravel__left_subtitle1" ref={field2Ref}>
                <p>{t("travelEurolife.informatii.subtitle4")}</p>
              </div>
              <div
                className={
                  !showNewPeople && !person.traveler && buttonNext === true
                    ? "sectionTravel__left_check1_red"
                    : "sectionTravel__left_check1"
                }
              >
                <label>
                  <input
                    type="checkbox"
                    required="required"
                    checked={person.traveler}
                    onChange={(e) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        traveler: e.target.checked,
                      }))
                    }
                  />
                  {t("travelEurolife.informatii.check8")}
                </label>
              </div>
              {!showNewPeople ? (
                <div>
                  <img
                    src={Add}
                    alt=""
                    className="image-with-hover-cursor"
                    onClick={handleViewPerson}
                  />
                  <label
                    className="sub_title_Travel_buy subtitle_Travel_spatiu image-with-hover-cursor"
                    onClick={handleViewPerson}
                  >
                    {t("travelEurolife.informatii.buttun2")}
                  </label>
                </div>
              ) : (
                <div>
                  {people.map((person, index) => (
                    <div key={index}>
                      <label
                        className={
                          person.nume.length < 1 && buttonNext === true
                            ? "sub_title_Travel_buy_red"
                            : "sub_title_Travel_buy"
                        }
                        style={{
                          marginRight: 30,
                        }}
                      >
                        {t("travelEurolife.informatii.request1")}
                        <br />
                        <input
                          type="text"
                          value={person.nume}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "nume",
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </label>

                      <label
                        className={
                          person.prenume.length < 1 && buttonNext === true
                            ? "sub_title_Travel_buy_red"
                            : "sub_title_Travel_buy"
                        }
                      >
                        {t("travelEurolife.informatii.request2")}
                        <br />
                        <input
                          type="text"
                          value={person.prenume}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "prenume",
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </label>

                      <label
                        className={
                          person.cnp_cui.length < 1 && buttonNext === true
                            ? "sub_title_Travel_buy_red"
                            : "sub_title_Travel_buy"
                        }
                      >
                        {t("travelEurolife.informatii.request4")}
                        <br />
                        <input
                          type="text"
                          value={person.cnp_cui}
                          onChange={(e) =>
                            handleInputChange(index, "cnp_cui", e.target.value)
                          }
                        />
                      </label>
                    </div>
                  ))}
                  {people.length < 10 ? (
                    <div>
                      <img
                        src={Add}
                        alt=""
                        className="image-with-hover-cursor"
                        onClick={handleAddPerson}
                      />
                      <label
                        className="sub_title_Travel_buy subtitle_Travel_spatiu image-with-hover-cursor"
                        onClick={handleAddPerson}
                      >
                        {t("travelEurolife.informatii.buttun2")}
                      </label>
                    </div>
                  ) : null}
                  <div>
                    <img
                      src={Add}
                      alt=""
                      className="image-with-hover-cursor"
                      onClick={handleRemoveLastPerson}
                    />
                    <label
                      className="sub_title_Travel_buy image-with-hover-cursor"
                      onClick={handleRemoveLastPerson}
                    >
                      Scad o persoana
                    </label>
                  </div>
                </div>
              )}

              {/* popup */}
              <label className="sub_title_Travel_buy subtitle_Travel_spatiu">
                {t("travelEurolife.informatii.subtitle1")}
              </label>
              <h5 className="subsub_title_Travel_buy">
                {t("travelEurolife.informatii.subtitle11")}
              </h5>
              <div>
                <Button
                  className="sub_title_rca_select_Cetatenie dimentionCetatenie"
                  onClick={handleShowPopupConsultantaClick}
                >
                  {t("travelEurolife.informatii.v1")}
                </Button>
                <Button className="sub_title_rca_select_CetatenieActiv dimentionCetatenie">
                  {t("travelEurolife.informatii.v2")}
                </Button>
              </div>
              <label className="sub_title_Travel_buy subtitle_Travel_spatiu">
                {t("travelEurolife.informatii.subtitle2")}
              </label>
              <div>
                <Button
                  className="sub_title_rca_select_Cetatenie dimentionCetatenie"
                  //onClick={(e) => setleasing_rcaValue1(0)}
                  // onClick={(event) => alert("FORM SUBMITTED1")}
                  onClick={handleShowPopupInfoClick}
                >
                  {t("travelEurolife.informatii.v1")}
                </Button>
                <Button
                  className="sub_title_rca_select_CetatenieActiv dimentionCetatenie"
                  // onClick={(e) => setleasing_rcaValue1(1)}
                >
                  {t("travelEurolife.informatii.v2")}
                </Button>
              </div>
            </Row>
          </Col>
        ) : (
          <Col xl="7" lg="12" md="12" className="sectionTravel_buy__left">
            <Row className="sectionTravel_buy__form11">
              <Col lg="6" md="6" className="sectionTravel_buy__left_mini">
                <label className="sub_title_Travel_buy_data" ref={field3Ref}>
                  {t("travelEurolife.informatii.request20")}
                  <br />
                  <select
                    value={person.adresa.tara}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          tara: event.target.value,
                          taraName:
                            event.target.options[event.target.selectedIndex]
                              .text,
                        },
                      }))
                    }
                  >
                    {tariNo.map((tara) => (
                      <option key={tara.code} value={tara.code}>
                        {tara.description}
                      </option>
                    ))}
                  </select>
                </label>
              </Col>

              <Col lg="4" md="4" className="sectionTravel_buy__left_mini1">
                <label
                  className={
                    person.adresa.cod_postal.length < 1 && buttonNext === true
                      ? "sub_title_Travel_buy_red"
                      : "sub_title_Travel_buy"
                  }
                >
                  {t("travelEurolife.informatii.request14")}
                  <br />
                  <input
                    type="text"
                    name="postcode"
                    value={person.adresa.cod_postal}
                    onChange={(event) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        adresa: {
                          ...prevState.adresa,
                          cod_postal: event.target.value.toUpperCase(),
                        },
                      }))
                    }
                  />
                  <a
                    href="https://worldpostalcode.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rca_info_a"
                  >
                    {t("travelEurolife.informatii.text")}
                    <img
                      src={inapoi_icon}
                      alt=""
                      className=""
                      onClick={(event) =>
                        (window.location.href = "https://worldpostalcode.com/")
                      }
                    />
                  </a>
                </label>
              </Col>
              <div className="sectionTravel__left_check1">
                <label>
                  <input
                    type="checkbox"
                    required="required"
                    checked={person.traveler}
                    onChange={(e) =>
                      setPerson((prevState) => ({
                        ...prevState,
                        traveler: e.target.checked,
                      }))
                    }
                  />
                  {t("travelEurolife.informatii.check8")}
                </label>
              </div>
              {!showNewPeople ? (
                <div>
                  <img
                    src={Add}
                    alt=""
                    className="image-with-hover-cursor"
                    onClick={handleViewPerson}
                  />
                  <label
                    className="sub_title_Travel_buy subtitle_Travel_spatiu image-with-hover-cursor"
                    onClick={handleViewPerson}
                  >
                    {t("travelEurolife.informatii.buttun2")}
                  </label>
                </div>
              ) : (
                <div>
                  {people.map((person, index) => (
                    <div key={index}>
                      <label
                        className={
                          person.nume.length < 1 && buttonNext === true
                            ? "sub_title_Travel_buy_red"
                            : "sub_title_Travel_buy"
                        }
                        style={{
                          marginRight: 30,
                        }}
                      >
                        {t("travelEurolife.informatii.request1")}
                        <br />
                        <input
                          type="text"
                          value={person.nume}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "nume",
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </label>

                      <label
                        className={
                          person.prenume.length < 1 && buttonNext === true
                            ? "sub_title_Travel_buy_red"
                            : "sub_title_Travel_buy"
                        }
                      >
                        {t("travelEurolife.informatii.request2")}
                        <br />
                        <input
                          type="text"
                          value={person.prenume}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "prenume",
                              e.target.value.toUpperCase()
                            )
                          }
                        />
                      </label>

                      <label
                        className={
                          person.cnp_cui.length < 1 && buttonNext === true
                            ? "sub_title_Travel_buy_red"
                            : "sub_title_Travel_buy"
                        }
                      >
                        {t("travelEurolife.informatii.request4")}
                        <br />
                        <input
                          type="text"
                          value={person.cnp_cui}
                          onChange={(e) =>
                            handleInputChange(index, "cnp_cui", e.target.value)
                          }
                        />
                      </label>
                    </div>
                  ))}
                  {people.length < 10 ? (
                    <div>
                      <img
                        src={Add}
                        alt=""
                        className="image-with-hover-cursor"
                        onClick={handleAddPerson}
                      />
                      <label
                        className="sub_title_Travel_buy subtitle_Travel_spatiu image-with-hover-cursor"
                        onClick={handleAddPerson}
                      >
                        {t("travelEurolife.informatii.buttun2")}
                      </label>
                    </div>
                  ) : null}
                  <div>
                    <img
                      src={Add}
                      alt=""
                      className="image-with-hover-cursor"
                      onClick={handleRemoveLastPerson}
                    />
                    <label
                      className="sub_title_Travel_buy image-with-hover-cursor"
                      onClick={handleRemoveLastPerson}
                    >
                      Scad o persoana
                    </label>
                  </div>
                </div>
              )}

              {/* popup */}
              <label className="sub_title_Travel_buy subtitle_Travel_spatiu">
                {t("travelEurolife.informatii.subtitle1")}
              </label>
              <h5 className="subsub_title_Travel_buy">
                {t("travelEurolife.informatii.subtitle11")}
              </h5>
              <div>
                <Button
                  className="sub_title_rca_select_Cetatenie dimentionCetatenie"
                  onClick={handleShowPopupConsultantaClick}
                >
                  {t("travelEurolife.informatii.v1")}
                </Button>
                <Button className="sub_title_rca_select_CetatenieActiv dimentionCetatenie">
                  {t("travelEurolife.informatii.v2")}
                </Button>
              </div>
              <label className="sub_title_Travel_buy subtitle_Travel_spatiu">
                {t("travelEurolife.informatii.subtitle2")}
              </label>
              <div>
                <Button
                  className="sub_title_rca_select_Cetatenie dimentionCetatenie"
                  //onClick={(e) => setleasing_rcaValue1(0)}
                  // onClick={(event) => alert("FORM SUBMITTED1")}
                  onClick={handleShowPopupInfoClick}
                >
                  {t("travelEurolife.informatii.v1")}
                </Button>
                <Button
                  className="sub_title_rca_select_CetatenieActiv dimentionCetatenie"
                  // onClick={(e) => setleasing_rcaValue1(1)}
                >
                  {t("travelEurolife.informatii.v2")}
                </Button>
              </div>
            </Row>
          </Col>
        )}

        <Col
          xl="5"
          lg="11"
          md="11"
          sm="11"
          className="sectionTravel_buy__leftAdd"
        >
          <div className="add_new_Travel_bay">
            <p>{t("travelEurolife.informatii.add")}</p>

            <p className="subtitle_add_new_Travel_bay_rutiera"></p>

            {isCountryInEurope(produs.tara_destinatie) &&
            produs.modalitate_transport === "Masina" ? (
              <div>
                <label className="add_new_Travel_bay_rutiera">
                  <input
                    type="checkbox"
                    required="required"
                    checked={produs.asistenta_rutiera}
                    onChange={(event) =>
                      setProdus((prevState) => ({
                        ...prevState,
                        asistenta_rutiera: event.target.checked,
                      }))
                    }
                  />
                  {t("travelEurolife.informatii.check3")}
                </label>
                {produs.asistenta_rutiera === true ? (
                  <div>
                    <label
                      className={
                        produs.nr_inmatriculare.length < 1 &&
                        buttonNext === true
                          ? "sub_title_Travel_buy_optiuni_red"
                          : "sub_title_Travel_buy_optiuni"
                      }
                    >
                      {t("travelEurolife.informatii.request17")}
                      <br />
                      <input
                        type="text"
                        name="city"
                        value={produs.nr_inmatriculare}
                        onChange={(event) =>
                          setProdus((prevState) => ({
                            ...prevState,
                            nr_inmatriculare: event.target.value.toUpperCase(),
                          }))
                        }
                      />
                    </label>
                    <p className="subtitle_add_new_Travel_bay_rutiera">
                      {t("travelEurolife.informatii.asistentaRutiera")}
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
            {produs.modalitate_transport === "Avion" ||
            produs.modalitate_transport === "AvionSiMasinaInchiriata" ? (
              <div>
                <label className="add_new_Travel_bay_rutiera">
                  <input
                    type="checkbox"
                    required="required"
                    checked={produs.bagaje}
                    onChange={(event) =>
                      setProdus((prevState) => ({
                        ...prevState,
                        bagaje: event.target.checked,
                      }))
                    }
                  />
                  {t("travelEurolife.informatii.check4")}
                </label>
                <p className="subtitle_add_new_Travel_bay_rutiera">
                  {t("travelEurolife.informatii.subcheck4")}
                </p>
              </div>
            ) : null}
            {produs.modalitate_transport === "AvionSiMasinaInchiriata" ? (
              <div>
                <label className="add_new_Travel_bay_rutiera">
                  <input
                    type="checkbox"
                    required="required"
                    checked={produs.franciza}
                    onChange={(event) =>
                      setProdus((prevState) => ({
                        ...prevState,
                        franciza: event.target.checked,
                      }))
                    }
                  />
                  {t("travelEurolife.informatii.check7")}
                </label>
                <p className="subtitle_add_new_Travel_bay_rutiera">
                  {t("travelEurolife.informatii.subcheck7")}
                </p>
              </div>
            ) : null}
            {produs.data_platii >= dateCompare() || differenceInDays > 10 ? (
              <div>
                <label className="add_new_Travel_bay_rutiera">
                  <input
                    type="checkbox"
                    required="required"
                    checked={produs.anularea_calatoriei}
                    onChange={(event) =>
                      setProdus((prevState) => ({
                        ...prevState,
                        anularea_calatoriei: event.target.checked,
                      }))
                    }
                  />
                  {t("travelEurolife.informatii.check5")}
                </label>
                <p className="subtitle_add_new_Travel_bay_rutiera">
                  {t("travelEurolife.informatii.subcheck5")}
                </p>
                {produs.anularea_calatoriei === true ? (
                  <p className="subtitle_add_new_Travel_bay_rutiera">
                    {t("travelEurolife.informatii.anulareaCalatoriei")}
                  </p>
                ) : null}
              </div>
            ) : null}

            <label className="add_new_Travel_bay_rutiera">
              <input
                type="checkbox"
                required="required"
                checked={produs.sport_extrem}
                onChange={(event) =>
                  setProdus((prevState) => ({
                    ...prevState,
                    sport_extrem: event.target.checked,
                  }))
                }
              />
              {t("travelEurolife.informatii.check6")}
            </label>

            <p className="subtitle_add_new_Travel_bay_rutiera">
              {t("travelEurolife.informatii.subcheck6")}
            </p>
            {produs.sport_extrem === true ? (
              <div className="subtitle_add_new_Travel_bay_rutiera">
                <textarea
                  className="scrollable-textbox"
                  rows="5"
                  cols="30"
                  value={t("travelEurolife.informatii.subcheck6text")}
                />
              </div>
            ) : null}
          </div>
          <img src={Bagaj} alt="" className="travelBagaj_img" />
        </Col>
      </Row>
      <div
        style={{
          borderTop: "1px solid #EEF5FC",
          marginTop: 25,
          marginLeft: 30,
          marginRight: 30,
        }}
      ></div>
      <div className="sectionTravel__left_check">
        <label>
          <input
            type="checkbox"
            required="required"
            onChange={(e) => setTc(e.target.checked)}
          />
          {t("travelEurolife.informatii.check1")}
        </label>
      </div>
      <div className="sectionTravel__left_check">
        <label>
          <input
            type="checkbox"
            required="required"
            onChange={(e) => setPc(e.target.checked)}
          />
          {t("travelEurolife.informatii.check2")}
        </label>
      </div>
      {showPopupCons && (
        <div className="popup-wrapper">
          <div
            className="popup-overlay"
            onClick={() => setShowPopupCons(false)}
          />
          <div className="popup-container">
            <p className="popup-text">
              {t("travelEurolife.pop-up1.text1")}
              <a href="/page-contact" target="_blank">
                {" "}
                {t("travelEurolife.pop-up1.form")}{" "}
              </a>
              {t("travelEurolife.pop-up1.text2")}{" "}
              <div className="image-button">
                <img
                  src={Whatsapp_btn}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "170px",
                    height: "auto",
                  }}
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                />

                <div
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "15px",
                    marginTop: "7px",
                  }}
                >
                  {t("header.menu5")}
                  <a href="https://wa.me/40724542277" target="_blank">
                    <svg
                      className="whats"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
                        fill="rgba(0,0,0,1)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {t("travelEurolife.pop-up1.text3")}
            </p>

            <button
              onClick={() => setShowPopupCons(false)}
              className="popup-button"
            >
              {t("travelEurolife.pop-up1.button2")}
            </button>
            <div className="popup-logo">
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>
      )}
      {showPopupInfo && (
        <div className="popup-wrapper">
          <div
            className="popup-overlay"
            onClick={() => setShowPopupInfo(false)}
          />
          <div className="popup-container">
            <p className="popup-text">
              {t("travelEurolife.pop-up2.text1")}
              <a href="/page-contact" target="_blank">
                {" "}
                {t("travelEurolife.pop-up2.form")}{" "}
              </a>
              {t("travelEurolife.pop-up2.text2")}{" "}
              <div className="image-button">
                <img
                  src={Whatsapp_btn}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "170px",
                    height: "auto",
                  }}
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                />

                <div
                  onClick={(event) =>
                    window.open("https://wa.me/40724542277", "_blank")
                  }
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "15px",
                    marginTop: "7px",
                  }}
                >
                  {t("header.menu5")}
                  <a href="https://wa.me/40724542277" target="_blank">
                    <svg
                      className="whats"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="30"
                      height="30"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
                        fill="rgba(0,0,0,1)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {t("travelEurolife.pop-up2.text3")}
            </p>

            <button
              onClick={() => setShowPopupInfo(false)}
              className="popup-button"
            >
              {t("travelEurolife.pop-up2.button2")}
            </button>
            <div className="popup-logo">
              <img src={Logo} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Calator;
