import React, { Fragment } from "react";
import Header from "../header/Header";
import FormContact from "../FormContact/FormContact";
import Footer from "../Footer/Footer";
import TabBar from "../TabBar/TabBar";

const PageContact = () => {
  return (
    <Fragment>
      <TabBar />
      <Header />
      <FormContact />
      <Footer />
    </Fragment>
  );
};

export default PageContact;
