import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import Spinner from "react-bootstrap/Spinner";

import Allianz from "../../assests/images/ensure/Allianz.svg";
import Grawe from "../../assests/images/ensure/Grawe.svg";
import Generali from "../../assests/images/ensure/Generalli.svg";
import Asirom from "../../assests/images/ensure/asirom.png";
import Uniqa from "../../assests/images/ensure/Uniqa.svg";
import Omniasig from "../../assests/images/ensure/Omniasig.svg";
import Euroins from "../../assests/images/ensure/Euroins.svg";
import Groupama from "../../assests/images/ensure/Groupama.svg";
import Axeria from "../../assests/images/ensure/Axeria.svg";
import Ergo from "../../assests/images/ensure/Ergo.svg";
import Eurolife from "../../assests/images/ensure/Eurolife.svg";
import Gothaer from "../../assests/images/ensure/Gothaer.svg";
import Hellas from "../../assests/images/ensure/hellasdirect.png";

import "./RCA_buy.css";
import btn from "../../assests/images/RCA/alege_btn.png";

import { useTranslation } from "react-i18next";
import "../../i18n";

function Oferte({ formData, setFormData, page, setPage, oferta, setOferta }) {
  const poze = {
    Allianz: Allianz,
    Grawe: Grawe,
    Generali: Generali,
    Asirom: Asirom,
    Uniqa: Uniqa,
    Omniasig: Omniasig,
    Euroins: Euroins,
    Groupama: Groupama,
    Axeria: Axeria,
    Ergo: Ergo,
    Eurolife: Eurolife,
    Gothaer: Gothaer,
    Hellas: Hellas,
  };

  const { t, i18n } = useTranslation();

  const [oferta12, setOferta12] = useState([]);
  const [oferta9, setOferta9] = useState([]);
  const [oferta6, setOferta6] = useState([]);
  const [oferta3, setOferta3] = useState([]);
  const [oferta1, setOferta1] = useState([]);

  const [oferta12dec, setOferta12dec] = useState([]);
  const [oferta9dec, setOferta9dec] = useState([]);
  const [oferta6dec, setOferta6dec] = useState([]);
  const [oferta3dec, setOferta3dec] = useState([]);
  const [oferta1dec, setOferta1dec] = useState([]);

  const [loading, setLoading] = useState(true);

  const [formData1, setFormData1] = useState({ ...formData, perioada: 6 });

  const [arrayToIterate, setarrayToIterate] = useState([{}]);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  let intrat = 0;

  useEffect(() => {
    let isMounted = true;

    const fetchData = async (ensureOptions) => {
      if (!isMounted || ensureOptions.length === 0) {
        // Toate cererile au fost finalizate, ieșiți din funcție
        return;
      }

      const ensure = ensureOptions[0];

      const requestOptions12 = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, ensure }),
      };

      const response1 = await fetch(
        `${process.env.REACT_APP_API_URL}/getCotatiePack`,
        requestOptions12
      );

      const json1 = await response1.json();

      // Actualizați oferta12 cu noul obiect json1
      if (json1.error === false)
        if (formData.tarif === "false")
          setOferta12((prevOferta12) => [...prevOferta12, json1]);
        else if (formData.tarif === "true")
          setOferta12dec((prevOferta12dec) => [...prevOferta12dec, json1]);
      // Faceți următoarea cerere de date recursiv
      fetchData(ensureOptions.slice(1));
    };

    if (
      (formData.tarif === "false" && oferta12.length === 0) ||
      (formData.tarif === "true" && oferta12dec.length === 0)
    ) {
      const ensureOptions = [
        "hellas",
        "grawe",
        "groupama",
        "axeria",
        "generali",
        "omniasig",
        "allianz",
        "asirom",
      ];

      // Apelați funcția fetchData pentru prima oară

      // if (intrat === 0) {
      //   intrat = 1;
      fetchData(ensureOptions);

      return () => {
        isMounted = false;
      };
    }
  }, [formData]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async (ensureOptions) => {
      if (!isMounted || ensureOptions.length === 0) {
        // Toate cererile au fost finalizate, ieșiți din funcție
        return;
      }

      const ensure = ensureOptions[0];

      const requestOptions = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData1, ensure }),
      };

      const response1 = await fetch(
        `${process.env.REACT_APP_API_URL}/getCotatiePack`,
        requestOptions
      );

      const json1 = await response1.json();

      // Actualizați oferta12 cu noul obiect json1
      //if (json1.error === false) {
      if (formData1.tarif === "false") {
        if (formData1.perioada === 6 && oferta6.length === 0)
          setOferta6((prevOferta6) => [...prevOferta6, json1]);
        if (formData1.perioada === 1 && oferta1.length === 0)
          setOferta1((prevOferta1) => [...prevOferta1, json1]);
        if (formData1.perioada === 3 && oferta3.length === 0)
          setOferta3((prevOferta3) => [...prevOferta3, json1]);
        if (formData1.perioada === 9 && oferta9.length === 0)
          setOferta9((prevOferta9) => [...prevOferta9, json1]);
      } else if (formData1.tarif === "true") {
        if (formData1.perioada === 6 && oferta6dec.length === 0)
          setOferta6dec((prevOferta6dec) => [...prevOferta6dec, json1]);
        if (formData1.perioada === 1 && oferta1dec.length === 0)
          setOferta1dec((prevOferta1dec) => [...prevOferta1dec, json1]);
        if (formData1.perioada === 3 && oferta3dec.length === 0)
          setOferta3dec((prevOferta3dec) => [...prevOferta3dec, json1]);
        if (formData1.perioada === 9 && oferta9dec.length === 0)
          setOferta9dec((prevOferta9dec) => [...prevOferta9dec, json1]);
      }
      //}

      // Faceți următoarea cerere de date recursiv
      fetchData(ensureOptions.slice(1));
    };

    if (
      (formData1.tarif === "false" &&
        formData1.perioada === 6 &&
        oferta6.length === 0) ||
      (formData1.tarif === "false" &&
        formData1.perioada === 1 &&
        oferta1.length === 0) ||
      (formData1.tarif === "false" &&
        formData1.perioada === 3 &&
        oferta3.length === 0) ||
      (formData1.tarif === "false" &&
        formData1.perioada === 9 &&
        oferta9.length === 0) ||
      (formData1.tarif === "true" &&
        formData1.perioada === 6 &&
        oferta6dec.length === 0) ||
      (formData1.tarif === "true" &&
        formData1.perioada === 1 &&
        oferta1dec.length === 0) ||
      (formData1.tarif === "true" &&
        formData1.perioada === 3 &&
        oferta3dec.length === 0) ||
      (formData1.tarif === "true" &&
        formData1.perioada === 9 &&
        oferta9dec.length === 0)
    ) {
      const ensureOptions = [
        "hellas",
        "grawe",
        "groupama",
        "axeria",
        "generali",
        "omniasig",
        "allianz",
        "asirom",
      ];

      // Apelați funcția fetchData pentru prima oară
      fetchData(ensureOptions);
      return () => {
        isMounted = false;
      };
    }
  }, [formData1]);

  useEffect(() => {
    if (formData.tarif === "false") {
      if (oferta12.length > 1) {
        const sortedOferta12 = [...oferta12].sort(
          (a, b) => parseFloat(a.prima) - parseFloat(b.prima)
        );

        setarrayToIterate(sortedOferta12);
        setLoading(false);
      } else {
        if (oferta12.length === 1) {
          setarrayToIterate(oferta12);
          setLoading(false);
        }
      }
    } else if (formData.tarif === "true") {
      if (oferta12dec.length > 1) {
        const sortedOferta12 = [...oferta12dec].sort(
          (a, b) => parseFloat(a.prima) - parseFloat(b.prima)
        );

        setarrayToIterate(sortedOferta12);
        setLoading(false);
      } else {
        if (oferta12dec.length === 1) {
          setarrayToIterate(oferta12dec);
          setLoading(false);
        }
      }
    }
  }, [
    oferta12,
    oferta6,
    oferta1,
    oferta3,
    oferta9,
    oferta12dec,
    oferta1dec,
    oferta3dec,
    oferta6dec,
    oferta9dec,
    formData.tarif,
  ]);

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Row
        className="sectionRCA_buy__form"
        //className={data.filter((item) => item.id === 2)[0].societate_nume; === 0 ? "sectionRCA_buy__right2" : "display-none"}
      >
        <Col xl="5" lg="5" md="5" sm="12" className="sectionRCA_buy__left">
          <label className="sectionRCA_buy__Oferte_subtitle">
            {t("rca.oferte.subtitle1")}
          </label>
          <div>
            <Button
              className={
                formData1.tarif === "false"
                  ? "sub_title_rca_select_active_tarif"
                  : "sub_title_rca_select_tarif"
              }
              disabled={loading}
              onClick={(event) => {
                setFormData({ ...formData, tarif: "false" });
                setFormData1({ ...formData1, tarif: "false", perioada: 6 });
              }}
            >
              {t("rca.oferte.text1")}
            </Button>
            <Button
              className={
                formData1.tarif === "true"
                  ? "sub_title_rca_select_active_tarif"
                  : "sub_title_rca_select_tarif"
              }
              disabled={loading}
              // onClick={(e) => setleasing_rcaValue1(1)}
              onClick={(event) => {
                if (oferta12dec.length === 0) setLoading(true);
                setFormData({ ...formData, tarif: "true" });
                setFormData1({ ...formData1, tarif: "true", perioada: 6 });
              }}
            >
              {t("rca.oferte.text2")}
            </Button>
          </div>
        </Col>

        <Col xl="5" lg="5" md="5" sm="12" className="sectionRCA_buy__left">
          <label className="sectionRCA_buy__Oferte_subtitle">
            {t("rca.oferte.subtitle2")}
          </label>
          <div>
            <Button
              className={
                formData1.perioada === 1
                  ? "sub_title_rca_select_active_tarif"
                  : "sub_title_rca_select_tarif"
              }
              disabled={loading}
              onClick={(event) => {
                //setLoading(true);
                setFormData1({ ...formData1, perioada: 1 });
              }}
            >
              1 {t("rca.oferte.text3")}
            </Button>
            <Button
              key={3}
              className={
                formData1.perioada === 3
                  ? "sub_title_rca_select_active_tarif"
                  : "sub_title_rca_select_tarif"
              }
              disabled={loading}
              onClick={(event) => {
                setFormData1({ ...formData1, perioada: 3 });
              }}
            >
              3 {t("rca.oferte.text4")}
            </Button>
            <Button
              key={4}
              className={
                formData1.perioada === 6
                  ? "sub_title_rca_select_active_tarif"
                  : "sub_title_rca_select_tarif"
              }
              disabled={loading}
              onClick={(event) => setFormData1({ ...formData1, perioada: 6 })}
            >
              6 {t("rca.oferte.text4")}
            </Button>
            <Button
              key={5}
              className={
                formData1.perioada === 9
                  ? "sub_title_rca_select_active_tarif"
                  : "sub_title_rca_select_tarif"
              }
              disabled={loading}
              onClick={(event) => {
                setFormData1({ ...formData1, perioada: 9 });
              }}
            >
              9 {t("rca.oferte.text4")}
            </Button>
            <Button
              key={6}
              className="sub_title_rca_select_active_tarif_12luni"
            >
              12 {t("rca.oferte.text4")}
            </Button>
          </div>
        </Col>
        {/* <Col xl="4" lg="3" md="3" sm="12" className="sectionRCA_buy__left">
          <label className="sectionRCA_buy__Oferte_subtitle sectionRCA_buy__Oferte_subtitleInfo">
            Pentru RCA în RATE apasă butonul de asistență.
          </label>
        </Col> */}
      </Row>
      <Row className="sectionRCA_buy__form">
        <Col xl="6" lg="6" md="6" sm="6" className="sectionRCA_buy__left"></Col>
        {screenSize > 767 ? (
          <Col xl="3" lg="3" md="3" sm="3" className="sectionRCA_buy__left">
            <label className="sectionRCA_buy__Oferte_subtitle_luni">
              {formData1.perioada === 1 ? "1 " + t("rca.oferte.text3") : null}
              {formData1.perioada === 3 ? "3 " + t("rca.oferte.text4") : null}
              {formData1.perioada === 6 ? "6 " + t("rca.oferte.text4") : null}
              {formData1.perioada === 9 ? "9 " + t("rca.oferte.text4") : null}
            </label>
          </Col>
        ) : null}
        {screenSize > 767 ? (
          <Col xl="3" lg="3" md="3" sm="3" className="sectionRCA_buy__left">
            <label className="sectionRCA_buy__Oferte_subtitle_luni">
              12 {t("rca.oferte.text4")}
            </label>
          </Col>
        ) : null}
      </Row>

      {loading === false ? (
        arrayToIterate.map((item, index) => (
          <Row className="sectionRCA_buy__oferte" key={index}>
            <Col lg="4" md="4" className="sectionRCA_buy__left_oferteImg">
              <img
                src={poze[item.societate_nume]}
                alt=""
                className="sectionRCA_buy__Oferte_img_ass"
              />
            </Col>
            <Col lg="2" md="2" className="sectionRCA_buy__left_oferteClasa">
              <label className="sectionRCA_buy__Oferte_Clasa">
                {t("rca.oferte.text5")} {item.clasa_bm}
              </label>
            </Col>
            <Col lg="3" md="3" className="sectionRCA_buy__left_oferte">
              {(screenSize < 767) &
              ((formData1.perioada === 9 &&
                formData1.tarif === "true" &&
                oferta9dec.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                )) ||
                (formData1.perioada === 6 &&
                  formData1.tarif === "true" &&
                  oferta6dec.some(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  )) ||
                (formData1.perioada === 3 &&
                  formData1.tarif === "true" &&
                  oferta3dec.some(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  )) ||
                (formData1.perioada === 1 &&
                  formData1.tarif === "true" &&
                  oferta1dec.some(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  )) ||
                (formData1.perioada === 9 &&
                  formData1.tarif === "false" &&
                  oferta9.some(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  )) ||
                (formData1.perioada === 6 &&
                  formData1.tarif === "false" &&
                  oferta6.some(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  )) ||
                (formData1.perioada === 3 &&
                  formData1.tarif === "false" &&
                  oferta3.some(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  )) ||
                (formData1.perioada === 1 &&
                  formData1.tarif === "false" &&
                  oferta1.some(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ))) ? (
                <label className="sectionRCA_buy__Oferte_subtitle_luni">
                  {formData1.perioada === 1
                    ? "1 " + t("rca.oferte.text3")
                    : null}
                  {formData1.perioada === 3
                    ? "3 " + t("rca.oferte.text4")
                    : null}
                  {formData1.perioada === 6
                    ? "6 " + t("rca.oferte.text4")
                    : null}
                  {formData1.perioada === 9
                    ? "9 " + t("rca.oferte.text4")
                    : null}
                </label>
              ) : null}

              <label className="sectionRCA_buy__Oferte_Pret">
                {formData1.perioada === 1 &&
                formData1.tarif === "false" &&
                !oferta1.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 1 &&
                  formData1.tarif === "false" &&
                  oferta1.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta1.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}

                {formData1.perioada === 3 &&
                formData1.tarif === "false" &&
                !oferta3.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 3 &&
                  formData1.tarif === "false" &&
                  oferta3.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta3.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}

                {formData1.perioada === 6 &&
                formData1.tarif === "false" &&
                !oferta6.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 6 &&
                  formData1.tarif === "false" &&
                  oferta6.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta6.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}

                {formData1.perioada === 9 &&
                formData1.tarif === "false" &&
                !oferta9.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 9 &&
                  formData1.tarif === "false" &&
                  oferta9.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta9.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}
              </label>
              <label className="sectionRCA_buy__Oferte_Pret">
                {formData1.perioada === 1 &&
                formData1.tarif === "true" &&
                !oferta1dec.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 1 &&
                  formData1.tarif === "true" &&
                  oferta1dec.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta1dec.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}
                {formData1.perioada === 3 &&
                formData1.tarif === "true" &&
                !oferta3dec.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 3 &&
                  formData1.tarif === "true" &&
                  oferta3dec.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta3dec.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}
                {formData1.perioada === 6 &&
                formData1.tarif === "true" &&
                !oferta6dec.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 6 &&
                  formData1.tarif === "true" &&
                  oferta6dec.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta6dec.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}
                {formData1.perioada === 9 &&
                formData1.tarif === "true" &&
                !oferta9dec.some(
                  (oferta) => oferta.societate_nume === item.societate_nume
                ) ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : formData1.perioada === 9 &&
                  formData1.tarif === "true" &&
                  oferta9dec.some(
                    (oferta) =>
                      oferta.societate_nume === item.societate_nume &&
                      oferta.error === false
                  ) ? (
                  oferta9dec.find(
                    (oferta) => oferta.societate_nume === item.societate_nume
                  ).prima + " lei"
                ) : null}
              </label>

              {(formData1.perioada === 9 &&
                formData1.tarif === "true" &&
                oferta9dec.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ||
              (formData1.perioada === 6 &&
                formData1.tarif === "true" &&
                oferta6dec.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ||
              (formData1.perioada === 3 &&
                formData1.tarif === "true" &&
                oferta3dec.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ||
              (formData1.perioada === 1 &&
                formData1.tarif === "true" &&
                oferta1dec.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ||
              (formData1.perioada === 9 &&
                formData1.tarif === "false" &&
                oferta9.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ||
              (formData1.perioada === 6 &&
                formData1.tarif === "false" &&
                oferta6.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ||
              (formData1.perioada === 3 &&
                formData1.tarif === "false" &&
                oferta3.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ||
              (formData1.perioada === 1 &&
                formData1.tarif === "false" &&
                oferta1.some(
                  (oferta) =>
                    oferta.societate_nume === item.societate_nume &&
                    oferta.error === false
                )) ? (
                <div className="image-button alegePlata">
                  <img
                    src={btn}
                    alt=""
                    className="image-with-hover-cursor"
                    onClick={() => {
                      if (
                        formData1.perioada === 1 &&
                        formData1.tarif === "false"
                      ) {
                        setOferta(
                          oferta1.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 1,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 3 &&
                        formData1.tarif === "false"
                      ) {
                        setOferta(
                          oferta3.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 3,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 6 &&
                        formData1.tarif === "false"
                      ) {
                        setOferta(
                          oferta6.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 6,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 9 &&
                        formData1.tarif === "false"
                      ) {
                        setOferta(
                          oferta9.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 9,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 1 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta1dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 1,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 3 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta3dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 3,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 6 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta6dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 6,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 9 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta9dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 9,
                          ensure: item.societate_nume,
                        });
                      }
                      setPage(3);
                    }}
                    style={{
                      width: "119px",
                      height: "auto",
                    }}
                  />
                  <div
                    onClick={() => {
                      if (
                        formData1.perioada === 1 &&
                        formData1.tarif === "false"
                      ) {
                        setFormData({
                          ...formData,
                          perioada: 1,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 3 &&
                        formData1.tarif === "false"
                      ) {
                        setOferta(
                          oferta3.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 3,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 6 &&
                        formData1.tarif === "false"
                      ) {
                        setOferta(
                          oferta6.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 6,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 9 &&
                        formData1.tarif === "false"
                      ) {
                        setOferta(
                          oferta9.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 9,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 1 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta1dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 1,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 3 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta3dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 3,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 6 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta6dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 6,
                          ensure: item.societate_nume,
                        });
                      } else if (
                        formData1.perioada === 9 &&
                        formData1.tarif === "true"
                      ) {
                        setOferta(
                          oferta9dec.find(
                            (oferta) =>
                              oferta.societate_nume === item.societate_nume
                          )
                        );
                        setFormData({
                          ...formData,
                          perioada: 9,
                          ensure: item.societate_nume,
                        });
                      }
                      setPage(3);
                    }}
                    className="button-text image-with-hover-cursor"
                    style={{
                      color: "white",
                      marginLeft: "0px",
                      marginTop: "10px",
                    }}
                  >
                    {t("rca.oferte.button1")}
                  </div>
                </div>
              ) : null}
              <br />
            </Col>
            <Col lg="3" md="3" className="sectionRCA_buy__left_oferte">
              {screenSize < 767 ? (
                <label className="sectionRCA_buy__Oferte_subtitle_luni">
                  12 {t("rca.oferte.text4")}
                </label>
              ) : null}

              <label className="sectionRCA_buy__Oferte_Pret">
                {item.prima} lei
                <br />
              </label>

              <div className="image-button alegePlata">
                <img
                  src={btn}
                  alt=""
                  className="image-with-hover-cursor"
                  onClick={() => {
                    setOferta(arrayToIterate[index]);
                    setFormData({
                      ...formData,
                      perioada: 12,
                      ensure: arrayToIterate[index].societate_nume,
                    });

                    setPage(3);
                  }}
                  style={{
                    width: "119px",
                    height: "auto",
                  }}
                />
                <div
                  onClick={() => {
                    setOferta(arrayToIterate[index]);
                    setFormData({
                      ...formData,
                      perioada: 12,
                      ensure: arrayToIterate[index].societate_nume,
                    });

                    setPage(3);
                  }}
                  className="button-text image-with-hover-cursor"
                  style={{
                    color: "white",
                    marginLeft: "0px",
                    marginTop: "10px",
                  }}
                >
                  {t("rca.oferte.button1")}
                </div>
              </div>
            </Col>
            <div
              className={
                index !== oferta12.length - 1
                  ? "sectionRCA_buy__oferte_border"
                  : "non_display"
              }
              key={index}
            >
              <hr></hr>
            </div>
          </Row>
        ))
      ) : (
        <div>
          <div className="floating-image">
            <Spinner
              animation="border"
              role="status"
              style={{
                width: "100px",
                height: "100px",
              }}
            ></Spinner>
          </div>
          <div className="AvertizmentRca">
            <label>
              Timpul mediu de așteptare este de 20 secunde. Vă rugăm să nu
              reîmprospătați pagina.
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default Oferte;
