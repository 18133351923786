import React from "react";
import "./ChatBubble.css";

const ChatBubble = ({ message, position, dimention }) => {
  return (
    <div className={`chat-bubble ${position}`}>
      <p className={`${dimention}`}>{message}</p>
    </div>
  );
};

export default ChatBubble;
