import React from "react";
import DocumentdeInformarePDF from "../../../../assests/images/HomeProtect/DocumentdeInformare_LoculTau.CasaTa.pdf";
import TermenisiConditiiPDF from "../../../../assests/images/HomeProtect/TermenisiConditii_LoculTTau.CasaTa.pdf";

import "./DownloadPDFButton.css";

import { useLanguage } from "../../../utils/utils.jsx";

const DownloadPDFButton = () => {
  const { t, i18n } = useLanguage();

  const handleDownload = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  return (
    <label className="buttonDownloadeLocuinteText">
      {t("homeProtect.downloadeInfo.text1")}
      <button
        className="buttonDownloadeLocuinte"
        onClick={() =>
          handleDownload(
            DocumentdeInformarePDF,
            "DocumentdeInformare_LoculTau.CasaTa.pdf"
          )
        }
      >
        {t("homeProtect.downloadeInfo.button1")}
      </button>
      {t("homeProtect.downloadeInfo.text3")}
      <button
        className="buttonDownloadeLocuinte"
        onClick={() =>
          handleDownload(
            TermenisiConditiiPDF,
            "TermenisiConditii_LoculTTau.CasaTa.pdf"
          )
        }
      >
        {t("homeProtect.downloadeInfo.button2")}
      </button>
      {t("homeProtect.downloadeInfo.text2")}
    </label>
  );
};

export default DownloadPDFButton;
