import React, { useState, useEffect } from "react";
import "./HomeProtectNew.css";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../utils/utils.jsx";

import Intro from "./Intro";
import Adresa from "./Adresa";
import Bunuri from "./Bunuri";
import Fransiza from "./Fransiza";
import Delatalii from "./Delatalii";
import DatePersonale from "./DatePersonale";

// import ReactGA from "react-ga";

const HomeProtectNew = () => {
  useEffect(() => {
    document.title = "Senflua - HomeProtectNew";
    // ReactGA.pageview(window.location.pathname);
  }, []);

  const { t, i18n } = useLanguage();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [citizenship, setCitizenship] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [textCod, setTextCod] = useState("");

  const [showNewPeople, setShowNewPeople] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [IsProprietar, setIsProprietar] = useState(true);
  const [adresaBoolean, setAdresaBoolean] = useState(false);

  const TipuriStrazi = {
    select: "Selecteaza",
    Alee: "Alee",
    AleeI: "AleeI",
    AleeII: "AleeII",
    AleeIII: "AleeIII",
    AleeIV: "AleeIV",
    AleeV: "AleeV",
    AleeVI: "AleeVI",
    AleeVII: "AleeVII",
    AleeVIII: "AleeVIII",
    Bulevard: "Bulevard",
    Cale: "Cale",
    Camp: "Camp",
    Canal: "Canal",
    Canton: "Canton",
    Cartier: "Cartier",
    Colonie: "Colonie",
    Curte: "Curte",
    Cvartal: "Cvartal",
    Drum: "Drum",
    Fundac: "Fundac",
    Fundatura: "Fundatura",
    Hotar: "Hotar",
    Intrare: "Intrare",
    Parc: "Parc",
    Pasaj: "Pasaj",
    Piata: "Piata",
    Piateta: "Piateta",
    Pietonal: "Pietonal",
    Platou: "Platou",
    Pod: "Pod",
    Poligon: "Poligon",
    Poteca: "Poteca",
    Prelungire: "Prelungire",
    Rampa: "Rampa",
    Scuar: "Scuar",
    Sir: "Sir",
    Sosea: "Sosea",
    Splai: "Splai",
    Statia: "Statia",
    Strada: "Strada",
    Stradela: "Stradela",
    Suis: "Suis",
    Trecatoare: "Trecatoare",
    Ulita: "Ulita",
    Vad: "Vad",
    Varianta: "Varianta",
    Zona: "Zona",
  };

  const TipuriEtaje = {
    select: "Selecteaza",
    Parter: "Parter",
    UnEtaj: "Un etaj",
    DouaEtaje: "Două etaje",
    TreiEtaje: "Trei etaje",
    PatruEtaje: "Patru etaje",
    CinciEtaje: "Cinci etaje",
    SaseEtaje: "Șase etaje",
    SapteEtaje: "Șapte etaje",
    OptEtaje: "Opt etaje",
    NouaEtaje: "Nouă etaje",
    ZeceEtaje: "Zece etaje",
    Intre11si20etaje: "Între 11 și 20 de etaje",
    MaiMultDe20Etaje: "Mai mult de 20 de etaje",
  };

  const [dateSuplimentare, setDateSuplimentare] = useState({
    email: "",
    number: "",
    date: null,
    totalPlata: 0,
  });

  const [cesionari, setCesionari] = useState([{ id: "", percentage: "" }]);

  const [contractant, setContractant] = useState({
    nume: "",
    prenume: "",
    cnp: "",
  });

  const [paid, setPaid] = useState({
    hasActivePad: "",
    hasExpiringPad: "",
    padValueInEur: "",
    padValueInRon: "",
    messages: [],
  });

  const [adresa, setAdresa] = useState({
    county: "100",
    city: "9999999999",
    postCode: "select",
    streetType: "select",
    street: "",
    number: "",
    building: "",
    entrance: "",
    floor: "",
    appartment: "",
  });

  const [home, setHome] = useState({
    customer: {
      personType: 0,
      uniqueIdentifier: "",
      firstName: "",
      lastName: "",
      // citizenship: "1",
    },
    house: {
      address: {
        county: 100,
        uat: "",
        city: 9999999999,
        postCode: "select",
        streetType: "select",
        street: "",
        number: "",
        building: "",
        entrance: "",
        floor: "",
        appartment: "",
      },
      technicalDetails: {
        houseType: "Bloc",
        constructionType: "100",
        constructionYear: "",
        usableArea: "",
        heightRegime: "select",
        rooms: "",
        buildingIdentification: "",
      },
    },
    currency: "RON",
    franchise: "0",
    frequency: "LUNARA",
    campaignCode: "",
    discount: "",
    assignmentDetails: "",
    assignees: [],
  });

  const [oferta, setOferta] = useState({
    error: false,
    offerId: "0",
    series: "0",
    number: "0",
    selectedFrequency: "LUNARA",
    currency: "RON",
    frequencies: [
      {
        frequency: "LUNARA",
        premium: 0,
        totalPremium: 0,
        offerCovers: null,
      },
      {
        frequency: "TRIMESTRIALA",
        premium: 0,
        totalPremium: 0,
        offerCovers: null,
      },
      {
        frequency: "SEMESTRIALA",
        premium: 0,
        totalPremium: 0,
        offerCovers: null,
      },
      {
        frequency: "ANUALA",
        premium: 0,
        totalPremium: 0,
        offerCovers: null,
      },
    ],
    covers: [
      {
        productCoverCode: "0814_LOCUINT",
        name: "LOCUINȚĂ",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_ASIS_TH",
        name: "ASISTENTA TEHNICĂ DE URGENTĂ",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_B_2000",
        name: "BUNURI CONȚINUTE INDIVIDUALE CU VALORI SUB 2000 EUR",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_DOCUMEN",
        name: "DOCUMENTE CARDURI CHEI",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_EEP_TAB",
        name: "APARATURĂ ELECTRONICĂ PORTABILĂ (TABLETE)",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_EEP_TEL",
        name: "APARATURĂ ELECTRONICĂ PORTABILĂ(TELEFOANE)",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_BUP",
        name: "BUNURI UZUALE PORTABILE",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_EEP_LAP",
        name: "APARATURĂ ELECTRONICĂ PORTABILĂ (LAPTOP, FOTO VIDEO)",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_FENOMEN",
        name: "FENOMENE ELECTRICE",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_AA_INST",
        name: "AVARII ACCIDENTALE LA INSTALAȚII",
        insuredSum: 0,
        premium: 0,
      },

      {
        productCoverCode: "0814_APA_CO",
        name: "INUNDAREA CU APĂ DE CONDUCTĂ, APĂ PROVENITĂ DE LA VECINI SAU REFULARE",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_ACC_CT",
        name: "AVARII ACCIDENTALE ALE CENTRALELOR TERMICE ȘI INSTALAȚIILOR DE AER CONDITIONAT",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_SPARG",
        name: "SPARGEREA SUPRAFETELOR VITRATE ȘI A BUNURILOR CASABILE",
        insuredSum: 0,
        premium: 0,
      },
      {
        productCoverCode: "0814_RC",
        name: "RĂSPUNDERE CIVILĂ FAȚĂ DE TERȚI",
        insuredSum: 0,
        premium: 0,
      },
    ],
    badRequest: {
      code: "",
      message: "",
    },
  });

  const etaje = {
    "": "Selecteaza",
    Subsol: "Subsol",
    Demisol: "Demisol",
    Parter: "Parter",
    Mansarda: "Mansarda",
    Mezanin: "Mezanin",
    ...Object.fromEntries(
      Array.from({ length: 30 }, (_, index) => [
        `Etaj${index + 1}`,
        `Etaj${index + 1}`,
      ])
    ),
  };

  const [judete, setJudete] = useState([]);
  const [orase, setOrase] = useState([
    { code: "9999999999", description: "Selecteaza" },
  ]);
  const [codPostal, setCodPostal] = useState([
    { code: "select", description: "Selecteaza" },
  ]);

  const [constructia, setConstructia] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [franchises, setFranchises] = useState([]);

  const [covers, setCovers] = useState({
    locuinta: "",
    bunuri: 0,
    unelte: 0,
    echipamenteTelefoane: 0,
    echipamenteTablete: 0,
    echipamenteFoto: 0,
    electrice: 0,
    gaz: 0,
    centrala: 0,
    geamuri: 0,
    restul: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getCounties`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        setJudete([{ code: "100", description: "Selecteaza" }, ...data]);
      } catch (error) {
        navigate("/eroare");
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getConstructionTypes`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        // Filtrăm elementele pe baza codului
        const newData = data.filter(
          (item) =>
            item.code !== "LemnSauOsb" && item.code !== "PaiantaSauChirpici"
        );

        // Actualizăm state-ul cu noul obiect de date
        setConstructia([
          { code: "100", description: "Selecteaza" },
          ...newData,
        ]);
      } catch (error) {
        navigate("/eroare");
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getCurrencies`,

          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setCurrency([{ code: "100", description: "Selecteaza" }, ...data]);
      } catch (error) {
        navigate("/eroare");
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getFranchises?currencies=RON`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        setFranchises(data);
        setHome((prevHome) => ({
          ...prevHome,
          franchise: data[0].code,
        }));
      } catch (error) {
        navigate("/eroare");
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getCitizenships`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setCitizenship([{ code: "", description: "Selecteaza" }, ...data]);
      } catch (error) {
        navigate("/eroare");
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/paidAssignees`,
          //`http://localhost:8080/getCounties`,
          {
            headers: {
              "API-Key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setAssignees([{ id: "", description: "Selecteaza" }, ...data]);
      } catch (error) {
        navigate("/eroare");
      }
    };

    fetchData();
  }, []);

  const PageDisplay = () => {
    if (page === 0) {
      return <Intro page={page} setPage={setPage} />;
    } else if (page === 1) {
      return (
        <Adresa
          home={home}
          setHome={setHome}
          judete={judete}
          setJudete={setJudete}
          orase={orase}
          setOrase={setOrase}
          constructia={constructia}
          covers={covers}
          setCovers={setCovers}
          page={page}
          setPage={setPage}
          codPostal={codPostal}
          setCodPostal={setCodPostal}
          franchises={franchises}
          setFranchises={setFranchises}
          etaje={etaje}
          confirm={confirm}
          setConfirm={setConfirm}
          TipuriStrazi={TipuriStrazi}
          TipuriEtaje={TipuriEtaje}
        />
      );
    } else if (page === 2) {
      return (
        <Bunuri
          home={home}
          setHome={setHome}
          judete={judete}
          setJudete={setJudete}
          orase={orase}
          setOrase={setOrase}
          covers={covers}
          setCovers={setCovers}
          page={page}
          setPage={setPage}
          oferta={oferta}
          setOferta={setOferta}
        />
      );
    } else if (page === 3) {
      return (
        <Fransiza
          home={home}
          setHome={setHome}
          judete={judete}
          setJudete={setJudete}
          orase={orase}
          setOrase={setOrase}
          covers={covers}
          setCovers={setCovers}
          page={page}
          setPage={setPage}
          oferta={oferta}
          setOferta={setOferta}
          franchises={franchises}
          setFranchises={setFranchises}
        />
      );
    } else if (page === 4) {
      return (
        <Delatalii
          home={home}
          setHome={setHome}
          judete={judete}
          setJudete={setJudete}
          orase={orase}
          setOrase={setOrase}
          covers={covers}
          setCovers={setCovers}
          page={page}
          setPage={setPage}
          oferta={oferta}
          setOferta={setOferta}
          franchises={franchises}
          setFranchises={setFranchises}
          textCod={textCod}
          setTextCod={setTextCod}
        />
      );
    } else if (page === 5) {
      return (
        <DatePersonale
          home={home}
          setHome={setHome}
          judete={judete}
          setJudete={setJudete}
          orase={orase}
          setOrase={setOrase}
          covers={covers}
          setCovers={setCovers}
          page={page}
          setPage={setPage}
          oferta={oferta}
          setOferta={setOferta}
          franchises={franchises}
          setFranchises={setFranchises}
          citizenship={citizenship}
          assignees={assignees}
          showNewPeople={showNewPeople}
          setShowNewPeople={setShowNewPeople}
          IsProprietar={IsProprietar}
          setIsProprietar={setIsProprietar}
          adresaBoolean={adresaBoolean}
          setAdresaBoolean={setAdresaBoolean}
          cesionari={cesionari}
          setCesionari={setCesionari}
          contractant={contractant}
          setContractant={setContractant}
          adresa={adresa}
          setAdresa={setAdresa}
          paid={paid}
          setPaid={setPaid}
          dateSuplimentare={dateSuplimentare}
          setDateSuplimentare={setDateSuplimentare}
          TipuriStrazi={TipuriStrazi}
          TipuriEtaje={TipuriEtaje}
          etaje={etaje}
        />
      );
    }
  };

  return <div className="locuinteDim">{PageDisplay()}</div>;
};

export default HomeProtectNew;
