import React from "react";
import { Row, Col } from "reactstrap";
import SenfluaLife from "../../../assests/images/senfluaLife.svg";
import SenfluaTHINGS from "../../../assests/images/senfluaTHINGS.svg";
import "./Section3.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section3 = () => {
  const { t, i18n } = useLanguage();

  return (
    <section className="section3">
      <Row>
        <Col lg="6" md="6" sm="12" xs="12" className="section3__lf">
          <div className="section3__content">
            <p className="section3__title">{t("section3.title1")}</p>
            <img src={SenfluaLife} alt="" className="senfluaLife" />
            <p className="section3__subtitle">
              {t("section3.description1")} <br /> {t("section3.description11")}
            </p>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="section3__content">
            <p className="section3__title">{t("section3.title2")}</p>
            <img src={SenfluaTHINGS} alt="" className="senfluaTHINGS" />
            <p className="section3__subtitle">
              {t("section3.description2")} <br /> {t("section3.description22")}
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Section3;
