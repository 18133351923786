import React, { useState } from "react";
import { Container } from "reactstrap";
import "./Accordion.css";
import Up from "../../assests/images/accordion/up.svg";
import Down from "../../assests/images/accordion/down.svg";

import { useLanguage } from "../utils/utils.jsx";

function Accordion() {
  const { t, i18n } = useLanguage();

  const dataCollection = [
    {
      question: t("accordion.question1"),
      answer: t("accordion.answer1"),
    },
    {
      question: t("accordion.question2"),
      answer: t("accordion.answer2"),
    },
    {
      question: t("accordion.question3"),
      answer: t("accordion.answer3"),
    },
    {
      question: t("accordion.question4"),
      answer: t("accordion.answer4"),
    },
    {
      question: t("accordion.question5"),
      answer: t("accordion.answer5"),
    },
    {
      question: t("accordion.question6"),
      answer: t("accordion.answer6"),
    },
  ];

  const [accordion, setActiveAccordion] = useState(-1);

  function toggleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  return (
    <section>
      <Container className="container2">
        <div className="accordion__faq">
          {dataCollection.map((item, index) => (
            <div key={index} onClick={() => toggleAccordion(index)}>
              <div className="accordion__faq-heading">
                <div className="icons">
                  {accordion === index ? (
                    <img src={Up} className="verticle" alt="" />
                  ) : (
                    <img src={Down} className="horizental" alt="" />
                  )}
                </div>
                <h3 className={accordion === index ? "active" : ""}>
                  {item.question}
                </h3>
                <p className={accordion === index ? "active" : "inactive"}>
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default Accordion;
