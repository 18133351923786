import React, { Fragment } from "react";

import Header from "../header/Header";
import HeroCondtii from "../HeroCondtii/HeroCondtii";
import SectionTextPoliticaConf from "../SectionTextPoliticaConf/SectionTextPoliticaConf";

import Footer from "../Footer/Footer";

const PoliticaConf = () => {
  return (
    <Fragment>
      <Header />
      <HeroCondtii title="Politica de confidențialitate" />
      <SectionTextPoliticaConf />

      <Footer />
    </Fragment>
  );
};

export default PoliticaConf;
