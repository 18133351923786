import React, { Fragment } from "react";
import Header from "../header/Header";
import HeroSection from "../SectionsHome/Hero-Section/HeroSection";
import Section2 from "../SectionsHome/Section2/Section2";
import Section3 from "../SectionsHome/Section3/Section3";
import SenfluaMeical from "../SectionsHome/SenfluaMeical/SenfluaMeical";
import Section4 from "../SectionsHome/Section4/Section4";
import SenfluaBussines from "../SectionsHome//SenfluaBussines/SenfluaBussines";
import Section5 from "../SectionsHome/Section5/Section5";
import Section6 from "../SectionsHome/Section6/Section6";
import Section7 from "../SectionsHome/Section7/Section7";
import Section8 from "../SectionsHome/Section8/Section8";
import Section9 from "../SectionsHome/Section9/Section9";
import Footer from "../Footer/Footer";
import TabBar from "../TabBar/TabBar";

const Home = () => {
  return (
    <Fragment>
      <TabBar />
      <Header />
      <HeroSection />
      <Section2 />
      <Section3 />
      <SenfluaMeical />
      <Section4 />
      <SenfluaBussines />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Footer />
    </Fragment>
  );
};

export default Home;
