import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import TrimiteRaspunsul from "../../assests/images/RCA/next.png";

import teleportareaeInviitor from "../../assests/images/teleportareaeInviitor.svg";

import { useTranslation } from "react-i18next";
import "../../i18n";

import "./FormContact.css";

const FormContact = () => {
  useEffect(() => {
    document.title = "Senflua - Contacteaza-ma";
  }, []);

  const { t, i18n } = useTranslation();

  const [formContact, setFormContact] = useState({
    numePrenume: "",
    mail: "",
    subiect: "auto",
    numarTel: "",
    mesaj: "",
  });

  const [tc, setTc] = useState(false);
  const [pc, setPc] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
  };

  const validateNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setFormContact({
      ...formContact,
      numarTel: value,
    });
  };

  function Subiect(event) {
    const selectedText = event.target.options[event.target.selectedIndex].text;

    setFormContact({
      ...formContact,
      subiect: selectedText,
    });
  }

  async function sandEmail(event) {
    if (
      validateEmail(formContact.mail) &&
      formContact.numePrenume.length > 4 &&
      formContact.numarTel.length > 9 &&
      formContact.mesaj.length > 4 &&
      tc === true &&
      pc === true
    ) {
      const requestOptions = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formContact),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formContact`,
        requestOptions
      );
      const data = await response.json();
      if (data === true) window.location.href = "/confirmare-mesaj";
      else window.location.href = "/eroare";
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="formContact">
      <Container>
        <div className="formContact_titlu">
          <p>{t("contact.title")}</p>
        </div>
        <Row>
          <Col lg="8" sm="12" xs="12" className="formContact_left">
            <Row>
              <Col
                xl="4"
                lg="6"
                md="6"
                sm="12"
                className="formContact_left_campuri"
              >
                <label className="formContact_input">
                  {t("contact.request1")}
                  <br />
                  <input
                    placeholder="ex. Ion Popescu"
                    type="text"
                    name="name"
                    value={formContact.numePrenume}
                    onChange={(event) =>
                      setFormContact({
                        ...formContact,
                        numePrenume: event.target.value,
                      })
                    }
                  />
                </label>
              </Col>
              <Col
                xl="4"
                lg="6"
                md="6"
                sm="12"
                className="formContact_left_campuri1"
              >
                <label className="formContact_input">
                  {t("contact.request2")}
                  <br />
                  <input
                    placeholder="ex. hello@senflua.ro"
                    type="email"
                    name="email"
                    value={formContact.mail}
                    onChange={(event) =>
                      setFormContact({
                        ...formContact,
                        mail: event.target.value,
                      })
                    }
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col
                xl="4"
                lg="6"
                md="6"
                sm="12"
                className="formContact_left_campuri"
              >
                <label className="formContact_input">
                  {t("contact.request3")}
                  <br />
                  <select Value={formContact.subiect} onChange={Subiect}>
                    <option value="auto">auto</option>
                    <option value="travel">travel</option>
                    <option value="locuinte">locuinte</option>
                    <option value="malpraxis">malpraxis</option>
                    <option value="business">business</option>
                    <option value="sanatate">sanatate</option>
                    <altele value="altele">business</altele>
                  </select>
                </label>
              </Col>
              <Col
                xl="4"
                lg="6"
                md="6"
                sm="12"
                className="formContact_left_campuri1"
              >
                <label className="formContact_input">
                  {t("contact.request4")}
                  <br />
                  <input
                    placeholder="ex. 0745 xxx xxx"
                    type="text"
                    name="name"
                    value={formContact.numarTel}
                    onChange={validateNumber}
                  />
                </label>
              </Col>
            </Row>
            <div className="formContact_inputMesageDiv">
              <label className="formContact_inputMesage">
                {t("contact.request5")}
                <br />
                <textarea
                  placeholder={t("contact.request6")}
                  type="text"
                  name="name"
                  value={formContact.mesaj}
                  onChange={(event) =>
                    setFormContact({
                      ...formContact,
                      mesaj: event.target.value,
                    })
                  }
                />
              </label>
              <div className="formContact_inputTC">
                <label className="formContact_inputChech">
                  <input
                    type="checkbox"
                    required="required"
                    onChange={(e) => setTc(e.target.checked)}
                  />
                  {t("contact.check1")}
                  <a href="/termeni-conditii" target="_blank">
                    {t("contact.check11")}
                  </a>
                </label>
              </div>
              <div className="formContact_inputTC">
                <label className="formContact_inputChech">
                  <input
                    type="checkbox"
                    required="required"
                    onChange={(e) => setPc(e.target.checked)}
                  />
                  {t("contact.check2")}
                  <a href="/politica-confidentialitate" target="_blank">
                    {t("contact.check21")}te
                  </a>
                </label>
              </div>
            </div>
            <p className="errorPage_RCAbuy_polita">
              {formContact.numarTel.length < 10 ? t("contact.error1") : null}
            </p>
            <p className="errorPage_RCAbuy_polita">
              {!validateEmail(formContact.mail) ? t("contact.error2") : null}
            </p>

            <p className="errorPage_RCAbuy_polita">
              {formContact.numePrenume.length < 4 ||
              formContact.mesaj.length < 4 ||
              tc === false ||
              pc === false
                ? t("contact.error3")
                : null}
            </p>

            <div className="image-button TrimiteRaspunsul">
              <img
                src={TrimiteRaspunsul}
                alt=""
                className="image-with-hover-cursor"
                style={{
                  width: "173px",
                  height: "auto",
                }}
                onClick={sandEmail}
              />

              <div
                onClick={sandEmail}
                className="button-text image-with-hover-cursor"
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {t("contact.button1")}
              </div>
            </div>
          </Col>
          <Col lg="4" sm="12" xs="12" className="textForm">
            <img
              src={teleportareaeInviitor}
              alt=""
              className="teleportareaeInviitor"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FormContact;
