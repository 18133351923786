import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import calatorie from "../../assests/images/RCA/calatorie.png";
import yayillustratie from "../../assests/images/Travel/yayillustratie.svg";
import senfluaSad from "../../assests/images/RCA/Sad_Senflua.svg";
import Plata from "../../assests/images/RCA/plata.png";
import Check from "../../assests/images/RCA/check.svg";
import VreauAsigurare from "../../assests/images/Travel/VreauAsigurareRCA_btn.png";
import Consultant from "../../assests/images/Travel/contacteeazaSenflua.png";

import contacteeazaSenflua from "../../assests/images/contacteeazaSenflua.svg";
import loadingGif from "../../assests/images/Travel/loader100.gif";

import "./Confirmare_HomeProtect.css";
//const [cookies] = useCookies(['oferta']);

import { useLanguage } from "../utils/utils.jsx";

const Confirmare_HomeProtect = () => {
  const { t, i18n } = useLanguage();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const [cookies] = useCookies([
    "quoteRequest",
    "oferta",
    "dateSuplimentare",
    "contractant",
    "adressFacturare",
    "orderId",
  ]);

  const [cookiesSession, setCookieSession] = useCookies([orderId]);

  const [plata, setPlata] = useState([]);
  const [loading, setLoading] = useState(false);

  const homeValue = cookies.quoteRequest;
  const ofertaValue = cookies.oferta;
  const dateSuplimentareValue = cookies.dateSuplimentare;
  const contractantValue = cookies.contractant;
  const adressFacturareValue = cookies.adressFacturare;
  const orderIdValue = cookies.orderId;

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  let intrat = 0;

  useEffect(() => {
    const fetchData = async () => {
      console.log(homeValue);
      console.log(ofertaValue);
      console.log(dateSuplimentareValue);
      console.log(contractantValue);
      console.log(adressFacturareValue);
      const requestOptions = {
        method: "POST",
        headers: {
          "API-Key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quoteRequest: homeValue,
          oferta: ofertaValue,
          dateSuplimentare: dateSuplimentareValue,
          contractant: contractantValue,
          adressFacturare: adressFacturareValue,
          orderId: orderId,
        }),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/validarePlataHomeProtect`,
        requestOptions
      );

      const json = await response.json();

      setPlata(json);
    };

    if (intrat === 0) {
      intrat = 1;

      const orderIdValue = cookiesSession[orderId];
      console.log(orderIdValue);

      if (!orderId) {
        window.location.href = "/";
      } else {
        if (!orderIdValue) {
          setCookieSession(orderId, Date.now());
          document.title = "Senflua - Confirmare Home Protect";
          console.log(1);
          fetchData();
        } else {
          window.location.href = "/";
        }
      }
    }
  }, []);

  useEffect(() => {
    if (plata.error === false || plata.error === true) {
      setLoading(true);
    }
  }, [plata]);

  return (
    <section className="section_formTravel">
      <Container className="containerTravel__form">
        <img
          src={contacteeazaSenflua}
          alt=""
          className="conTravel_sticky"
          onClick={(event) =>
            (window.location.href = "https://wa.me/40724542277")
          }
        />
        <div className={"confirmare_travel_title"}>
          {loading === true ? (
            plata.errorBT1 === false &&
            plata.errorBT2 === false &&
            plata.emitere === false &&
            plata.sendEmail === false &&
            plata.error === false ? (
              <p>
                {t("travel.confirmare.title11")}
                <br /> {t("homeProtect.confirmare.title12")}
                <img src={Check} alt="" className="check_btn" />
              </p>
            ) : (
              <>
                {plata.errorBT1 === true ||
                plata.errorBT2 === true ||
                plata.emitere === true ||
                plata.error === true ? (
                  <p>{t("travel.confirmare.error")}</p>
                ) : plata.sendEmail === true ? (
                  <p>{t("travel.confirmare.error1")}</p>
                ) : null}

                {plata.errorBT1 === true ? (
                  <p className={"sectionTravel__formConfirmare_subtitle"}>
                    {plata.errorbtText1}
                  </p>
                ) : null}

                {plata.errorBT2 === true ? (
                  <p className={"sectionTravel__formConfirmare_subtitle"}>
                    {plata.errorbtText2} <br /> {plata.errorbtText3}
                  </p>
                ) : null}

                {plata.emitere === true ? (
                  <p className={"sectionTravel__formConfirmare_subtitle"}>
                    {plata.emitereText}
                  </p>
                ) : null}

                {plata.sendEmail === true ? (
                  <p className={"sectionTravel__formConfirmare_subtitle"}>
                    A aparut o eroare la trimiterea email-ului acum, il vom
                    trimite mai tarziu nu-ti face griji.
                  </p>
                ) : (
                  <p className={"sectionTravel__formConfirmare_subtitle"}>
                    Erorile mai apar, diferenta e de cum reactionam la ele.{" "}
                    <br /> Senflua iti este alaturi la fiecare pas. Daca eroarea
                    persista si ai nevoie sa intri in contact cu un asistent
                    Senflua
                  </p>
                )}
              </>
            )
          ) : (
            <div className="loading_gf">
              <p className="sectionTravel__Loading">
                {t("travel.confirmare.wait")}
              </p>
              <img src={loadingGif} alt="Loading..." />
            </div>
          )}
        </div>
        <Row className="sectionTravel__formConfirmare">
          {loading === false ||
          (plata.errorBT1 === false &&
            plata.errorBT2 === false &&
            plata.emitere === false &&
            plata.error === false) ? (
            <Col xl="6" lg="6" md="12" sm="12" className="sectionRCA__Center">
              <img src={yayillustratie} alt="" className="" />
            </Col>
          ) : (
            <Col xl="12" lg="12" md="12" sm="12" className="sectionRCA__Center">
              <img
                style={{ width: "348px", height: "auto" }}
                src={senfluaSad}
                alt=""
                className=""
              />
            </Col>
          )}

          {loading === false ||
          (plata.errorBT1 === false &&
            plata.errorBT2 === false &&
            plata.emitere === false &&
            plata.error === false) ? (
            <Col xl="6" lg="6" md="12" sm="12" className="sectionRCA__right">
              <p className={"sectionTravel__formConfirmare_subtitle"}>
                {t("travel.confirmare.subtitle11")}
                <br /> {t("travel.confirmare.subtitle12")}
              </p>
              <p className={"sectionTravel__formConfirmare_text"}>
                {t("travel.confirmare.spam")}
              </p>
              <p className={"sectionTravel__formConfirmare_text"}>
                {t("travel.confirmare.subtitle21")}
                <br /> {t("travel.confirmare.subtitle22")}
              </p>

              <div
                className="image-button rca_btn_calatorie"
                style={{
                  marginTop: "10px",
                  marginBottom: "30px",
                }}
              >
                <img
                  src={VreauAsigurare}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "303px",
                    height: "auto",
                  }}
                  onClick={(event) => (window.location.href = "/asigurare-rca")}
                />

                <div
                  onClick={(event) => (window.location.href = "/asigurare-rca")}
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                  }}
                >
                  {t("travel.confirmare.button1")}
                </div>
              </div>

              <p className={"sectionTravel__formConfirmare_text"}>
                {t("travel.confirmare.subtitle31")} <br />{" "}
                {t("travel.confirmare.subtitle32")}
              </p>
              <div
                className="image-button rca_btn_calatorie"
                style={{
                  marginTop: "10px",
                  marginBottom: "30px",
                }}
              >
                <img
                  src={Consultant}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "303px",
                    height: "auto",
                  }}
                  onClick={(event) =>
                    (window.location.href = "https://wa.me/40724542277")
                  }
                />

                <div
                  onClick={(event) =>
                    (window.location.href = "https://wa.me/40724542277")
                  }
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "30px",
                    marginTop: "10px",
                  }}
                >
                  {t("travel.confirmare.button2")}
                </div>
              </div>
            </Col>
          ) : null}
        </Row>
      </Container>
    </section>
  );
};

export default Confirmare_HomeProtect;
