import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";

import Rapid from "../../../assests/images/benefits/Rapid.svg";
import Flexibil from "../../../assests/images/benefits/Flexibil.svg";
import Consiliere from "../../../assests/images/benefits/Consiliere.svg";
import Corect from "../../../assests/images/benefits/Corect.svg";

import Feedback from "../../../assests/images/feedback_form.svg";
import TrimiteRaspunsul_btn from "../../../assests/images/homePage/VreauRCA.png";
// import RCAbtn from "../../../assests/images/homePage/VreauRCA.png";

import "./Section6.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section6 = () => {
  const { t, i18n } = useLanguage();

  const [feedback, setFeedback] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    text: "",
  });

  const [message, setMessage] = useState(false);

  function handleSendEmail(event) {
    const requestOptions12 = {
      method: "POST",
      headers: {
        "API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedback),
    };
    fetch(`${process.env.REACT_APP_API_URL}/feebackForm`, requestOptions12);

    setMessage(true);
    setFeedback({
      ...feedback,
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      text: "",
    });
  }

  return (
    <section className="section6" id="tele-Portezi">
      <Container>
        <div className="section6__Title">
          <p>{t("section6.title")}</p>
        </div>
        <div className="section6__subTitle">
          <p>
            {t("section6.subtitle")}
            <br /> {t("section6.subtitle1")}
          </p>
        </div>

        <Row className="section6__form">
          <Col lg="3" md="3" className="section6__form__benefits">
            <img src={Rapid} alt="" className="Consiliere" />
            <h3>{t("section6.titleList1")}</h3>
            <p>
              {t("section6.descriptionList1")} <br />{" "}
              {t("section6.descriptionList12")}
              <br />
              {t("section6.descriptionList13")}
            </p>
          </Col>
          <Col lg="3" md="3" className="section6__form__benefits">
            <img src={Consiliere} alt="" className="Consiliere" />
            <h4>{t("section6.titleList2")}</h4>
            <p>
              {t("section6.descriptionList2")}
              <br /> {t("section6.descriptionList22")}
            </p>
          </Col>

          <Col lg="3" md="3" className="section6__form__benefits">
            <img src={Flexibil} alt="" className="Consiliere" />
            <h4>{t("section6.titleList3")}</h4>
            <p>
              {t("section6.descriptionList3")}
              <br /> {t("section6.descriptionList32")}
              <br /> {t("section6.descriptionList33")}
            </p>
          </Col>

          <Col lg="3" md="3" className="section6__form__benefits">
            <img src={Corect} alt="" className="Consiliere" />
            <h5>{t("section6.titleList4")}</h5>
            <p>
              {t("section6.descriptionList4")}
              <br /> {t("section6.descriptionList42")}
              <br /> {t("section6.descriptionList43")}
            </p>
          </Col>
        </Row>

        <Row className="section6__form">
          <Col lg="7" md="7" className="section6__form__content">
            <div className="section6__form__content_Title">
              <p>{t("section6.title2")}</p>
            </div>
            <div className="section6__form__content_subTitle">
              <p>{t("section6.subtitle2")}</p>
            </div>
            <div className="section6__form__content_subTitle2">
              <p>{t("section6.subtitle12")}</p>
            </div>

            <div
              className={
                feedback.one === false
                  ? "section6__form_label1"
                  : "section6__form_label2"
              }
              onClick={(e) => {
                setFeedback({
                  ...feedback,
                  one: !feedback.one,
                });
              }}
            >
              <label>{t("section6.optiune1")}</label>
            </div>

            <div
              className={
                feedback.two === false
                  ? "section6__form_label1"
                  : "section6__form_label2"
              }
              onClick={(e) => {
                setFeedback({
                  ...feedback,
                  two: !feedback.two,
                });
              }}
            >
              <label>{t("section6.optiune2")}</label>
            </div>

            <div
              className={
                feedback.three === false
                  ? "section6__form_label1"
                  : "section6__form_label2"
              }
              onClick={(e) => {
                setFeedback({
                  ...feedback,
                  three: !feedback.three,
                });
              }}
            >
              <label>{t("section6.optiune3")}</label>
            </div>

            <div
              className={
                feedback.four === false
                  ? "section6__form_label1"
                  : "section6__form_label2"
              }
              onClick={(e) => {
                setFeedback({
                  ...feedback,
                  four: !feedback.four,
                });
              }}
            >
              <label>{t("section6.optiune4")}</label>
            </div>
            <br />
            <div
              className={
                feedback.five === false
                  ? "section6__form_label1"
                  : "section6__form_label2"
              }
              onClick={(e) => {
                setFeedback({
                  ...feedback,
                  five: !feedback.five,
                });
              }}
            >
              <label>{t("section6.optiune5")}</label>
            </div>
            <div
              className={
                feedback.five === false
                  ? "section6__form_input1"
                  : "section6__form_input2"
              }
            >
              <input
                type="text"
                placeholder={t("section6.optiune6")}
                value={feedback.text}
                onChange={(event) =>
                  setFeedback({
                    ...feedback,
                    text: event.target.value,
                  })
                }
              />
            </div>
            <div>{message ? t("section6.mesaj") : null}</div>
            <div className="section6_button">
              <div className="image-button">
                <img
                  src={TrimiteRaspunsul_btn}
                  alt=""
                  className="image-with-hover-cursor"
                  onClick={handleSendEmail}
                  style={{
                    width: "202px",
                    height: "auto",
                  }}
                />

                <div
                  className="button-text image-with-hover-cursor"
                  onClick={handleSendEmail}
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  {t("section6.button")}
                </div>
              </div>
            </div>
          </Col>

          <Col lg="5" md="5" className="section6__content">
            <div>
              <img src={Feedback} alt="" className="Feedback" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section6;
