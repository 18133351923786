import React, { useState, useEffect } from "react";

import { useLanguage } from "../utils/utils.jsx";

function Email({ formPers, setFormPers }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="nameTitle">{t("typeForm.email.title")}</p>
      <p className="nameTitle">{t("typeForm.email.title1")}</p>
      <p className="namesubTitle"></p>
      <input
        type="email"
        name="email"
        value={formPers.email}
        onChange={(event) =>
          setFormPers({
            ...formPers,
            email: event.target.value,
          })
        }
        placeholder="email@address.com"
        className="snfluaNameInput"
      />
    </div>
  );
}

export default Email;
