import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Allianz from "../../../assests/images/ensure/Allianz.svg";
import Grawe from "../../../assests/images/ensure/Grawe.svg";
import Generalli from "../../../assests/images/ensure/Generalli.svg";
import Asirom from "../../../assests/images/ensure/asirom.png";
import Uniqa from "../../../assests/images/ensure/Uniqa.svg";
import Omniasig from "../../../assests/images/ensure/Omniasig.svg";
import Euroins from "../../../assests/images/ensure/Euroins.svg";
import Groupama from "../../../assests/images/ensure/Groupama.svg";

import Axeria from "../../../assests/images/ensure/Axeria.svg";
import Ergo from "../../../assests/images/ensure/Ergo.svg";
import Eurolife from "../../../assests/images/ensure/Eurolife.svg";
import Gothaer from "../../../assests/images/ensure/Gothaer.svg";

import Mondial from "../../../assests/images/ensure/mondial.png";

import asiguratori from "../../../assests/images/RCA/asiguratori.png";

import "./Section4.css";

import { useLanguage } from "../../utils/utils.jsx";

const Section4 = () => {
  const { t, i18n } = useLanguage();

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section className="section4">
      <Container>
        <Row>
          <Col lg="8" md="7" sm="12" xs="12">
            <div className="section4__title">
              <p>{t("section4.title")}</p>
            </div>
            {screenSize > 1000 ? (
              <div className="section4__subtitle1">
                <p>
                  {t("section4.description")}
                  <br /> {t("section4.description1")}
                </p>
              </div>
            ) : (
              <div className="section4__subtitle1">
                <p>
                  {t("section4.description")}
                  {t("section4.description1")}
                </p>
              </div>
            )}
            {screenSize > 1000 ? (
              <div className="section4__subtitle2">
                <p>
                  {t("section4.description2")}
                  <br /> {t("section4.description3")}
                </p>
              </div>
            ) : (
              <div className="section4__subtitle2">
                <p>
                  {t("section4.description2")}
                  {t("section4.description3")}
                </p>
              </div>
            )}
          </Col>

          <Col lg="4" md="5" sm="12" xs="12">
            {screenSize > 750 ? (
              <img src={asiguratori} alt="" className="section4__asiguratori" />
            ) : (
              <Slider {...settings} className="section4__slider">
                <div>
                  <img className="" src={Allianz} alt="" />
                </div>
                <div>
                  <img className="" src={Grawe} alt="" />
                </div>
                <div>
                  <img className="" src={Mondial} alt="" />
                </div>
                <div>
                  <img className="" src={Generalli} alt="" />
                </div>
                <div>
                  <img className="" src={Asirom} alt="" />
                </div>
                <div>
                  <img className="" src={Eurolife} alt="" />
                </div>
                <div>
                  <img className="" src={Uniqa} alt="" />
                </div>
                <div>
                  <img className="" src={Omniasig} alt="" />
                </div>
                <div>
                  <img className="" src={Axeria} alt="" />
                </div>
                <div>
                  <img className="" src={Groupama} alt="" />
                </div>
                <div>
                  <img className="" src={Gothaer} alt="" />
                </div>
              </Slider>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Section4;
