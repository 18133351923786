import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

import "./HomeProtectNew.css";

import DocumentdeInformarePDF from "../../assests/images/HomeProtect/DocumentdeInformare_LoculTau.CasaTa.pdf";
import TermenisiConditiiPDF from "../../assests/images/HomeProtect/TermenisiConditii_LoculTTau.CasaTa.pdf";

import { useLanguage } from "../utils/utils.jsx";

import start from "../../assests/images/HomeProtect/inceput.png";
import contact from "../../assests/images/HomeProtect/contact.svg";

import casa from "../../assests/images/HomeProtect/Casa-Apartament.svg";
import bunuri from "../../assests/images/HomeProtect/bunuri.svg";
import portabile from "../../assests/images/HomeProtect/portabile.svg";
import inundatie from "../../assests/images/HomeProtect/Avarii.svg";

function Intro({ page, setPage }) {
  useEffect(() => {
    document.title = "Senflua - HomeProtectNew";
  }, []);

  const { t, i18n } = useLanguage();
  const navigate = useNavigate();

  const handleDownload = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  const handleStartClick = () => {
    window.scrollTo(0, 0);
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <section className="sectionLocuinte">
      <Container className="containerLocuinte">
        <Row>
          <Col xl="1" lg="1" md="0" sm="0"></Col>
          <Col xl="7" lg="7" md="8" sm="12">
            <div className="colLocuinteLeft">
              <p className="locuinteTitle">{t("homeProtect.info.title")}</p>
              <p className="locuinteText">{t("homeProtect.info.subtitle")}</p>
              <p className="locuinteSubTitle">
                {t("homeProtect.info.titleasig")}{" "}
                <b> {t("homeProtect.info.titleloccasa")}</b>
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  style={{
                    width: "85px",
                    height: "auto",
                    marginRight: "20px",
                  }}
                  src={casa}
                  alt=""
                />
                <div>
                  <b>{t("homeProtect.info.titleLocuinta")} </b>
                  <span style={{ display: "block" }} className="locuinteText">
                    {t("homeProtect.info.descriptionLocuinta")}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <img
                  style={{
                    width: "85px",
                    height: "auto",
                    marginRight: "20px",
                  }}
                  src={bunuri}
                  alt=""
                />
                <div>
                  <b>{t("homeProtect.info.titleBunuri")} </b>
                  <span style={{ display: "block" }} className="locuinteText">
                    {t("homeProtect.info.descriptionBunuri")}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <img
                  style={{
                    width: "85px",
                    height: "auto",
                    marginRight: "20px",
                  }}
                  src={portabile}
                  alt=""
                />
                <div>
                  <b>{t("homeProtect.info.titleEchipamente")} </b>
                  <span style={{ display: "block" }} className="locuinteText">
                    {t("homeProtect.info.descriptionEchipamente")}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <img
                  style={{
                    width: "85px",
                    height: "auto",
                    marginRight: "20px",
                  }}
                  src={inundatie}
                  alt=""
                />
                <div>
                  <b>{t("homeProtect.info.titleInstalatii")} </b>
                  <span style={{ display: "block" }} className="locuinteText">
                    {t("homeProtect.info.descriptionInstalatii")}
                  </span>
                </div>
              </div>

              <div
                className="locuinteBtnStart image-button"
                style={{
                  marginTop: "30px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={start}
                  alt=""
                  className="image-with-hover-cursor"
                  style={{
                    width: "347px",
                    height: "51px",
                  }}
                  onClick={handleStartClick}
                />
                <div
                  className="button-text image-with-hover-cursor"
                  style={{
                    marginLeft: "5px",
                    marginTop: "10px",
                  }}
                  onClick={handleStartClick}
                >
                  {t("homeProtect.info.button")}
                </div>
              </div>
            </div>
          </Col>
          <Col xl="3" lg="3" md="4" sm="12">
            <div className="colLocuinteRight">
              <p
                className="locuinteTitle"
                style={{
                  color: "#6689FF",
                }}
              >
                {t("homeProtect.info.titleInformare")}
              </p>
              <label className="locuinteText">
                {t("homeProtect.info.textInfo1")}
              </label>
              <div>
                <label className="locuinteTextDr">
                  √ {t("homeProtect.info.textInfo2")}
                </label>
              </div>
              <label className="locuinteTextDr">
                √ {t("homeProtect.info.textInfo3")}
              </label>
              <label
                className="locuinteText"
                style={{
                  marginTop: "10px",
                }}
              >
                {t("homeProtect.info.textInfo4")}
              </label>
              <div>
                <button
                  className="buttonDownloadeLocuinteIntro"
                  onClick={() =>
                    handleDownload(
                      DocumentdeInformarePDF,
                      "DocumentdeInformare_LoculTau.CasaTa.pdf"
                    )
                  }
                >
                  √ {t("homeProtect.downloadeInfo.button1")}
                </button>
              </div>

              <button
                className="buttonDownloadeLocuinteIntro"
                onClick={() =>
                  handleDownload(
                    TermenisiConditiiPDF,
                    "TermenisiConditii_LoculTTau.CasaTa.pdf"
                  )
                }
              >
                √ {t("homeProtect.downloadeInfo.button2")}
              </button>
            </div>
            <div className="contactLocuinte">
              <img src={contact} alt="" />
            </div>
          </Col>
          <Col xl="1" lg="1" md="0" sm="0"></Col>
        </Row>
      </Container>
    </section>
  );
}

export default Intro;
