import React, { useState, useEffect } from "react";

import Copilul from "../../assests/images/SenfluaForm/Copilul.svg";
import PeMine from "../../assests/images/SenfluaForm/PeMine.svg";
import Partenerul from "../../assests/images/SenfluaForm/Partenerul.svg";

import { useLanguage } from "../utils/utils.jsx";

function PeCineAsiguri({ formAig, setFormAig }) {
  const { t, i18n } = useLanguage();

  return (
    <div className="nameSection">
      <p className="numeTileTip" style={{ marginTop: "20px" }}>
        #senflua.LIFE
      </p>
      <p className="nameTitle2">{t("typeForm.peCineAsiguri.title")}</p>
      <p className="namesubTitle">{t("typeForm.peCineAsiguri.subtitle")}</p>
      <div>
        <label
          className={
            formAig.senfluaLife.pemine
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                pemine: !formAig.senfluaLife.pemine,
              },
            }));
          }}
        >
          <img src={PeMine} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.peCineAsiguri.v1")}
          <spam style={{ color: "white" }}> .</spam>
        </label>

        <label
          className={
            formAig.senfluaLife.pertener
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                pertener: !formAig.senfluaLife.pertener,
              },
            }));
          }}
        >
          <img src={Partenerul} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.peCineAsiguri.v2")}
          <spam style={{ font: "10px Alegreya" }}> (membru al familiei) </spam>
        </label>

        <label
          className={
            formAig.senfluaLife.copil
              ? "senfluaFormTipAsigLife_active"
              : "senfluaFormTipAsigLife"
          }
          onClick={(event) => {
            setFormAig((prevState) => ({
              ...prevState,
              senfluaLife: {
                ...prevState.senfluaLife,
                copil: !formAig.senfluaLife.copil,
              },
            }));
          }}
        >
          <img src={Copilul} className="senfluaFormTipAsigImgLife" />
          {t("typeForm.peCineAsiguri.v3")}
          <spam style={{ color: "white" }}> .</spam>
        </label>
      </div>
    </div>
  );
}

export default PeCineAsiguri;
