import React, { Fragment } from "react";
import HeaderTravel from "../HeaderTravel/HeaderTravel";
import Travel_buy_Eurolife_New from "../Travel_buy_Eurolife_New/Travel_buy_Eurolife_New";
import FooterTravel from "../FooterTravel/FooterTravel";
import TabBar from "../TabBar/TabBar";

const NewTravel = () => {
  return (
    <Fragment>
      <TabBar />
      <HeaderTravel />
      <Travel_buy_Eurolife_New />
      <FooterTravel />
    </Fragment>
  );
};

export default NewTravel;
