import React from "react";

const fuelType = [
  { key: "nimic", nume: "" },
  { key: "benzina", nume: "Benzina" },
  { key: "motorina", nume: "Motorina" },
  { key: "electric", nume: "Electric" },
  { key: "benzina si gpl", nume: "Benzina si gpl" },
  { key: "benzina si alcool", nume: "Benzina si alcool" },
  { key: "hybrid_benzina", nume: "Hybrid benzina" },
  { key: "hybrid_motorina", nume: "Hybrid motorina" },
  { key: "fara", nume: "Fara" },
  { key: "altul", nume: "Altul" },
];

function ListCombustibil({ formData, setFormData }) {
  function handleCombustibilChange(event) {
    const selectedText = event.target.value;

    setFormData({
      ...formData,
      combustibil: selectedText,
    });
  }

  const options = fuelType.map(({ key, nume }) => (
    <option key={key} value={key}>
      {nume}
    </option>
  ));

  return (
    <select value={formData.combustibil} onChange={handleCombustibilChange}>
      {options}
    </select>
  );
}

export default ListCombustibil;
